import MyClassroomsView from "../MyClassroomsView.vue";
import MyClassroomsPage from "../Pages/MyClassroomsPage.vue";
import NotificationsPage from "../Pages/NotificationsPage.vue";
import CourseLibraryPage from "../Pages/CourseLibraryPage.vue";

const routes = [
  {
    path: '/',
    component: MyClassroomsView,
    children: [
      {
        path: '',
        name: 'MyClassroomsPage',
        meta: { authRequired: true },
        component: MyClassroomsPage,
      },
      {
        path: '/notifications',
        name: 'NotificationsPage',
        meta: { authRequired: true },
        component: NotificationsPage,
      },
      {
        path: 'course-library',
        name: 'CourseLibraryPage',
        meta: { authRequired: true },
        component: CourseLibraryPage,
      },
    ]
  }
]

export default routes
