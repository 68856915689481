<template>
  <div class="form-group">
    <b-form-datepicker
      :placeholder="placeholder"
      v-model="selectedDate"
      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
      @input="onInput"
      :class="customClass"
      :min="min" :max="max"
    >
      <template v-slot:button-content>
        <span v-if="deleteButton" class="icon-delete" @click.self="$emit('input', '')"/>
        <i class="icon-calendar"></i>
      </template>
    </b-form-datepicker>

    <b-form-invalid-feedback :id="`input-live-feedback-${_uid}`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`input-datepicker-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  data: function () {
    return {}
  },
  props: {
    value: {
      type: [Number, String],
    },
    min: {
      type: Date,
    },
    max: {
      type: Date,
    },
    classDatePicker: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    deleteButton: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
  },
  computed: {
    selectedDate: {
      get: function () {
        return this.value ?? ''
      },
      set: function () {
      }
    },
    customClass() {
      const className = {}

      className[this.classDatePicker] = !!this.classDatePicker
      className['is-invalid'] = this.state === false

      return {
        ...className
      }
    },
    /**
     * false (denotes invalid state) is great for when there's a blocking or required field. A user must fill in this field properly to submit the form.
     * true (denotes valid state) is ideal for situations when you have per-field validation throughout a form and want to encourage a user through the rest of the fields.
     * null Displays no validation state (neither valid nor invalid)
     * @returns {boolean}
     */
    state() {
      return !this.errors.length ? null : false
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.b-form-datepicker
  > button
    padding 0

    .icon-calendar, .icon-delete
      padding 7px 20px
      max-height none
      position absolute
      right 0
      top 0
      height 100%

      &:before
        color color-3
        vertical-align middle
        text-align center

    .icon-delete
      right 30px

  ::v-deep
    .dropdown-menu
      .b-form-date-calendar
        .b-calendar-inner
          .form-control
            border  none
            max-height none

::v-deep
  footer
  output
    display none

  button
    padding 0

  .no-gutters
  .no-gutters > .col
  .no-gutters > [class*=col-]
    padding-right 0 !important
    padding-left 0 !important
    margin 0 !important

  label.form-control
    padding 7px 20px !important


</style>
