<template>
  <div class="tab-container-task-create">
    <b-overlay :show="sendingData" no-wrap fixed>
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">Please wait...</p>
          <b-spinner label="Spinning"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <div class="task-create-back">
      <a href="javascript:void(0)" @click="onBack"><span class="icon-caret-left"></span> Back</a>
    </div>
    <hr>
    <div v-if="task" class="task-create-header">
      <h3>EDIT TASK</h3>
      <span class="required-fields">Required field *</span>
    </div>
    <div v-else class="task-create-header">
      <h3>CREATE NEW TASK</h3>
      <span class="required-fields">Required field *</span>
    </div>
    <div class="task-create-body">
      <form action="">
        <upload-file
          @File:loaded="onFileLoaded"
          @error="onFileLoadedError"
          v-model="form.task_thumbnail"
          :errors="errors.task_thumbnail"
          :currentFile="form.task_thumbnail"
          :maxSize="262144000"
          acceptTypes=".png,.jpg,.jpeg,.gif"
          place-holder="Task Thumbnail *"
        ></upload-file>

        <custom-input custom-class="form-control-task" v-model="form.task_name" :errors="errors.task_name"
                      label="Task Name *" :maxlength="254"/>
        <custom-input custom-class="form-control-task" v-model="form.topic"
                      label="Topic" :maxlength="254"/>

        <label for="tags-limit">Tags (Up To 5 Tags)</label>
        <b-form-tags input-id="tags-limit" v-model="form.tags" :limit="limitTags" remove-on-delete
                     :tag-validator="tagValidator" :state="state"></b-form-tags>
        <hr>
        <custom-textarea custom-class="form-control-task" v-model="form.teacher_instructions" maxlength="20000"
                         label="TEACHER INSTRUCTIONS" rich-text/>
        <custom-textarea custom-class="form-control-task" v-model="form.can_do_statement" maxlength="20000"
                         label="LEARNING OBJECTIVES" rich-text/>

        <b-form-checkbox class="custom-checkbox-primary checkbox-reflection-task"
                         v-model="form.display_students"
                         v-if="isActivityOrReflectionTask">
          Display to Students
        </b-form-checkbox>
        <custom-textarea custom-class="form-control-task"
                        v-model="form.create_prompt"
                        maxlength="20000"
                        :label="`STUDENT PROMPT (${studentPromptLabel})`"
                        rich-text/>
        <h3>STUDENT PROMPT DUE DATE</h3>
        <date-picker class-date-picker="form-datepicker-task"
                     v-model="form.due_date"
                     :delete-button="!!form.due_date"
                     :min="minStartDate"/>
        <custom-textarea custom-class="form-control-task"
                        v-model="form.respond_prompt"
                        maxlength="20000"
                        v-if="!isActivityOrReflectionTask && !isIntroductionTask"
                        :label="`RESPONSE PROMPT (${responsePromptLabel})`"
                        rich-text/>
        <section v-if="!isActivityOrReflectionTask && !isIntroductionTask">
          <h3>RESPOND PROMPT DUE DATE</h3>
          <date-picker class-date-picker="form-datepicker-task"
                       v-model="form.response_due_date"
                       :delete-button="!!form.response_due_date"
                       :min="minStartDate"/>
        </section>

        <upload-file-attach-group v-model="form.materials" @Attachment:delete="removeMaterial"
                                  placeholder="Student Support Materials" thumbnail></upload-file-attach-group>
        <upload-file-attach-group v-model="form.links" @Attachment:delete="removeLink" only-attach
                                  placeholder="Additional Links"></upload-file-attach-group>
        <upload-file-attach-group v-model="form.media" @Attachment:delete="removeMedia"
                                  placeholder="Media" thumbnail @click-video="showVideoPlayer"/>
        <hr>

        <div v-if="!isActivityOrReflectionTask && !isIntroductionTask" class="form-group">
          <h4 class="form-group-title">Task Responses</h4>
          <custom-radio v-model="form.response_type" :options="response_types" custom-class="common-input"/>
        </div>
        <div class="form-group" v-if="!isActivityOrReflectionTask && !isIntroductionTask">
          <h4 class="form-group-title">Number of Responses</h4>
          <b-form-spinbutton class="form-control-spin-button" min="1" max="100"
                             v-model="form.number_responses"></b-form-spinbutton>
        </div>
        <div class="form-group form-group--time-limit" v-if="!isActivityOrReflectionTask">
          <h4 class="form-group-title">Time Limit On Video Post</h4>
          <div class="container-time-limit">
            <custom-input type="number"
                          custom-class="form-control-task form-control-task-time custom-input-limit"
                          v-model="form.video_time_limit"
                          @input="validateTimeLimit"
                          :min="1"
                          :max="maxVideoTimeLimit"
                          :errors="errorTimeLimit"/>
            <span class="seconds-label">Seconds</span>
          </div>
        </div>
        <div class="form-group">
          <h4 class="form-group-title">Visible</h4>
          <div class="container-custom-switch">
            <p class="container-custom-switch__label">Hide</p>
            <custom-switch v-model="form.is_hidden" @input="onInputSwitch"/>
            <p class="container-custom-switch__label container-custom-switch__label--show">Show</p>
          </div>
          <p class="alert-label">(Students and teachers in both classrooms will {{form.is_hidden ? 'NOT' : ''}} see this task.)</p>
        </div>
        <div class="form-group form-group-buttons">
          <b-button @click="onBack" btn-text="Cancel" style="margin-right: 10px"/>
          <b-button v-if="task" @click="saveData" btn-text="Save"/>
          <b-button v-else @click="saveData" btn-text="Create"/>
        </div>
      </form>
      <video-player-popup :src="attachmentVideoSelect.url" :label="attachmentVideoSelect.label"/>
    </div>
  </div>
</template>

<script>
import UploadFile from "@/core/components/UploadFileAttachLink/UploadFile";
import Input from "@/core/components/FormInput/Input";
import Textarea from "@/core/components/FormInput/Textarea";
import DatePicker from "@/core/components/DatePicker/DatePicker";
import {Button} from "@/core/components/Button";
import UploadFileAttachGroup from "@/core/components/UploadFileAttachLink/UploadFileAttachGroup";
import {TasksMixins} from "@/modules/Classroom/Mixins";
import Radio from "@/core/components/FormInput/Radio";
import {UploadService} from "@/core/Services";
import CustomSwitch from '@/core/components/CustomSwitch/CustomSwitch';
import {TaskMixins} from "@/modules/MyClassrooms/Mixins"
import videoPlayerPopup from '@/core/components/videoPlayerPopup/videoPlayerPopup.vue';
import {taskCategories} from "@/core/Utils"

export default {
  name: "ClassroomDetailTabMyTasksCreate",
  mixins: [TasksMixins, TaskMixins],
  components: {
    UploadFileAttachGroup,
    UploadFile,
    'custom-input': Input,
    'custom-textarea': Textarea,
    'b-button': Button,
    DatePicker,
    'custom-radio': Radio,
    CustomSwitch,
    videoPlayerPopup
  },
  data() {
    return {
      form: {
        "class_pairing_id": null,
        "course_task_id": null,
        "task_name": null,
        "task_thumbnail": null,
        "topic": null,
        "description": null,
        "create_prompt": null,
        "essential_question": null,
        "teacher_instructions": null,
        "response_type": 1,
        "video_time_limit": 120,
        "number_responses": 3,
        "order": null,
        "due_date": null,
        "response_due_date": null,
        "is_custom_task": null,
        "is_hidden": false,
        "created_at": null,
        "updated_at": null,
        "can_do_statement": null,
        "teacher_description": null,
        "respond_prompt": null,
        "media": [],
        "links": [],
        "materials": [],
        "tags": [],
        "display_students": true
      },
      validations: {
        task_thumbnail: {
          validate: 'required',
        },
        task_name: {
          validate: 'required',
        },
        video_time_limit: {
          validate: 'required|number'
        }
      },
      dirty: false,
      response_types: [
        {id: 1, name: 'Video Response'},
        {id: 2, name: 'Text Response'}
      ],
      task_thumbnail_file: null,
      sendingData: false,
      minStartDate: new Date(),
      maxLengthTag: 31,
      limitTags: 5,
      errorTimeLimit: [],
      attachmentVideoSelect: {},
      maxVideoTimeLimit: 300
    }
  },
  props: {
    task: [Array, Object],
  },
  mounted() {
    this.form.video_time_limit = parseInt(this.form.video_time_limit)

    if (this.task) {
      this.form = this.task;
      this.form.display_students = !!this.task.display_students
      if (this.task.tags) {
        this.form.tags = this.task.tags.map(a => a.name);
      }
    }
  },
  methods: {
    showVideoPlayer(attachment) {
      this.attachmentVideoSelect = attachment
      this.$bvModal.show('video-player-popup')
    },
    onInputSwitch () {
      const message = `This task will be ${this.form.is_hidden ? 'hidden from' : 'visible to'} all students in both classrooms in this exchange. Would you like to continue?`

      this.$bvModal.msgBoxConfirm(message, {
        size: 'sm',
        dialogClass: "modal-message-box purchase-center",
        centered: true,
        cancelTitle: 'Cancel',
      }).then((res) => {
        if (!res) {
          this.form.is_hidden = !this.form.is_hidden
        }
      })
    },
    removeMaterial(item) {
      this.form.materials.splice(this.form.materials.indexOf(item), 1);
    },
    removeLink(item) {
      this.form.links.splice(this.form.links.indexOf(item), 1);
    },
    removeMedia(item) {
      this.form.media.splice(this.form.media.indexOf(item), 1);
    },
    validImageType(file) {
      const fileTypes = [
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
        "image/x-icon"
      ];
      return fileTypes.includes(file.type);
    },
    validateTimeLimit(value) {
      this.errorTimeLimit = []
      if (value <= 0) {
        this.errorTimeLimit.push('Invalid Value must be greater than or equal to 1')
      }
      if (value > this.maxVideoTimeLimit) {
        this.errorTimeLimit.push(`Invalid Value must be less than or equal to ${this.maxVideoTimeLimit}`)
      }
    },
    onFileLoaded(files) {
      if (files.length > 0) {
        const file = files[0];
        this.errors.task_thumbnail = []
        if (!this.validImageType(file)) {
          this.errors.task_thumbnail.push('File must be png, jpg, jpeg or gif.')
          return
        }

        this.task_thumbnail_file = {
          file: file,
          index_file: 0,
          type_file: 'task_thumbnail'
        }

        this.form.task_thumbnail = URL.createObjectURL(file)
      }
    },
    onFileLoadedError(e) {
      if (e.code==='max_size_exceeded') {
        this.errors.task_thumbnail.push(e.message)
      }
    },
    onBack() {
      this.$emit('on-change-component', 'ClassroomDetailTabMyTasksList')
    },
    getFilesToUpload() {
      let files = []

      for (let i = 0; i < this.form['materials'].length; i++) {
        if (this.form['materials'][i].file) {
          files.push({
            file: this.form['materials'][i].file,
            index_file: i,
            type_file: 'materials'
          })
        }
      }

      for (let i = 0; i < this.form['media'].length; i++) {
        if (this.form['media'][i].file) {
          files.push({
            file: this.form['media'][i].file,
            index_file: i,
            type_file: 'media'
          })
        }
      }

      if (this.task_thumbnail_file) {
        files.push(this.task_thumbnail_file)
      }

      return files
    },
    async saveData() {
      if (!this.isValidForm()) {
        return
      }
      if (this.errorTimeLimit.length) return

      if (!this.sendingData) {
        this.sendingData = true
        try {
          const files = this.getFilesToUpload()
          if (files.length > 0) {
            const result = await UploadService.uploadMultipleFiles(files)
            for (let i = 0; i < result.length; i++) {

              let index_file = result[i].index_file,
                type_file = result[i].type_file

              if (type_file === 'task_thumbnail') {
                this.form.task_thumbnail = result[i].name
              } else {
                this.form[type_file][index_file].url = result[i].name
                this.form[type_file][index_file].type = 'file'
                this.form[type_file][index_file].original_file = result[i].originalFile
              }
            }
          }
          await this.sendForm()
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error while submitting form.'
          await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        } finally {
          this.sendingData = false
        }
      }
    },
    async sendForm() {
      this.sendingData = true
      this.form.due_date = this.form.due_date ? (this.form.due_date.split(' '))[0] : null
      this.form.response_due_date = this.form.response_due_date ? (this.form.response_due_date.split(' '))[0] : null

      if (this.task) {
        const response = await this.updateTask({
          classroom_id: this.classroom.id,
          task_id: this.task.id,
          form: this.form
        })
        const taskStatus = {
          isCreate: this.task.isCreate,
          isCreateTaskCompleted: this.task.isCreateTaskCompleted,
          isPartnerCreateTaskCompleted: this.task.isPartnerCreateTaskCompleted,
          isResponseTaskCompleted: this.task.isResponseTaskCompleted,
          isPartnerResponseTaskCompleted: this.task.isPartnerResponseTaskCompleted,
        }
        this.$emit('on-change-component', 'ClassroomDetailTabMyTaskDetail', {...response.data, ...taskStatus})
      } else {
        await this.createTask({classroom_id: this.classroom.id, form: this.form})
        this.$emit('on-change-component', 'ClassroomDetailTabMyTasksList')
      }
    },
    tagValidator(tag) {
      if (tag) {
        return tag.length < this.maxLengthTag
      }
    }
  },
  computed: {
    state() {
      if (this.form.tags?.length > 0) {
        return this.dirty ? this.form.tags?.length <= this.limitTags ? null : false : null
      } else {
        return null
      }
    },
    isActivityOrReflectionTask () {
      return this.task?.task_category === taskCategories.activity || this.task?.task_category === taskCategories.reflection
    },
    isIntroductionTask() {
      return this.task?.task_category === taskCategories.introduction
    }
  },
  watch: {
    form(newValue, oldValue) {
      if (newValue.tags !== oldValue.tags) {
        this.dirty = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.tab-container-task-create
  padding-left 10px
  padding-right 10px
  padding-bottom 99px
  padding-top 13px

.task-create-header
  text-align center
  margin-top 40px
  margin-bottom 40px

  h3
    color color-8
    font font-opensans-bold
    font-size 24px
    margin-bottom 0

.task-create-body
  padding 0

  .form-group-buttons
    display flex
    justify-content center

  .form-group-title, h3
    font font-opensans-regular
    font-size 1rem
    color color-8
    letter-spacing 0.16px

  .form-control-spin-button
    border-color transparent
    width 30%
    padding 0
    height unset
    background-color transparent

    ::v-deep
      .btn
        border 2px solid color-3 !important
        border-radius 50% !important
        padding 10px 0

        svg
          fill color-3

      output
        border 0 !important
  .container-time-limit
    display flex
    position relative

    .seconds-label
      position absolute
      left 90px
      top 8px

    ::v-deep
      .form-group
        margin-bottom 0

  ::v-deep
    .b-form-tags
    .form-datepicker-task
      border-color color-16
      background-color color-20
      width 100%

    .form-control-task
      border-color color-16
      padding 7px 20px
      max-height unset
      height unset
      background-color color-20

      placeholder-styles(@block{
        font font-opensans-regular
        font-size 14px
        color color-8
      })

      &-time
        padding 7px 10px
        width 60px

    .custom-input-limit
      width 80px

.form-group--time-limit
  margin-bottom 0

.container-custom-switch
  display flex
  padding-top 4px

  &__label
    margin-right 15px
    font-size 14px

  &__label--show
    margin-left 15px

.alert-label
  font-size 14px

@media (min-width: 768px)
  .task-create-body
    .form-control-spin-button
      width 17%


@media (min-width 992px)
  .task-create-body
    padding-left 100px
    padding-right 100px

@media (min-width: 1200px)
  .task-create-body
    padding-left 175px
    padding-right 175px

    .form-control-spin-button
      width 20%

@media (min-width: 1400px)
  .task-create-body
    padding-left 250px
    padding-right 250px


hr
  margin 0.8em 0

.task-create-back
  a
    font font-opensans-bold
    font-size 17px
    cursor pointer
    text-decoration none
    color color-3
    display flex
    align-items center

    span
      font-size 0.7em
      padding-right 5px

      &:before
        color color-3

.checkbox-reflection-task
  margin 0.7rem 0 1.4rem 0

  ::v-deep
    label
      font font-opensans-regular
      font-size 1rem
      color color-8 !important
      letter-spacing 0.16px

::v-deep
  .modal-dialog
    margin auto

  .modal-message-box .modal-content
    align-items center
    padding 5px

    .modal-body
      margin-bottom 0
      text-align center

      &::first-letter
        text-transform uppercase

    .modal-footer
      margin-bottom 12px

      .btn-primary
        background-color color-3
        border-color color-7
        font font-opensans-semibold
        border-radius 0.25rem

</style>
