<template>
  <div class="contact-support-container" :class="customClass">
    <a id="handle-contact-support">Contact Support</a>
    <b-popover target="handle-contact-support"
               id="popover-contact-support"
               placement="top">
        <div class="content-contact-support">
          <h2 class="subtitle center">Level Up Village Support</h2>
          <div class="contact-buttons-container" >
            <b-button @click="handleIntercom" btn-text="Chat with Support" v-if="false"/>
            <b-button href="mailto: support@levelupvillage.com" btn-text="Email Support"/>
          </div>
          <div class="end-chat-container" v-if="false">
            <b-button @click="handleIntercom" btn-text="END CHAT"/>
          </div>
        </div>
    </b-popover>
  </div>
</template>
<script>
import { Button } from "@/core/components/Button";

export default {
  name: "ContactSupport",
  components: {
    'b-button': Button
  },
  props: {
    customClass: {
      type: String
    }
  },
  data () {
    return {
      interval: null,
    }
  },
  methods: {
    handleIntercom () {
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "styles/footer.styl";
#handle-contact-support
  cursor pointer
  &:hover
    text-decoration underline

.content-contact-support
  .subtitle
    font-size 18px
    font font-opensans-bold
    margin-bottom 15px
    text-align center

  .contact-buttons-container
    display flex
    gap 10px
    @media(max-width 600px)
      flex-direction column

  .end-chat-container
    display flex
    justify-content center


.contact-support-container
  span
    margin-left 20px
    text-decoration underline
    cursor pointer
    display inline-block

</style>
<style lang="stylus">
#popover-contact-support
  min-width fit-content

</style>
