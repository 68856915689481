<template>
  <classroom-detail-tab-message v-if="message.status !== 'deleted'" :id="message.id"
                                :user-id="message.user_id"
                                :user-thumbnail="message.user.student.thumbnail"
                                :user-first-name="message.user.student.first_name"
                                :user-last-initial="message.user.student.last_initial"
                                :user-type="message.user.user_type.name"
                                :message-title="message.message_title"
                                :message-text="message.message_text"
                                :post-date="message.created_at"
                                :status="message.status"
                                :links="message.links"
                                :media="message.media"
                                :content-class="messageContentStudentClass">
    <template v-slot:messageActions>
      <div v-if="message.status === 'approved'" class="message-status">
        <span class="icon-status-approved"></span>
          {{message.status}}
        </div>
      <b-button v-if="message.status === 'pending'" @click="approveMessage" btn-text="Approve Message" small/>
      <b-button @click="deleteMessage" btn-text="Delete" small class="btn-delete"/>
    </template>
  </classroom-detail-tab-message>
</template>

<script>
import MessageBoardMixin from './ClassroomDetailTabMessageMixins'
import ClassroomDetailTabMessage from "./ClassroomDetailTabMessage";
import {Button} from "@/core/components/Button";

export default {
  name: "ClassroomDetailTabMessageStudent",
  components: {
    ClassroomDetailTabMessage,
    'b-button': Button,
  },
  mixins: [MessageBoardMixin],
  data() {
    return {
      highlightedComment: false
    }
  },
  mounted() {
    if (this.message.highlightedComment) {
      let comment_offset_top = this.$el.offsetTop
      this.highlightedComment = true
      window.scrollTo(0, comment_offset_top)
    }
  },
  methods: {
    approveMessage() {
      this.$emit('on-update-message-comment', {
        comment: this.message,
        status: 'approved'
      })},
    deleteMessage() {
      this.$emit('on-update-message-comment', {
        comment: this.message,
        status: 'deleted'
      })
    }
  },
  computed: {
    messageContentStudentClass() {
      return this.highlightedComment ? 'message-content-student message-highlighted':'message-content-student'
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.message-content-student
  ::v-deep
    & > .message-right
      .footer
        padding-top 20px

        button
          margin-bottom 10px
  .message-status
    width 100%
    text-transform capitalize
  .icon-status-approved
    color color-3
    margin-bottom 10px
    display inline-block

@media (min-width: 768px)
  .message-content-student
    ::v-deep
      & > .message-right
        .footer
          flex-direction row

          button
            margin-left 10px
            margin-bottom 0
</style>
