<template>
  <modal modal-class="flag-inappropriate-modal"
         :hide-footer="true"
         @hide="hideModal"
         id="flag-inappropriate-modal"
         ref="FlagInappropriateModal">
    <template #body>
      <h2 class="title title--modal">Flag partner Video as Inappropriate</h2>
      <p class="prompt-label">Please tell us why you have flagged this student video. *</p>
      <checkbox-group :options="options"
                      v-model="$v.optionsSelected.$model"
                      :validator="$v.optionsSelected"
                      variant-message-required="At least one reason must be selected"
                      custom-class="custom-checkbox-primary"/>
      <p class="informatoin-label">Your report will be sent for review and may be shared with your partner teacher, as needed</p>
      <div class="d-flex justify-content-around buttons">
        <b-button btn-text="CANCEL" @click="hideModal"></b-button>
        <b-button btn-text="SUBMIT" @click="handleSendInappropriateVideo"></b-button>
      </div>
    </template>
    <b-overlay no-wrap :show="loading"/>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { ClassroomService } from "@/core/Services";
import Modal from '../Modal/Modal.vue'
import CheckboxGroup from '../Form/CheckboxGroup.vue'
import Button from '../Button/Button.vue'

export default {
  name: 'FlagInappropriateModal',
  components: {
    CheckboxGroup,
    Modal,
    'b-button': Button
  },
  props: {
    classroomId: {
      type: [Number, String]
    },
    submissionId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      options: [
        { text: 'Inappropriate or offensive language.', value: 'language' },
        { text: 'Inappropriate or offensive behavior.', value: 'behavior' },
        { text: 'Student shares last name or social media handles.', value: 'doxing' },
        { text: 'Bullying.', value: 'bullying' },
        { text: 'Other.', value: 'other' }
      ],
      optionsSelected: [],
      loading: false
    }
  },
  validations: {
    optionsSelected: {
      required
    }
  },
  methods: {
    showModal () {
      this.$refs['FlagInappropriateModal'].showModal()
      this.$v.optionsSelected.$reset()
    },
    hideModal () {
      this.optionsSelected = []
      this.$refs['FlagInappropriateModal'].hideModal()
    },
    handleSendInappropriateVideo () {
      this.$v.$touch()
      if (!this.$v.optionsSelected.$anyError) {
        this.loading = true
        const data = this.optionsSelected.reduce((a, v) => ({ ...a, [v]: true }), {})
        data.student_submission_id = this.submissionId
        data.classroom_id = Number(this.classroomId)
        ClassroomService.reportStudentVideo(data).then((res) => {
          if (res.success) {
            this.$bvModal.msgBoxOk('Thank you. We will contact you for any additional information needed or follow up.', {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            }).then(() => {
              this.hideModal()
              this.$emit('close-flag-inappropriate-modal')
            })
          } else {
            const errorMessage = res.message ? res.message : 'Error reporting.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            })
          }
        }).finally(() => (this.loading = false))
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.title--modal
  font font-opensans-bold
  text-align center
  font-size 24px
  margin-bottom 30px
  text-transform uppercase

.informatoin-label
  text-align center
  margin 30px 0
  margin-bottom 60px

.prompt-label
  margin-bottom 1rem

::v-deep
  .custom-checkbox-primary
    column-count 1 !important

    .custom-control-inline
      display flex

      .custom-control-label
        font-size 15px

</style>
<style lang="stylus">
.flag-inappropriate-modal
  .modal-body
    .body
      padding 3rem
      @media (max-width 400px)
        padding 1rem
</style>
