<template>
  <div class="container-detail-submission">
    <div class="profile">
      <div class="profile-left">
        <b-avatar :src="student_submission.student_thumbnail"></b-avatar>
      </div>
      <div class="profile-right">
        <div class="full-name">{{ studentFullName }}
        </div>
        <div class="username">{{ student_submission.student_username }}</div>
      </div>
    </div>
    <hr>
    <h3 v-if="!student_submission.video_processed" class="video-not-ready-status">The video is not ready yet</h3>
    <div class="attribute">
      <div class="attribute-left">
        <video-custom class="img-fluid"
                      ref="video-custom"
                      :src="selectedVideo.url"
                      @on-video-play="playVideo"
                      :video_processed="selectedVideo.video_processed"/>
        <flag-as-inappropiate v-if="!showActions"
                              @show-flag-inappropriate-modal="pauseVideo"
                              :classroom-id="classroom_id"
                              :submission-id="selectedVideo.id"/>
      </div>
      <div class="attribute-right">
        <div class="attribute-right-course">
          <h3 class="title">{{ student_submission.course_name }}</h3>
          <h3 class="title">{{ student_submission.task_name }}</h3>
          <div class="subtitle subtitle--prompt"
               v-if="student_submission.create_prompt">Prompt
            <span class="icon-dropdown"
                  v-b-toggle.collapse-prompt/>
          </div>
          <b-collapse class="description"
                      id="collapse-prompt">
                      <span v-html="student_submission.create_prompt"/>
          </b-collapse>
          <div class="posted">Posted On: {{ student_submission.created_at | dateMonthDayYearHour }}</div>
        </div>
        <div class="attribute-right-status">
          <h3 :class="student_submission.status" v-if="showActions">STATUS: {{ student_submission.status }}</h3>
          <div class="actions" id="actions-submission-detail">
            <select-dropdown v-if="actions.length && showActions"
                             v-model="action"
                             value-field="value"
                             :disabled="classroomExchangeExpired"
                             placeholder="Video Approval"
                             @input="applyAction"
                             :options="actions"/>
            <b-button @click="onNextSubmission"
                      class="action-submissions-button"
                      :disabled="disabledNextButton"
                      btn-text="Next">
                      <template #icon-after>
                        <b-icon icon="arrow-right"/>
                      </template>
            </b-button>
          </div>
          <div class="comment-container" v-if="showActions">
            <div class="comment-container__label">Optional comments for {{ studentFullName }}</div>
            <b-form-textarea
              id="comment-textarea"
              v-model="userComment"
              maxLength="500"
              rows="5"
              max-rows="5"/>
            <b-button @click="saveComment"
                      :disabled="disableSaveCommentButton"
                      class="save-button"
                      btn-text="Save"/>
          </div>
        </div>
      </div>
    </div>
    <b-overlay no-wrap :show="loading || loadingComment"></b-overlay>
  </div>
</template>

<script>
import {SelectDropdown} from "@/core/components/SelectDropdown";
import VideoCustom from "@/core/components/Video/VideoCustom";
import {Button} from "@/core/components/Button";
import {VideoCommentsMixins} from "@/modules/Classroom/Mixins";
import {CourseMixins} from "@/modules/Classroom/Mixins"
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"
import {FlagAsInappropiate} from '@/core/components/FlagAsInappropiate'
import {actionOptions} from "./ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabSubmissions/constantsTabSubmissions"
import {TrackingActivityService} from '@/core/Services'
import {TrackingTypes} from "@/core/Utils"

export default {
  name: "StudentSubmissionModal",
  mixins: [VideoCommentsMixins, CourseMixins, ExchangesMixins],
  components: {
    'select-dropdown': SelectDropdown,
    'video-custom': VideoCustom,
    'b-button': Button,
    FlagAsInappropiate
  },
  data() {
    return {
      action: '',
      actions: actionOptions,
      userComment: '',
      loadingComment: false
    }
  },
  props: {
    selectedVideo: Object,
    classroom_id: Number,
    loading: Boolean,
    disabledNextButton: Boolean,
    showActions: {
      type: Boolean,
      default: true
    },
    isSubmissionTab: {
      Type: Number,
      default: false
    }
  },
  mounted() {
    this.userComment = this.selectedVideo.teacher_comment ? this.selectedVideo.teacher_comment : ''
  },
  methods: {
    applyAction() {
      this.$emit('applyAction', {action: this.action, videos: [this.selectedVideo.id]})
    },
    async onNextSubmission() {
      const validationResponse = await this.validateSavedComment(false)
      if (validationResponse === null || validationResponse === true) {
        return
      }
      this.$emit('next-submission')
    },
    pauseVideo() {
      this.$refs['video-custom'].pauseVid()
    },
    playVideo() {
      if (this.isSubmissionTab) {
        TrackingActivityService.trackingActivityTeachers(TrackingTypes.watchSubmissionVideo)
      } else {
        TrackingActivityService.trackingActivityTeachers(TrackingTypes.watchStudentVideo)
      }
    }
  },
  computed: {
    student_submission() {
      return this.selectedVideo
    },
    studentFullName() {
      return this.student_submission.student_first_name + ' ' + (this.isIndividualGroupType ? this.student_submission.student_last_initial : '')
    }
  },
  watch: {
    selectedVideo () {
      this.action = ''
      this.userComment = this.selectedVideo.teacher_comment ? this.selectedVideo.teacher_comment : ''
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.video-not-ready-status
  font font-opensans-bold
  font-size 14px
  text-transform uppercase
  letter-spacing 0
  color #262626
  padding 10px 14px
  background: color-43 0% 0% no-repeat padding-box
  text-align center

.container-detail-submission
  padding-top 30px

  .profile
    display flex
    align-items center

    &-left
      width 80px

      ::v-deep
        .b-avatar
          width 80px
          height 80px


    &-right
      text-align left
      padding-left 25px
      font font-opensans-bold
      font-size 15px
      line-height 25px
      max-width 300px
      @media(max-width 800px)
        max-width 250px
      @media(max-width 400px)
        max-width 200px

      .username, .full-name
        overflow hidden
        text-overflow ellipsis
        text-transform capitalize

  .attribute
    display flex
    flex-direction column

    &-left
      padding-top 15px

      .video-overlay
        width 100%
        height 395px
        border-radius 4px

    &-right
      text-align left
      margin-top 35px

      &-course
        height: auto
        overflow-y auto
        margin-bottom 50px

        .title
          font font-opensans-bold
          font-size 20px
          text-transform uppercase
          word-break break-word

        .subtitle
          font-size 14px
          font font-opensans-semibold
          margin-bottom 0

        .subtitle--prompt
          margin-bottom 15px

          .icon-dropdown
            margin-left 5px
            transition 0.1s transform
            display inline-block
            position relative
            top 3px

            &:before
              color color-3
              font-size 25px

          .collapsed
            transform rotate(90deg)
            transition 0.1s transform

        .posted
        .tags
        .description
          font font-opensans-regular
          font-size 12px
          word-break break-word

        .description
        .tags
          margin-bottom 15px


      &-status
        h3
          font font-opensans-bold
          font-size 14px
          text-transform uppercase
          text-align left
          letter-spacing 0px

          color: #262626;
          padding 10px 14px

          &.pending
          &.deleted
          &.blocked
            background: color-39 0% 0% no-repeat padding-box

          &.approved
            background: color-35 0% 0% no-repeat padding-box

        .actions
          display flex
          align-items center
          justify-content flex-end

          .custom-dropdown
            flex-basis 197px
            width auto
            margin-right 10px

            ::v-deep
              .dropdown-toggle
                border none

          .btn-common
            padding 8px 30px

          .bi-arrow-right
            font-size 1.8rem
            margin-left 10px

        .comment-container
          .comment-container__label
            font font-opensans-semibold
            margin 10px 0
            margin-top 20px
            font-size 13px

          #comment-textarea
            resize none
            overflow-y auto

          .save-button
            float right
            transform none !important
            border-width 0 !important

::v-deep
  .flag-inappropriate-menu
    padding-top 20px
    margin 0 auto

@media (min-width: 992px)
  .container-detail-submission
    .profile
      &-left
        width 104px

        ::v-deep
          .b-avatar
            width 104px
            height 104px

      &-right
        font-size 16px
        line-height 30px

    .attribute
      flex-direction row

      &-left
        padding-top 0
        flex-basis 70%
        margin 0 auto
        margin-right 38px

        .video-overlay
          width 100%
          height 665px
          border-radius 4px

      &-right
        flex 1
        margin-top 30px
        word-break break-word

</style>
<style lang="stylus">
@import "~@/core/styles/variables.styl"
#actions-submission-detail
  .btn-secondary.dropdown-toggle
    background-color color-6 !important

  .show > .btn-secondary.dropdown-toggle
    background-color color-50 !important
</style>
