import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/core/plugins'
import '@/core/Mixins/app'
import '@/core/filters/date-filters'
import '@/core/directives'
import '@/core/styles/main.styl'


Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue()
Vue.use(require('vue-cookies'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
