import {ClassroomService} from "@/core/Services"

export const VideoCommentsMixins = {
  methods: {
    validateSavedComment(close) {
      if (this.userComment !== this.teacherComment) {
        return this.$bvModal.msgBoxConfirm('You have unsaved comments. Would you like to save them?', {
          size: 'sm',
          dialogClass: "modal-message-box purchase-center",
          buttonSize: 'sm',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(async (res) => {
          if (res) {
            await this.saveComment(close, res)
          }
          if (res === false && close !== false) {
            this.$emit('on-close-submission-modal')
          }
          return res
        })
      }
    },
    saveComment(close, validationResponse) {
      this.loadingComment = true

      ClassroomService.saveSubmissionComment({classroomId: this.classroom_id, submissionId: this.selectedVideo.id, comment: this.userComment})
      .then(() => {
        this.$bvModal.msgBoxOk('Comment saved successfully!', {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        }).then(() => {
          if (validationResponse !== null && close === true) {
            this.$emit('on-close-submission-modal')
          }
          if (close === false) {
            this.$emit('next-submission')
          }
        })
      })
      .catch((e) => {
        const errorMessage = e.message ? e.message : 'Error'
        this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      })
      .finally(() => {
        this.loadingComment = false
        this.$emit('save-comment')
      })
    }
  },
  computed: {
    teacherComment() {
      return this.selectedVideo.teacher_comment ? this.selectedVideo.teacher_comment : ''
    },
    disableSaveCommentButton() {
      return this.userComment === this.teacherComment
    }
  }
}
