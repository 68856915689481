import Vue from 'vue'
import VueRouter from 'vue-router'
import SecurityRoutes from '@/modules/Security/Routes'
import MyClassroomsRoutes from '@/modules/MyClassrooms/Routes'
import AccountRoutes from '@/modules/Account/Routes'
import ClassroomRoutes from '@/modules/Classroom/Routes'
import {AppStorage, userTypes} from '@/core/Utils';

Vue.use(VueRouter);

let routes = [];

routes = routes.concat(SecurityRoutes, MyClassroomsRoutes, ClassroomRoutes, AccountRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //redirect to previous view of ClassroomPage if id is not a number
  const isNumber = to.params && isNaN(to.params.classroomId)
  if (to.name === 'ClassroomPage' && isNumber) {
    return router.go(-1)
  }

  // redirect to login page if not logged in and trying to access a restricted page
  const user = AppStorage.getUser()
  const loggedIn = !!AppStorage.getUser().token
  const off_pages = [ // user can only access this if is not login
    "LoginPage",
    "CreateAccountPage",
    "VerificationAccountPage",
    "RecoverPasswordPage",
    "ForgotPasswordPage",
  ]
  const isLibrarian = user?.user_type?.slug === userTypes.librarian

  if (to.meta.authRequired && !loggedIn) {
    return next({name: 'LoginPage', query: {redirect: to.fullPath}})
  } else if (off_pages.find(page => page === to.name) && loggedIn) {
    return next({name: 'MyClassroomsPage'})
  } else {
    if (to.name !== 'LogOutPage' && loggedIn) {
      if (user.first_name && to.name === 'ProfileWizardPage') {
        return next({name: 'TeacherProfilePage'})
      }
      if ((to.name !== 'TeacherProfilePage') && (to.name !== 'ProfileWizardPage') && (to.name !== 'LoginAdminByTokenPage') && (!user.school_id || !user.is_email_validated || !user.is_teacher_validated)) {
        if (user.first_name) {
          router.push({name: 'TeacherProfilePage'})
        } else {
          router.push({name: 'ProfileWizardPage'})
        }
      }
    }
    if (to.meta.librarian && !isLibrarian) {
      return next({ name: 'MyClassroomsPage' })
    }
    next()
  }
})

export default router
