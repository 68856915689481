<template>
  <div class="tab-container-task-library">
    <div class="task-library-back">
      <a href="javascript:void(0)" @click="onBack"><span class="icon-caret-left"></span> Back</a>
    </div>
    <hr>
    <div class="task-library-header">
      <h3>TASK LIBRARY</h3>
      <span>common repository</span>
    </div>
    <div class="task-library-body">
      <classroom-detail-tab-filter :filters-by="filterBy" :dropdown-view-show="false"
                                   v-model="filters"></classroom-detail-tab-filter>
      <hr>
      <teacher-table table-class-content="table-body-task-library" :rows="tableRows" view="grid">
        <template v-slot:tableRowGrid="slotProps">
          <div class="column">
            <div class="column-picture">
              <img class="img-fluid" :src="require('@/core/assets/task-image-test.png')" alt="">
            </div>
          </div>
          <div class="wrapper column-attributes">
            <div class="column column-attribute-title">{{ slotProps.row.title }}</div>
            <div class="wrapper column-attribute-subject">
              <div class="column subject">Subject: <span>{{ slotProps.row.subject }}</span></div>
              <div class="column topic">Topic: <span>{{ slotProps.row.topic }}</span></div>
            </div>
            <div class="column column-attribute-tags">Tags: <span>{{ slotProps.row.tags }}</span></div>
          </div>
        </template>
      </teacher-table>
    </div>
  </div>
</template>

<script>
import ClassroomDetailTabFilter from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabFilter";
import TeacherTable from "@/modules/Classroom/Components/TeacherTable";

export default {
  name: "ClassroomDetailTabMyTasksLibrary",
  components: {
    ClassroomDetailTabFilter,
    TeacherTable
  },
  data() {
    return {
      filters: {
        filterBy: null,
        search: null,
      },
      filterBy: [
        {
          name: "A - Z",
          value: "1"
        },
        {
          name: "Z - A",
          value: "2"
        },
        {
          name: "Pending Review",
          value: "3"
        }
      ],
      tableRows: [
        {
          id: "1",
          title: "Task 1",
          subject: "Task Subject",
          topic: "Topic X",
          tags: "#tag01 #tag02 #tag03 #tag04"
        },
        {
          id: "1",
          title: "Task 1",
          subject: "Task Subject",
          topic: "Topic X",
          tags: "#tag01 #tag02 #tag03 #tag04"
        },
        {
          id: "1",
          title: "Task 1",
          subject: "Task Subject",
          topic: "Topic X",
          tags: "#tag01 #tag02 #tag03 #tag04"
        },
        {
          id: "1",
          title: "Task 1",
          subject: "Task Subject",
          topic: "Topic X",
          tags: "#tag01 #tag02 #tag03 #tag04"
        }
      ]
    }
  },
  methods: {
    onBack() {
      this.$emit('on-change-component', 'ClassroomDetailTabMyTasksList')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.tab-container-task-library
  padding-left 10px
  padding-right 10px
  padding-bottom 99px
  padding-top 13px

.task-library-header
  text-align center
  margin-top 40px
  margin-bottom 40px

  h3
    color color-8
    font font-opensans-bold
    font-size 24px
    margin-bottom 0

  span
    font font-opensans-regular
    font-size 16px
    color color-8

.task-library-body
  padding 0

@media (min-width: 1200px)
  .task-library-body
    padding-left 112px
    padding-right 112px

hr
  background-color color-23
  height 1px
  opacity 1
  margin 0.8em 0

.task-library-back
  a
    font font-opensans-bold
    font-size 17px
    cursor pointer
    text-decoration none
    color color-3
    display flex
    align-items center

    span
      font-size 0.7em
      padding-right 5px

      &:before
        color color-3

.table-body-task-library
  ::v-deep
    .table-body-view
      &.grid
        .table-row
          width calc(100% - 36px)
          margin 0 18px 15px 18px
          box-shadow 0 2px 4px #00000029
          border 1px solid color-15
          border-radius 4px
          padding 0

          &.header
            display none

          .column-attributes
            flex-direction(column)
            padding 16px 20px
            width 100%
            flexbox(flex)
            justify-content(center)

          .column-show
            display none

          .column-picture
            width 110px

          .column-attribute-subject
          .column-attribute-tags
            flex-wrap(wrap)
            font-size 13px

          .column-attribute-title
            font font-opensans-bold
            font-size 15px
            text-transform uppercase


  @media (min-width: 992px)
    .table-body-view
      &.grid
        grid-template-columns: 1fr 1fr

        & > .table-row:not(.header)
          width calc(100% - 36px)
          margin-bottom 15px
          margin-top 0

          &:nth-child(odd)
            margin-left 18px

          &:nth-child(even)
            margin-right 18px
</style>
