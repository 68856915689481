import {Utils} from "@/core/Utils";

export default {
  data() {
    return {
      form: {},
      validations: {},
      errors: {}
    }
  },
  computed: {
    isValid() {
      return !Object.keys(this.errors).some(key => {
        return this.errors[key].length > 0
      });
    }
  },
  mounted() {
    this.setValidators()
  },
  methods: {
    setValidators() {
      for (const field in this.validations) {
        this.$set(this.errors, field, [])
      }
    },
    clearForm() {
      for (const field in this.form) {
        this.form[field] = ''
      }
    },
    isValidForm() {
      this.validateForm()
      return this.isValid
    },
    validateForm() {
      for (const field in this.validations) {
        this.validateField(field)
      }
    },
    validateFieldEvent(field) {
      this.validateField(field)
    },
    validateField(field) {
      if (this.validations[field]) {

        let validations = this.validations[field].validate.split('|')
        this.errors[field] = []

        validations.forEach(type => {
          if (type === 'required') {
            if (!this.form[field]) {
              this.errors[field].push('Field required.')
            }
          } else if (type === 'email') {
            this.validateEmail()
          } else if (type === 'password') {
            this.validatePassword()
          } else if (type === 'passwordConfirm') {
            this.confirmedPassword()
          } else if (type === 'url') {
            if (!Utils.isValidUrl(this.form[field])) (
              this.errors[field].push('Invalid url format')
            )
          } else if (type === 'phone') {
            if (!Utils.isValidNumber(this.form[field])) {
              this.errors[field].push('Only numeric characters are allowed')
            }
          } else if (type === 'alphanumeric') {
            if (!Utils.isAlphanumeric(this.form[field])) {
              this.errors[field].push('Only alphanumeric characters are allowed')
            }
          } else if (type === 'letter') {
            if (!Utils.isValidLetter(this.form[field])) {
              this.errors[field].push('Do not include numbers, symbols, or blank spaces')
            }
          } else if (type === 'number') {
            if (!Utils.isValidNumber(this.form[field])) {
              this.errors[field].push('Only numbers.')
            }
          } else if (type === 'numberStudentsAllowed') {
            if (!Utils.isNumberStudentsAllowed(this.form[field])) {
              this.errors[field].push('Please enter a valid number between 2 and 30.')
            }
          }
        })
      }

    },
    validateEmail() {
      const {email} = this.form;

      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!pattern.test(email)) {
        this.errors['email'].push('Enter a valid email.');
      }
    },
    validatePassword() {
      const {password} = this.form

      const minLenth = (password.length >= 8)
      const maxLenth = (password.length <= 16)
      const hasNumber = (/\d/).test(password)
      const hasLowercase = (/[a-z]/).test(password)
      const hasUppercase = (/[A-Z]/).test(password)
      const hasSpecial = (/[!@#$%^&*)(+=._-]/).test(password)

      if (!minLenth) {
        this.errors['password'].push('Require a minimum of 8 characters.')
      }

      if (!maxLenth) {
        this.errors['password'].push('Require a maximum of 16 characters.')
      }

      if (!hasNumber) {
        this.errors['password'].push('Require Numeric characters.')
      }

      if (!hasLowercase) {
        this.errors['password'].push('Require Lowercase characters.')
      }

      if (!hasUppercase) {
        this.errors['password'].push('Require Uppercase characters.')
      }

      if (!hasSpecial) {
        this.errors['password'].push('Requires at least one special character !@#$%^&*)(+=._-.')
      }
    },
    confirmedPassword() {
      const {password, passwordConfirm} = this.form

      if (password !== passwordConfirm) {
        this.errors['passwordConfirm'].push('Passwords must match')
      }
    },
  }
};
