import {http} from './http'
import moment from 'moment'

const operatingSystem = navigator.platform.toLowerCase()
const iosDevices = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod'
]

export default {
  trackingActivityTeachers (message) {
    let currentSystem

    if (operatingSystem.includes('win')) {
      currentSystem = 'Windows'
    }
    if (operatingSystem.includes('mac')) {
      currentSystem = 'Mac Os'
    }
    if (operatingSystem.includes('android')) {
      currentSystem = 'Android'
    }
    if (iosDevices.includes(navigator.platform)) {
      currentSystem = 'Ios'
    }

    const currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss')
    const data = {
      "message": message,
      "device_name": currentSystem ? `From an ${currentSystem} device`: '',
      "device_time": currentTimestamp
    }

    try {
      http.post('/api/teacher/activity', data).then(response => {
        return response
      })
    } catch (error) {
      console.error(error)
    }
  }
}
