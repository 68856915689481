<template>
  <div class="partner-connection-requests-wrapper" v-if="rows.length">
    <slot name="header"></slot>

    <teacher-table table-class-content="partner-connection-requests-table-body" :rows="rows" :view="'list'">
      <template #tableHeader>
        <div class="column column-header column-type">
          <div class="order" @click="onChangeOrder('type')">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          SENT/RECEIVED
        </div>
        <div class="column column-header column-partner">
          <div class="order" @click="onChangeOrder('partner')">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          GLOBAL PARTNER
        </div>
        <div class="column column-header column-country">
          <div class="order" @click="onChangeOrder('country')">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          COUNTRY
        </div>
        <div class="column column-header column-date">
          <div class="order" @click="onChangeOrder('date')">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          DATE
        </div>
        <div class="column column-header column-classroom">
          <div class="order" @click="onChangeOrder('classroom')">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          MY CLASSROOM
        </div>
        <div class="column column-header column-status">
          <div class="order" @click="onChangeOrder('status')">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          STATUS
        </div>
      </template>
      <template v-slot:tableRowList="slotProps">
        <partner-connection-requests-list-row :ref="`connection-request-${slotProps.row.id}`"
                                              :connection="slotProps.row"
                                              @on-show-connection-received-detail="onShowConnectionReceivedDetailModal"
                                              @on-show-connection-sent-detail="onShowConnectionSentDetailModal"/>
      </template>
    </teacher-table>

    <modal id="connection-received-detail-modal" class="modal-width" hide-footer no-close-on-backdrop no-close-on-esc
           hide-close-button>
      <template #body>
        <partner-connection-received-detail ref="partner-connection-received-detail"
                                            :invitation_id="selected_invitation.id">
          <template v-slot:controls="slotProps">
            <b-button @click="onHideConnectionReceivedDetailModal" btn-text="CLOSE" :disabled="sendingRequest"/>
            <b-button @click="onAcceptConnectionRequest(slotProps.connection_request)" btn-text="ACCEPT"
                      :disabled="sendingRequest || !slotProps.selected_classroom_id || !slotProps.pending"/>
            <b-button @click="onShowRejectModal" btn-text="DECLINE"
                      :disabled="sendingRequest || slotProps.accepted || slotProps.cancelled || slotProps.declined"/>
          </template>
        </partner-connection-received-detail>
      </template>
    </modal>

    <modal id="connection-sent-detail-modal" class="modal-width" hide-footer no-close-on-backdrop no-close-on-esc
           hide-close-button>
      <template #body>
        <partner-connection-sent-detail ref="partner-connection-sent-detail" :invitation_id="selected_invitation.id">
          <template v-slot:controls="slotProps">
            <b-button @click="onHideConnectionSentDetailModal" btn-text="CLOSE" :disabled="sendingRequest"/>
            <b-button @click="onShowPersonalNoteModal('RESEND REQUEST')" btn-text="RESEND REQUEST"
                      :disabled="sendingRequest || slotProps.accepted"/>
            <b-button @click="onShowPersonalNoteModal('CANCEL REQUEST')" btn-text="CANCEL REQUEST"
                      :disabled="sendingRequest || slotProps.accepted || slotProps.cancelled || slotProps.declined"/>
          </template>
        </partner-connection-sent-detail>

        <b-overlay no-wrap :show="sendingRequest"></b-overlay>
      </template>
    </modal>

    <modal id="reject-partner-modal" @hidden="message = null">
      <template #header>
      </template>
      <template #body>
        <h3 class="modal-title">Include a Personal Note (Optional)</h3>
        <custom-input v-model="message" :disabled="sendingRequest"/>
      </template>
      <template #footer>
        <b-button @click="onHideRejectPartner" btn-text="CANCEL" :disabled="sendingRequest"/>
        <b-button @click="onDeclineConnectionRequest" btn-text="CONFIRM DECLINE" :disabled="sendingRequest"/>
      </template>
    </modal>

    <modal id="personal-note-modal" @hidden="message = null">
      <template #header>
      </template>
      <template #body>
        <h3 class="modal-title">Include a Personal Note (Optional)</h3>
        <custom-input v-model="message" :disabled="sendingRequest"/>
      </template>
      <template #footer>
        <b-button @click="onHidePersonalNoteModal" btn-text="CANCEL" :disabled="sendingRequest"/>
        <b-button @click="onRequestAction" :btn-text="requestAction" :disabled="sendingRequest"/>
      </template>
    </modal>

    <purchase-bundles-modal id="purchase_bundles_modal"
                            ref="purchase_bundles_modal"
                            @on-purchase-success="onPurchaseSuccess"/>
  </div>
</template>

<script>
import TeacherTable from "@/modules/Classroom/Components/TeacherTable"
import PartnerConnectionSentDetail from "./PartnerConnectionSentDetail"
import PartnerConnectionRequestsListRow from "./PartnerConnectionRequestsListRow"
import PartnerConnectionReceivedDetail from "./PartnerConnectionReceivedDetail"
import {PurchaseBundlesModal} from "@/core/components/PurchaseBundlesModal";
import {Modal} from "@/core/components/Modal"
import {Button} from "@/core/components/Button";
import {ClassroomService} from "@/core/Services";
import {ClassroomMixins, UtilsMixins} from "@/core/Mixins";
import Input from "@/core/components/FormInput/Input";

export default {
  name: "PartnerConnectionRequestsList",
  components: {
    TeacherTable,
    Modal,
    PartnerConnectionSentDetail,
    PartnerConnectionRequestsListRow,
    PartnerConnectionReceivedDetail,
    PurchaseBundlesModal,
    'b-button': Button,
    'custom-input': Input,
  },
  mixins: [UtilsMixins, ClassroomMixins],
  props: {
    invitation_id: {
      type: Number,
      default: null
    },
    classroom_id: {
      type: Number,
      required: false
    },
  },
  data() {
    return {
      selected_invitation: null,
      accepted_connection_request: null,
      sendingRequest: true,
      sort_by: null,
      rows: [],
      message: null,
      requestAction: null
    }
  },
  mounted() {
    this.onFetchConnectionList()
  },
  methods: {
    async onFetchConnectionList() {
      try {
        this.sendingRequest = true

        const connections = await ClassroomService.partnerConnectionList(this.classroom_id, this.sort_by)

        this.rows = connections.data

        if (this.invitation_id && this.rows.find((row) => row.id === this.invitation_id)) {
          this.$nextTick(function () {
            this.$refs[`connection-request-${this.invitation_id}`].onShowRequestDetail()
          })
        }
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error fetching connection requests.'
        this.$bvModal.msgBoxOk(errorMessage, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.sendingRequest = false
      }
    },
    async onResendConnectionRequest() {
      if (!this.sendingRequest && this.selected_invitation) {
        try {
          this.sendingRequest = true
          const requestMessage = this.message ?? ''
          await ClassroomService.partnerConnectionResend(this.selected_invitation.id, requestMessage)

          this.$bvModal.msgBoxOk('Connection request sent', {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }).then(() => {
            this.onHidePersonalNoteModal()
            this.$refs["partner-connection-sent-detail"].fetchConnectionRequest()
          })

        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error sending connection request.'
          this.$bvModal.msgBoxOk(errorMessage, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        } finally {
          await this.onFetchConnectionList()
          this.sendingRequest = false
        }
      }
    },
    async onCancelConnectionRequest() {
      if (!this.sendingRequest && this.selected_invitation) {
        try {
          this.sendingRequest = true
          const requestMessage = this.message ?? ''
          await ClassroomService.partnerConnectionCancel(this.selected_invitation.id, requestMessage)

          this.$bvModal.msgBoxOk('Connection request cancelled', {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }).then(() => {
            this.onHidePersonalNoteModal()
            this.$refs["partner-connection-sent-detail"].fetchConnectionRequest()
          })

        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error cancelling connection request.'
          this.$bvModal.msgBoxOk(errorMessage, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        } finally {
          await this.onFetchConnectionList()
          this.sendingRequest = false
        }
      }
    },
    async onAcceptConnectionRequest(accepted_connection_request) {
      if (this.sendingRequest) {
        return
      }
      if (!accepted_connection_request) {
        return
      }

      this.accepted_connection_request = accepted_connection_request

      if (this.accepted_connection_request.invited_classroom_id === 'create_new') {
        this.goToPartnerCreateClassroomPage(this.accepted_connection_request.id)
      } else {
        try {
          this.sendingRequest = true

          await this.initClassroom()
          await this.getProfile()
          await this.getClassroom(this.accepted_connection_request.invited_classroom_id)

          const need_tokens_message = this.needPurchaseTokensMessage()
          if (need_tokens_message) {
            this.showPurchaseBundlesModal(need_tokens_message)
          } else {
            if (await this.showRefundOrBillingMessage()) {
              await this.partnerConnectionAccept()
            }
          }

          this.sendingRequest = false
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error accepting connection request.'
          this.$bvModal.msgBoxOk(errorMessage, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })

          this.sendingRequest = false
        }
      }
    },
    onShowRejectModal() {
      this.$bvModal.show('reject-partner-modal')
    },
    onHideRejectPartner() {
      this.$bvModal.hide('reject-partner-modal')
    },
    onShowPersonalNoteModal(action) {
      this.requestAction = action
      this.$bvModal.show('personal-note-modal')
    },
    onHidePersonalNoteModal() {
      this.$bvModal.hide('personal-note-modal')
    },
    onRequestAction() {
      if (this.requestAction === 'RESEND REQUEST') {
        this.onResendConnectionRequest()
      }
      if (this.requestAction === 'CANCEL REQUEST') {
        this.onCancelConnectionRequest()
      }
    },
    needPurchaseTokensMessage() {
      let message = null

      const my_classroom_tokens = this.classroom.number_of_tokens
      const partner_classroom_tokens = this.accepted_connection_request.classroom.number_of_tokens
      const available_tokens_count = this.user.available_tokens_count ?? 0

      if (my_classroom_tokens < partner_classroom_tokens) {
        const required_tokens = partner_classroom_tokens - my_classroom_tokens
        if (required_tokens > available_tokens_count) {
          message = `You do not have enough tokens to accept this partnership. Please purchase at least ${required_tokens - available_tokens_count} additional tokens to continue.`
        }
      }

      return message
    },
    showPurchaseBundlesModal(need_tokens_message) {
      this.$bvModal.msgBoxConfirm(
        need_tokens_message,
        {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        }
      ).then((user_press_ok) => {
        if (user_press_ok) {
          this.$refs["purchase_bundles_modal"].showPurchaseBundlesModal()
        }
      })
    },
    refundOrBillingMessage() {
      let message = null

      const my_classroom_tokens = this.classroom.number_of_tokens
      const partner_classroom_tokens = this.accepted_connection_request.classroom.number_of_tokens
      const available_tokens_count = this.user.available_tokens_count ?? 0

      if (my_classroom_tokens < partner_classroom_tokens) {
        const required_tokens = partner_classroom_tokens - my_classroom_tokens

        message = `This classroom exchange requires ${required_tokens} additional token(s). You will have ${available_tokens_count - required_tokens} remaining token(s) in your account if you accept. Press OK to accept this invitation or press Cancel to return to the prior screen`
      } else if (my_classroom_tokens > partner_classroom_tokens) {
        message = `This classroom exchange requires fewer tokens than your current classroom. ${my_classroom_tokens - partner_classroom_tokens} token(s) will be returned to your account.`
      }

      return message
    },
    async showRefundOrBillingMessage() {
      const message = this.refundOrBillingMessage()

      if (message) {
        return this.$bvModal.msgBoxConfirm(
          message,
          {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }
        )
      } else {
        return new Promise((resolve) => {
          return resolve(true);
        })
      }
    },
    async partnerConnectionAccept() {
      if (!this.accepted_connection_request || !this.classroom.id) {
        return
      }

      await ClassroomService.partnerConnectionAccept(this.accepted_connection_request.id, this.classroom.id)

      this.$bvModal.msgBoxOk('Connection request accepted', {
        size: 'sm',
        dialogClass: "modal-message-box",
        noCloseOnBackdrop: true,
        centered: true
      }).then(() => {
        this.goToClassroomPage(this.classroom.id)

        this.initClassroom()
        this.selected_invitation = null
        this.accepted_connection_request = null
      })
    },
    onPurchaseSuccess() {
      this.$refs["purchase_bundles_modal"].hidePurchaseBundlesModal()
      this.onAcceptConnectionRequest(this.accepted_connection_request)
    },
    async onDeclineConnectionRequest() {
      if (!this.sendingRequest && this.selected_invitation) {
        try {
          this.sendingRequest = true
          const messageDecline = this.message ?? ''
          await ClassroomService.partnerConnectionDecline(this.selected_invitation.id, messageDecline)

          this.$bvModal.msgBoxOk('Connection request declined', {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }).then(() => {
            this.$refs['partner-connection-received-detail'].fetchConnectionRequest()
            this.$bvModal.hide('reject-partner-modal')
          })
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error declining connection request.'
          this.$bvModal.msgBoxOk(errorMessage, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        } finally {
          await this.onFetchConnectionList()
          this.sendingRequest = false
        }
      }
    },
    onShowConnectionSentDetailModal(connection) {
      this.selected_invitation = connection
      this.$bvModal.show('connection-sent-detail-modal')
    },
    onHideConnectionSentDetailModal() {
      this.$bvModal.hide('connection-sent-detail-modal')
    },
    onShowConnectionReceivedDetailModal(connection) {
      this.selected_invitation = connection
      this.$bvModal.show('connection-received-detail-modal')
    },
    onHideConnectionReceivedDetailModal() {
      this.$bvModal.hide('connection-received-detail-modal')
    },
    onChangeOrder(field) {
      if (this.sort_by) {
        if (this.sort_by.field === field) {
          this.sort_by.direction = this.sort_by.direction === 'asc' ? 'desc' : 'asc'
        } else {
          this.sort_by = {
            field: field,
            direction: 'asc',
          }
        }
      } else {
        this.sort_by = {
          field: field,
          direction: 'asc',
        }
      }

      this.onFetchConnectionList()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.partner-connection-requests
  &-wrapper
    width 100%
    padding-left 10px
    padding-right 10px
    padding-bottom 13px
    padding-top 41px

  &-table-body
    ::v-deep
    .table-body-view
      &.list
        overflow hidden
        overflow-x auto

        .table-row
          padding 6px 0
          align-items(center)
          margin-bottom 6px
          border 1px solid color-28
          color color-8

          &.header
            min-width 800px
            background-color rgba(color-27, 0.27)
            margin-bottom 3px
            border 0

          &:not(.header)
            .column
              overflow hidden
              text-overflow ellipsis
              white-space nowrap

          .column
            margin-left .2rem
            font font-opensans-regular
            font-size 1rem
            text-align left

            &:not(.column-header)
              padding-left 1.5rem

            &:first-child:not(.column-header)
              padding-left 1rem

            &-header
              font-weight bold
              text-transform uppercase
              display flex

              &:not(:first-child)
                padding-left .5rem

              .order
                display flex
                flex-direction(column)
                align-items(center)
                justify-content(center)
                width 1rem
                padding-right 3px

                &-bottom
                &-top
                  font-size 0.6rem
                  cursor pointer

            &-type
              flex-basis(calc(20% - 10px))

            &-partner
              flex-basis(calc(20% - 10px))

            &-country
              flex-basis(calc(15% - 10px))

            &-date
              flex-basis(calc(10% - 10px))

            &-classroom
              flex-basis(calc(25% - 10px))

            &-status
              flex-basis(calc(10% - 10px))

            @media (max-width: 360px)
              &-header
                font-size 0.7rem

              &-type
              &-partner
              &-country
              &-date
              &-classroom
              &-status
                font-size 0.65rem

            @media (min-width: 450px)
              &-header
                font-size 0.7rem

              &-type
              &-partner
              &-country
              &-date
              &-classroom
              &-status
                font-size 0.65rem

            @media (min-width: 768px)
              &-header
                font-size 0.9rem

              &-type
              &-partner
              &-country
              &-date
              &-classroom
              &-status
                font-size 0.8rem

            @media (min-width: 1400px)
              &-header
                font-size 0.9rem

              &-type
              &-partner
              &-country
              &-date
              &-classroom
              &-status
                font-size 0.9rem
</style>

<style lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#connection-sent-detail-modal
#connection-received-detail-modal
  .modal-dialog
    margin 0 auto
    max-width 1000px
    width 100%

  .modal-body
    margin 0
    padding 0

  .body
    padding-top 4rem
    padding-bottom 4rem

</style>
