<template>
  <div class="step-item">
    <div class="step-item-title">Student Data Privacy Agreement</div>
    <div class="step-item-subtitle">All schools are required to have a signed data privacy agreement with Level Up Village</div>
    <div class="step-item-subtitle">Level Up Village requires that all schools participating in our virtual exchange programs complete a data privacy agreement with LUV. Please send this link to a school administrator for an electronic signature. Once we receive the signed form, you will be able to participate in our virtual exchange programs.</div>
    <a @click.prevent="copyLink"
       :href="formQuery"
       class="step-item-link"
       v-b-tooltip="{ title: 'Copied!', trigger:'click'}">{{formQuery}}</a>
    <b-button @click="copyLink"
              v-b-tooltip="{ title: 'Copied!', disabled: false, trigger:'click'}"
              btn-text="Copy link"
              class="button-copy"/>
    <div class="step-item">
      <b-button type="button" class="button primary --sm back"
                @click="changeStep(3)"
                btn-text="Back"/>
      <b-button type="button" class="button primary --sm"
                @click="changeStep(5)"
                btn-text="Next"/>
    </div>
  </div>
</template>

<script>
import { Button } from "@/core/components/Button"
import { MyClassRoomsMixins } from "@/modules/MyClassrooms/Mixins"

export default {
  name: 'Step4',
  mixins: [MyClassRoomsMixins],
  components: {
    'b-button': Button
  },
  props: {
    profileData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  validations () {
    return {
    }
  },
  computed: {
    formQuery () {
      let query = `?&school_id=${this.profileData?.schoolId}&teacher_id=${this.user.id}`
      return `${process.env.VUE_APP_LUV_PARENT_ORGANIZATION_AGREEMENT_FORM_URL}${query}`
    }
  },
  methods: {
    changeStep (step) {
      this.$emit('change-step', {step, form: {...this.form}})
    },
    copyLink () {
      navigator.clipboard.writeText(this.formQuery);
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip')
      }, 1000)
    },
  },
  async mounted() {
    this.form = {...this.form, ...this.profileData}
  },
  watch: {
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.step-item
  text-align center

  .button-copy
    margin 20px auto

  .button
    display inline
    margin-top 2rem

    &.back
      margin-right 4rem

    &:disabled
      border-width 0 !important

  &-title
    font-size 1.5rem
    margin-bottom 18px

  &-subtitle
    margin 18px 0
    font-size 1rem

  .avatar-image
    max-width 500px
    margin 0 auto

  .tep-item-link
    word-break break-word

  @media (max-width 350px)
    .button
      margin 0 5px
      margin-top 2rem

      &.back
        margin-right 0

</style>
