import SecurityView from "../SecurityView.vue";
import LoginPage from "../Pages/LoginPage.vue";
import CreateAccountPage from "../Pages/CreateAccountPage.vue";
import RecoverPasswordPage from "../Pages/RecoverPasswordPage.vue";
import ForgotPasswordPage from "../Pages/ForgotPasswordPage.vue";
import VerificationAccountPage from "../Pages/VerificationAccountPage.vue";
import LogOutPage from "../Pages/LogOutPage.vue";
import LoginAdminByTokenPage from "../Pages/LoginAdminByTokenPage";

const routes = [
  {
    path: '/security',
    component: SecurityView,
    children: [
      {
        path: 'login',
        name: 'LoginPage',
        component: LoginPage,
      },
      {
        path: 'create-account',
        name: 'CreateAccountPage',
        component: CreateAccountPage,
      },
      {
        path: 'verification-account',
        name: 'VerificationAccountPage',
        component: VerificationAccountPage,
      },
      {
        path: 'recover-password/:token',
        name: 'RecoverPasswordPage',
        component: RecoverPasswordPage,
      },
      {
        path: 'forgot-password',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage,
      },
      {
        path: 'logout',
        name: 'LogOutPage',
        component: LogOutPage,
      },
      {
        path: 'login-as-teacher',
        name: 'LoginAdminByTokenPage',
        component: LoginAdminByTokenPage,
      }
    ]
  }
]

export default routes
