<template>
  <div class="container-best-match">
    <div class="content-best-match-back">
      <a href="javascript:void(0)" @click="onBack"><span class="icon-caret-left"></span> Back</a>
    </div>
    <hr>
    <div v-if="!classroomPartners.length" class="content-best-match-result">
      <h3>No matches found.</h3>
      <p style="max-width: 500px;text-align: center;">
        Please revise your search and try again. <br>
        If you would like help, please contact us at (include existing support form) or support@levelupvillage.com
      </p>
    </div>
    <div v-else class="content-best-match-result">
      <div class="div-best-match-result">
        <h3>POTENTIAL MATCHES</h3>
        <teacher-table table-class-content="table-body-best-match" :rows="classroomPartners" view="grid">
          <template v-slot:tableRowGrid="slotProps">
            <partner-card :partner="slotProps.row"
                          @on-show-partner-detail="onShowPartnerDetail"
                          @on-connect-partner-direct="onConnectPartnerDirect"></partner-card>
          </template>
        </teacher-table>
      </div>
    </div>
    <modal id="invite_partner_modal" @hidden="selectedPartnerClassroom = null">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">CONNECTING WITH {{ selectedPartnerName }}!</h2>
        <p class="text-center">Click CONNECT to invite {{ selectedPartnerName }} to partner with your
          classroom! You will be notified once they have accepted your invitation.</p>

        <h3 class="modal-title">Include a Personal Note (Optional)</h3>

        <custom-input v-model="connectionMessage" :disabled="sendingRequest"/>
      </template>
      <template #footer>
        <b-button @click="onHideConnectPartner" btn-text="CANCEL" :disabled="sendingRequest"/>
        <b-button @click="onConnectPartner" btn-text="CONNECT" :disabled="sendingRequest"/>
      </template>
    </modal>
    <modal id="partner_detail_modal" class="modal-width" @hidden="selectedPartner = null">
      <template #header>
      </template>
      <template #body>
        <partner-detail :partner="selectedPartner"
                       @on-show-connect-classroom="onShowConnectClassroom"/>
      </template>
      <template #footer>
        <b-button @click="onHidePartnerDetail()" btn-text="CLOSE" :disabled="sendingRequest"/>
        <b-button @click="onShowConnectPartner()" btn-text="CONNECT" :disabled="sendingRequest"/>
      </template>
    </modal>
  </div>
</template>

<script>
import TeacherTable from "@/modules/Classroom/Components/TeacherTable";
import {Button} from "@/core/components/Button";
import {ClassroomService} from "@/core/Services";
import {Modal} from "@/core/components/Modal";
import PartnerDetail from "./PartnerDetail";
import PartnerCard from "./PartnerCard";
import Input from "@/core/components/FormInput/Input";

export default {
  name: "GlobalPartnerCreateBestMatch",
  components: {
    TeacherTable,
    Modal,
    'b-button': Button,
    PartnerDetail,
    PartnerCard,
    'custom-input': Input,
  },
  data () {
    return {
      sendingRequest: false,
      connectionMessage: '',
      selectedPartner:null,
      selectedPartnerClassroom:null,
    }
  },
  props: {
    classroomPartners: {
      type: Array,
      required: true
    },
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    },
  },
  methods: {
    onBack () {
      this.$emit('on-change-component', {component: 'GlobalPartnerCreateChoose'})
    },
    onConnectPartnerDirect: function (classroomPartner) {
      this.selectedPartner = classroomPartner
      this.onShowConnectPartner()
    },
    async onConnectPartner() {
      if (this.selectedPartner && !this.sendingRequest) {
        try {
          this.sendingRequest = true

          await ClassroomService.partnerConnectionRequest(
            {
              classroom_id: this.classroom.id,
              partner_teacher_id: this.selectedPartner.id,
              partner_classroom_id: this.selectedPartnerClassroom ? this.selectedPartnerClassroom.id : null,
              message: this.connectionMessage
            }
          )

          this.selectedPartner = null
          this.selectedPartnerClassroom = null
          this.connectionMessage = null

          this.$emit('on-change-component', {component: 'GlobalPartnerCreateFind'})
        } catch(e) {
          const errorMessage = e.message ? e.message : 'Error sending partnership request.'
          this.$bvModal.msgBoxOk(errorMessage, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        } finally {
          this.sendingRequest = false
        }
      }
    },
    onShowPartnerDetail (partner) {
      this.selectedPartner = partner
      this.$bvModal.show('partner_detail_modal')
    },
    onHidePartnerDetail () {
      this.$bvModal.hide('partner_detail_modal')
    },
    onShowConnectPartner () {
      this.selectedPartnerClassroom = null
      this.$bvModal.show('invite_partner_modal')
    },
    onShowConnectClassroom (classroom) {
      this.selectedPartnerClassroom = classroom
      this.$bvModal.show('invite_partner_modal')
    },
    onHideConnectPartner () {
      this.selectedPartnerClassroom = null
      this.connectionMessage = null
      this.$bvModal.hide('invite_partner_modal')
    },
  },
  computed: {
    selectedPartnerName () {
      if (this.selectedPartner) {
        return `${this.selectedPartner.first_name} ${this.selectedPartner.last_name.charAt(0)}`
      }

      return `Nothing selected`
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.container-best-match
  margin-top 10px
  margin-bottom 136px

  .b-avatar
    height 100%
    width 100%

  .content-best-match-result
    .div-best-match-result
      display flex
      flex-direction column

.content-best-match-back
  padding-right 10px
  padding-left 10px

  a
    font font-opensans-bold
    font-size 1.05rem
    cursor pointer
    text-decoration none
    color color-3
    display flex
    align-items center

    span
      font-size 0.7em
      padding-right 5px

      &:before
        color color-3


.content-best-match-result
  display flex
  flex-direction column
  padding 25px 15px 0 15px
  align-items center

  h3
    font font-opensans-bold
    font-size 17px
    color color-8
    margin-bottom 24px

  @media (min-width: 768px)
    padding 54px 15px 0 15px
    h3
      font-size 24px


.table-body-best-match
  ::v-deep
    .table-body-view
      &.grid
        grid-template-columns: 1fr

        .table-row
          box-shadow 0 2px 4px #00000029
          border 1px solid color-15
          border-radius 4px
          padding 0
          width 100%
          height 235px
          max-width: 285px
          margin-bottom 15px
          font-size 8px

          @media (min-width 450px)
            max-width 380px
            height 221px

          @media (min-width: 768px)
            max-width 579px

</style>
<style lang="stylus">
@import "~@/core/styles/variables.styl"

#partner_detail_modal
#invite_partner_modal
  .modal-dialog
    margin 0 auto

</style>

