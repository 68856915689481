<template>
  <div class="wrapper-security-page">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col cols="12" xl="5" lg="6" md="8">
          <div class="wrapper-security-form">
            <div class="security-form-page-info">
              <div class="your-info">LET’S GET STARTED</div>
              <h2 class="security-form-subtitle">CREATE YOUR TEACHER ACCOUNT</h2>
              <div class="required-fields">all fields required *</div>
            </div>
            <form @submit.prevent="">
              <custom-input @blur="validateFieldEvent('email')"
                            @keydown.enter="createAccount" v-model="form.email" :errors="errors.email"
                            placeholder="Your School Email (john.doe@school.org) *" type="email"/>
              <custom-input @blur="validateFieldEvent('password')"
                            @keydown.enter="createAccount" v-model="form.password" :errors="errors.password"
                            placeholder="Password *" type="password"/>
              <custom-input @blur="validateFieldEvent('passwordConfirm')"
                            @keydown.enter="createAccount" v-model="form.passwordConfirm"
                            :errors="errors.passwordConfirm"
                            placeholder="Confirm Password *" type="password"/>
              <div class="checkbox-container">
                <b-form-checkbox class="custom-checkbox-primary"
                                 v-model="acceptedPrivacyPolicy">
                </b-form-checkbox>
                <div>
                  I agree to LUV’s
                  <span class="terms" @click="onPrivacyPolicyModal(termsOfUseUrl)">Terms of Use</span>
                  and consent to LUV’s
                  <span class="privacy-policy" @click="onPrivacyPolicyModal(privacyPolicyUrl)">Privacy Policy</span>
                </div>
              </div>
              <div class="security-form-container-btns c-btns-secondary">
                <b-button @click="goToLoginPage()" btn-text="CANCEL"/>
                <b-button @click="createAccount" :disabled="disableCreateButton" btn-text="CREATE"/>
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <modal ref="account_create_success">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">EMAIL VERIFICATION</h2>
        <p class="text-center">Please check your email to complete the verification process. If you do not see an email,
          check your
          Junk/Spam folder.</p>
      </template>
      <template #footer>
        <b-button @click="closeSuccessModal()" btn-text="OK"/>
      </template>
    </modal>
    <modal ref="account_create_error">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">ERROR</h2>
        <p v-if="errorEmail" class="text-center"> {{ errorEmail }}.</p>
        <p v-else class="text-center">An error occurred while trying to create the account, please contact an
          administrator.</p>
      </template>
      <template #footer>
        <b-button @click="closeErrorModal()" btn-text="OK"/>
      </template>
    </modal>

    <modal id="terms-privacy-policy-modal" :hide-close-button="true">
      <template #body>
        <iframe v-if="showIframe === termsOfUseUrl" :src="termsOfUseUrl + '#toolbar=0'" width="100%" height="100%"/>
        <iframe v-if="showIframe === privacyPolicyUrl" :src="privacyPolicyUrl + '#toolbar=0'" width="100%" height="100%"/>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('terms-privacy-policy-modal')" btn-text="OK"/>
      </template>
    </modal>
  </div>
</template>

<script>
import validateForms from '@/core/Mixins/validate-forms'
import {Modal} from '@/core/components/Modal'
import {Button} from '@/core/components/Button'
import {AccountService} from '@/core/Services'
import Input from "@/core/components/FormInput/Input";
import axios from 'axios'
import {Utils} from "@/core/Utils"

export default {
  name: "CreateAccountPage",
  components: {
    Modal,
    'b-button': Button,
    'custom-input': Input,
  },
  mixins: [validateForms],
  data() {
    return {
      form: {
        email: null,
        password: null,
        passwordConfirm: null
      },
      acceptedPrivacyPolicy: false,
      privacyPolicy: process.env.VUE_APP_LUV_PRIVACY_POLICY,
      termsOfUse: process.env.VUE_APP_LUV_TERMS_OF_USE,
      privacyPolicyUrl: '',
      termsOfUseUrl: '',
      errorEmail: '',
      showIframe: '',
      validations: {
        email: {
          validate: 'required|email',
        },
        password: {
          validate: 'required|password',
        },
        passwordConfirm: {
          validate: 'required|passwordConfirm',
        }
      }
    }
  },
  methods: {
    createAccount: function () {
      this.errorEmail = ''
      if (this.isValidForm()) {
        const data = this.form
        if (Utils.isPlayerTrustApp()) {
          data.playersTrust = 1
        }
        AccountService.create(data.email, data.password, this.privacyPolicy, this.termsOfUse, data?.playersTrust).then((response) => {
          if (response.success === true) {
            this.showSuccessModal()
          } else {
            this.showErrorModal()
          }
        }).catch((err) => {
          if (typeof err.errors !== 'undefined' && typeof err.errors.email !== 'undefined') {
            this.errorEmail = err.errors.email[0]
          }

          this.showErrorModal()
        })
      }
    },
    showSuccessModal: function () {
      return this.$refs["account_create_success"].showModal()
    },
    closeSuccessModal: function () {
      return this.$refs["account_create_success"].hideModal().then(() => {
        this.goToLoginPage()
      })
    },
    showErrorModal: function () {
      return this.$refs["account_create_error"].showModal()
    },
    closeErrorModal: function () {
      return this.$refs["account_create_error"].hideModal().then(() => {
        this.goToLoginPage()
      })
    },
    onPrivacyPolicyModal(url) {
      this.showIframe = url
      this.$bvModal.show('terms-privacy-policy-modal')
    },
    setUrl(url) {
      let response = axios.get(url, {
        responseType: 'blob',
      })
      return response
    }
  },
  computed: {
    disableCreateButton() {
      return !this.acceptedPrivacyPolicy || !this.form.email || !this.form.password || !this.form.passwordConfirm
    }
  },
  async mounted() {
    const responsePrivacyPolicy = await this.setUrl(this.privacyPolicy)
    const responsetermsOfUse = await this.setUrl(this.termsOfUse)

    this.privacyPolicyUrl = window.URL.createObjectURL(new Blob([responsePrivacyPolicy.data], {type: "application/pdf"}))
    this.termsOfUseUrl = window.URL.createObjectURL(new Blob([responsetermsOfUse.data], {type: "application/pdf"}))
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/security.styl";
@import "~@/core/styles/variables.styl"

.security-form-subtitle
  margin-bottom 0

.security-form-page-info
  margin-bottom 34px

.checkbox-container
  display flex
  font font-opensans-regular
  font-size 14px

  span
    color #000
    text-decoration underline
    cursor pointer
    display inline-block
    margin-top 2px

</style>
<style lang="stylus">
#terms-privacy-policy-modal
  .modal-dialog
    max-width 720px !important

    .modal-content
      height 100%

      .modal-body
        overflow-x hidden !important
        margin-bottom 0

        .body
          height 99%
          padding 0

      .modal-footer
        margin 10px 0

  @media(max-width 700px)
    .modal-dialog
      .modal-content
        .modal-body
          overflow hidden !important

</style>
