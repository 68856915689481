<template>
  <div class="tab-container-task-create">
    <div class="task-create-back">
      <a href="javascript:void(0)" @click="onBack"><span class="icon-caret-left"></span> Back</a>
    </div>
    <hr>
    <div class="task-create-body">
      <div class="task-create-body-information">
        <div class="information-left">
          <img class="img-fluid" :src="task.task_thumbnail" :alt="task.task_thumbnail">
        </div>
        <div class="information-right">
          <h3>{{ task.task_name }}</h3>
          <h3>Topic</h3>
          <div class="topic">{{ task.topic }}</div>
          <h3>Tags</h3>
          <div class="tags">{{ tags }}</div>
        </div>
      </div>
      <b-button :disabled="false"
                v-if="isWholeClassGroupType"
                @click="showSubmissionDetail"
                :btn-text="buttonLabel"/>
      <hr>
      <div v-if="task.teacher_instructions" class="task-create-body-card task-create-body-card--teacher-instruction">
        <h4>TEACHER INSTRUCTIONS</h4>
        <p class="description" v-html="task.teacher_instructions"></p>
        <hr>
      </div>

      <div class="task-create-body-card task-create-body-card--teacher-instruction">
        <h4>LEARNING OBJECTIVES</h4>
        <p class="description" v-html="task.can_do_statement"></p>
      </div>

      <b-form-checkbox class="custom-checkbox-primary checkbox-reflection-task"
                       :disabled="true"
                       v-if="isActivityOrReflectionTask"
                       :checked="!!task.display_students">
        Display to Students
      </b-form-checkbox>

      <div class="task-create-body-card task-create-body-card--teacher-instruction" v-if="!isWholeClassGroupType">
        <h4>STUDENT PROMPT ({{studentPromptLabel}})</h4>
        <p class="description" v-html="task.create_prompt"></p>
      </div>

      <div v-if="task.due_date" class="task-create-body-card">
        <h4>STUDENT PROMPT DUE DATE</h4>
        <span class="text text--date">{{ task.due_date | dateMonthDayYear }}</span>
      </div>

      <div class="task-create-body-card task-create-body-card--teacher-instruction" v-if="!isWholeClassGroupType && !isActivityOrReflectionTask && !isIntroductionTask">
        <h4>RESPONSE PROMPT ({{responsePromptLabel}})</h4>
        <p class="description" v-html="task.respond_prompt"></p>
      </div>

      <div v-if="task.response_due_date && !isActivityOrReflectionTask && !isIntroductionTask" class="task-create-body-card">
        <h4>RESPOND PROMPT DUE DATE</h4>
        <span class="text text--date">{{ task.response_due_date | dateMonthDayYear }}</span>
      </div>

      <div v-if="task.materials.length" class="task-create-body-card">
        <h4>STUDENT SUPPORT MATERIALS</h4>

        <b-list-group horizontal>
          <b-list-group-item
            class="attachment-item"
            v-for="(item,index) in task.materials"
            :key="'task-materials-' + index"
            :href="item.url"
            target="_blank">
            <thumbnail-attachment :data="item"/>
            <b-tooltip :target="'task-materials-' + index" triggers="hover" placement="bottom">
              {{ item.label }}
            </b-tooltip>
            <div class="attachment-item__label" :id="'task-materials-' + index">{{ truncateString(item.label, 20) }}</div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div v-if="task.links.length" class="task-create-body-card">
        <h4>ADDITIONAL LINKS</h4>
        <ul>
          <li v-for="(item,index) in task.links"
              :key="'task-links-' + index"
              class="link-label">
            <b-link :href="item.url">{{ item.label }}</b-link>
          </li>
        </ul>
      </div>

      <div v-if="task.media.length" class="task-create-body-card">
        <h4>MEDIA</h4>
        <b-list-group horizontal>
          <b-list-group-item
            class="attachment-media"
            v-for="(item, index) in task.media"
            :key="'task-media-' + index">
            <span v-if="item.type === 'transcoder'" class="attachment-media-item" @click="showVideoPlayer(item)">
              <thumbnail-attachment :data="item"/>
              <b-tooltip :target="'task-media-' + index" triggers="hover">
                {{ item.label }}
              </b-tooltip>
              <div class="attachment-media-item__label" :id="'task-media-' + index">{{ truncateString(item.label, 20) }}</div>
            </span>
            <a v-else :href="item.url" target="_blank" class="attachment-media-item">
              <thumbnail-attachment :data="item"/>
              <b-tooltip :target="'task-media-' + index" triggers="hover">
                {{ item.label }}
              </b-tooltip>
              <div class="attachment-media-item__label" :id="'task-media-' + index">{{ truncateString(item.label, 20) }}</div>
            </a>
          </b-list-group-item>
        </b-list-group>
      </div>

      <hr>
      <div v-if="!isActivityOrReflectionTask && !isIntroductionTask" class="task-create-body-card">
        <h4>Task Responses</h4>
        <custom-Radio v-model="task.response_type" :options="response_types" custom-class="common-input"
                    :disabled="true"/>
      </div>

      <div class="task-create-body-card" v-if="!isActivityOrReflectionTask && !isIntroductionTask">
        <h4>TARGET NUMBER OF RESPONSES</h4>
        <span class="text">{{ task.number_responses }}</span>
      </div>

      <div class="task-create-body-card" v-if="!isActivityOrReflectionTask">
        <h4>TIME LIMIT ON VIDEO POST</h4>
        <span class="text">{{ task.video_time_limit }} seconds</span>
      </div>

      <div class="task-create-body-card">
        <h4>VISIBLE</h4>
        <div class="container-visible">
          <img v-if="task.is_hidden"
               src="@/core/assets/x-mark.png"
               alt="x-mark"
               class="icon-mark">
          <img v-else
               src="@/core/assets/checkmark.png"
               alt="checkmark"
               class="icon-mark">
          <span class="text text--label-visible">(Students and teachers in both classrooms will {{task.is_hidden ? 'NOT' : ''}} see this task.)</span>
        </div>
      </div>

      <div class="task-create-body-card task-create-body-card--buttons">
        <b-button @click="onBack" btn-text="Cancel" style="margin-right: 10px"/>
        <b-button :disabled="classroomExchangeExpired"
                  @click="onEditTask"
                  btn-text="Edit"/>
      </div>
    </div>
    <video-player-popup :src="attachmentVideoSelect.url" :label="attachmentVideoSelect.label"/>
    <submission-detail-modal :task-id="task.id"
                             :is-create="task.isCreate"
                             :create-prompt="task.create_prompt"
                             :response-prompt="task.respond_prompt"
                             ref="submission-detail-modal"
                             @upload-video-success="uploadVideoSuccess"/>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button";
import {ExchangesMixins, TaskMixins} from "@/modules/MyClassrooms/Mixins"
import {ClassroomMixins} from "@/core/Mixins"
import Radio from "@/core/components/FormInput/Radio";
import ThumbnailAttachments from "@/core/components/Thumbnail/ThumbnailAttachments";
import videoPlayerPopup from '@/core/components/videoPlayerPopup/videoPlayerPopup';
import SubmissionDetailModal from "@/modules/Classroom/Components/SubmissionDetailModal"
import {taskCategories} from "@/core/Utils"

export default {
  name: "ClassroomDetailTabMyTaskDetail",
  mixins: [ExchangesMixins, TaskMixins, ClassroomMixins],
  components: {
    'b-button': Button,
    'custom-Radio': Radio,
    'thumbnail-attachment': ThumbnailAttachments,
    videoPlayerPopup,
    SubmissionDetailModal
  },
  data() {
    return {
      response_types: [
        {id: 1, name: 'Video Response'},
        {id: 2, name: 'Text Response'}
      ],
      attachmentVideoSelect: {}
    }
  },
  props: {
    task: [Array, Object],
  },
  methods: {
    showSubmissionDetail() {
      this.$refs['submission-detail-modal'].openSubmissionDetailModal()
    },
    uploadVideoSuccess() {
    },
    showVideoPlayer(attachment) {
      this.attachmentVideoSelect = attachment
      this.$bvModal.show('video-player-popup')
    },
    onBack() {
      this.$emit('on-change-component', 'ClassroomDetailTabMyTasksList')
    },
    onEditTask() {
      this.$emit('on-change-component', 'ClassroomDetailTabMyTasksCreate', this.task)
    },
    truncateString(str, num) {
      return str.length > num ? str.slice(0, num) + "..." : str
    },
  },
  computed: {
    tags() {
      return this.task.tags ? ('#' + this.task.tags.map(a => a.name).join(' #')) : '';
    },
    buttonLabel() {
      const isCreate = this.task?.isCreate
      const isCompleted = isCreate ? this.task?.isCreateTaskCompleted : this.task?.isResponseTaskCompleted
      return isCompleted ? 'View Video' : 'Upload Video'
    },
    isActivityOrReflectionTask () {
      return this.task.task_category === taskCategories.activity || this.task.task_category === taskCategories.reflection
    },
    isIntroductionTask() {
      return this.task.task_category === taskCategories.introduction
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.tab-container-task-create
  padding-left 10px
  padding-right 10px
  padding-bottom 80px
  padding-top 13px

hr
  margin 1rem 0

.task-create-body
  padding 0

  h3
    color color-8
    font font-opensans-bold
    font-size 24px
    margin-bottom 24px

  &-information
    padding 0 0 13px 0
    display flex

    .information-left
      width 35%

      ::v-deep
        .img-fluid
          min-width: 80px
          min-height: 80px
          object-fit cover

    .information-right
      padding-left 18px
      font font-opensans-regular
      font-size 14px
      color color-8

      div
        margin-bottom 11px

      .topic
      .tags
        font-size 16px

  &-card
    margin-bottom 30px

    &--description
      margin-top 30px

    &--teacher-instruction
      margin-top 54px

    &--buttons
      display flex
      justify-content center

    h4
      font font-opensans-semibold
      font-size 18px
      text-transform uppercase
      margin-bottom 9px
      color color-8

    .description
      font font-opensans-regular
      font-size 14px
      color color-21

    .text
      font font-opensans-regular
      color color-17
      font-size 14px

      &--date
        font font-opensans-semibold
        font-size 14px
        color color-8

    ul
      padding 0
      margin 0
      list-style-type none
      display block

      & > li
        border 0
        padding 0
        display block
        color color-3

        a
          font font-opensans-semibold
          font-size 14px
          text-decoration underline
          color color-34

  @media (min-width: 992px)
    padding-left 150px
    padding-right 150px

    &-information
      .information-left
      width 110px

      ::v-deep
        .img-fluid
          max-width 110px
          height: 110px
          min-width: 110px

    .information-right
      padding-left 30px

  @media (min-width: 1400px)
    padding-left 251px
    padding-right 251px


.task-create-back
  a
    font font-opensans-bold
    font-size 17px
    cursor pointer
    text-decoration none
    color color-3
    display flex
    align-items center

    span
      font-size 0.7em
      padding-right 5px

      &:before
        color color-3

.list-group
  overflow-x auto

.list-group-item-action.attachment-item
  width unset
  display flex
  flex-direction column
  align-items center
  margin-right 10px
  border 2px solid color-3 !important

  .attachment-item__label
    margin-top 6px
    font-weight 800
    text-transform uppercase
    font-size 14px

.attachment-media
  border 2px solid color-3 !important
  padding 0
  margin-right 10px
  cursor pointer

  .attachment-media-item
    width unset
    display flex
    flex-direction column
    align-items center
    position relative
    padding 0.75rem 1.25rem

    .attachment-media-item__label
      margin-top 6px
      font font-opensans-semibold
      font-size 14px
      color black
      text-transform uppercase

.link-label
  text-transform uppercase

.container-visible
  display flex

  .icon-mark
    width 38px

  .text--label-visible
    margin-left 15px
    display flex
    align-items center

.checkbox-reflection-task
  margin-bottom 30px

  ::v-deep
    label
      font font-opensans-semibold
      font-size 14px
      text-transform uppercase
      color color-8 !important

</style>
