<template>
  <div class="tab-container">
    <div class="message-actions">
      <b-button @click="onCreateMessage()"
                :disabled="classroomExchangeExpired"
                btn-text="Create Message"
                outline
                small/>
    </div>
    <b-overlay :show="sendingData" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">Please wait...</p>
          <b-spinner label="Spinning"></b-spinner>
        </div>
      </template>
      <classroom-detail-tab-filter :dropdown-view-show="false" :filters-by="filterBy" v-model="filters"
                                   @Tasks:Search="getMessagesList"></classroom-detail-tab-filter>
      <div class="message-body">

        <classroom-detail-tab-message-teacher
          :key="'teacher-' + index" v-for="(message, index) in messages"
          :message="message"
          :ref="'message-id-'+message.id"
          :isSelected="isCommentSelected(message)"
          @on-update-message-comment="onUpdateMessageComment">
        </classroom-detail-tab-message-teacher>
      </div>
    </b-overlay>

    <modal modal-class="modal-create-message" :hide-footer="true" ref="modalCreateMessage">
      <template #body>
        <b-overlay :show="sendingData" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">Please wait...</p>
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </template>

          <classroom-detail-create-message @on-cancel-create-message="onCancelCreateMessage"
                                           @on-post-message="onPostMessage"></classroom-detail-create-message>
        </b-overlay>
      </template>
    </modal>
    <modal ref="no_results_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">NO MESSAGES FOUND</h2>
        <p class="text-center">
          No messages were found matching the entered criteria.
        </p>
      </template>
      <template #footer>
        <b-button @click="closeNoResultsModal()" btn-text="OK"/>
      </template>
    </modal>
  </div>
</template>

<script>
import {ClassroomService, UploadService} from "@/core/Services";
import ClassroomDetailTabFilter from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabFilter";
import ClassroomDetailTabMessageTeacher from "./ClassroomDetailTabMessageTeacher"
import {Modal} from "@/core/components/Modal";
import {Utils} from "@/core/Utils";
import ClassroomDetailCreateMessage
  from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabMessageBoard/ClassroomDetailCreateMessage";
import {Button} from "@/core/components/Button";
import {Notifications, AppStorage} from "@/core/Utils";
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"


export default {
  name: "ClassroomDetailTabMessageBoard",
  mixins: [ExchangesMixins],
  components: {
    ClassroomDetailCreateMessage,
    ClassroomDetailTabMessageTeacher,
    ClassroomDetailTabFilter,
    Modal,
    'b-button': Button,
  },
  data() {
    return {
      filters: {
        filterBy: null,
        search_string: null,
      },
      filterBy: [
        {
          name: "A - Z",
          type: "order",
          value: "asc"
        },
        {
          name: "Z - A",
          type: "order",
          value: "desc"
        },
        {
          name: "Unapprove",
          type: "filter",
          value: "pending"
        },
        {
          name: "Approve",
          type: "filter",
          value: "approved"
        },
        {
          name: "All",
          type: "filter",
          value: "all"
        }
      ],
      messages: [],
      lastSearchStringWithResults: '',
      lastFilter: null,
      sendingData: false,
      commentSelected: [],
      interval: null,
      comment_id: null,
    }
  },
  props: {
    classroom: Object
  },
  mounted() {
    this.getMessagesList().then(() => {
      this.interval = setInterval(() => {
        this.getMessagesList(false)
      }, 15000)
    })
  },
  methods: {
    onCreateMessage() {
      this.$refs.modalCreateMessage.showModal()
    },
    getFilesToUpload(form) {
      let files = []

      for (let i = 0; i < form['media'].length; i++) {
        if (form['media'][i].file) {
          files.push({
            file: form['media'][i].file,
            index_file: i,
            type_file: 'media'
          })
        }
      }

      return files
    },
    onPostMessage(form) {
      if (this.sendingData) {
        return
      }

      let files = this.getFilesToUpload(form)
      if (files.length > 0) {
        this.sendingData = true

        UploadService.uploadMultipleFiles(files).then((result) => {
          for (let i = 0; i < result.length; i++) {
            let index_file = result[i].index_file,
              type_file = result[i].type_file

            form[type_file][index_file].url = result[i].name;
          }

          this.saveMessage(form)
        })
      } else {
        this.saveMessage(form)
      }
    },
    saveMessage(form) {
      this.sendingData = true
      this.commentSelected = []
      ClassroomService.createMessage({classroom_id: this.classroom.id, form: form}).then(() => {
        // this.messages.push(response.data)
        this.getMessagesList()
        this.$refs.modalCreateMessage.hideModal()
        this.sendingData = false
      })
    },
    onCancelCreateMessage() {
      this.$refs.modalCreateMessage.hideModal()
    },
    getMessagesList(showLoading = true) {
      if (showLoading) {
        this.sendingData = true
      }
      this.commentSelected = []
      return this.filterMessages(showLoading)
    },
    closeNoResultsModal() {
      this.$refs["no_results_modal"].hideModal()
      ClassroomService.getMessages({classroom_id: this.classroom.id}).then((response) => {
        this.messages = response.data
      })
      this.sendingData = false
    },
    onUpdateMessageComment(comment_update) {
      let {message, comment, status} = comment_update
      let messageIndex = this.messages.indexOf(message)
      let commentIndex = this.messages[messageIndex].comments.indexOf(comment)

      ClassroomService.updateMessageStatus({
        classroom_id: this.classroom.id,
        message_id: comment.id,
        status: status
      }).then((response) => {
        this.$set(this.messages[messageIndex].comments, commentIndex, response.data)
      })
    },
    filterMessages(showLoading = true) {
      if (showLoading) {
        this.sendingData = true
      }
      return ClassroomService.getMessages({classroom_id: this.classroom.id}).then((response) => {
        if (parseInt(this.comment_id)) {
          this.messages = response.data.map((message) => {
            if (message.comments.length) {
              message.comments = message.comments.map((comment) => {
                if (comment.id === parseInt(this.comment_id)) {
                  comment.highlightedComment = true
                }
                return comment;
              })
            }
            return message
          })
        } else {
          this.messages = response.data
        }

        if (this.filters.filterBy &&
          (this.filters.filterBy.value === 'approved' || this.filters.filterBy.value === 'pending')) {
          this.messages = this.messages.filter((message) => {
            if (message.comments) {
              message.comments.forEach(comment => {
                comment.status === this.filters.filterBy.value ? this.commentSelected.push(comment.id) : ''
              })
            }
            return message.status === this.filters.filterBy.value
          })
        }
        if (this.filters.search_string && this.filters.search_string.length > 0) {
          this.messages = this.messages.filter((message) => {
            if (message.comments) {
              message.comments.forEach(comment => {
                (comment.message_text.includes(this.filters.search_string) || comment.message_title.includes(this.filters.search_string)) ? this.commentSelected.push(comment.id) : ''
              })
            }
            return message.message_text.includes(this.filters.search_string) || message.message_title.includes(this.filters.search_string)
          })

        }
        if (this.filters.filterBy &&
          (this.filters.filterBy.type === 'order')) {

          const order = this.filters.filterBy.value === 'desc'
          const messageSort = Utils.sortBy('message_title', order)
          this.messages.sort(messageSort)
        }

        if ((this.messages && this.messages.length > 0) || (!this.filters.filterBy && !this.filters.search_string)) {

          this.lastSearchStringWithResults = this.filters.search_string
          this.lastFilter = this.filters.filterBy
        } else {
          this.filters = {
            search_string: this.lastSearchStringWithResults,
            filterBy: this.lastFilter
          }
          this.$refs["no_results_modal"].showModal()
        }
      }).finally(() => {
        this.sendingData = false
      })
    },
    isCommentSelected(message) {
      if (message.comments.length > 0) {
        const messages = message.comments.filter(el => this.commentSelected.includes(el.id))
        return messages.length > 0
      } else {
        return false
      }
    }
  },
  watch: {
    '$route.query.notification_id': {
      handler: function (notification_id) {
        if (notification_id) {
          const notificationOpen = AppStorage.getNotificationOpen(notification_id);

          if (notificationOpen.type === Notifications.type.studentTeacherStatusMessage) {
            if (notificationOpen.object_id) {
              if (typeof notificationOpen.object_id === 'string') {
                this.comment_id = parseInt(notificationOpen.object_id)
              } else {
                this.comment_id = notificationOpen.object_id
              }
            }

            AppStorage.removeNotificationOpen(notification_id);
          }
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"


.tab-container
  padding-left 10px
  padding-right 10px
  padding-bottom 64px
  padding-top 41px

.message-error
  flexbox(flex)
  justify-content(center)
  margin 16px

@media (min-width: 1200px)
  .tab-container
    padding-left 112px
    padding-right 112px


.message-actions
  flexbox(flex)
  justify-content(end)
  padding-bottom 17px

  .btn
    margin-left 5px

.message-body
  margin-top 39px

</style>
