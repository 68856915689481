<template>
  <fragment>
    <b-button @click="onShowModal"
              :disabled="classroomExchangeExpired"
              btn-text="Edit"
              class="btn-edit"/>
    <modal id="edit-student-modal">
      <template #body>
        <b-overlay :show="sendingData" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">Please wait...</p>
              <b-spinner label="Spinning"/>
            </div>
          </template>
          <h2 class="modal-title">EDIT GROUP NAME</h2>
          <p class="modal-label">Name</p>
          <form-Input type="text"
                      :maxlength="25"
                      v-model="$v.form.first_name.$model"
                      :validator="$v.form.first_name"
                      custom-class="common-input common-input--primary"
                      placeholder="Name *"/>
        </b-overlay>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('edit-student-modal')" btn-text="CANCEL"/>
        <b-button @click="saveGroupName()" btn-text="SAVE" :disabled="sendingData || !isNewGroupName"/>
      </template>
    </modal>
  </fragment>
</template>

<script>
import {ClassroomService} from "@/core/Services";
import {Modal} from "@/core/components/Modal";
import {Button} from "@/core/components/Button";
import FormInput from "@/core/components/Form/Input"
import {required, maxLength} from "vuelidate/lib/validators"
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"

export default {
  name: "ClassroomDetailGroupEdit",
  mixins: [ExchangesMixins],
  components: {
    Modal,
    FormInput,
    'b-button': Button,
  },
  data() {
    return {
      form: {
        first_name: '',
      },
      sendingData: false
    }
  },
  props: {
    classroom: Object,
    student: Object
  },
  validations() {
    return {
      form: {
        first_name: {
          required,
          maxLength: maxLength(25),
          alphaWithSpaces: function(value) {
            return /^[a-zA-Z\s]+$/.test(value)
          }
        }
      }
    }
  },
  methods: {
    saveGroupName() {
      this.$v.$touch()
      if (this.$v.form.first_name.$anyError) {
        return
      }
      this.sendingData = true

      const group = {
        id: this.student.id,
        groupName: this.form.first_name
      }

      const data = {classroom_id: this.classroom.id, data: group}

      ClassroomService.updateGroupName(data)
        .then((resp) => {
          if (resp.success) {
            this.$bvModal.msgBoxOk('Success!', {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            }).then(() => {
              this.$v.$reset()
              this.$emit('on-edit-student-name', {...this.form})
              this.$bvModal.hide('edit-student-modal')
            })
          } else {
            const message = resp.message ? resp.message : 'Error editing group name.'
            this.$bvModal.msgBoxOk(message, {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          }
        })
        .catch(error => {
          this.$bvModal.msgBoxOk(error.message, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        })
        .finally(() => this.sendingData = false)
    },
    onShowModal() {
      this.form =  {
        first_name: this.student.first_name
      }
      this.$bvModal.show('edit-student-modal')
    }
  },
  computed: {
    isNewGroupName() {
      return (this.form.first_name?.trim() !== this.student.first_name) && !this.$v.form.first_name.$anyError
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn-edit
  min-width 0
  font-size 13px
  margin-left 28px
  @media(max-width 500px)
    margin-left 0
    margin 10px 0

.modal-label
  font-size 16px
  margin-bottom 10px
</style>
