<template>
  <div class="tab-container-submissions">
    <div class="container-submissions-actions">
      <classroom-detail-tab-filter v-model="filters"
                                   :filters-by="filterByValues"
                                   ref="classroom-detail-tab-filter"
                                   :default-filters-by="defaultFiltersBy"
                                   :is-submissions-tab="true"
                                   default-view='students'
                                   :field-search-show="false"/>
      <div class="action-right">
        <select-dropdown v-if="actions.length && !isPartnerStudentsSelected"
                         v-model="action"
                         :disabled="classroomExchangeExpired"
                         @input="showModalAction"
                         value-field="value"
                         placeholder="Video Approval"
                         :options="actions" class-select="custom-dropdown--md custom-dropdown--secondary"/>
      </div>
    </div>
    <div class="container-submissions-actions">
      <div class="action-left">
        <b-button v-if="!isPartnerStudentsSelected"
                  @click="selectAllVideos"
                  btn-text="select all"
                  class="common-btn-small common-btn-small--primary"
                  small
                  outline/>
      </div>
    </div>

    <teacher-table table-class-content="table-body-submission"
                   :rows="taskVideos"
                   :view="currentView"
                   :loading-teacher-table="loadingTeacherTable"
                   @on-table-row="({row}) => showModal(row)">
      <template #tableHeader>
        <div class="column column-select" v-if="!isPartnerStudentsSelected">Select</div>
        <div class="column column-select-mobile" v-if="!isPartnerStudentsSelected"></div>
        <div class="column column-image"></div>
        <div class="column column-status">
          <div class="order" v-if="false">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          <span class="text">Status</span>
        </div>
        <div class="column column-student">
          <div class="order" v-if="false">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          <span class="text">{{isSmallGroupType ? 'Name' : 'Student Name'}}</span>
        </div>
        <div class="column column-task">
          <div class="order" v-if="false">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          <span class="text">Task Name</span>
        </div>
        <div class="column column-type">
          <div class="order" v-if="false">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          <span class="text">TYPE</span>
        </div>
        <div class="column column-date">
          <div class="order" v-if="false">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          <span class="text">Date</span>
        </div>
        <div class="column play"></div>
      </template>
      <template v-slot:tableRowList="slotProps">
        <div class="column column-select"
             @click.stop=""
             v-if="!isPartnerStudentsSelected">
          <b-form-checkbox class="custom-checkbox-primary"
                           v-model="selectedVideos['task_'+slotProps.row.id]"/>
        </div>
        <div class="column column-select-mobile"
             @click.stop=""
             v-if="!isPartnerStudentsSelected">
          <b-form-checkbox class="custom-checkbox-primary"
                           v-model="selectedVideos['task_'+slotProps.row.id]"/>
        </div>
        <div class="column column-image">
          <b-avatar :src="slotProps.row.student_thumbnail" icon="people-fill" rounded></b-avatar>
        </div>
        <div class="column column-status">
          <span v-if="slotProps.row.status === 'pending'" class="label">Submitted</span>
          <span v-if="slotProps.row.status === 'pending'" class="label-desc">(Pending Review)</span>
          <span v-else class="label">{{ slotProps.row.status }}</span>
        </div>
        <div class="column column-student">
          {{ slotProps.row.student_first_name + ' ' + (isIndividualGroupType ? slotProps.row.student_last_initial : '') }}
        </div>
        <div class="column column-task">{{ slotProps.row.task_name }}</div>
        <div class="column column-type">
          <span v-if="slotProps.row.is_response" class="label">Response</span>
          <span v-else class="label">Submission</span>
        </div>
        <div class="column column-date">{{ slotProps.row.created_at | dateformatDDMMYYYY }}</div>
        <div class="column column-play" @click="showModal(slotProps.row)">
          <span class="icon-play-circle"></span>
        </div>
      </template>
      <template v-slot:tableRowGrid="slotProps">
        <div class="column">
          <SubmissionsGridViewItem :submission="slotProps.row"
                                   :selectedVideo="selectedVideos['task_'+slotProps.row.id] || 0"
                                   @on-show-submission-modal="showModal"
                                   @on-selected-video="onSelectedVideo"/>
        </div>
      </template>
    </teacher-table>
    <modal modal-class="modal-detail-submission"
           :hide-footer="true"
           @hide="closeModalDetailSubmission"
           id="view-detail-submission"
           ref="viewDetailSubmission">
      <template #body>
        <StudentSubmissionModal v-if="!isResponseVideo"
                                :is-submission-tab="true"
                                :classroom_id="classroom_id"
                                :selectedVideo="submissionSelected"
                                :loading="loadingSubmissionSelected"
                                :disabled-next-button="disabledNextButton"
                                :show-actions="!isPartnerStudentsSelected"
                                ref="studentSubmissionModal"
                                @save-comment="saveComment"
                                @on-close-submission-modal="hideStudentSubmissionModal"
                                @next-submission="onNextSubmission"
                                @applyAction="updateStatusVideo"/>

        <StudentResponseModal v-else
                              :is-submission-tab="true"
                              :classroom_id="classroom_id"
                              :selectedVideo="submissionSelected"
                              :loading="loadingSubmissionSelected"
                              :disabled-next-button="disabledNextButton"
                              :show-actions="!isPartnerStudentsSelected"
                              ref="studentResponseModal"
                              @save-comment="saveComment"
                              @on-close-submission-modal="hideStudentSubmissionModal"
                              @next-submission="onNextSubmission"
                              @applyAction="updateStatusVideo"/>
      </template>
    </modal>

    <modal modal-class="modal-action-confirmation-submissiones" ref="modalActionConfirmation">
      <template #body>
        <div>The selected {{ selectedVideosLength }} videos will be changed to {{ action }} status. Would you like to continue?</div>
      </template>
      <template #footer>
        <b-button @click="applyAction"
                  class="action-submissions-button"
                  btn-text="Ok"/>
        <b-button @click="onHideActionModal"
                  class="action-submissions-button"
                  btn-text="Cancel"/>
      </template>
    </modal>
  </div>
</template>

<script>
import StudentSubmissionModal from "@/modules/Classroom/Components/StudentSubmissionModal";
import StudentResponseModal from "@/modules/Classroom/Components/StudentResponseModal";
import ClassroomDetailTabFilter from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabFilter";
import TeacherTable from "@/modules/Classroom/Components/TeacherTable";
import SubmissionsGridViewItem from "@/modules/Classroom/Components/SubmissionsGridViewItem";
import {SelectDropdown} from "@/core/components/SelectDropdown";
import {ClassroomService, TrackingActivityService} from "@/core/Services";
import {Modal} from "@/core/components/Modal";
import {TasksMixins, CourseMixins} from "@/modules/Classroom/Mixins";
import {Button} from "@/core/components/Button";
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"
import {statusOptions, actionOptions} from "./constantsTabSubmissions"
import {TrackingTypes} from "@/core/Utils"

export default {
  name: "ClassroomDetailTabSubmissions",
  mixins: [TasksMixins, CourseMixins, ExchangesMixins],
  components: {
    StudentSubmissionModal,
    StudentResponseModal,
    Modal,
    TeacherTable,
    SubmissionsGridViewItem,
    ClassroomDetailTabFilter,
    'select-dropdown': SelectDropdown,
    'b-button': Button,
  },
  data() {
    let defaultFiltersBy = this.$route.query.filterBy

    if (!defaultFiltersBy) {
      defaultFiltersBy = this.classroom['allowing_post_without_approval'] ? 'all' : 'pending'
    }
    return {
      filters: {
        filterBy: null,
        view: {},
        search: null,
      },
      defaultFiltersBy: defaultFiltersBy,
      selectedVideos: {},
      selectedVideoIndex: 0,
      submissionSelected: null,
      submissionSelectedId: null,
      isResponseVideo: null,
      action: '',
      actions: actionOptions,
      filtersBy: statusOptions,
      videos: [],
      interval: null,
      loadingSubmissions: false,
      loadingSubmissionSelected: false,
      loadingTeacherTable: false
    }
  },
  props: {
    student: Object,
    classroom: Object,
  },
  async mounted() {
    const submissionId = this.$route.query.submissionId
    if (submissionId) {
      this.submissionSelected = await this.getSubmissionSelected(submissionId)
      this.submissionSelected && this.showModal(this.submissionSelected)
    }
    this.getTasksList({classroom_id: this.classroom_id}).then(() => {
      this.getVideoList().then(() => {
        this.interval = setInterval(() => {
          if (!this.loadingSubmissions) {
            this.getVideoList(false)
          }
        }, 15000)
      })
    })
    if (this.isWholeClassGroupType) {
      this.$refs['classroom-detail-tab-filter'].setDefaultFilter('all')
    }
  },
  methods: {
    async saveComment() {
      this.submissionSelected = await this.getSubmissionSelected()
    },
    onHideActionModal() {
      this.action = ''
      this.$refs.modalActionConfirmation.hideModal()
    },
    async showModal(video) {
      this.loadingTeacherTable = true
      this.selectedVideoIndex = await this.videos.indexOf(video)
      this.submissionSelectedId = video.id
      this.isResponseVideo = video.is_response

      this.submissionSelected = await this.getSubmissionSelected()
      this.$refs.viewDetailSubmission.showModal()

      this.loadingTeacherTable = false
    },
    closeModal() {
      this.$refs['viewDetailSubmission'].hideModal()
      this.$refs['viewDetailResponse'].hideModal()
    },
    async closeModalDetailSubmission(e) {
      e.trigger !== "event" && e.preventDefault()

      let validationResponse

      if (!this.isResponseVideo) {
        validationResponse = await this.$refs.studentSubmissionModal.validateSavedComment(true)
      } else {
        validationResponse = await this.$refs.studentResponseModal.validateSavedComment(true)
      }

      if (validationResponse === undefined) {
        this.hideStudentSubmissionModal()
      }
    },
    hideStudentSubmissionModal() {
      this.$bvModal.hide('view-detail-submission')
    },
    async getVideoList(showOverlay = true) {
      showOverlay && (this.loadingTeacherTable = true)
      let filterBy = 'all'

      if (this.filters?.filterBy?.value) {
        filterBy = this.filters.filterBy.value
      }

      const data = {
        classroom_id: this.classroom_id,
        filter_by: filterBy
      }

      const response = this.isPartnerStudentsSelected ? await ClassroomService.getPartnerVideos(data) : await ClassroomService.getSubmissions(data)

      if (typeof response.data[Symbol.iterator] === 'function') {
        this.videos = response.data
      } else {
        this.videos = []
      }

      let newSelection = {}

      for (let i = 0; i < this.videos.length; i++) {
        newSelection['task_' + this.videos[i].id] = false
      }

      this.videos.map((video) => {
        for (const [key, value] of Object.entries(this.selectedVideos)) {
          let video_id = Number(key.split('_')[1])
          if (video.id === video_id && value) {
            newSelection['task_' + video.id] = true
          }
        }
        this.selectedVideos = newSelection
      })
      showOverlay && (this.loadingTeacherTable = false)
    },
    selectAllVideos() {
      let newSelection = {}

      if (!Object.keys(this.selectedVideos).length) {
        this.onCleanSelection()
      }

      if (Object.keys(this.selectedVideos).every((k) => this.selectedVideos[k])) {
        for (let i = 0; i < this.videos.length; i++) {
          newSelection['task_' + this.videos[i].id] = false
        }
      } else {
        for (let i = 0; i < this.videos.length; i++) {
          newSelection['task_' + this.videos[i].id] = true
        }
      }

      this.selectedVideos = newSelection
    },
    showModalAction() {
      this.selectedVideosLength !== 0 && this.$refs.modalActionConfirmation.showModal()
    },
    applyAction() {
      let selectedVideos = []

      for (const [key, value] of Object.entries(this.selectedVideos)) {
        if (value === true) {
          let video_id = key.split('_')[1]
          selectedVideos.push(video_id)
        }
      }

      if (this.action && selectedVideos.length) {
        this.updateStatusVideo({action: this.action, videos: selectedVideos})
      }

      this.onCleanSelection()
      this.action = ''
      this.$refs.modalActionConfirmation && this.$refs.modalActionConfirmation.hideModal()
    },
    onCleanSelection () {
      let cleanSelection = {}
      for (let i = 0; i < this.videos.length; i++) {
        cleanSelection['task_' + this.videos[i].id] = false
      }
      this.selectedVideos = cleanSelection
    },
    updateStatusVideo(params) {
      this.loadingSubmissionSelected = true
      ClassroomService.setSubmissionsStatus({
        classroom_id: this.classroomId,
        form: params
      }).then(() => {
        this.getVideoList().finally(async () => {

          this.submissionSelected = await this.getSubmissionSelected()

          this.loadingSubmissionSelected = false
        })
      })
      TrackingActivityService.trackingActivityTeachers(TrackingTypes.changeSubmissionStatus)
    },
    async getSubmissionSelected(submissionId) {
      const data = {
        classroom_id: this.classroom.id,
        submission_id: submissionId ? submissionId : this.submissionSelectedId
      }
      let responseData
      if (this.isPartnerStudentsSelected) {
        await ClassroomService.getPartnerVideoById(data).then(({data}) => {
          responseData = data[0]
        })
      } else {
        await ClassroomService.getSubmission(data).then(({data}) => {
          responseData = data
        })
      }
      return responseData
    },
    async onNextSubmission() {
      this.loadingSubmissionSelected = true
      if (this.videos.find((video) => video.id === this.submissionSelected.id)) {
        this.selectedVideoIndex += 1
      }
      if (this.selectedVideoIndex === this.videos.length) {
        this.selectedVideoIndex = 0
      }
      this.submissionSelectedId = await this.videos.at(this.selectedVideoIndex).id
      const  submissionSelected = await this.getSubmissionSelected()
      this.isResponseVideo = submissionSelected.is_response
      this.submissionSelected = submissionSelected
      this.loadingSubmissionSelected = false
    },
    onSelectedVideo(video) {
      const newSelectionVideo = this.selectedVideos
      newSelectionVideo['task_' + video.id] = !!video.value

      this.selectedVideos = newSelectionVideo
    }
  },
  computed: {
    taskVideos: function () {
      return this.videos
    },
    classroom_id: function () {
      return this.classroom.id ?? null
    },
    selectedVideosLength: function () {
      return Object.values(this.selectedVideos).filter((video) => video === true).length
    },
    disabledNextButton () {
      if (!this.videos.find((video) => video.id === this.submissionSelected.id) && this.videos.length === 1) return false
      return this.videos.length <= 1
    },
    isPartnerStudentsSelected() {
      return this.filters?.view?.value === 'partner-students'
    },
    currentView() {
      return 'list'
    },
    isWholeClassroom(){
      return this.myClassroom.course_student_group_type_id === 3
    },
    filterByValues(){
      if(this.isWholeClassroom){
        return this.filtersBy.filter(f => f.name !== 'Pending')
      }

      return this.filtersBy
    }
  },
  watch: {
    tasks: {
      handler(newTasks) {
        const visibleTasks = newTasks.filter(task => !task.is_hidden)
        const taskOptions = visibleTasks.map(t => {
          return {
            name: t.task_name,
            value: t.id
          }
        })

        this.filtersBy = [...this.filtersBy, ...taskOptions]
      }
    },
    async 'filters.filterBy'() {
      await this.getVideoList()

      const newSelection = {}

      for (let i = 0; i < this.videos.length; i++) {
        newSelection['task_' + this.videos[i].id] = false
      }
      this.selectedVideos = newSelection
    },
    isPartnerStudentsSelected (newValue) {
      if (newValue) {
      this.filtersBy = [
        {name: "Approved", value: "approved"},
      ]
      this.$refs['classroom-detail-tab-filter'].setDefaultFilter('approved')
     } else {
      this.filtersBy = statusOptions
      if (this.isWholeClassGroupType) {
        this.$refs['classroom-detail-tab-filter'].setDefaultFilter('all')
      } else {
        this.$refs['classroom-detail-tab-filter'].setDefaultFilter('pending')
      }
     }
     this.getTasksList({classroom_id: this.classroom_id})
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.tab-container-submissions
  padding-left 10px
  padding-right 10px
  padding-bottom 59px
  padding-top 41px

  .container-submissions-actions
    flexbox(flex)
    align-items(center)
    margin-top 8px
    margin-bottom 15px
    flex-direction(column)

    ::v-deep
      .dropdown-item
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        padding 6px 20px 6px 20px

    .action-right
      width 85%
      flexbox(flex)
      flex-direction(column)
      align-items(center)
      flex 1

      ::v-deep
        .btn-common--small
          margin-top 10px
          font-size 12px
          line-height 15px
          padding 9px 30px
          letter-spacing 0

      .custom-dropdown
        padding-right 10px

      @media (min-width: 768px)
        flex-direction(row)
        .custom-dropdown
          flex-basis 35%
          margin-right 8px
          min-width 165px

    .action-left
      align-self flex-start
      margin-bottom -6px

      ::v-deep
        .btn-common--small
          margin-top 10px
          font-size 11px
          line-height 15px
          padding 4px 12px
          letter-spacing 0

    ::v-deep
      .task-filter .custom-dropdown span
        font-size 14px

    @media (min-width: 768px)
      flex-direction(row)
      margin-bottom 30px

      .action-right
        justify-content(end)
        margin-top -5px

      .action-right, .action-left
        ::v-deep
          .btn-common--small
            margin-top 0
            font-size 10.5px
            line-height 15px
            padding 4px 10px

    @media (min-width: 992px)
      .action-right, .action-left
        ::v-deep
          .btn-common--small
            font-size 12px
            padding 9px 18px

    @media (min-width: 1200px)
      .action-right, .action-left
        ::v-deep
          .btn-common--small
            padding 9px 30px

::v-deep
  .task-filter
    flex-direction column
    margin 0
    width 85%

    .custom-dropdown
      padding-right 8px
      height 40px

    @media (min-width: 768px)
      flex-direction(row)
      width auto
      .custom-dropdown
        flex-basis 30%
        padding-right 8px
        width 165px
    @media (min-width: 992px)
      width 50%

@media (min-width: 992px)
  .tab-container-submissions
    padding-left 70px
    padding-right 70px

@media (min-width: 1200px)
  .tab-container-submissions
    padding-left 145px
    padding-right 150px

.table-body-submission
  overflow-x auto

  ::v-deep
    .table-body-view
      min-width 700px

      &.list
        .table-row
          padding 6px 8px
          align-items(center)
          margin-bottom 6px
          border 1px solid color-28
          color color-8

          &:hover:not(.header)
            background-color row-color-hover

          &.header
            margin-bottom 3px
            border 0
            text-transform uppercase

            .column
              font font-opensans-bold
              font-size 0.5rem
              display flex
              align-items(center)

              .order
                display flex
                flex-direction(column)
                align-items(center)
                justify-content(center)
                width 16px
                padding-right 3px

                &-bottom
                &-top
                  font-size 0.6rem

              &-select
                display none

          .column
            padding-top 0

            .text
              margin-left 12px

            &-select
              display none
              text-align center

            &-select-mobile
              width 20px
              margin-left 0
              text-align center

            &-image
              width 35px
              margin-right 2px
              margin-left 3px

              .b-avatar
                width 30px
                height 30px

            &-status
            &-type
              flex-basis(15%)
              font font-opensans-bold
              font-size 0.5rem
              margin-right 3px

              .label
                display block
                width 100%

              .label-desc
                display block
                width 100%
                font font-opensans-regular
                font-style italic
                font-size 0.5rem

            &-task
              flex-basis(18%)
              font font-opensans-regular
              font-size 0.5rem
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
              width 45px

            &-type
              overflow hidden
              text-overflow ellipsis
              white-space nowrap

            &-date
            &-student
              flex-basis(19%)
              font font-opensans-regular
              font-size 0.5rem

            &-date
              flex-basis(15%)
              margin-right 5px

            &-play
              width 20px

              span
                &:before
                  color color-3

          &:not(.header)
            .column
              &-status
              &-task
              &-date
              &-student
              &-date
              &-type
                padding-left 0.9rem

      &.grid
        grid-template-columns: 1fr
        column-gap 20px
        row-gap 1em

        .table-row
          background-color rgba(color-29, 0.35)
          padding 0
          flex-direction(column)
          border-radius 4px
          overflow hidden
          position relative

          .column
            padding-top 120%


          .content-image
            position absolute
            bottom 0
            width 100%
            height 100%

    @media (min-width: 450px)
      .table-body-view
        min-width 850px

        &.grid
          grid-template-columns: 1fr 1fr
          column-gap 20px
          row-gap 1em

        &.list
          .table-row
            &.header
              .column
                font-size 0.7rem

                &-select
                  display block

                &-select-mobile
                  display none

            .column
              &-select
                display block
                width 30px

              &-select-mobile
                display none

              &-image
                width 40px

                .b-avatar
                  width 35px
                  height 38px
                  border-radius 2px

              &-status
              &-task
              &-date
              &-student
              &-type
                font-size 0.65rem

              &-status .label-desc
                font-size 0.65rem

              &-play
                flex(1)
                text-align right

                span
                  font-size 1.2rem


    @media (min-width: 768px)
      .table-body-view
        &.grid
          grid-template-columns: 1fr 1fr 1fr
          column-gap 20px
          row-gap 1em

        &.list
          .table-row
            &.header
              .column
                font-size 0.9rem

                &-select
                  display block

                &-select-mobile
                  display none

            .column
              &-select
                display block
                width 50px

              &-select-mobile
                display none

              &-image
                width 60px

                .b-avatar
                  width 48px
                  height 44px

              &-task
              &-date
              &-student
                font-size 0.8rem
                flex-basis 19%

              &-date
                font-size 0.8rem
                flex-basis 15%

              &-status
                font-size 0.8rem
                flex-basis 12%

              &-type
                font-size 0.8rem
                flex-basis 12%

              &-status .label-desc
                font-size 0.8rem

              &-play
                flex(1)
                text-align center

                span
                  font-size 1.5rem

    @media (min-width: 1400px)
      .table-body-view
        min-width 900px

        &.list
          .table-row
            .column
              &-select
                width 60px

              &-image
                width 65px

              &-task
              &-date
              &-student
                font-size 0.9rem
                flex-basis 20%

              &-date
                font-size 0.9rem
                flex-basis 10%

              &-status
                font-size 0.8rem
                flex-basis 10%

              &-type
                font-size 0.8rem
                flex-basis 15%

@media (max-width 450px)
  .font-size-btn
    font-size 10px

</style>

<style lang="stylus">
.modal-action-confirmation-submissiones
  text-align center

  .action-submissions-button
    margin 0 15px

    @media (max-width 450px)
      margin 0.25rem

#view-detail-submission
  .modal-dialog
    max-width 95%

    .close
      position relative
      z-index 1

    .body
      padding 0 20px
</style>
