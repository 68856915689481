import {http} from './http'
const resource = "/api/teacher";

export default {
  getAlert() {
    return http.get(`${resource}/alerts`).then(response => {
        return response
      }
    );
  },
}
