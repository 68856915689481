import { render, staticRenderFns } from "./TeacherWizard.vue?vue&type=template&id=b130a71e&scoped=true&"
import script from "./TeacherWizard.vue?vue&type=script&lang=js&"
export * from "./TeacherWizard.vue?vue&type=script&lang=js&"
import style0 from "./TeacherWizard.vue?vue&type=style&index=0&id=b130a71e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b130a71e",
  null
  
)

export default component.exports