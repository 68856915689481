<template>
  <fragment>
    <div class="header-wrapper">
      <div class="header-title">
        <h1 class="title">{{ classroom.name }}</h1>
        <div class="actions">
          Classroom Code: <copy-text-element class="code" :text="classroom.code"/>
        </div>
        <a class="teacher-guide-link"
           :href="courseGuideLink"
           v-if="courseGuideLink"
           target="_blank">View Teacher Guide</a>
      </div>
      <b-button @click="onMasquerade" v-if="false" class="button-masquerade" btn-text="Upload Student Videos"/>
    </div>
    <masquerade-whole-class ref="masqueradeStudentModal"/>
  </fragment>
</template>

<script>
import CopyTextElement from "@/core/components/Copy/CopyTextElement"
import {Button} from "@/core/components/Button"
import {CourseMixins} from "@/modules/Classroom/Mixins"
import MasqueradeWholeClass from '@/core/components/MasqueradeWholeClass/MasqueradeWholeClass';

export default {
  name: "ClassroomDetailHeader",
  mixins: [CourseMixins],
  components: {
    CopyTextElement,
    'b-button': Button,
    MasqueradeWholeClass
    },
  props: {
    classroom: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    onMasquerade () {
      this.$refs.masqueradeStudentModal.onMasquerade({classroomId: this.classroom.id})
    }
  },
  computed: {
    courseGuideLink() {
      return this.classroom.course?.course_guide_link
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/common.styl"

.header-wrapper
  display flex
  justify-content space-between
  @media (max-width: 500px)
    flex-direction column

  .header-title
    margin-bottom 40px

    .title
      @extend .security-form-subtitle
      text-align left
      margin 0
      word-break break-all

    .actions
      font font-opensans-regular
      font-size 13px

      .code
        font font-opensans-bold
        font-size 13px

    .teacher-guide-link
      font font-opensans-semibold
      font-size 13px
      text-decoration underline

  @media (min-width: 768px)
    .header-title
      flex-direction row
      height auto
      justify-content space-between

  .button-masquerade
    height fit-content
    font-size 14px

</style>

<style lang="stylus">
#modal-masquerade-whole-class
  .body
    padding-bottom 20px

    .masquerade-link
      word-wrap break-word
      text-decoration underline
</style>
