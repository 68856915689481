<template>
  <div class="student-activity">
    <h2 class="title">{{ title }}</h2>
    <div class="student-activity-content">
      <div class="task-container" v-if="!!this.taskList.length">
        <div class="task-header">
          <h1>Task: {{ taskSelected.task_name }}</h1>
          <div class="buttons-nav">
            <span class="icon-dropdown" @click="counter--" v-if="hideDecrement"/>
            <span class="icon-dropdown icon-dropdown--right" @click="counter++" v-if="hideIncrement"/>
          </div>
        </div>
        <div class="task-card">
          <div class="task-content task-content--create">
            <p class="task-card__title">Create Video</p>
            <div class="task-values" :class="{'task-values--whole-classroom': isWholeClassGroupType }">
              <div class="due-date">
                <div class="status" v-if="!!taskSelected.due_date" :class="{'status--whole-classroom': isWholeClassGroupType }">
                  <div class="status-text">DUE</div>
                  <div class="status-text status-text--mm"
                       :class="{'status-text--expired': taskExpired(taskSelected.due_date)}">
                    {{ setDueDate(taskSelected.due_date, 'MMM') }}
                  </div>
                  <div class="status-text status-text--dd"
                       :class="{'status-text--expired': taskExpired(taskSelected.due_date)}">
                    {{ setDueDate(taskSelected.due_date, 'DD') }}
                  </div>
                </div>
                <div v-else class="status status--to-do" :class="{'status--whole-classroom': isWholeClassGroupType }">
                  <div class="status-text">to-do</div>
                </div>
              </div>
              <div class="task-value submitted" :class="{'submitted--whole-classroom': isWholeClassGroupType}">
                <div class="amount">
                  <div class="icon-value submitted" :class="submittedCompleted(taskSelected.percent_submitted_create_video)"/>
                  <p>{{ taskSelected.percent_submitted_create_video }}% {{!isWholeClassGroupType ? 'of Students' : ''}}</p>
                </div>
                <p class="type-value">Submitted</p>
              </div>
              <div class="task-value pending" v-if="!isWholeClassGroupType">
                <div class="amount">
                  <div class="icon-value pending"/>
                  <p>{{ taskSelected.number_pending_create_video }}</p>
                </div>
                <p class="type-value">Pending Approval</p>
              </div>

              <div class="task-value inactive"
                   id="popover-create-students-list"
                   v-if="!isWholeClassGroupType"
                   :style="{'pointer-events': !taskSelected.inactive_create_video_students.length ? 'none' : ''}">
                <div class="amount">
                  <div class="icon-value inactive"/>
                  <p>{{ taskSelected.inactive_create_video_students.length }}</p>
                </div>
                <p class="type-value">Inactive Students</p>
              </div>

              <b-popover target="popover-create-students-list"
                         v-if="!isWholeClassGroupType"
                         triggers="hover"
                         placement="top">
                <ul class="inactive-student-list">
                  <li v-for="(student, idx) in taskSelected.inactive_create_video_students" :key="student.id + idx">
                    {{ student.first_name + ' ' + student.last_initial }}
                  </li>
                </ul>
              </b-popover>
            </div>
          </div>
          <div class="parting-line"/>
          <div class="task-content task-content--respond">
            <p class="task-card__title">Respond to Global Partners</p>
            <div class="task-values" :class="{'task-values--whole-classroom': isWholeClassGroupType }">
              <div class="due-date">
                <div class="status" v-if="!!taskSelected.response_due_date" :class="{'status--whole-classroom': isWholeClassGroupType }">
                  <div class="status-text">DUE</div>
                  <div class="status-text status-text--mm"
                       :class="{'status-text--expired': taskExpired(taskSelected.response_due_date)}">
                    {{ setDueDate(taskSelected.response_due_date, 'MMM') }}
                  </div>
                  <div class="status-text status-text--dd"
                       :class="{'status-text--expired': taskExpired(taskSelected.response_due_date)}">
                    {{ setDueDate(taskSelected.response_due_date, 'DD') }}
                  </div>
                </div>
                <div v-else class="status status--to-do" :class="{'status--whole-classroom': isWholeClassGroupType }">
                  <div class="status-text">to-do</div>
                </div>
              </div>
              <div class="task-value submitted" :class="{'submitted--whole-classroom': isWholeClassGroupType}">
                <div class="amount">
                  <div class="icon-value submitted" :class="submittedCompleted(taskSelected.percent_submitted_response_video)"/>
                  <p>{{ taskSelected.percent_submitted_response_video }}% {{!isWholeClassGroupType ? 'of Students' : ''}}</p>
                </div>
                <p class="type-value">Submitted</p>
              </div>
              <div class="task-value pending" v-if="!isWholeClassGroupType">
                <div class="amount">
                  <div class="icon-value pending"/>
                  <p>{{ taskSelected.number_pending_response_video }}</p>
                </div>
                <p class="type-value">Pending Approval</p>
              </div>
              <div class="task-value inactive"
                   id="popover-respond-students-list"
                   v-if="!isWholeClassGroupType"
                   :style="{'pointer-events': !taskSelected.inactive_response_video_students.length ? 'none' : ''}">
                <div class="amount">
                  <div class="icon-value inactive"/>
                  <p>{{ taskSelected.inactive_response_video_students.length }}</p>
                </div>
                <p class="type-value">Inactive Students</p>
              </div>
              <b-popover target="popover-respond-students-list"
                         triggers="hover"
                         v-if="!isWholeClassGroupType"
                         placement="top">
                <ul class="inactive-student-list">
                  <li v-for="(student, idx) in taskSelected.inactive_response_video_students" :key="student.id + idx">
                    {{ student.first_name + ' ' + student.last_initial }}
                  </li>
                </ul>
              </b-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-overlay no-wrap :show="isLoading"/>
  </div>
</template>

<script>
import { StudentActivityService } from '@/core/Services'
import moment from 'moment'
import {CourseMixins} from "@/modules/Classroom/Mixins"
import {taskCategories} from "@/core/Utils"

export default {
  name: "StudentActivity",
  mixins: [CourseMixins],
  props: {
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    },
  },
  data () {
    return {
      actions: [],
      isLoading: true,
      taskList: [],
      counter: 0,
      taskSelected: {},
      students: []
    }
  },
  mounted () {
    this.getStudentActivity()
  },
  methods: {
    getStudentActivity () {
      StudentActivityService.studentActivity(this.classroom.id).then((response) => {
        this.taskList = response.data ? response.data.filter((task) => (task.task_category !== taskCategories.activity && task.task_category !== taskCategories.reflection)) : []
        this.taskSelected = this.taskList[this.counter]
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error get actions'
        this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }).finally(() => this.isLoading = false)
    },
    setDueDate (date, string) {
      switch (string) {
        case 'MMM':
          return moment(date).format('MMM')
        case 'DD':
          return moment(date).format('DD')
      }
    },
    taskExpired (expirationDate) {
      const currentTimestamp = moment()
      return moment(currentTimestamp).isAfter(expirationDate)
    },
    submittedCompleted (submitted = 0) {
      if (submitted === 0 && this.isWholeClassGroupType) return 'submitted--pending'
    }
  },
  computed: {
    hideDecrement () {
      return this.counter >= 1
    },
    hideIncrement () {
      return this.counter < this.taskList.length - 1
    },
    title () {
      return this.isWholeClassGroupType ? 'MY CLASSROOM ACTIVITY' : 'STUDENT ACTIVITY'
    }
  },
  watch: {
    counter (newCounter) {
      this.taskSelected = this.taskList[newCounter]
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
.student-activity
  position relative

  .title
    font font-opensans-bold
    font-size 24px
    line-height 24px
    color color-3
    text-transform uppercase
    text-align left
    padding 10px
    padding-top 40px
    padding-left 30px

  .student-activity-content
    padding 10px 10px 0 40px
    @media (max-width 1050px)
      padding 10px 20px 0 20px

    .task-container
      width 100%

      .task-header
        display flex
        justify-content space-between

        h1
          font font-opensans-bold
          font-size 18px
          color color-3
          width 100%
          word-break break-word

        .buttons-nav
          width 90px
          position relative

          .icon-dropdown
            transform rotate(90deg)
            cursor pointer
            position absolute
            left 0

            &::before
              color color-3
              font-size 25px

          .icon-dropdown--right
            transform rotate(270deg)
            right 0
            left unset

      .task-card
        border 2px solid color-50
        border-radius 15px
        padding 10px
        margin-top 6px

        .parting-line
          border-top 2px solid color-50
          margin 20px 60px
          margin-bottom 10px

        .task-content
          .task-card__title
            font font-opensans-regular
            font-size 16px
            margin 0
            padding-bottom 20px
            color color-3

          .task-values
            width 100%
            display flex
            padding 0 20px
            align-items center
            @media (max-width: 1400px)
              padding 0
            @media (max-width: 1050px)
              padding 0 20px
            @media (max-width: 767px)
              padding 0

            .due-date
              width 20%
              display flex
              justify-content end
              padding-right 7%
              @media (max-width: 1400px)
                padding 0
              @media (max-width: 1050px)
                padding-right 8%
              @media (max-width: 767px)
                padding-right 0

              .status
                display inline-block
                vertical-align middle
                height 100%
                width 100%
                border 2px solid white
                background-color color-50
                padding-bottom 6px
                max-width 70px
                min-height 62px
                @media (max-width: 1200px)
                  width 80%
                @media (max-width: 991px)
                  width 90%
                @media (max-width: 550px)
                  width 100%

                .status-text
                  color #414D94
                  text-transform uppercase
                  font-weight bolder
                  font-family "Open Sans"
                  text-align center
                  @media (min-width: 992px)
                    font-size 9px
                    margin-top 6px
                  @media (max-width: 991px)
                    font-size 7px
                    margin-top 3px

                .status-text--mm
                  color white
                  font-size 12px
                  margin-top 0

                .status-text--dd
                  color white
                  font-size 16px
                  margin-top 0
                  line-height 16px

                .status-text--expired
                  color red

              .status--to-do
                display flex
                justify-content center
                align-items center

              .status--whole-classroom
                min-width 50px

            .task-value
              width 25%
              padding-left 5px
              margin-left 5px
              border-left 2px solid color-50
              height fit-content

              p
                margin 0
                color color-3

              .amount
                display flex

                .icon-value
                  width 15px
                  height 15px
                  min-width 15px
                  background-color #9bcc48
                  border-radius 5px
                  margin-right 3px

                .pending
                  background-color #f9b417

                .inactive
                  background-color #e16756

                p
                  font font-opensans-regular
                  font-size 14px

              .type-value
                font font-opensans-semibold
                font-size 14px
                margin-top 4px

            .submitted
              width 26.6%

            .submitted--whole-classroom
              width auto

            .submitted--pending
              background-color #e16756 !important

          .task-values--whole-classroom
            padding 0 20px
</style>
<style lang="stylus">
.popover-body
  max-height 300px
  overflow-y auto

  .inactive-student-list
    list-style none
    margin 0
    padding 0

    li
      max-width 120px
      text-overflow ellipsis
      overflow hidden
      white-space nowrap

</style>
