<template>
  <i :class="thumbnailClass"></i>
</template>

<script>
export default {
  name: 'ThumbnailAttachments',
  data: function () {
    return {}
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    isDoc: function () {
      return true
    }
  },
  computed: {
    thumbnailClass: function () {
      let classs = 'fa fa-file-o'

      if (this.isVideo) {
        classs = 'fa fa-file-video-o'
      } else if (this.isAudio) {
        classs = 'fa fa-file-audio-o'
      } else if (this.isDocx) {
        classs = 'fa fa-file-word-o'
      } else if (this.isTxt) {
        classs = 'fa fa-file-text-o'
      } else if (this.isPdf) {
        classs = 'fa fa-file-pdf-o'
      } else if (this.isExcel) {
        classs = 'fa fa-file-excel-o'
      } else if (this.isXml) {
        classs = 'fa fa-file-code-o'
      } else if (this.isPowerPoint) {
        classs = 'fa fa-file-powerpoint-o'
      } else if (this.isImage) {
        classs = 'fa fa-file-image-o'
      }

      return classs
    },
    isVideo: function () {
      const types = ['mp4', 'webm', 'mkv', 'mpeg', 'mpg', 'avi', 'mov', 'wmv', 'm4v', 'mpg', '3gp']

      return types.includes(this.srcExtension)
    },
    isAudio: function () {
      const types = ['mp3', 'aac', 'ogg', 'flac', 'alac', 'wav', 'aiff', 'dsd', 'pcm']

      return types.includes(this.srcExtension)
    },
    isDocx: function () {
      const types = ['docx']

      return types.includes(this.srcExtension)
    },
    isTxt: function () {
      const types = ['txt']

      return types.includes(this.srcExtension)
    },
    isPdf: function () {
      const types = ['pdf']

      return types.includes(this.srcExtension)
    },
    isExcel: function () {
      const types = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xls', 'xlam', 'xla', 'xlw', 'xlr']

      return types.includes(this.srcExtension)
    },
    isXml: function () {
      const types = ['xml']

      return types.includes(this.srcExtension)
    },
    isPowerPoint: function () {
      const types = ['pptx', 'pptm', 'ppt']

      return types.includes(this.srcExtension)
    },
    isImage: function () {
      const types = ['jpeg', 'png', 'gif', 'tiff']

      return types.includes(this.srcExtension)
    },
    srcExtension: function () {
      let text = this.data.url.toLowerCase()
      return text.substr((text.lastIndexOf('.')) + 1)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

i.fa
  color color-3
  font-size 2rem
</style>
