<template>
  <div>
    <EtagBanner />
    <HeaderApp :is-security-page="isSecurityPage"/>
    <b-overlay :show="loadingClassrooms && !isSecurityPage" rounded="sm">
      <template #overlay>
        <div class="d-flex align-items-center loading-overlay">
          <b-spinner class="ml-auto"></b-spinner>
          <span class="loading-overlay-label">Loading...</span>
        </div>
      </template>
      <div class="wrapper-inner-page" :class="{'wrapper-inner-page--logged-out': isSecurityPage}">
        <slot v-if="!loadingClassrooms || isSecurityPage"></slot>
      </div>
    </b-overlay>
    <FooterApp/>

    <modal id="not-verified-teacher" modal-class="classroom_crated_classroom_modal" @hidden="goToTeacherProfilePage">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">Unverified Teacher</h2>
        <p class="second-text text-center">
          Your account is awaiting for verification and you will be notified once the verification is complete.
        </p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('not-verified-teacher')" btn-text="OK"/>
      </template>
    </modal>
    <modal id="teacher-disabled-modal" modal-class="classroom_crated_classroom_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">Account locked</h2>
        <p class="second-text text-center">
          Your account has been locked. Please contact support@levelupvillage.com for more information.
        </p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('teacher-disabled-modal')" btn-text="OK"/>
      </template>
    </modal>
    <modal id="classroom-error-modal" modal-class="classroom_crated_classroom_modal" @hidden="goToMyClassroomsPage">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">Classroom Error</h2>
        <p class="second-text text-center">{{ classroomError }}</p>
      </template>
      <template #footer>
        <b-button @click="goToMyClassroomsPage" btn-text="OK"/>
      </template>
    </modal>
    <modal id="wrong-teacher-partner-invitation-modal" modal-class="classroom_crated_classroom_modal"
           @hidden="goToMyClassroomsPage">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">Error</h2>
        <p class="second-text text-center">
          The connection request belong to another account, please log in with the right credentials.
        </p>
      </template>
      <template #footer>
        <b-button @click="goToMyClassroomsPage" btn-text="Back" outline/>
        <b-button @click="goToLogoutPage({redirect: $route.fullPath})" btn-text="Log out"/>
      </template>
    </modal>
  </div>
</template>
<script>
import HeaderApp from "./HeaderApp.vue"
import FooterApp from "./FooterApp.vue"
import { MyClassRoomsMixins } from "@/modules/MyClassrooms/Mixins";
import { Modal } from "@/core/components/Modal";
import { Button } from "@/core/components/Button";
import EtagBanner from "@/core/components/PageBase/EtagBanner";

export default {
  name: "PageBase",
  mixins: [MyClassRoomsMixins],
  data () {
    return {
      classroomError: '',
      loadingClassrooms: true
    }
  },
  props: {
    isSecurityPage: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    EtagBanner,
    Modal,
    HeaderApp,
    FooterApp,
    'b-button': Button,
  },
  mounted () {
    if (!this.isSecurityPage) {
      this.loadingClassrooms = true

      this.$EventBus.$on("on-classroom-error", (data) => {
        const messageText = `You do not have rights for the selected classroom. If you believe you received this message in error, please logout and log back in, then select your desired classroom from the My Classrooms link above.`
        const errorMessage = data.error_code === 10 ? messageText : data.message
        this.classroomError = errorMessage ?? 'An error happen when try to fetch classroom data.'
        this.$bvModal.show('classroom-error-modal')
      });

      Promise.all([
        this.getMiscData(),
        this.getProfile(),
        // this.getClassrooms({}),
      ]).then(() => {
        if (!['TeacherProfilePage', 'ProfileWizardPage'].includes(this.$root._route.name) && !this.user.is_teacher_validated) {
          // if user not verified show message and redirect to profile page
          this.$bvModal.show('not-verified-teacher')
        }

        this.loadingClassrooms = false
      })
    }
  }
};
</script>

<style lang="stylus" scoped>
.loading-overlay
  &-label
    font-size 2rem

  .loading-overlay-label
    margin-left 15px

</style>
