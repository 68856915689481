<template>
  <div class="wrapper-security-page">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col cols="12" xl="4" lg="6" md="8">
          <div class="wrapper-security-form">
            <h2 class="security-form-subtitle mb-1">FORGOT PASSWORD</h2>
            <div class="security-form-page-info mb-4">
              <div class="required-fields">all fields required *</div>
            </div>
            <form @submit.prevent="" class="mt-2">
              <custom-input @keyup.enter.native="requestLink" v-model="form.email" :errors="errors.email"
                            placeholder="Your School Email (john.doe@school.org) *" type="email"/>

              <div class="security-form-container-btns c-btns-secondary">
                <b-button @click="goToLoginPage()" btn-text="Cancel"/>
                <b-button @click="requestLink" btn-text="APPLY"/>
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <modal ref="account_forgot_password_success">
      <template #body>
        <h2 class="modal-title"></h2>
        <p>A recovery link as been sent to your email. Please check your email to complete the password recovery
          process. If you do not see an email, check your Junk/Spam folder.</p>
      </template>
      <template #footer>
        <b-button @click="closeModal" btn-text="OK"/>
      </template>
    </modal>
    <modal ref="account_forgot_password_error">
      <template #body>
        <h2 class="modal-title">The email is not in the system.</h2>
<!--        <p>the email is not in the system.</p>-->
      </template>
      <template #footer>
        <b-button @click="closeErrorModal" btn-text="OK"/>
      </template>
    </modal>
  </div>
</template>

<script>
import validateForms from '@/core/Mixins/validate-forms'
import {Button} from '@/core/components/Button'
import {Modal} from '@/core/components/Modal'
import {AuthService} from '@/core/Services'
import Input from "@/core/components/FormInput/Input";

export default {
  name: "ForgotPasswordPage",
  mixins: [validateForms],
  components: {
    Modal,
    'b-button': Button,
    'custom-input': Input,
  },
  data() {
    return {
      form: {
        email: '',
      },
      validations: {
        email: {
          validate: 'required|email',
        },
      }
    }
  },
  methods: {
    requestLink: function () {
      const {email} = this.form

      if(this.isValidForm()) {
        AuthService.passwordRecoveryEmail(email).then((response) => {
          if (response.success === true) {
            this.showSuccessModal()
          } else {
            this.showErrorModal()
          }
        }).catch(() => {
          this.clearForm()
        })
      }
    },
    showSuccessModal: function () {
      return this.$refs["account_forgot_password_success"].showModal()
    },
    closeModal: function () {
      return this.$refs["account_forgot_password_success"].hideModal().then(() => {
        this.goToLoginPage()
      })
    },
    showErrorModal: function () {
      return this.$refs["account_forgot_password_error"].showModal()
    },
    closeErrorModal: function () {
      return this.$refs["account_forgot_password_error"].hideModal().then(() => {
        this.goToCreateAccountPage()
      })
    },
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/security.styl";
</style>
