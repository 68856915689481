<template>
  <div class="step-item">
    <div class="step-item-title">Subjects I Teacher</div>
    <form-checkbox-group v-model="$v.form.subjects.$model"
                         :validator="$v.form.subjects"
                         :is-object-array="true"
                         value-field="id"
                         text-field="name"
                         class="custom-form-my-instruction step-item-input"
                         :options="subjectsList"
                         custom-class="custom-checkbox-primary custom-checkbox--one-column"/>

    <div class="step-item">
      <b-button type="button" class="button primary --sm back"
                @click="changeStep(profileData.schoolDpaSigned ? 3 : 4)"
                btn-text="Back"/>
      <b-button type="button" class="button primary --sm"
                @click="changeStep(6)"
                :disabled="$v.stepValid.$invalid"
                btn-text="Next"/>
    </div>
  </div>
</template>

<script>
import { Button } from "@/core/components/Button"
import { required } from 'vuelidate/lib/validators'
import { MyClassRoomsMixins } from "@/modules/MyClassrooms/Mixins"
import FormCheckboxGroup from "@/core/components/Form/CheckboxGroup"
import {userTypeMixins} from "@/core/Mixins"

export default {
  name: 'Step5',
  mixins: [MyClassRoomsMixins, userTypeMixins],
  components: {
    'b-button': Button,
    'form-checkbox-group': FormCheckboxGroup,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        subjects: []
      }
    }
  },
  validations () {
    return {
      form: {
        subjects: {
          required,
        },
      },
      stepValid: ['form.subjects']
    }
  },
  methods: {
    changeStep (step) {
      this.$emit('change-step', {step, form: {...this.form}})
    },
  },
  async mounted() {
    this.form = {...this.form, ...this.profileData}
  },
  computed: {
    subjectsList() {
      return this.isLibrarian ? this.subjects : this.subjects.filter((subject) => subject.name !== 'Library')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.step-item
  text-align center

  .button-copy
    margin 20px auto

  .button
    display inline
    margin-top 2rem

    &.back
      margin-right 4rem

    &:disabled
      border-width 0 !important

  &-title
    font-size 1.5rem
    margin-bottom 18px

  &-subtitle
    margin 18px 0
    font-size 1rem

  &-input
    max-width 470px
    margin 0 auto
    margin-bottom 12px

  .avatar-image
    max-width 500px
    margin 0 auto

  @media (max-width 350px)
    .button
      margin 0 5px
      margin-top 2rem

      &.back
        margin-right 0

  ::v-deep
    .custom-form-my-instruction
      margin-top 20px

      .custom-checkbox-primary
        margin-top 4px

    .custom-checkbox-primary
      display grid
      grid-template-columns 1fr 1fr 1fr
      gap 10px 10px
      grid-gap 10px 10px

    .custom-checkbox--two-columns
      grid-template-columns 1fr 1fr

    .custom-checkbox--one-column
      grid-template-columns 1fr
</style>
