import { render, staticRenderFns } from "./ClassroomDetailTabMessageStudent.vue?vue&type=template&id=fa385700&scoped=true&"
import script from "./ClassroomDetailTabMessageStudent.vue?vue&type=script&lang=js&"
export * from "./ClassroomDetailTabMessageStudent.vue?vue&type=script&lang=js&"
import style0 from "./ClassroomDetailTabMessageStudent.vue?vue&type=style&index=0&id=fa385700&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa385700",
  null
  
)

export default component.exports