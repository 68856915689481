<template>
  <div class="dashboard-detail-container">
    <div class="student-detail-back">
      <a href="javascript:void(0)" @click="onBack"
        ><span class="icon-caret-left"></span>Back</a
      >
    </div>
    <div class="details-area">
      <div class="information">
        <div class="post-video">
          <video-custom
            v-if="!!actionItem.teacher_action && !actionItem.completed"
            :src="actionItem.teacher_action.video_url"
            @on-video-play="playedActionVideo"
          />
        </div>
        <div class="information-content">
          <div class="task-name">{{ actionItem.action.task }}</div>
          <div class="topic" v-if="responseLabel">RESPOND TO YOUR PARTNER TEACHER</div>
        </div>
        <router-link v-if="isCompleted" class="action-icon" :to="getGoRoute()">
          <div class="do-task">Go!</div>
        </router-link>
        <router-link v-else class="action-icon" :to="getViewRoute()">
          <div class="do-task">View</div>
        </router-link>
      </div>
    </div>
    <div class="prompt-area">
      <span v-if="!actionItem.teacher_action">
        <div
          class="promt-text"
          v-html="replaceLineBreaks(actionItem.action.details_create)"
        />
      </span>
      <span v-else>
        <div
          class="promt-text"
          v-html="replaceLineBreaks(actionItem.action.details_response)"
        />
      </span>
    </div>
    <b-overlay no-wrap :show="loading" />
  </div>
</template>

<script>
import moment from "moment";
import { ActionsService } from "@/core/Services";
import { VideoCustom } from "@/core/components/Video";

export default {
  name: "ClassroomTaskDetail",
  components: {
    VideoCustom,
  },
  props: {
    classroomId: {
      type: Number,
      required: true,
    },
    teacherActionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      actionItem: {
        action: {},
      },
      loading: true,
    };
  },
  mounted() {
    this.getActionItem()
  },
  computed: {
    isCompleted() {
      return !this.actionItem.video_url;
    },
    responseLabel() {
      return this.actionItem?.teacher_action?.id && !this.actionItem?.completed
    }
  },
  methods: {
    playedActionVideo() {
      if (!this.actionItem.teacher_action?.video_played) {
        ActionsService.videoPlayed(this.actionItem.teacher_action?.id)
          .catch((e) => {
            const errorMessage = e.message ? e.message : "Error update status.";
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: "sm",
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true,
            });
          })
          .then(() => {
            this.getActionItem(false);
          });
      }
    },
    getActionItem(showLoader = true) {
      if (showLoader) {
        this.loading = true;
      }
      ActionsService.getActionItemById(this.teacherActionId)
        .then((res) => {
          this.actionItem = res.data
        })
        .catch((e) => {
          const errorMessage = e.message ? e.message : "Information not found.";
          this.$bvModal
            .msgBoxOk(`${errorMessage} Please try again.`, {
              size: "sm",
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true,
            })
            .then(() => {
              this.$router.push({
                name: "ClassroomTaskDetail",
                params: {
                  classroomId: this.classroomId,
                  teacherActionId: this.teacherActionId,
                },
              });
            });
        })
        .finally(() => (this.loading = false));
    },
    getGoRoute() {
      return {
        name: "UploadOrRecordPage",
        params: {
          classroomId: this.classroomId,
          teacherActionId: this.teacherActionId,
        },
      };
    },
    getViewRoute() {
      return {
        name: "ClassroomSubmissionDetail",
        params: {
          classroomId: this.classroomId,
          teacherActionId: this.teacherActionId,
        },
      };
    },
    onBack() {
      this.goToClassroomPage(this.classroomId);
    },
    setDueDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    replaceLineBreaks(text) {
      //replacing the string classroom-id with the id of the classroom
      const idAddedInLinks = text?.replace(/classroom-id/g, this.classroomId);
      return idAddedInLinks
        ? idAddedInLinks.replace(/(?:\\r\\n|\\r|\\n)/g, "<br />")
        : "";
    },
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.dashboard-detail-container
  max-width 625px
  margin auto
  padding-top 40px

  .student-detail-back
    a
      font font-opensans-bold
      font-size 17px
      cursor pointer
      text-decoration none
      color color-3
      display flex
      align-items center

      span
        font-size 0.7em
        padding-right 5px

        &:before
          color color-3

  .details-area
    min-height 140px
    padding 0 20px

    .photo
      display inline-block
      vertical-align top
      height 140px
      width 130px
      background-repeat no-repeat
      background-size cover
      background-position center

    .information
      position relative
      display inline-block
      vertical-align top
      min-height 140px
      width 100%
      padding-top 20px

      .post-video
        padding 10px
        width 100%
        border-top 1px solid #DDDDDD

        .video-overlay
          width 100%
          height 395px
          border-radius 4px

      .information-content
        min-height 50px

      .task-name
        font-weight bold
        text-transform uppercase
        color #3D3D3D
        word-break break-word
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px

      .topic
        margin-top 5px
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px
        text-transform uppercase
        color #3D3D3D
        word-break break-word

      .do-task
        display inline-block
        margin-top 8px
        background-color color-3
        border solid 2px color-7
        color #FCFCFC
        padding 5px 10px
        border-radius 4px
        line-height 19px
        text-align center
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px

  .prompt-area
    padding-bottom 20px
    margin 20px
    border-bottom solid 2px #D4D4D4
    word-break break-word

    .promt-title
      text-transform uppercase
      font-weight bold
      color #3D3D3D
      margin-bottom 10px
      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

    .task-video-time-limit
      text-transform uppercase
      font-weight bold
      color #3D3D3D
      margin-top 10px

      span
        font-weight normal
        text-transform none
        color #3D3D3D
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px

      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

    .promt-text
      color #3D3D3D
      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px
</style>
