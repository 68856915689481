<template>
  <b-avatar :src="thumbnail" icon="people-fill" square></b-avatar>
</template>

<script>
import { UploadService } from '@/core/Services'

export default {
  name: "SubmissionThumbnail",
  data() {
    return {
      thumbnail: null
    }
  },
  props: {
    submission: Object,
  },
  async mounted() {
    if (!this.isTextResponse && this.submission.url) {
      const url = this.submission.url.split('/')
      const key = url[url.length - 1]

      this.thumbnail = await UploadService.getVideoThumbnail(key)
    }
  },
  methods: {
  },
  computed: {
    isTextResponse: function () {
      return this.submission.is_response && this.submission.response_type === 'text'
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.student-profile-course-videos-actions
  flexbox(flex)
  align-items(center)
  margin-top 8px
  margin-bottom 30px
  flex-direction(column)

  .action-right
    width 85%
    flexbox(flex)
    flex-direction(column)
    align-items(center)
    flex 1

    ::v-deep
      .btn-common--small
        margin-top 10px
        font-size 12px
        line-height 15px
        padding 8px 30px

    .custom-dropdown
      padding-right 10px

    @media (min-width: 768px)
      flex-direction(row)
      .custom-dropdown
        flex-basis 35%
        margin-right 8px
        min-width 165px

  ::v-deep
    .task-filter .custom-dropdown span
      font-size 14px

  @media (min-width: 768px)
    flex-direction(row)
    .action-right
      ::v-deep
        .btn-common--small
          margin-top 0
          font-size 10.5px
          line-height 15px
          padding 4px 10px

  @media (min-width: 992px)
    .action-right
      ::v-deep
        .btn-common--small
          font-size 11px
          padding 8px 18px

  @media (min-width: 1200px)
    .action-right
      ::v-deep
        .btn-common--small
          font-size 12px
          padding 8px 30px

  @media (min-width: 1400px)
    .action-right
      justify-content(end)

.action-left
  margin-bottom 10px
  margin-left 30px
  @media (min-width: 768px)
    margin-left 0

::v-deep
  .task-filter
    flex-direction column
    margin 0
    width 85%

    .custom-dropdown
      padding-right 8px
      height 40px

    @media (min-width: 768px)
      flex-direction(row)
      .custom-dropdown
        flex-basis 30%
        padding-right 8px
        width 165px
    @media (min-width: 992px)
      width 50%

.table-body-course-videos
  ::v-deep
    .table-body-view
      &.list
        .table-row
          padding 6px 10px
          align-items(center)
          margin-bottom 6px
          border 1px solid color-28
          color color-8

          &.header
            margin-bottom 3px
            border 0

            .column
              font font-opensans-bold
              font-size 0.5rem
              display flex
              align-items(center)

              .order
                display flex
                flex-direction(column)
                align-items(center)
                justify-content(center)
                width 16px

                &-bottom
                &-top
                  font-size 0.6rem

              &-select
                display none

          .column
            margin-left 3px
            padding-top 0

            &-select
              display none

            &-select-mobile
              width 20px
              margin-left 0

            &-image
              width 40px
              margin-right 13px
              margin-left 13px

            &-status
              flex-basis(20%)
              font font-opensans-bold
              font-size 0.5rem

              .label
                display block
                width 100%

              .label-desc
                display block
                width 100%
                font font-opensans-regular
                font-style italic
                font-size 0.5rem

            &-task
            &-date
              flex-basis(20%)
              font font-opensans-regular
              font-size 0.5rem

            &-task
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
              width 45px

            &-play
              width 20px

              span
                &:before
                  color color-3

          &:not(.header)
            .column
              &-status
              &-task
              &-date
              &-student
              &-date
                padding-left 0.9rem

      &.grid
        padding-left 50px
        padding-right 50px

        .table-row
          background-color rgba(color-29, 0.35)
          padding 0
          flex-direction(column)
          border-radius 4px
          overflow hidden
          position relative
          margin-bottom 15px

          .column
            padding-top 120%

    @media (min-width: 469px)
      .column-play
        flex(1)
        text-align right

    @media (min-width: 768px)
      .table-body-view
        &.grid
          grid-template-columns: 1fr 1fr 1fr
          column-gap 20px
          row-gap 1em
          padding-left 0
          padding-right 0

        &.list
          .table-row
            &.header
              .column
                font-size 0.9rem

                &-select
                  display block

                &-select-mobile
                  display none

            .column
              &-select
                display block
                width 50px

              &-select-mobile
                display none

              &-image
                width 60px

                .content-image
                  width 48px

              &-status
              &-task
              &-date
              &-student
                font-size 0.8rem

              &-status .label-desc
                font-size 0.8rem

              &-play
                flex(1)
                text-align right

                span
                  font-size 1.5rem


.student-video-information
  width 1248px
</style>
