import {http} from './http'

export default {
  countries() {
    return http.get(`/api/teacher/countries`).then(response => {
      return response
    })
  },
  states(country_id) {
    return http.get(`/api/teacher/countries/${country_id}/states`).then(response => {
      return response
    })
  },
  schools(country_id, state_id) {
    return http.get(`/api/teacher/countries/${country_id}/states/${state_id}/schools`).then(response => {
      return response
    })
  },
  allCountriesSchools(country_id, state_id) {
    return http.get(`/api/teacher/countries/${country_id}/schools`, {params: {state_id: state_id}}).then(response => {
      return response
    })
  },
  paymentMethods() {
    return http.get(`/api/teacher/payment-methods`).then(response => {
      return response
    })
  },
  levels() {
    return http.get(`/api/teacher/levels`).then(response => {
      return response
    })
  },
  subjects() {
    return http.get(`/api/teacher/subjects`).then(response => {
      return response
    })
  },
  languages() {
    return http.get(`/api/teacher/languages`).then(response => {
      return response
    })
  },
  ageRanges() {
    return http.get(`/api/teacher/age-ranges`).then(response => {
      return response
    })
  },
  numberStudentsRanges() {
    return http.get(`/api/teacher/number-students-ranges`).then(response => {
      return response
    })
  },
  standards() {
    return http.get(`/api/teacher/standards`).then(response => {
      return response
    })
  },
  bundles() {
    return http.get(`/api/teacher/bundles`).then(response => {
      return response
    })
  },
  bundlesPurchase(bundleId, data = []) {
    return http.post(`/api/teacher/bundles/${bundleId}`, data).then(response => {
      return response
    });
  },
  exchangeTokensPurchases(filters) {
    return http.get(`/api/teacher/exchange-tokens-purchases`, {params: {filters: filters}}).then(response => {
      return response
    });
  },
  exchangeTokensPurchasesExport(filters) {
    return http.get(`/api/teacher/exchange-tokens-purchases-export`, {
      responseType: "blob",
      params: {filters: filters}
    })
  },
  masquerade(classroom_id, student_id) {
    return http.post(`/api/teacher/classrooms/${classroom_id}/students/${student_id}/masquerade-token`)
  },
  masqueradeWholeClass(classroom_id) {
    return http.post(`/api/teacher/classrooms/${classroom_id}/whole-class-token`)
  },
  checkSchoolDpaSigned(parentOrganizationId = null, schoolId = null) {
    return http.get('/api/teacher/parent-organizations/check-dpa-signed', {params: {school_id: schoolId, parent_organization_id: parentOrganizationId}})
  },
  topicsRequest() {
    return http.get(`/api/teacher/topics`).then(response => {
      return response
    })
  }
}
