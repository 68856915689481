<template>
  <div :class="classroom_create_class">
    <div class="classroom-step-wrapper">
      <b-list-group class="steps-list" horizontal>
        <b-list-group-item
          v-for="step in steps"
          :key="'step-' + step.num"
          class="step-item"
          :class="{'step-item-active': (currentStep >= step.num ) }">
          <b-icon v-if="currentStep >= step.num" icon="check2" class="step-item-active-icon"
                  aria-hidden="true"></b-icon>
        </b-list-group-item>
      </b-list-group>
      <slot :currentStep="currentStep"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeacherWizard",
  props: {
    steps: {
      type: Array,
      default: () => {
        return [
          { num: 1 },
          { num: 2 },
          { num: 3 }
        ]
      }
    },
    currentStep: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      buttonAction: null
    }
  },
  computed: {
    classroom_create_class () {
      return {
        'wrapper-create-classroom-page': true,
        'wrapper-classroom-page': true
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.wrapper-create-classroom-page
  min-height inherit
  display flex
  align-items center

.classroom-step
  &-wrapper
    max-width 875px
    min-width 320px
    width 100%
    margin 0 auto
    padding 25px

    .form-group
      max-width 470px
      margin 0 auto

    @media (max-width 414px)
      padding 2rem
      min-height: calc(100vh - (80px + 115px));

  &-item
    text-align center

    .button
      display inline
      margin-top 2rem

      &.back
        margin-right 4rem

      &:disabled
        border-width 0 !important

    @media (max-width 350px)
      .button
        margin 0 5px
        margin-top 2rem

        &.back
          margin-right 0

.steps-list
  margin-bottom 2rem

  .step-item
    background-color color-4
    margin-right 2rem
    border-radius 50% !important
    height 2rem
    width 2rem
    padding 0
    border 2px solid color-7 !important
    text-align center

    &:not(:last-child):before
      content ''
      border-bottom .1rem solid color-3
      position absolute
      transform translateY(-50%)
      top 50%
      right -1.5rem
      width 1rem

    &-active
      background-color color-5

      &-icon
        font-size 1.5rem
        height 100%
</style>

