<template>
  <div class="step-item">
    <div class="step-item-subtitle">Create your teacher profile to get started using Level Up Village! Please provide the following information so we can find the best partner class for you and your students.</div>
    <form-input label="First Name"
                v-model="$v.form.firstName.$model"
                :validator="$v.form.firstName"
                class="step-item-input"/>
    <form-input label="Last Name"
                v-model="$v.form.lastName.$model"
                :validator="$v.form.lastName"
                class="step-item-input"/>
    <form-input-data-list id="dropdown-contries-list"
                          v-model="$v.form.country.$model"
                          v-if="this.countries.length"
                          :validator="$v.form.country"
                          value-field="id"
                          label="Country"
                          :options="countries"
                          class="step-item-input"/>
    <form-select v-if="showState"
                 v-model="$v.form.state.$model"
                 :validator="$v.form.state"
                 label="State"
                 :options="states"
                 class="step-item-input"
                 custom-class="custom-dropdown--md custom-dropdown--secondary"/>
    <form-input label="City"
                v-if="showCity"
                v-model="$v.form.city.$model"
                :validator="$v.form.city"
                class="step-item-input"/>
    <div class="step-item-subtitle">This is your opportunity to share some information that will help other teachers connect with you. You might add details about the classes you teach, professional experience, interests, etc.</div>
    <form-textarea v-model.trim="$v.form.aboutInfo.$model"
                   :validator="$v.form.aboutInfo"
                   label="About You"
                   class="step-item-input step-item-input--area"/>
    <div class="step-item">
      <b-button type="button"
                class="button primary --sm"
                :disabled="$v.stepValid.$invalid"
                @click="nextStep"
                btn-text="Next"/>
    </div>
    <b-overlay no-wrap :show="isLoading"></b-overlay>
  </div>
</template>

<script>
import { Button } from "@/core/components/Button"
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import FormInput from "@/core/components/Form/Input"
import FormInputDataList from "@/core/components/Form/InputDataList"
import FormTextarea from "@/core/components/Form/Textarea"
import FormSelect from "@/core/components/Form/Select"
import {MiscService} from '@/core/Services'

export default {
  name: 'Step1',
  components: {
    'b-button': Button,
    'form-input': FormInput,
    'form-input-data-list': FormInputDataList,
    'form-select': FormSelect,
    'form-textarea': FormTextarea,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        country: '',
        state: '',
        city: '',
        aboutInfo: '',
      },
      countries: [],
      states: [],
      isLoading: false
    }
  },
  validations () {
    return {
      form: {
        firstName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100)
        },
        lastName: {
          required,
          maxLength: maxLength(100)
        },
        country: {
          required,
          maxLength: maxLength(250)
        },
        state: {
          required: requiredIf(() => {
            return this.form.country && this.isUSA
          })
        },
        city: {
          required,
          maxLength: maxLength(250)
        },
        aboutInfo: {
          required,
          maxLength: maxLength(500)
        }
      },
      stepValid: ['form.firstName', 'form.lastName', 'form.country', 'form.state', 'form.city', 'form.aboutInfo']
    }
  },
  computed: {
    isUSA() {
      const country = this.countries.find(country => country.id === this.form.country)
      return (country && country.iso.toLowerCase() === 'us')
    },
    showState() {
      return this.form.country && this.isUSA
    },
    showCity() {
      return this.form.country
    },
  },
  methods: {
    nextStep () {
      this.$emit('change-step', {step: 2, form: {...this.form}})
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      await MiscService.countries().then((response) => {
        this.$nextTick(() => this.countries = response.data)
      })
      this.form = {...this.form, ...this.profileData}
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false
    }
  },
  watch: {
    'form.country'(countryId) {
      if (typeof countryId === 'string' || !countryId) {
        this.form.country = ''
        return
      }
      this.isLoading = true
      MiscService.states(countryId).then((response) => {
        this.states = response.data
      }).finally(() => this.isLoading = false)
      this.form = {
        ...this.form,
        schoolName: '',
        schoolId: '',
        schoolAddress: '',
        schoolWebsite: '',
        schoolPhoneNumber: ''
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.step-item
  text-align center

  .button
    display inline
    margin-top 2rem

    &.back
      margin-right 4rem

    &:disabled
      border-width 0 !important

  &-title
    font-size 1.5rem
    margin-bottom 18px

  &-subtitle
    margin 18px 0
    font-size 1rem

  &-input
    max-width 470px
    margin 0 auto
    margin-bottom 12px

    ::v-deep
      label
        font-size 1.5rem
        margin-bottom 18px

      .list-group
        top 6.2rem !important

  &-input--area
    ::v-deep
      .form-control
        resize none

  @media (max-width 350px)
    .button
      margin 0 5px
      margin-top 2rem

      &.back
        margin-right 0
</style>
