import {mapActions, mapGetters} from 'vuex';

export const ClassroomMixins = {
  computed: {
    ...mapGetters({
      classroom: 'ClassroomModule/getClassroomData',
      loadingClassroom: 'ClassroomModule/isLoadingClassroom',
      creatingClassroom: 'ClassroomModule/creatingClassroom',
    }),
    isWorldLanguage() {
      const selectedSubject = this.subjects.find(subject => subject.id === this.classroom.subject_id)
      return selectedSubject !== undefined && selectedSubject.type === 'world_languages'
    }
  },
  methods: {
    ...mapActions({
      getClassroom: 'ClassroomModule/getClassroom',
      createClassroom: 'ClassroomModule/createClassroom',
      updateClassroom: 'ClassroomModule/updateClassroom',
      initClassroom: 'ClassroomModule/initClassroom',
      setCourseId: 'ClassroomModule/setCourseId',
      getSubject: 'ClassroomModule/getSubject',
      setCourse: 'ClassroomModule/addCourseContent',
      setPlayersTrust: 'MiscModule/setPlayersTrust',
      getClassroomNoOverlay: 'ClassroomModule/getClassroomNoOverlay'
    })
  },
};
