import { render, staticRenderFns } from "./TeacherTable.vue?vue&type=template&id=53c67a17&scoped=true&"
import script from "./TeacherTable.vue?vue&type=script&lang=js&"
export * from "./TeacherTable.vue?vue&type=script&lang=js&"
import style0 from "./TeacherTable.vue?vue&type=style&index=0&id=53c67a17&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c67a17",
  null
  
)

export default component.exports