import {AppStorage, userTypes} from '@/core/Utils'

export const userTypeMixins = {
  computed: {
    isLibrarian() {
      const user = AppStorage.getUser()
      return user?.user_type?.slug === userTypes.librarian
    }
  },
  methods: {
  },
};
