<template>
  <div :class="['message', contentClass]">
    <div class="message-left">
      <div class="picture">
        <b-avatar class="img-fluid" :src="userThumbnail"></b-avatar>
      </div>
    </div>
    <div class="message-right">
      <div class="full_name">
        <span class="name">{{ userFirstName }} {{ isIndividualGroupType ? userLastInitial : '' }}</span><span class="label">{{ userType }}</span>
      </div>
      <div class="date">{{ postDate }}</div>
      <div class="subject">
        {{ messageTitle }}
      </div>
      <div class="description">
        {{ getDescription }}
      </div>
      <div class="form-group-links">
        <ul>
          <li :key="'attach-file-'+index" v-for="(link, index) in links">
            <a target="_blank" class="text-normal" :href="link.url">
              {{ link.label }}
            </a>
          </li>
        </ul>
      </div>
      <div class="form-group-media">
        <ul>
          <li :key="'attach-file-'+index" v-for="(file, index) in media">
            <a target="_blank" class="text-normal" :href="file.url">
              {{ file.label }}
            </a>
          </li>
        </ul>
      </div>
      <div v-if="showMoreLink" class="show-more" @click="showAll = true">
        SHOW MORE
      </div>
      <div class="additional">
        <slot name="messageAdditional"></slot>
      </div>
      <div v-if="!!this.$slots.messageActions" class="footer">
        <slot name="messageActions"></slot>
      </div>
      <div v-if="!!this.$slots.messageChild" class="child">
        <slot name="messageChild"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBoardMixin from './ClassroomDetailTabMessageMixins'
import {CourseMixins} from "@/modules/Classroom/Mixins"

const maxCharacterCount = 400

export default {
  name: "ClassroomDetailTabMessage",
  mixins: [MessageBoardMixin, CourseMixins],
  data() {
    return {
      showAll: false
    }
  },
  computed: {
    showMoreLink() {
      return this.messageText && this.messageText.length > maxCharacterCount && !this.showAll
    },
    getDescription() {
      if (this.showMoreLink) {
        return `${this.messageText.slice(0, maxCharacterCount)} (...)`
      }
      return this.messageText
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.message
  display flex
  flex-direction row
  padding-top 31px
  border-top 2px solid color-23

  &-left
    padding-right 20px

    .picture
      .b-avatar
        width 30px
        height 30px
      .badge-secondary
        background-color transparent

  &-right
    width: 90%
    flex 1

    .full_name, .date, .subject, .description
      // width: 638px
      word-break break-all

    .full_name
      color color-21
      margin-bottom 3px

      .name
        font font-opensans-bold
        font-size 16px
        text-transform capitalize
        padding-right 10px

      .label
        font font-opensans-regular
        font-style italic
        font-size 16px

    .date
      font font-opensans-bold
      font-size 16px
      margin-bottom 25px

    .subject
      font font-opensans-semibold
      font-size 14px
      text-transform uppercase
      color color-8
      margin-bottom 9px

    .description
      font font-opensans-regular
      font-size 12px
      color color-21
      line-height 1.6em
      margin-bottom 9px

    .show-more
      cursor pointer
      text-decoration underline
      font font-opensans-bold
      font-size 14px
      color color-8

    .additional
      padding-bottom 26px
      border-bottom 2px solid color-23

    .footer
      margin-bottom 30px

    .form-group-links
    .form-group-media
      ul
        list-style none
        padding-left 0
        li
          overflow-wrap: break-word

  &-highlighted
    box-shadow: none;
    animation: 4 flashing 2s;

  &-content-student
    .description
      margin-right 1.563rem
@keyframes flashing
  0%
    box-shadow(0em 0em 1em color-38)
  50%
    box-shadow(0em 0em 1em color-38)
  100%
    box-shadow(0em 0em 0em color-38)

@media (min-width: 768px)
  .message
    &-left
      .picture
        .b-avatar
          width 104px
          height 104px

</style>
