<template>
  <div class="partner-connection-requests-wrapper">
    <b-container class="partner-connection-requests-container">
      <b-row class="justify-content-md-center">
        <b-col cols="12" class="partner-connection-requests-header">
          <h2>My Connection Requests</h2>
          <p>Click on a connection request below to see more details about a global educator and invitation for a classroom exchange</p>
          <p>If no classroom is listed, you will be prompted to create a new classroom
            before you can accept the invitation.</p>
        </b-col>
      </b-row>
      <b-row class="justify-content-md-center">
        <b-col cols="12" class="partner-connection-requests-list">
          <partner-connection-requests-list :invitation_id="invitation_id"></partner-connection-requests-list>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PartnerConnectionRequestsList
  from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabGlobalPartner/PartnerConnectionRequestsList";

export default {
  name: "PartnerConnectionsPage",
  components: {
    PartnerConnectionRequestsList,
  },
  data () {
    return {
      sendingRequest: false,
      selectedInvitation: null,
      invitations: [],
    }
  },
  props: {
    invitation_id: {
      type: Number,
      required: false
    }
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.partner-connection-requests
  &-header
    text-align center

    h2
      font: 700 16px 'Open Sans', sans-serif;
      color: #262626;
      text-transform: uppercase;
      text-align: center;
      margin-top: 2rem;
      font-size 2rem

    p
      font-size 1.3rem
      font-weight  600
      max-width 730px
      margin 0 auto
      margin-top 2rem
      margin-bottom 1rem

</style>
