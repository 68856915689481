import {
  CREATE_CLASSROOM_START,
  CREATE_CLASSROOM_DATA,
  FETCH_CLASSROOM_START,
  FETCH_CLASSROOM_DATA,
  CLASSROOM_EDIT_START,
  CLASSROOM_EDIT_SAVE,
  CLASSROOM_ADD_CONTENT_START,
  CLASSROOM_ADD_CONTENT_SAVE,
  FETCH_CLASSROOM_CHAT_MESSAGES_START,
  FETCH_CLASSROOM_CHAT_MESSAGES_DATA,
  FETCH_TASK_LIST_START,
  FETCH_TASK_LIST_DATA,
  CREATE_TASK_START,
  CREATE_TASK_DATA,
  FETCH_TASK_START,
  FETCH_TASK_DATA,
  TASK_EDIT_START,
  TASK_EDIT_SAVE,
  TASK_EDIT_VISIBILITY_START,
  TASK_EDIT_VISIBILITY_END
} from "@/modules/Classroom/Storage/mutation-types";

export const
  mutations = {
    [CREATE_CLASSROOM_START](state) {
      state.creatingClassroom = true
    },
    [CREATE_CLASSROOM_DATA](state, classroom) {
      state.creatingClassroom = false
      state.classroom = classroom
    },
    [FETCH_CLASSROOM_START](state) {
      state.loadingClassroom = true
    },
    [FETCH_CLASSROOM_DATA](state, classroom) {
      state.classroom = classroom
      state.loadingClassroom = false
    },
    [CLASSROOM_EDIT_START](state) {
      state.loading = true
    },
    [CLASSROOM_EDIT_SAVE](state, classroom) {
      state.classroom = classroom
      state.loading = false
    },
    [CLASSROOM_ADD_CONTENT_START](state) {
      state.addingClassroomContent = true
    },
    [CLASSROOM_ADD_CONTENT_SAVE](state) {
      state.addingClassroomContent = false
    },
    [FETCH_TASK_LIST_START](state) {
      state.loadingTask = true
    },
    [FETCH_TASK_LIST_DATA](state, tasks) {
      state.tasksList = tasks
      state.loadingTask = false
    },
    [CREATE_TASK_START](state) {
      state.loadingTask = true
    },
    [CREATE_TASK_DATA](state, task) {
      state.task = task
      state.loadingTask = false
    },
    [FETCH_TASK_START](state) {
      state.loadingTask = true
    },
    [FETCH_TASK_DATA](state, task) {
      state.task = task
      state.loadingTask = false
    },
    [TASK_EDIT_START](state) {
      state.loadingTask = true
    },
    [TASK_EDIT_SAVE](state, task) {
      state.task = task
      state.loadingTask = false
    },
    [TASK_EDIT_VISIBILITY_START](state) {
      state.loadingTask = true
    },
    [TASK_EDIT_VISIBILITY_END](state) {
      state.loadingTask = false
    },
    [FETCH_CLASSROOM_CHAT_MESSAGES_START](state) {
      state.loadingChatMessages = true
    },
    [FETCH_CLASSROOM_CHAT_MESSAGES_DATA](state, chat_messages) {
      state.loadingChatMessages = false
      state.chat_messages = chat_messages
    },
    initClassroom(state) {
      state.classroom = Object.assign({}, {
        id: null,
        name:null,
        subject_id:null,
        age_range_id:null,
        number_students_range_id:null,
        lang_native_id:null,
        lang_instruction_id:null,
        start_date:null,
        end_date:null,
        level_id:null,
      })
    },
    setCourseId(state, course_id) {
      state.classroom = Object.assign(state.classroom, {
        course_id: course_id,
      })
    }
  }
