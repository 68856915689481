<template>
  <div class="wrapper-classroom-page">
    <b-container class="classroom-page-container">
      <b-row>
        <b-col cols="12" v-if="!loading">
          <h2 class="security-form-subtitle mb-4">COURSE LIBRARY</h2>
          <div class="wrapper-classroom-content">
            <courses-list :no-add-button="true" :is-course-library="true"></courses-list>
          </div>
          <div class="security-form-container-btns c-btns-secondary mt-0">
            <b-button @click="goToMyClassroomsPage" btn-text="My Classrooms"/>
          </div>
        </b-col>
      </b-row>
      <b-overlay no-wrap :show="loading"></b-overlay>
    </b-container>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button";
import CoursesList from "@/core/components/CoursesList";
import {UserMixins} from "@/modules/Classroom/Mixins";
import {ClassroomMixins, UtilsMixins} from "@/core/Mixins";

export default {
  name: "CourseLibraryPage",
  mixins: [UtilsMixins, ClassroomMixins, UserMixins],
  components: {
    'b-button': Button,
    CoursesList,
  },
  data() {
    return {
      loading: true,
    }
  },
  mounted: async function () {
    this.loading = false
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.security-form-subtitle--normal
  font font-opensans-regular
  font-size 20px
  margin-bottom 30px !important

.modal-title
  margin-top 80px

.container-courses
  position relative

#course-pdf-modal
  iframe
    height calc(100vh - 222px)

</style>
