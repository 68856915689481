<template>
  <div class="wrapper-my-classrooms-page">
    <b-container class="my-classrooms-page-container">
      <b-row>
        <b-col cols="12" class="content-header">
          <h2 class="security-form-subtitle page-name mb-1">My Classrooms</h2>
        </b-col>
        <b-col cols="12">
          <div class="wrapper-my-classrooms-content">
            <div class="wrapper-my-classrooms-content__inner">
              <div class="container-course-content">
                <b-row align-h="end" style="margin-bottom: 19px">
                  <b-col cols="auto" class="create-classroom-button-container">
                    <b-button v-if="!isLibrarian" @click="goToCreateClassroomPage" btn-text="Create Classroom Exchange"/>
                  </b-col>
                </b-row>

                <div class="container-header-filters">
                  <div class="container-item-filter container-view">
                    <select-dropdown
                      placeholder="VIEW"
                      v-model="viewModeSelected"
                      value-field="value"
                      :options="viewModes"
                      @input="viewModeChanged" class-select="custom-dropdown--md custom-dropdown--secondary"/>
                  </div>
                  <div class="container-item-filter container-filter-by">
                    <select-dropdown
                      placeholder="FILTER BY"
                      v-model="form.filterBy"
                      :options="filters"
                      @input="sortClassrooms" class-select="custom-dropdown--md custom-dropdown--secondary"/>
                  </div>

                  <div class="container-item-filter">
                    <select-dropdown v-if="filterBy === 'subject'" @input="onSearchClassroom" placeholder="SUBJECTS"
                                     v-model="form.searchString"
                                     :options="subjects"
                                     class-select="custom-dropdown--md custom-dropdown--secondary"/>
                    <select-dropdown v-else-if="filterBy === 'language'" @input="onSearchClassroom"
                                     placeholder="LANGUAGES" v-model="form.searchString"
                                     :options="languages"
                                     class-select="custom-dropdown--md custom-dropdown--secondary"/>
                    <select-dropdown v-else-if="filterBy === 'level'" @input="onSearchClassroom" placeholder="LEVELS"
                                     v-model="form.searchString"
                                     :options="levels"
                                     class-select="custom-dropdown--md custom-dropdown--secondary"/>
                    <select-dropdown v-else-if="filterBy === 'age_range'" @input="onSearchClassroom"
                                     placeholder="AGE RANGES"
                                     v-model="form.searchString" :options="age_ranges"
                                     class-select="custom-dropdown--md custom-dropdown--secondary"/>
                    <div v-else class="common-search-bar-container">
                      <input @keydown.enter="onSearchClassroom" v-model="form.searchString" type="text"
                             placeholder="SEARCH" class="common-search-bar">
                    </div>
                  </div>
                </div>
              </div>

              <div class="separator-filters"></div>
              <div class="container-classrooms">
                <teacher-table table-class-content="table-body-classrooms" :rows="classrooms"
                               :view="viewModeSelected">
                  <template #tableHeader>
                    <div class="column column-name">Name</div>
                    <div class="column column-status">Status</div>
                    <div class="column column-code">Code</div>
                    <div class="column column-progressbar">Course Completion</div>
                    <div class="column column-global-partner">GLOBAL PARTNER</div>
                  </template>
                  <template v-slot:tableRowList="slotProps">
                    <classroom-card :classroom="slotProps.row" :is-view-list="true"></classroom-card>
                  </template>
                  <template v-slot:tableRowGrid="slotProps">
                    <classroom-card :classroom="slotProps.row"></classroom-card>
                  </template>
                </teacher-table>
              </div>
              <b-overlay no-wrap :show="isLoadingSources" rounded="sm"></b-overlay>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <modal ref="classroom_crated_classroom_modal" modal-class="classroom_crated_classroom_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">GOOD JOB!</h2>
        <p class="first-text text-center">
          You’ve successfully created your {{ user.recent_class_room_created }} classroom!
        </p>
        <p class="second-text text-center">
          Next you will select the course your students will use to interact with your global partner classroom.
        </p>
      </template>
      <template #footer>
        <b-button @click="goToClassroomPage(classroom_id)" btn-text="OK"/>
      </template>
    </modal>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button";
import {SelectDropdown} from "@/core/components/SelectDropdown";
import {ClassroomCard} from "@/core/components/Card"
import TeacherTable from "@/modules/Classroom/Components/TeacherTable"
import {MyClassRoomsMixins} from "@/modules/MyClassrooms/Mixins"
import {Modal} from "@/core/components/Modal";
import {Utils} from '@/core/Utils'
import {userTypeMixins} from "@/core/Mixins"

export default {
  name: "MyClassroomsPage",
  mixins: [MyClassRoomsMixins, userTypeMixins],
  components: {
    ClassroomCard,
    Modal,
    'b-button': Button,
    'select-dropdown': SelectDropdown,
    TeacherTable
  },
  data() {
    return {
      classroom_id: null,
      keyword: null,
      form: {
        filterBy: 'newest',
        searchString: ''
      },
      viewModeSelected: 'list',
      viewModes: [
        {name: "Grid View", value: "grid"},
        {name: "List View", value: "list"}
      ],
      filterSelected: {},
      filters: [
        {id: 'all', name: 'All'},
        {id: 'newest', name: 'Newest'},
        {id: 'oldest', name: 'Oldest'},
        {id: 'subject', name: 'Subject'},
        {id: 'language', name: 'Language'},
        {id: 'level', name: 'Level'},
        {id: 'age_range', name: 'Age'},
        {id: 'az', name: 'A - Z'},
        {id: 'za', name: 'Z - A'},
      ],
      customSearch: false,
    }
  },
  mounted() {
    this.getClassrooms(this.form)
  },
  methods: {
    onSearchClassroom() {
      this.getClassrooms(this.form).then(res => !res.length
        ? this.$bvModal.msgBoxOk('No results found.', {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
        : res)
    },
    viewModeChanged: function (value) {
      this.viewModeSelected = value
    },
    showClassroomCreatedModal: function () {
      return this.$refs["classroom_crated_classroom_modal"].showModal()
    },
    closeClassroomCreatedModal: function () {
      return this.$refs["classroom_crated_classroom_modal"].hideModal()
    },
    sortClassrooms() {
      if (this.filterBy === 'all') {
        this.form.filterBy = ''
        this.form.searchString = ''
        this.allClassrooms()
      }

      if (this.filterBy === 'oldest' || this.filterBy === 'newest') {
        const order = this.filterBy === 'newest'
        const sortClassrooms = Utils.sortBy('created_at', order)
        this.classrooms.sort(sortClassrooms)
      }
      if (this.filterBy === 'az' || this.filterBy === 'za') {
        const order = this.filterBy === 'za'
        const sortClassrooms = Utils.sortBy('name', order)
        this.classrooms.sort(sortClassrooms)
      }

    },
    async allClassrooms() {
      this.form.filterBy = ''
      await this.getClassrooms(this.form)
    }
  },
  computed: {
    isLoadingSources() {
      return (this.isLoadingProfile || this.isLoadingClassrooms) && !this.customSearch
    },
    filterBy() {
      return this.form.filterBy
    }
  },
  watch: {
    'form.filterBy': {
      handler: function () {
        this.form.searchString = ''
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.container-classrooms
  max-width 1190px
  width 100%
  margin 0 auto

.table-body-classrooms
  ::v-deep
    .table-body-view
      &.list
        .table-row
          padding 6px
          align-items center
          margin-bottom 6px
          border 1px solid color-28
          color color-8

          @media (min-width 768px)
            padding 7px 9px
          @media (min-width 992px)
            padding 8px 10px

          &.header
            background-color rgba(color-27, 0.27)
            margin-bottom 3px
            border 0

            .column
              font font-opensans-bold
              font-size 0.5rem
              text-transform uppercase
              margin-right 3px

            @media (min-width: 768px)
              .column
                font-size 0.9rem
                margin-right 6px

            @media (min-width: 992px)
              .column
                font-size 1rem

          .column
            &-name
              flex-basis 25%

            &-status
              flex-basis 10%

            &-code
              flex-basis 13%

            &-progressbar
              flex-basis 25%

            &-global-partner
            &-global-partner-not
              flex-basis 25%

            @media (min-width 1200px)
              &-name
                flex-basis 30%

              &-global-partner
              &-global-partner-not
                flex-basis 20%

      &.grid
        grid-template-columns: 1fr
        grid-gap 40px 60px

        .table-row
          padding 0

      @media (min-width: 768px)
        &.grid
          grid-template-columns: 1fr 1fr

      @media (min-width: 992px)
        &.grid
          grid-template-columns: 1fr 1fr 1fr


.first-text
  max-width 247px
  padding-bottom 23px
  font-weight bold

.second-text
  max-width 435px

.create-classroom-button-container
  ::v-deep
    .btn-common
      min-width 130px
      margin-bottom 16px
      padding 6px 16px
      font font-opensans-semibold
      letter-spacing 0

::v-deep
  .modal-dialog
    margin auto

  .modal-message-box .modal-content
    align-items center
    padding 5px

    .modal-body
      margin-bottom 0
      text-align center

      &::first-letter
        text-transform uppercase

    .modal-footer
      margin-bottom 12px

      .btn-primary
        background-color color-3
        border-color color-7
        font font-opensans-semibold
        border-radius 0.25rem
</style>
