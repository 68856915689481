export const getters = {
  isLoadingMiscData: (state) => {
    return state.loadingMiscData
  },
  getPaymentMethods: (state) => {
    return state.payment_methods
  },
  getLevels: (state) => {
    return state.levels
  },
  getSubjects: (state) => {
    return state.subjects
  },
  getLanguages: (state) => {
    return state.languages
  },
  getAgeRanges: (state) => {
    return state.age_ranges
  },
  getNumberStudentsRanges: (state) => {
    return state.number_students_ranges
  },
  getBundles: (state) => {
    return state.bundles
  },
  getTopics: (state) => {
    return state.topics
  },
  isPlayersTrustClassroom: (state) => {
    return state.isPlayersTrustClassroom
  },
}
