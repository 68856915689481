<template>
  <div class="account">
    <h2 class="account__title">My Account</h2>
    <div class="account-balance-wrapper">
      <div class="account-balance-content">
        <div class="account-balance" v-for="exchange in user.exchange_tokens_balance" :key="exchange.id">
          {{exchange.balance_tokens}}
          <div class="account-balance__label">Balance</div>
          <div class="account-balance__tokens">{{exchange.name}} Exchange(s)</div>
        </div>
      </div>
      <b-button @click="onPurchaseTokens" class="account-balance__button" btn-text="Purchase Additional Exchanges"/>
    </div>
    <div class="account-filters">
      <div class="account-actions-container">
        <select-dropdown
            placeholder="FILTER BY"
            value-field="value"
            v-model="selectedDropdown"
            :options="filters"
            @input="getTokensFilterBy"
            class-select="custom-dropdown--md custom-dropdown--secondary"/>
        <b-button @click="onTokensExport" :disabled="!selectedDropdown" btn-text="Download List"/>
      </div>
      <b-row>
        <b-table responsive
                 hover
                 sort-icon-left
                 id="tokens-table"
                 :fields="fields"
                 :items="tokens"
                 v-if="selectedDropdown !== 'usage-history'"
                 sort-by="purchased_date"
                 :sort-desc="true"
                 table-variant="ligth"
                 head-variant="ligth">
          <template #cell(purchased_date)="data">{{ data.item.created_at && setDate(data.item.created_at) }}</template>
          <template #cell(subject)="data">{{ data.item.subject }}</template>
          <template #cell(number_of_tokens_purchased)="data">{{ data.item.amount_tokens }}</template>
          <template #cell(number_of_exchanges)="data">{{ data.item.available_tokens_count || data.item.active_tokens_count || data.item.concluded_tokens_count || data.item.expired_tokens_count }}</template>
          <template #cell(expiration_date)="data">{{ data.item.expire_at && setDate(data.item.expire_at) }}</template>
        </b-table>
        <b-table responsive
                 hover
                 sort-icon-left
                 id="tokens-table"
                 v-if="selectedDropdown === 'usage-history'"
                 :fields="historyFields"
                 :items="tokens"
                 sort-by="date"
                 :sort-desc="true"
                 table-variant="ligth"
                 head-variant="ligth">
          <template #cell(date)="data">{{ data.item.date && setDate(data.item.date) }}</template>
          <template #cell(subject)="data">{{ data.item.subject }}</template>
          <template #cell(description)="data">{{ truncateString(data.item.description || '', 60) }}</template>
          <template #cell(number_of_exchanges)="data">{{ data.item.number_of_exchanges }}</template>
        </b-table>
      </b-row>
    </div>
    <purchase-bundles-modal ref="purchase_bundles_modal" :reload-after-purchase="true" :subjectId="subjectId"/>
    <b-overlay :show="isLoading" spinner-medium rounded="sm" no-wrap/>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button"
import {PurchaseBundlesModal} from "@/core/components/PurchaseBundlesModal"
import {SelectDropdown} from "@/core/components/SelectDropdown";
import {MiscService} from '@/core/Services'
import {UtilsMixins} from "@/core/Mixins"
import moment from "moment"

export default {
  name: 'MyAccount',
  components: {
    'b-button': Button,
    'select-dropdown': SelectDropdown,
    PurchaseBundlesModal,
  },
  mixins: [UtilsMixins],
  data() {
    return {
      selectedDropdown: 'available',
      tokens: [],
      isLoading: false,
      balance: null,
      subjectId: null,
      filters: [
        {
          id: 1,
          name: 'Exchanges Available',
          value: 'available'
        },
        {
          id: 2,
          name: 'Exchanges Active',
          value: 'active'
        },
        {
          id: 3,
          name: 'Exchanges Concluded',
          value: 'concluded'
        },
        {
          id: 4,
          name: 'Exchanges Expired',
          value: 'expired'
        },
        {
          id: 5,
          name: 'Usage History',
          value: 'usage-history'
        },
      ],
      fields: [
        {key: 'purchased_date', label: 'PURCHASE DATE', sortable: true},
        {key: 'subject', label: 'SUBJECT', sortable: true},
        {key: 'number_of_tokens_purchased', label: 'NUMBER PURCHASED', sortable: true},
        {key: 'number_of_exchanges', label: 'NUMBER AVAILABLE', sortable: true},
        {key: 'expiration_date', label: 'EXPIRATION DATE', sortable: true},
      ],
      historyFields: [
        {key: 'date', label: 'DATE', sortable: true},
        {key: 'subject', label: 'SUBJECT', sortable: true},
        {key: 'description', label: 'DESCRIPTION', sortable: true},
        {key: 'number_of_exchanges', label: 'NUMBER OF EXCHANGES', sortable: true}
      ],
    }
  },
  methods: {
    async getTokensFilterBy(value) {
      this.isLoading = true
      const data = {scope: value, type: 'teacher'}
      await MiscService.exchangeTokensPurchases(data).then(({data: exchanges}) => {
        this.tokens = Object.values(exchanges)
        this.isLoading = false
      })
    },
    onPurchaseTokens() {
      if (this.user.subjects) {
        if (this.user.subjects.length === 1) {
          this.subjectId = this.user.subjects[0].id
        }
      }
      this.$refs["purchase_bundles_modal"].showPurchaseBundlesModal()
    },
    setDate(date) {
      return moment(date).format('M/D/YYYY')
    },
    truncateString(str, num) {
      return str.length > num ? str.slice(0, num) + "..." : str
    },
    onTokensExport() {
      const data = {scope: this.selectedDropdown}
      MiscService.exchangeTokensPurchasesExport(data).then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.filters.find((fil) => fil.value === this.selectedDropdown).name}.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
    }
  },
  watch: {
    selectedDropdown(newValue) {
      let idxField
      this.fields.map((field, idx )=> {
        if (field.key === 'number_of_exchanges') {
          idxField = idx
        }
      })
      switch (newValue) {
        case 'active':
          this.fields[idxField].label = 'NUMBER ACTIVE'
          break;

        case 'concluded':
          this.fields[idxField].label = 'NUMBER CONCLUDED'
          break;

        case 'expired':
          this.fields[idxField].label = 'NUMBER EXPIRED'
          break;

        default:
          this.fields[idxField].label = 'NUMBER AVAILABLE'
          break;
      }
    }
  },
  async mounted() {
    this.getTokensFilterBy(this.selectedDropdown)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
.account
  max-width 1440px
  margin 0 auto

  .account__title
    padding 40px 100px 40px 100px
    font font-opensans-bold
    text-transform uppercase
    font-size 24px

  .account-balance-wrapper
    padding 0px 100px 40px 100px
    width 100%
    display flex
    justify-content space-between

    .account-balance-content
      display flex

      .account-balance
        position relative
        font font-opensans-regular
        font-size 120px
        line-height 140px
        margin-right 120px
        margin-right 180%

        .account-balance__label, .account-balance__tokens
          line-height normal
          font font-opensans-regular
          position absolute

        .account-balance__label
          top -20px

        .account-balance__tokens
          bottom 60px
          right -80px
          right -120px
          max-width 100px

    .account-balance__button
      background-color color-35
      max-width 170px
      color #000
      max-height 110px

  .account-filters
    padding 40px 100px 40px 100px
    background-color color-32

    .account-actions-container
      width 100%
      display flex
      justify-content space-between

      .custom-dropdown--secondary
        max-width 300px

        ::v-deep
          button
            span
              text-transform uppercase

    ::v-deep
      .row
        margin-top 50px

        #tokens-table
          border-spacing 0 2px !important
          font-size 16px

          thead
            tr
              background-color #fef9ec

              th
                padding-left 50px
                background-position left calc(30px) center !important

          tbody
            tr
              background-color #fff
              td
                padding-left 50px

@media (max-width: 900px)
  .account
    .account__title
      padding 40px 30px 40px 30px

    .account-balance-wrapper
      padding 0px 30px 40px 30px

    .account-filters
      padding 40px 30px 40px 30px

@media (max-width: 750px)
  .account
    .account-balance-wrapper
      flex-direction column

      .account-balance-content
        .account-balance
          margin-right 40%

      .account-balance__button
        margin 0 auto
        margin-top 40px
        max-width none

@media (max-width: 650px)
  .account
    .account-filters
      .account-actions-container
        flex-direction column

        .custom-dropdown--secondary
          max-width none
          margin-bottom 20px

@media (max-width: 450px)
   .account
    .account-balance-wrapper
      .account-balance-content
        flex-direction column

        .account-balance
          max-width 600px
          margin 0 auto
          right 20px
          margin-bottom 50px

          &:last-child
            margin-bottom 0

</style>
