export const state = {
  payment_methods:[],
  levels:[],
  bundles: [],
  subjects:[],
  languages:[],
  age_ranges:[],
  topics:[],
  number_students_ranges:[],
  loadingMiscData: false,
  loadingPaymentMethods: false,
  loadingLevels: false,
  loadingSubjects: false,
  loadingLanguages: false,
  loadingAgeRanges: false,
  loadingNumberStudentsRanges: false,
  loadingBundles: false,
  loadingTopics: false,
  isPlayersTrustClassroom: false
}

