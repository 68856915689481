<template>
  <modal ref="modal"
         id="form-payment"
         :hideFooter="true"
         :hideCloseButton="true"
         :noCloseOnBackdrop="true"
         @hidden="init_form()">
    <template #body>
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col cols="12" md="10">
            <form @submit.prevent="" class="mt-5">
              <form-select v-model="$v.payment_method_id.$model"
                           :validator="$v.payment_method_id"
                           label="Payment Method *"
                           :options="paymentMethodsList"
                           custom-class="custom-dropdown--md custom-dropdown--secondary"/>
              <div v-if="payment_method_type === 'credit-card'">
                <form-input v-model="$v.credit_card.number.$model"
                            :validator="$v.credit_card.number"
                            label="Credit Card Number *"
                            :maxlength="40"
                            custom-class="common-input common-input--primary"/>
                <form-input v-model="$v.credit_card.holder.$model"
                            :validator="$v.credit_card.holder"
                            label="Credit Card Holder *"
                            :maxlength="40"
                            custom-class="common-input common-input--primary"/>
                <div class="expiration-wrapper">
                  <p class="expiration-label">Credit Card Expiration *</p>
                  <div class="expiration-container">
                    <form-input v-model="$v.expiration.MM.$model"
                                :show-errors="true"
                                type="text"
                                :maxlength="2"
                                :validator="$v.expiration.MM"
                                :numeric-errors="true"
                                placeholder="MM"
                                custom-class="common-input common-input--primary"/>
                    <span class="expiration-container-bar"> / </span>
                    <form-input v-model="$v.expiration.AA.$model"
                                :show-errors="false"
                                :numeric-errors="true"
                                :maxlength="2"
                                type="text"
                                placeholder="AA"
                                :validator="$v.expiration.AA"
                                custom-class="common-input common-input--primary"/>
                  </div>
                  <form-input v-model="$v.credit_card.cvv.$model"
                              id="input-cvv"
                              :maxlength="4"
                              :validator="$v.credit_card.cvv"
                              label="CVV *"
                              :numeric-errors="true"
                              custom-class="common-input common-input--primary"/>
                </div>
              </div>
              <div v-show="payment_method_type === 'invoice-me'">
                <p class="po-message">If your organization requires a PO number on our invoice, enter that number below.
                  You may also upload a copy of the invoice below or email it to support@levelupvillage.com.</p>
                <div class="upload-container"
                     :class="{'upload-container--error': false}">
                  <form-input v-model="po.number"
                              label="PO Number"
                              :maxlength="40"
                              custom-class="common-input common-input--primary"/>
                  <div class="po-container">
                    <p class="expiration-label">Purchase Order (PO)</p>
                    <upload-file @File:loaded="onFileLoaded"
                                 labelButton="Upload Invoice"
                                 id="upload-invoice"
                                 v-model="po.url"
                                 :errors="uploadErrors"
                                 :currentFile="po.url"/>
                  </div>
                </div>
                <b-form-invalid-feedback id="invalid-feedback-po" v-if="false">
                  <ul class="error-content">
                    <li class="error-text">
                      Field is required
                    </li>
                  </ul>
                </b-form-invalid-feedback>
                <form-input v-model="$v.invoice_me.billing_contact_organization.$model"
                            :validator="$v.invoice_me.billing_contact_organization"
                            label="Billing Contact Organization *"
                            :maxlength="40"
                            custom-class="common-input common-input--primary"/>
                <form-input v-model="$v.invoice_me.billing_contact_name.$model"
                            :validator="$v.invoice_me.billing_contact_name"
                            label="Billing Contact Name *"
                            :maxlength="40"
                            custom-class="common-input common-input--primary"/>
                <form-input v-model="$v.invoice_me.billing_contact_title.$model"
                            :validator="$v.invoice_me.billing_contact_title"
                            label="Billing Contact Title *"
                            :maxlength="40"
                            custom-class="common-input common-input--primary"/>
                <form-input v-model="$v.invoice_me.billing_contact_email_address.$model"
                            :validator="$v.invoice_me.billing_contact_email_address"
                            type="email"
                            :maxlength="40"
                            label="Billing Contact Email Address *"
                            custom-class="common-input common-input--primary"/>
                <form-input v-model="$v.invoice_me.billing_contact_phone_number.$model"
                            :validator="$v.invoice_me.billing_contact_phone_number"
                            label="Billing Contact Phone Number *"
                            :maxlength="40"
                            custom-class="common-input common-input--primary"/>
                <form-input v-model="$v.invoice_me.billing_contact_address.$model"
                            :validator="$v.invoice_me.billing_contact_address"
                            label="Billing Contact Address *"
                            :maxlength="40"
                            custom-class="common-input common-input--primary"/>
              </div>
              <div class="security-form-container-btns c-btns-secondary">
                <b-button @click="onProcessPayment" btn-text="Pay Now" :disabled="processing_form"/>
                <b-button @click="$bvModal.hide('form-payment')" btn-text="Cancel" :disabled="processing_form"/>
              </div>
            </form>
            <b-overlay no-wrap :show="processing_form"></b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </modal>
</template>

<script>
import FormInput from "@/core/components/Form/Input"
import FormSelect from "@/core/components/Form/Select"
import UploadFile from "@/core/components/UploadFileAttachLink/UploadFile";
import {Modal} from "../Modal"
import {required, maxValue, numeric, minValue, email, minLength, maxLength} from "vuelidate/lib/validators"
import {Button} from "@/core/components/Button"
import {UtilsMixins} from "@/core/Mixins"
import {UploadService} from "@/core/Services";

export default {
  name: "FormPayment",
  mixins: [UtilsMixins],
  components: {
    FormInput,
    Modal,
    FormSelect,
    UploadFile,
    'b-button': Button,
  },
  data() {
    return {
      processing_form: true,
      payment_method_selected: null,
      payment_method_id: null,
      credit_card: {
        number: null,
        holder: '',
        cvv: null,
        expiration: ''
      },
      invoice_me: {
        billing_contact_organization: '',
        billing_contact_name: '',
        billing_contact_title: '',
        billing_contact_email_address: '',
        billing_contact_phone_number: '',
        billing_contact_address: '',
      },
      expiration: {
        MM: '',
        AA: ''
      },
      po: {
        number: '',
        url: '',
        file: null
      },
      validator: {},
      uploadErrors: [],
      poError: false,
      paymentMethodsList: []
    }
  },
  validations() {
    return {
      payment_method_id: {
        required,
      },
      credit_card: {
        number: {
          required,
          numeric
        },
        holder: {
          required
        },
        cvv: {
          required,
          numeric,
          minLength: minLength(3),
          maxLength: maxLength(4)
        }
      },
      invoice_me: {
        billing_contact_organization: {required},
        billing_contact_name: {required},
        billing_contact_title: {required},
        billing_contact_email_address: {required, email},
        billing_contact_phone_number: {required},
        billing_contact_address: {required}
      },
      expiration: {
        MM: {
          required,
          maxValue: maxValue(12),
          minValue: minValue(1),
          minLength: minLength(2),
          numeric
        },
        AA: {
          required,
          maxValue: maxValue(99),
          minValue: minValue(0),
          minLength: minLength(2),
          numeric,
        }
      },
      pay_with_credit_card: ['payment_method_id', 'credit_card', 'expiration'],
      pay_with_invoice_me: ['payment_method_id', 'invoice_me'],
    }
  },
  async mounted() {
    this.init_form()
    this.paymentMethodsOptions()
  },
  methods: {
    async onProcessPayment() {
      if (this.processing_form) {
        return
      }

      this.processing_form = true

      this.$v.payment_method_id.$touch()
      if (this.$v.payment_method_id.$invalid) {
        this.processing_form = false
        return
      }

      let form_data = {payment_method_id: this.payment_method_id}

      if (this.payment_method_type === 'invoice-me') {
        this.$v.pay_with_invoice_me.$touch()
        if (this.$v.pay_with_invoice_me.$invalid) {
          this.processing_form = false
          return
        }

        form_data = {...form_data, ...this.invoice_me}

        if (this.po.file) {
          try {
            const result = await UploadService.uploadFile(this.po.file, 0, 'payment_order')
            form_data.po = {url: result.name, number: this.po.number}
          } catch (e) {
            const errorMessage = e.message ? e.message : 'Error uploading the PO file'
            this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })

            this.processing_form = false
            return
          }
        }
      } else if (this.payment_method_type === 'credit-card') {
        this.$v.pay_with_credit_card.$touch()
        if (this.$v.pay_with_credit_card.$invalid) {
          this.processing_form = false
          return
        }

        form_data.credit_card = {...this.credit_card, expiration: `${this.expiration.MM}${this.expiration.AA}`}
      } else {
        this.processing_form = false
        return
      }

      this.$emit('on-to-pay', form_data)
      // this.$bvModal.hide('form-payment')
    },
    onFileLoaded(files) {
      if (files.length > 0) {
        this.po.url = files[0].name
        this.po.file = files[0]
      }
    },
    paymentMethodsOptions() {
      this.payment_methods.map(method => {
        method.type === 'credit-card' && this.paymentMethodsList.push(method)
        method.type === 'invoice-me' && this.paymentMethodsList.push(method)
      })
    },
    init_form() {
      this.payment_method_id = null

      this.credit_card = {
        number: null,
        holder: '',
        cvv: null,
        expiration: ''
      }

      this.invoice_me = {
        billing_contact_organization: '',
        billing_contact_name: '',
        billing_contact_title: '',
        billing_contact_email_address: '',
        billing_contact_phone_number: '',
        billing_contact_address: '',
      }

      this.po = {
        number: '',
        url: '',
        file: null
      }

      this.expiration.MM = ''
      this.expiration.AA = ''

      this.$v.$reset()

      this.processing_form = false
    }
  },
  computed: {
    payment_method_type() {
      return this.payment_method_selected ? this.payment_method_selected.type : null
    },
  },
  watch: {
    'payment_method_id': {
      handler: function (newValue) {
        if (newValue) {
          this.payment_method_selected = this.paymentMethodsList.find((method) => method.id === newValue)
        } else {
          this.payment_method_selected = null
        }
      },
    }
  },
  destroyed() {
    this.init_form()
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#form-payment
  ::v-deep
    .modal-dialog
      max-width 800px

  .expiration-label
    font font-opensans-regular
    margin-bottom 0.5rem

  .expiration-container
    display flex

    ::v-deep
      .form-group
        max-width 90px

        .invalid-feedback
          min-width 200px

    .expiration-container-bar
      font font-opensans-regular
      font-size 25px
      margin 4px 6px 6px

  .po-message
    margin-bottom 10px

  .upload-container
    display flex
    justify-content space-between

    .po-container
      width 70%
      margin-left 10px

  @media (max-width 450px)
    .upload-container
      display block

      .po-container
        width 100%
        margin-left unset

  .upload-container--error
    ::v-deep
      .common-input, .form-upload-file
        border solid 2px #dc3545

        &:focus
          border-color #dc3545
          box-shadow 0 0 0 .2rem rgba(220, 53, 69, .25)

#input-cvv
  max-width 90px

  ::v-deep
    .invalid-feedback
      min-width 200px

#invalid-feedback-po
  display block !important
</style>
<style lang="stylus">
#form-payment
  .modal-dialog
    max-width 600px

</style>
