export const InputMixins = {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    validator: {
      type: Object,
      default: function () {
        return {
          $error: false,
          $params: {}
        }
      }
    },
    numericErrors: {
      type: Boolean
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    /**
     * false (denotes invalid state) is great for when there's a blocking or required field. A user must fill in this field properly to submit the form.
     * true (denotes valid state) is ideal for situations when you have per-field validation throughout a form and want to encourage a user through the rest of the fields.
     * null Displays no validation state (neither valid nor invalid)
     * @returns {boolean}
     */
    state () {
      return !this.errors.length ? null : false
    },
    errors () {
      const messages = {
        'required': () => {
          return 'Field is required'
        },
        'requiredIf': (param) => {
          return `requiredIf ${param}.`
        },
        'requiredUnless': (param) => {
          return `requiredUnless ${param}.`
        },
        'minLength': (param) => {
          const variantMessage = this.numericErrors ? `numbers` : `letters`
          return `Field must have at least ${param.min} ${variantMessage}.`
        },
        'maxLength': (param) => {
          const variantMessage = this.numericErrors ? `numbers` : `letters`
          return `Field must have maximum ${param.max} ${variantMessage}.`
        },
        'minValue': (param) => {
          return `Min value ${param.min}.`
        },
        'maxValue': (param) => {
          return `Max value ${param.max}.`
        },
        'between': (param) => {
          return `Must be between  ${param.min} and  ${param.max}.`
        },
        'alpha': () => {
          return `Do not include numbers or symbols.`
        },
        'alphaWithSpaces': () => {
          return `Do not include numbers or symbols.`
        },
        'alphaNum': (param) => {
          return `alphaNum ${param}.`
        },
        'numeric': (param) => {
          return `Field must be ${param.type}.`
        },
        'integer': (param) => {
          return `integer ${param.type}.`
        },
        'decimal': (param) => {
          return `decimal ${param}.`
        },
        'email': (param) => {
          return `Invalid ${param.type}.`
        },
        'ipAddress': (param) => {
          return `ipAddress ${param}.`
        },
        'macAddress': (param) => {
          return `macAddress ${param}.`
        },
        'sameAs': (param) => {
          return `sameAs ${param}.`
        },
        'url': () => {
          return `Field must have a valid url format.`
        },
        'or': (param) => {
          return `or ${param}.`
        },
        'and': (param) => {
          return `and ${param}.`
        },
        'not': (param) => {
          return `not ${param}.`
        },
        'withParams': (param) => {
          return `withParams ${param}.`
        },
        'validateDomainExtension': () => {
          return `Field must have a valid url format..`
        },
      }

      let errors = []
      if (this.validator.$error) {
        Object.keys(this.validator.$params).forEach(key => {
          if (typeof messages[key] === 'function') {
            if (!this.validator[key]) {
              errors.push(messages[key](this.validator.$params[key]))
            }
          }
        });
      }

      return errors
    },
    inputValue: {
      get: function () {
        return this.value ?? ''
      },
      set: function () {
      }
    }
  }
};
