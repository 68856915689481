<template>
  <form @submit.prevent="" class="mt-2">
    <div>
      <b-form-group label="Classroom Name *" label-for="input-sm">
        <custom-input v-model="form.name" :multiple="true"
                      :errors="errors.name" placeholder="Classroom Name *"/>
      </b-form-group>
      <b-form-group label="Subject *" label-for="input-sm" v-if="false">
        <custom-select v-model="form.subject_id" :errors="errors.subject_id" :options="subjects"
                       placeholder="Subject *"/>
      </b-form-group>

      <div v-show="isWorldLanguage">
        <p class="mb-2">Level *</p>
        <level-descriptions-modal v-show="isWorldLanguage" class="mb-2" />
      </div>
      <b-form-group v-if="isWorldLanguage" label-for="input-sm">
        <custom-select v-model="form.level_id" :errors="errors.level_id" :options="levels"
                       placeholder="Level *"/>
      </b-form-group>
      <b-form-group label="What is students primary language? *" v-if="isWorldLanguage" label-for="input-sm">
        <custom-select v-model="form.lang_native_id" :errors="errors.lang_native_id" :options="languages"
                       placeholder="What is students primary language? *"/>
      </b-form-group>
      <b-form-group :label="placeholderLangIntruction" label-for="input-sm">
        <custom-select v-model="form.lang_instruction_id"
                       :errors="errors.lang_instruction_id" :options="languages"
                       :placeholder="placeholderLangIntruction"
                       custom-class="custom-dropdown--primary"/>
      </b-form-group>
      <b-form-group label="How many students will be in this classroom? *" label-for="input-sm">
        <custom-select v-model="form.number_students_range_id"
                       :errors="errors.number_students_range_id"
                       :options="number_students_ranges"/>
      </b-form-group>
      <b-form-group label="Age Range in this classroom *" label-for="input-sm">
        <custom-select v-model="form.age_range_id" :errors="errors.age_range_id" :options="payingStudentAges"
                       placeholder="Age Range in this classroom *"/>
      </b-form-group>
    </div>
    <div class="security-form-page-info mt-5 mb-5">
      <div class="your-info">Desired Start Date</div>
    </div>
    <div class="range-time">
      <div class="range-time--list">
        <div class="range-time--list-item">
          <div class="common-half-field">
            <date-picker v-model="form.start_date"
                         placeholder="Start"
                         :errors="errors.start_date"
                         :min="minStartDate"/>
          </div>
        </div>
      </div>
    </div>
    <!--    <div>-->
    <!--      <custom-Radio v-model="form.approval_method" :errors="errors.approval_method"-->
    <!--                    :options="approveStudentsPostsOptions" custom-class="common-input"/>-->
    <!--    </div>-->

    <div  v-if="id" class="security-form-page-info mt-5 mb-5">
      <div class="your-info">MY CLASSROOM CODE</div>
      <div class="subttitle">classroom code: {{ classroom.code }}</div>
      <div class="security-form-container-btns c-btns-secondary">
        <b-button @click="onReset" btn-text="Reset Code" outline id="btn-reset"/>
      </div>
    </div>

    <div class="security-form-container-btns c-btns-secondary c-btns-create-classroom">
      <b-button @click="cancel()" btn-text="Cancel"/>
      <b-button v-if="id" @click="showAgeMessageUpdate()" btn-text="Save Changes"/>
      <b-button v-else @click="createNewClassroom()" btn-text="Continue"/>
    </div>
    <b-overlay no-wrap :show="sendingData"></b-overlay>
  </form>
</template>

<script>
import DatePicker from "@/core/components/DatePicker/DatePicker";
import Input from "@/core/components/FormInput/Input";
import Select from "@/core/components/FormInput/Select";
import {ClassroomMixins} from "@/modules/Classroom/Mixins";
import {UtilsMixins} from "@/core/Mixins";
import {ClassroomService} from "@/core/Services"
import {Button} from "@/core/components/Button";
import LevelDescriptionsModal from './LevelDescriptionsModal.vue';

export default {
  name: "ClassroomForm",
  mixins: [ClassroomMixins, UtilsMixins],
  components: {
    'b-button': Button,
    'date-picker': DatePicker,
    'custom-input': Input,
    'custom-select': Select,
    LevelDescriptionsModal
    // 'custom-Radio': Radio,
  },
  data() {
    return {
      form: {
        name: null,
        subject_id: null,
        age_range_id: null,
        lang_instruction_id: null,
        lang_native_id: null,
        start_date: null,
        end_date: null,
        number_students_range_id: null
      },
      validations: {
        name: {
          validate: 'required|alphanumeric',
        },
        subject_id: {
          validate: 'required',
        },
        level_id: {
          validate: 'required',
        },
        age_range_id: {
          validate: 'required',
        },
        lang_instruction_id: {
          validate: 'required',
        },
        lang_native_id: {
          validate: 'required',
        },
        start_date: {
          validate: 'required',
        },
        end_date: {
          validate: 'required',
        },
        number_students_range_id: {
          validate: 'required',
        },
      },
      errors: [],
      approveStudentsPostsOptions: [
        {
          id: 1,
          name: 'I want to approve student videos before others can view them.'
        },
        {
          id: 2,
          name: 'I will allow students to post videos without my approval.'
        },
      ],
      selectedLanguageInstruction: null,
      selectedNativeLanguage: null,
      minStartDate: new Date(),
      sendingData: false
    }
  },
  props: {
    id: {
      type: [String, Number]
    }
  },
  mounted() {
  },
  methods: {
    createNewClassroom() {
      if(!this.sendingData) {
        this.sendingData = true

        if (!this.isWorldLanguage) {
          this.form.lang_native_id = this.form.lang_instruction_id
        }

        const form = this.form

        if (this.isValidForm()) {
          this.createClassroom(form).then((response) => {
            if (response.success === true) {
              const selectedSubject = this.subjects.find(subject => subject.id === this.form.subject_id)
              let createdMessage = selectedSubject.name
              if (this.isWorldLanguage) {
                const selectedLanguage = this.languages.find(language => language.id === this.form.lang_instruction_id)
                createdMessage = `${selectedSubject.name}, ${selectedLanguage.name}`
              }
              this.$emit('Classroom:Create:Success', response.data, createdMessage)
            } else {
              this.createErrorMessage = response.message
              this.$emit('Classroom:Create:Error', response.message)
            }

            this.sendingData = false
          }).catch(err => {
            this.$emit('Classroom:CreateAPI:Error', err)
            this.sendingData = false
          })
        } else {
          this.$emit('Classroom:Form:Error', this.errors)
          this.sendingData = false
        }
      }
    },
    async onReset() {
      this.sendingData = true
      try {
        await ClassroomService.resetClassroomCode(this.id)
        const classroom = await ClassroomService.get(this.id)
        this.classroom.code = classroom.data.code
        document.getElementById("btn-reset").blur()
      } catch(error) {
        this.$bvModal.msgBoxOk(error.message, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.sendingData = false
      }

    },
    showAgeMessageUpdate() {
      const selectedAge = this.age_ranges.find((val) => val.id === this.form.age_range_id)
      if (selectedAge.min_age < 13) {
        return this.$bvModal.msgBoxOk(`Please note that you will be required to review and
          approve each video from your students before they will be shared with your partner classroom.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }).then(() => {
            this.updateClassroomData()
          })
      }
      this.updateClassroomData()
    },
  },
  watch: {
    selectedSubject: function (newValue) {
      this.form.subjectId = newValue.id

      this.selectedNativeLanguage = null
      this.selectedLanguageInstruction = null
      this.form.nativeLanguage = null
      this.form.langInstruction = null
    },
    selectedNativeLanguage(newValue) {
      this.form.nativeLanguage = newValue.id ?? null
    },
    selectedLanguageInstruction(newValue) {
      this.form.langInstruction = newValue.id ?? null

      if (!this.isWorldLanguage) {
        this.selectedNativeLanguage = newValue
      }
    },
    selectedAgeRange(newValue) {
      this.form.ageRangeId = newValue.id ?? null
    },
    isWorldLanguage: {
      handler: function (newValue) {
        if (newValue) {
          this.validations.level_id = {validate: 'required'}

          delete this.validations.age_range_id
          delete this.errors.age_range_id
        } else {
          delete this.validations.level_id
          delete this.errors.level_id

          this.validations.age_range_id = {validate: 'required'}
        }
      },
      immediate: true
    },
    'form.start_date'(start_date) {
      if (start_date) {
        let date = new Date(start_date + 'T00:00:00')
        let end_date = new Date(date)
        end_date.setDate(end_date.getDate() + 90)

        this.form.end_date = `${end_date.getFullYear()}-${String(end_date.getMonth()).padStart(2, '0')}-${String(end_date.getDate()).padStart(2, '0')}`
      } else {
        this.form.end_date = null
      }
    },
  },
  computed: {
    isWorldLanguage() {
      const selectedSubject = this.subjects.find(subject => subject.id === this.form.subject_id)
      return selectedSubject !== undefined && selectedSubject.type === 'world_languages'
    },
    placeholderLangIntruction() {
      return !this.isWorldLanguage ? 'My students can complete task in *' : 'What language are students learning? *'
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

::v-deep
  .custom-radio
    font-size 14px

    input[type="radio"]
      margin-right 13px

  .modal-dialog
    margin auto

  .modal-message-box .modal-content
    align-items center
    padding 5px

    .modal-body
      margin-bottom 0
      text-align center
      &::first-letter
        text-transform uppercase

    .modal-footer
      margin-bottom 12px
      .btn-primary
        background-color color-3
        border-color color-7
        font font-opensans-semibold
        border-radius 0.25rem

.common-half-field
  ::v-deep
    .form-group
      padding-right 10px
      padding-left 10px
      flex 1

      &:first-child
        padding-left 0

      &:last-child
        padding-right 0

    .b-form-btn-label-control.form-control > .form-control
      color color-7 !important

.subttitle
  margin-top 20px
  font font-opensans-bold
  color color-8
  font-size 14px
  text-transform capitalize

::v-deep
  .modal-message-box
    margin 0 auto !important
</style>
