export const getters = {
  getClassroomData: (state) => {
    Object.assign(state.classroom, {paymentRequired: !!state.classroom.student_payment_required})

    return state.classroom
  },
  tasksList: (state) => {
    return state.tasksList
  },
  isLoadingClassroom: (state) => {
    return state.loadingClassroom
  },
  creatingClassroom: (state) => {
    return state.creatingClassroom
  },
  isLoadingTasks: (state) => {
    return state.loadingTask
  },
  isAddingClassroomContent: (state) => {
    return state.addingClassroomContent
  },
  chat_messages: (state) => {
    return state.chat_messages
  },
}
