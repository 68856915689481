<template>
  <teacher-wizard :current-step="currentStep" :steps="steps">
    <template v-slot="{currentStep}">
      <component :is="`Step${currentStep}`"
                  :profile-data="profile"
                  @change-step="changeStep"
                  @school-dpa-signed="schoolDpaSigned"/>
    </template>
  </teacher-wizard>
</template>

<script>
import TeacherWizard from '@/core/components/TeacherWizard/TeacherWizard'
import {Step1, Step2, Step3, Step4, Step5, Step6} from '@/core/components/TeacherWizard/ProfileSteps'

export default {
  name: 'ProfileWizardPage',
  components: {
    TeacherWizard,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6
  },
  data() {
    const steps = [
      { num: 1 },
      { num: 2 },
      { num: 3 },
      { num: 4 },
      { num: 5 },
      { num: 6 }
    ]
    return {
      profile: {},
      stepsList: [...steps],
      steps: [...steps],
      currentStep: 1
    }
  },
  methods: {
    changeStep({step, form}) {
      this.currentStep = step
      this.profile = {
        ...this.profile,
        ...form
      }
    },
    schoolDpaSigned(signed) {
      const steps = [
        { num: 1 },
        { num: 2 },
        { num: 3 },
        { num: 5 },
        { num: 6 }
      ]
      this.steps = signed ? steps : this.stepsList
    }
  },
  watch: {
  }
}
</script>

<style>

</style>
