<template>
  <div class="input-student-container">
    <form-input v-model="$v.name.$model"
                :validator="$v.name"
                custom-class="common-input common-input--primary"
                :maxlength="25"
                @has-error="(error) => $emit('has-error', error)"
                @input="(value) => $emit('input', value)"/>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators"
import FormInput from "@/core/components/Form/Input"
export default {
  name: 'ClassroomDetailTabInputGroupName',
  components: {
    FormInput
  },
  props: {
    groupName: {
      type: String,
    }
  },
  data () {
    return {
      name: ''
    }
  },
  validations() {
    return {
      name: {
        required,
        alphaWithSpaces: function(value) {
          return /^[a-zA-Z\s]+$/.test(value)
        }
      }
    }
  },
  mounted() {
    this.name = this.groupName
  }
}
</script>

<style lang="stylus" scoped>
.input-student-container
  ::v-deep
    .form-group
      margin-bottom 0 !important

      .form-control
        margin-bottom 0 !important

      .invalid-feedback
        .error-content
          margin 0 !important
</style>
