<template>
  <div class="partner-detail-wrapper">
    <b-container class="partner-detail" v-if="connection_request">
      <b-row class="partner-detail-row" v-if="!invitation_received">
        <b-col cols="12">
          You invited {{ full_name }} to connect
          <span v-if="my_classroom"> with your Classroom {{ my_classroom.name }}</span>
          on {{ connection_request.created_at | dateMonthDayYear2 }}.
        </b-col>
      </b-row>
      <b-row class="partner-detail-row">
        <b-col cols="6" sm="6" md="3" class="partner-detail-picture">
          <b-avatar class="partner-detail-avatar" :src="partner.avatar" square></b-avatar>
        </b-col>
        <b-col cols="6" sm="6" md="4" class="partner-detail-profile">
          <div class="partner-detail-name">{{ full_name }}</div>
          <div class="partner-detail-country">{{ ubigeo }}</div>
          <div class="partner-detail-country-flag"><span :class="flag"></span></div>
        </b-col>
        <b-col cols="12" sm="12" md="5" class="partner-detail-school">
          <div class="partner-detail-school-item"><span>School: </span>{{ school_name }}</div>
          <div class="partner-detail-school-item"><span>Subject(s):</span>{{ instruction_subjects }}</div>
          <div class="partner-detail-school-item"><span>Students’ Primary Language:</span>{{ native_language }}</div>
          <div class="partner-detail-school-item"><span>Students Are Learning:</span>{{ instruction_languages }}</div>
          <div class="partner-detail-school-item"><span>Ages:</span>{{ ages }}</div>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row">
        <b-col cols="12" class="partner-detail-bio">
          <div class="partner-detail-bio-title">About Me:</div>
          <div class="partner-detail-bio-text">{{ partner.about_info }}</div>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row">
        <b-col cols="12" sm="6" class="partner-detail-classroom ">
          <div class="partner-detail-bio-title my-classroom">My Classroom</div>
          <div class="partner-detail-classroom-item"><span>Classroom Name:</span> {{ my_classroom.name }}</div>
          <div class="partner-detail-classroom-item" v-if="my_classroom.level"><span>Level:</span>
            {{ my_classroom.level.name }}
          </div>
          <div class="partner-detail-classroom-item" v-if="my_classroom.number_students_range">
            <span>Number of Students:</span> {{ my_classroom.number_students_range.name }}
          </div>
          <div class="partner-detail-classroom-item" v-if="my_classroom.lang_native">
            <span>Students’ Primary Language:</span> {{ my_classroom.lang_native.name }}
          </div>
          <div class="partner-detail-classroom-item" v-if="my_classroom.lang_instruction">
            <span>Students Are Learning:</span> {{ my_classroom.lang_instruction.name }}
          </div>
          <div class="partner-detail-classroom-item" v-if="my_classroom.age_range"><span>Ages:</span>
            {{ my_classroom.age_range.name }}
          </div>
          <div class="partner-detail-classroom-item"><span>Desired Start Date:</span>
            {{ my_classroom.start_date | dateMonthDayYear2 }}
          </div>
        </b-col>
        <b-col cols="12" sm="6" class="partner-detail-classroom" v-if="partner_classroom">
          <div class="partner-detail-bio-title partner-classrrom">{{partner.first_name}}'s Classroom</div>
          <div class="partner-detail-classroom-item"><span>Classroom Name:</span> {{ partner_classroom.name }}</div>
          <div class="partner-detail-classroom-item" v-if="partner_classroom.level"><span>Level:</span>
            {{ partner_classroom.level.name }}
          </div>
          <div class="partner-detail-classroom-item" v-if="partner_classroom.number_students_range"><span>Number of Students:</span>
            {{ partner_classroom.number_students_range.name }}
          </div>
          <div class="partner-detail-classroom-item" v-if="partner_classroom.lang_native"><span>Students’ Primary Language:</span>
            {{ partner_classroom.lang_native.name }}
          </div>
          <div class="partner-detail-classroom-item" v-if="partner_classroom.lang_instruction"><span>Students Are Learning:</span>
            {{ partner_classroom.lang_instruction.name }}
          </div>
          <div class="partner-detail-classroom-item" v-if="partner_classroom.age_range"><span>Ages:</span>
            {{ partner_classroom.age_range.name }}
          </div>
          <div class="partner-detail-classroom-item"><span>Desired Start Date:</span>
            {{ partner_classroom.start_date | dateMonthDayYear2 }}
          </div>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row partner-detail-row-controls justify-content-center">
        <slot name="controls" :show="sendingRequest" :pending="connection_request.status === 'pending' "></slot>
      </b-row>
    </b-container>
    <b-overlay no-wrap :show="sendingRequest"></b-overlay>
  </div>
</template>

<script>
import {ClassroomService} from "@/core/Services";
import {UtilsMixins} from "@/core/Mixins";

export default {
  name: "PartnerConnectionSentDetail",
  data() {
    return {
      sendingRequest: true,
      connection_request: null,
    }
  },
  mixins: [UtilsMixins],
  components: {},
  props: {
    invitation_id: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.fetchConnectionRequest()
  },
  methods: {
    async fetchConnectionRequest() {
      try {
        this.sendingRequest = true

        const invitation = await ClassroomService.partnerConnection(this.invitation_id)

        this.connection_request = invitation.data
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error fetching connection request.'
        this.$bvModal.msgBoxOk(errorMessage, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.sendingRequest = false
      }
    },
  },
  computed: {
    invitation_received() {
      return this.user.id === this.connection_request.invited_teacher_id
    },
    partner() {
      return this.invitation_received ? this.connection_request.teacher : this.connection_request.invited_teacher
    },
    partner_classroom() {
      return this.invitation_received ? this.connection_request.classroom : this.connection_request.invited_classroom
    },
    my_classroom() {
      return this.invitation_received ? this.connection_request.invited_classroom : this.connection_request.classroom
    },
    flag() {
      let result = {
        'fi': true
      }

      if (this.partner.country && this.partner.country.iso) {
        result[`fi-${this.partner.country.iso.toLowerCase()}`] = true
      } else {
        result[`fi-xx`] = true
      }

      return result
    },
    full_name() {
      return `${this.partner.first_name} ${this.partner.last_name.charAt(0)}`
    },
    isUsa() {
      return this.partner.country.iso.toLowerCase() === 'us'
    },
    ubigeo() {
      let result = []

      if (this.partner.city) {
        result.push(this.partner.city.name)
      }
      if (this.isUsa && this.partner.state) {
        result.push(this.partner.state.name)
      }

      if (this.partner.country) {
        result.push(this.partner.country.name)
      }

      return result.join(', ')
    },
    instruction_subjects() {
      let result = []

      if (this.partner.subjects) {
        for (let i = 0; i < this.partner.subjects.length; i++) {
          result.push(this.partner.subjects[i].name)
        }
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    native_language() {
      if (!this.partner.native_language) {
        return 'not set'
      }

      return this.partner.native_language.name
    },
    instruction_languages() {
      let result = []

      if (this.partner.instruction_languages) {
        for (let i = 0; i < this.partner.instruction_languages.length; i++) {
          result.push(this.partner.instruction_languages[i].name)
        }
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    ages() {
      let result = []

      if (this.partner.age_ranges) {
        for (let i = 0; i < this.partner.age_ranges.length; i++) {
          result.push(this.partner.age_ranges[i].name)
        }
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    school_name() {
      return this.partner.school ? this.partner.school.name : 'not set'
    },
  },
  destroyed() {
    this.connection_request = null
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.partner-detail
  font font-opensans-regular
  font-size 1.15rem
  padding 0 !important

  &-row
    margin-bottom 1rem

    &-controls
      margin 0
      margin-top 3rem

      & > *
        margin 0.25rem

  &-picture
  &-profile
  &-school
    margin-top 1rem

  &-avatar
    width 100%
    height 13rem

  &-name
    font-weight bold
    margin-bottom 20px
    text-transform capitalize

  &-country
    margin-bottom 17px

    &-flag
      font-size 6rem
      line-height 1

  &-school-item
    span
      font-weight bold

  &-bio
    font-size 1.15rem

    &-title
      font-weight bold

    &-text
      max-height 8rem
      overflow-y auto

  &-classroom
    &-match
      border 2px solid color-45
      background-color color-46
      padding-bottom 1rem
      max-width 40rem

      &-flag
        background color-44
        bottom 0
        width 15rem
        padding 0.2rem 1rem
        vertical-align middle
        margin 1rem 0

        .fa-star
          color color-47

    &-item
      word-break break-word

      span
        font-weight bold

    &-connect
      margin 1rem 0

.partner-classrrom,
.my-classroom
  margin-bottom 15px

@media (max-width: 575px)
  .partner-classrrom
    margin-top 30px

@media (max-width: 360px)
  .partner-detail
    &-row
      margin-right 0
      margin-left 0
      width 100%

    &-picture
      flex 100%
      max-width 100%

    &-profile
      flex 100%
      max-width 100%

    &-school
      flex 100%
      max-width 100%

</style>
