import AWS from 'aws-sdk'
import { http } from '@/core/Services/http'

const COGNITO_POOL_URL = process.env.VUE_APP_S3_COGNITO_POOL_URL
const COGNITO_IDENTITY_POOL_ID = process.env.VUE_APP_S3_COGNITO_IDENTITY_POOL_ID
const COGNITO_REGION = process.env.VUE_APP_S3_COGNITO_REGION

const apiPath = '/api/teacher'

const getCognitoToken = async function () {
  const response = await http.get(`${apiPath}/cognito-token`)

  console.log(response)
  if (response.success) {
    return response.data.token
  }

  throw Error(response.message)
}

const s3Upload = async function (file, key, bucket, onUploadProgress) {
  await authenticateUser()

  return uploadFile(file, key, bucket, onUploadProgress)
}

const authenticateUser = async () => {
  const token = await getCognitoToken()
  await setConfig(token)
}

const setConfig = (JwtToken) => {
  return new Promise((resolve, reject) => {
    // upload credentials
    AWS.config.region = COGNITO_REGION
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
      Logins: {
        [COGNITO_POOL_URL]: JwtToken
      }
    })
    AWS.config.credentials.refresh(err => {
      // eslint-disable-next-line prefer-promise-reject-errors
      if (err) reject({ message: err.message || JSON.stringify(err) })

      resolve()
    })
  })
}

const VUE_APP_S3_TIMEOUT = 1200 * 1000

const customBackoff = (retryCount) => {
  console.log(`retry count: ${retryCount}, waiting: 1000ms`)
  return 1000
}

const uploadFile = (file, key, bucket, onUploadProgress) => {
  const s3 = new AWS.S3({
    region: COGNITO_REGION,
    maxRetries: 30,
    retryDelayOptions: { customBackoff },
    httpOptions: {
      timeout: VUE_APP_S3_TIMEOUT,
      connectTimeout: VUE_APP_S3_TIMEOUT
    }
  })

  const params = {
    Bucket: bucket,
    Key: key,
    Body: file,
    ContentType: file.type
  }

  const request = s3.upload(
    params,
    (err, data) => {
      if (err) throw new Error(err.message || JSON.stringify(err))

      return data
    })
    .on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round(progress.loaded / progress.total * 100)
      onUploadProgress(progressPercentage)
    })

  setTimeout(request.abort.bind(request), VUE_APP_S3_TIMEOUT)

  return request.promise()
}

export default {
  s3Upload: s3Upload
}
