import {ProfileService} from '@/core/Services'
import * as labels from "./mutation-types";

export const actions = {
  getProfile({commit}, form = {}) {
    commit(labels.FETCH_PROFILE_START)

    return ProfileService.get(form).then(user => {
      commit(labels.FETCH_PROFILE_DATA, user)

      return user
    })
  },
  updateProfile({commit}, form = {}) {
    commit(labels.UPDATE_PROFILE_START)

    return ProfileService.update(form).then((user) => {
      commit(labels.UPDATE_PROFILE_DATA, user)

      return user
    })
  },
  readNotification({commit}, read) {
    commit(labels.READ_NOTIFICATION, read)
  },
}

