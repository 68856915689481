import moment from 'moment';
import {mapGetters} from 'vuex';
import {userTypeMixins} from "@/core/Mixins"

export const ExchangesMixins = {
  mixins: [userTypeMixins],
  computed: {
    ...mapGetters({
      myClassroom: 'ClassroomModule/getClassroomData',
      userData: 'UserModule/getUser',
    }),
    classroomExchangeExpired() {
      const currentTimestamp = moment()
      if (this.isLibrarian) {
        if (this.userData.school?.librarian_subscription?.end_date) {
          return moment(currentTimestamp).isAfter(this.userData.school?.librarian_subscription?.end_date) || moment(currentTimestamp).isAfter(this.myClassroom.expire_at)
        } else {
          return true
        }
      }
      return moment(currentTimestamp).isAfter(this.myClassroom.expire_at)
    }
  }
};
