const FETCH_MISC_START = 'FETCH_MISC_START';
const FETCH_MISC_DATA = 'FETCH_MISC_DATA';
const FETCH_PAYMENT_METHODS_START = 'FETCH_PAYMENT_METHODS_START';
const FETCH_PAYMENT_METHODS_DATA = 'FETCH_PAYMENT_METHODS_DATA';
const FETCH_LEVELS_START = 'FETCH_LEVELS_START';
const FETCH_LEVELS_DATA = 'FETCH_LEVELS_DATA';
const FETCH_SUBJECTS_START = 'FETCH_SUBJECTS_START';
const FETCH_SUBJECTS_DATA = 'FETCH_SUBJECTS_DATA';
const FETCH_LANGUAGES_START = 'FETCH_LANGUAGES_START';
const FETCH_LANGUAGES_DATA = 'FETCH_LANGUAGES_DATA';
const FETCH_AGE_RANGES_START = 'FETCH_AGE_RANGES_START';
const FETCH_AGE_RANGES_DATA = 'FETCH_AGE_RANGES_DATA';
const FETCH_NUMBER_STUDENTS_RANGES_START = 'FETCH_NUMBER_STUDENTS_RANGES_START';
const FETCH_NUMBER_STUDENTS_RANGES_DATA = 'FETCH_NUMBER_STUDENTS_RANGES_DATA';
const FETCH_BUNDLES_START = 'FETCH_BUNDLES_START';
const FETCH_BUNDLES_DATA = 'FETCH_BUNDLES_DATA';
const FETCH_TOPICS_START = 'FETCH_TOPICS_START';
const FETCH_TOPICS_DATA = 'FETCH_TOPICS_DATA';
const IS_PLAYERS_TRUST = 'IS_PLAYERS_TRUST';

export {
  FETCH_MISC_START,
  FETCH_MISC_DATA,
  FETCH_PAYMENT_METHODS_START,
  FETCH_PAYMENT_METHODS_DATA,
  FETCH_LEVELS_START,
  FETCH_LEVELS_DATA,
  FETCH_SUBJECTS_START,
  FETCH_SUBJECTS_DATA,
  FETCH_LANGUAGES_START,
  FETCH_LANGUAGES_DATA,
  FETCH_AGE_RANGES_START,
  FETCH_AGE_RANGES_DATA,
  FETCH_NUMBER_STUDENTS_RANGES_START,
  FETCH_NUMBER_STUDENTS_RANGES_DATA,
  FETCH_BUNDLES_START,
  FETCH_BUNDLES_DATA,
  FETCH_TOPICS_START,
  FETCH_TOPICS_DATA,
  IS_PLAYERS_TRUST
}
