const userTypes = Object.freeze({
  teacher: 'teacher',
  teacherDemo: 'teacher-demo',
  librarian: 'teacher-librarian'
})
const taskCategories = Object.freeze({
  video: 'video',
  introduction: 'introduction',
  activity: 'activity',
  reflection: 'reflection'
})

export {
  userTypes,
  taskCategories
}
