<template>
  <div class="task-filter">
    <select-dropdown v-if="dropdownViewShow"
                     v-model="selectedView"
                     placeholder="View"
                     value-field="value" @input="$emit('on-change-view', selectedView)"
                     :options="views" class-select="custom-dropdown--md custom-dropdown--secondary"/>
    <select-dropdown v-if="filtersBy && filtersBy.length"
                     v-model="selectedFilter"
                     :disabled="disabled"
                     value-field="value"
                     :placeholder="placeholder" @input="$emit('on-change-filter', selectedFilter)"
                     :options="filtersBy" class-select="custom-dropdown--md custom-dropdown--secondary"/>
    <div v-if="fieldSearchShow" class="common-search-bar-container">
        <!-- @Option:selected="searchTasks" -->
      <input type="text" v-model="value.search_string" placeholder="SEARCH" class="common-search-bar" :disabled="disabled"
             v-on:keyup.enter="searchTasks">
    </div>
  </div>
</template>

<script>
import {SelectDropdown} from "@/core/components/SelectDropdown"
import {CourseMixins} from "@/modules/Classroom/Mixins"

export default {
  name: "ClassroomDetailTabFilter",
  mixins: [CourseMixins],
  components: {
    'select-dropdown': SelectDropdown,
  },
  data() {
    return {
      views: [
        {
          name: "List View",
          value: "list"
        },
        {
          name: "Grid View",
          value: "grid"
        }
      ],
      selectedView: null,
      selectedFilter: '',

    }
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          view: null,
          search_string: null,
          filterBy: null
        }
      }
    },
    filtersBy: {
      type: Array,
      default: () => []
    },
    dropdownViewShow: {
      type: Boolean,
      default: true
    },
    fieldSearchShow: {
      type: Boolean,
      default: true
    },
    defaultView: {
      type: String,
      default: 'list'
    },
    placeholder: {
      type: String,
      default: 'FILTER BY'
    },
    defaultFiltersBy: {
      type: String,
      default: ''
    },
    isSubmissionsTab: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.selectedView = this.defaultView
    this.setDefaultFilter(this.defaultFiltersBy)

    if (this.isSubmissionsTab) {
      this.views = [
        {
          name: "My Students",
          value: "students"
        },
        {
          name: "My Partner's Students",
          value: "partner-students"
        }
      ]
    }
  },
  methods: {
    searchTasks() {
      this.$emit('Tasks:Search')
    },
    setDefaultFilter (filtersBy = 'pending') {
      this.selectedFilter = filtersBy
    }
  },
  watch: {
    selectedView(value) {
        this.value.view = this.views.find((view) => view.value === value)
    },
    selectedFilter(value) {
      this.value.filterBy = this.filtersBy.find((filter) => filter.value === value)
      this.searchTasks()

    },
    value() {
      this.selectedFilter = this.value.filterBy ? this.value.filterBy.value : this.value.filterBy
      this.$emit('input', this.value);
    }
  }
}
</script>

<style lang="stylus" scoped>
.task-filter
  display flex
  flex-direction column

  & > *
    flex 1
    margin-bottom 5px
    padding-right 10px

    &:last-child
      padding-right 0

  .custom-dropdown
    flex 1
    ::v-deep
      span
        font-size 15px
        font-weight 700
        line-height 22px

  ::v-deep
    .common-search-bar
      font-size 15px


@media (min-width: 576px)
  .task-filter
    flex-direction row

    .custom-dropdown
      flex-basis 10%
      margin-right 10px
      ::v-deep
        span
          font-size 16px

    .common-search-bar-container
      flex 1

    ::v-deep
      .common-search-bar
        font-size 16px

    .custom-dropdown + .common-search-bar-container
      flex-basis 70%

    .custom-dropdown + .custom-dropdown
      .common-search-bar-container
        flex-basis 40%

</style>
