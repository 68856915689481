<template>
  <div class="tab-container-create">
    <b-tabs content-class="tab-panel-global-partner" nav-class="nav-tabs-global-partner" align="center">
      <global-partner-create-find-content @on-change-component="onChangeComponent"
                                          v-bind="{...$props, formGlobalPartnerChoose}"/>
    </b-tabs>
  </div>
</template>

<script>
import GlobalPartnerCreateFindContent from "./GlobalPartnerCreateFindContent";
import {UtilsMixins} from "@/core/Mixins";

export default {
  name: "GlobalPartnerCreate",
  components: {GlobalPartnerCreateFindContent},
  mixins: [UtilsMixins],
  props: {
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    },
  },
  data() {
    return {
      formGlobalPartnerChoose: {
        subject_id: 'all',
        lang_instruction_id: 'all',
        lang_native_id: 'all',
        age_range_id: 'all',
      },
    }
  },
  methods: {
    onChangeComponent(data) {
      this.$emit('on-change-component', {...data})
    }
  },
  mounted () {
    this.formGlobalPartnerChoose.subject_id = this.classroom.subject_id ?? 'all'
    this.formGlobalPartnerChoose.lang_instruction_id = this.classroom.lang_instruction_id ?? 'all'
    this.formGlobalPartnerChoose.lang_native_id = this.classroom.lang_native_id ?? 'all'

    if (this.classroom.age_range_id) {
      this.formGlobalPartnerChoose.age_range_id = this.classroom.age_range_id
    } else {
      const age_ranges = this.age_ranges.find((val) => val.name === '4-6 years old')

      if (age_ranges) {
        this.formGlobalPartnerChoose.age_range_id = age_ranges.id
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.tab-container-create
  ::v-deep
    .nav-tabs-global-partner
      border-bottom-color color-23

      .nav-item
        display flex
        justify-content center
        align-items center
        margin-left 5px
        margin-right 5px

        .nav-link
          font font-opensans-semibold
          font-size 0.5rem
          padding-top 14px
          color color-33
          border-left 0
          border-right 0
          border-top-left-radius 0
          border-top-right-radius 0
          padding-left 0
          padding-right 0
          text-transform uppercase

          span
            font-size 16px

            &:before
              color color-22

          &.active
            font font-opensans-bold
            font-size 0.6rem
            border-bottom 3px solid color-3
            border-top-color transparent
            border-left-color transparent
            border-right-color transparent
            color color-3

            &:hover
              border-bottom-color color-3

          &:hover
            border-color transparent

@media (min-width: 768px)
  .tab-container-create
    ::v-deep
      .nav-tabs-global-partner
        .nav-item
          margin-left 30px

          &:first-child
            margin-left 0
            margin-right 30px

          .nav-link
            font-size 0.9rem

            &.active
              font-size 1rem
</style>
