class AppStorage {
  constructor(storage) {
    this.storage = storage || window.localStorage;

    /** Is storage is supported or not */
    if (!this.isSupported()) {
      throw new Error('Storage is not supported by browser!');
    }
  }

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    return JSON.parse(this.storage.getItem(key));
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  clear(clearAll = false) {
    clearAll ? this.storage.clear() : localStorage.removeItem('user')
  }

  isSupported() {
    let supported = true;

    if (!this.storage) {
      supported = false;
    }

    return supported;
  }

  getUser() {
    const user = this.storage.getItem('user') || '{}'
    return JSON.parse(user)
  }

  setUser(user) {
    delete user.token
    delete user.token_admin

    // get local User Information
    let currentUser = this.getUser()

    const userProfile = Object.assign({}, currentUser, user)

    this.storage.setItem('user', JSON.stringify(userProfile))

    return userProfile;
  }

  setUserToken(token) {
    const user = this.getUser()
    const updatedUser = Object.assign({}, user, {
      token: token
    })

    this.storage.setItem('user', JSON.stringify(updatedUser))
  }

  setUserAdminToken(token_admin, token, user_type) {
    const user = this.getUser()
    const updatedUser = Object.assign({}, user, {
      token: token,
      token_admin: token_admin,
      user_type: {
        slug: user_type
      }
    })

    this.storage.setItem('user', JSON.stringify(updatedUser))
  }

  setNotificationOpen(notification_id,notification) {
    this.storage.setItem(notification_id, JSON.stringify(notification))
  }

  getNotificationOpen(notification_id) {
    const notification = this.storage.getItem(notification_id) || false
    return notification ? JSON.parse(notification): notification
  }

  removeNotificationOpen(notification_id) {
    this.storage.removeItem(notification_id)
  }
}

export default new AppStorage()
