<template>
  <b-container>
    <b-row class="partner-row">
      <b-col cols="4" class="attribute-picture" no-gutters>
        <b-avatar rounded="left" :src="partner.avatar" square></b-avatar>
        <div v-if="classroom_match" class="attribute-classroom-match">
          <i class="fa fa-star"></i>
          Classroom Match
        </div>
      </b-col>
      <b-col cols="8" class="attribute">
        <b-row>
          <b-col cols="7" class="attribute-info">
            <div class="attribute-name">{{ full_name }}</div>
            <div class="attribute-country">{{ ubigeo }}</div>
            <div class="attribute-languages"><span>Languages:</span> {{ languages }}</div>
            <div class="attribute-ages"><span>Ages:</span> {{ ages }}</div>
          </b-col>
          <b-col cols="5" class="attribute-bio">
            <p>{{ partner.about_info }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="attribute-actions">
            <b-button @click="onShowPartnerDetail" btn-text="SEE DETAILS" outline/>
            <b-button @click="onConnectPartnerDirect" btn-text="CONNECT" outline/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {Button} from "@/core/components/Button";

export default {
  name: "PartnerCard",
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  components: {
    'b-button': Button,
  },
  methods: {
    onShowPartnerDetail () {
      this.$emit('on-show-partner-detail', this.partner)
    },
    onConnectPartnerDirect () {
      this.$emit('on-connect-partner-direct', this.partner)
    },
  },
  computed: {
    full_name () {
      return `${this.partner.first_name} ${this.partner.last_name.charAt(0)}`
    },
    isUsa () {
      return this.partner.country.iso.toLowerCase() === 'us'
    },
    ubigeo () {
      let result = []

      if (this.partner.city) {
        result.push(this.partner.city.name)
      }
      if (this.isUsa) {
        result.push(this.partner.state.name)
      }

      result.push(this.partner.country.name)

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    languages () {
      let result = []

      if (this.partner.native_language) {
        result.push(this.partner.native_language.name)
      }
      for (let i = 0; i < this.partner.instruction_languages.length; i++) {
        result.push(this.partner.instruction_languages[i].name)
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    ages () {
      let result = []

      for (let i = 0; i < this.partner.age_ranges.length; i++) {
        result.push(this.partner.age_ranges[i].name)
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    classroom_match () {
      return !!this.partner.classrooms.length
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.partner-row
  height 100%

.b-avatar
  width 100%
  height 100%

.btn-common
  min-width 130px
  padding 6px 16px
  font font-opensans-bold
  letter-spacing 0

h3
  font font-opensans-bold
  font-size 17px
  color color-8
  margin-bottom 24px

.attribute
  flex-direction column
  padding 14px 5px
  padding-bottom 0 !important
  display flex

  &-picture
    padding 0
    position relative

  &-classroom-match
    text-align: center;
    position: absolute;
    font-weight: bolder;
    width: 100%;
    background color-44
    bottom: 0;
    color: white;
    font-size: 1.2rem;

    .fa-star
      color color-47

  &-info
    height 140px

  &-group
    display flex

  &-name
    font font-opensans-bold
    font-size 16px
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    margin-bottom 0.5rem
    text-transform capitalize

  &-country
    font font-opensans-regular
    font-size 12px
    height 36px

  &-languages
  &-ages
    max-height 36px

  &-languages
  &-ages
  &-bio
    font font-opensans-regular
    font-size 12px

    span
      font font-opensans-regular
      font-weight bold
      font-size 12px

  &-country
  &-languages
  &-ages
    display -webkit-box
    -webkit-line-clamp 2
    -webkit-box-orient vertical
    overflow hidden
    text-overflow ellipsis

  &-bio
    overflow hidden
    text-overflow ellipsis

    p
      display -webkit-box
      -webkit-line-clamp 7
      -webkit-box-orient vertical
      overflow hidden
      text-overflow ellipsis

  &-actions
    display flex
    justify-content space-between
    flex-direction: column
    padding-top 13px

    button
      padding 4px 20px
      font-size 11px
      margin-bottom 6px
      min-width 0


@media (min-width 450px)
  .b-avatar
    width 100%
    height 219px

  .attribute
    &-name
      font-size 13px

    &-country
    &-languages
    &-ages
    &-bio
      font-size 12px

      span
        font-size 12px

    &-actions
      flex-direction row
      justify-content center

      button:first-child
        margin-right 10px

@media (min-width: 768px)
  h3
    font-size 24px

  .attribute
    padding 14px 28px

    &-name
      font-size 17px

    &-country
    &-languages
    &-ages
    &-bio
      font-size 14px

      span
        font-size 14px

    &-actions
      button
        margin-left 10px
        padding 6px 31px
        font-size 13px

</style>
