<template>
  <div class="wrapper-level-descriptions-modal" :class="modalClass">
    <a @click="$bvModal.show('level-descriptions-modal')" class="label-description-modal">Level Descriptions</a>
    <modal id="level-descriptions-modal" hideFooter>
      <template #body>
        <div class="description-container"
             v-for="level in levels"
             :key="level.id">
          <h2 class="description-name">{{level.name}}</h2>
          <p class="description-content">{{level.description}}</p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal} from "@/core/components/Modal";
import {ClassroomMixins} from "@/modules/Classroom/Mixins";

export default {
  name: "LevelDescriptionsModal",
  mixins: [ClassroomMixins],
  components: {
    Modal
  },
  props: {
    modalClass: {
      type: [Object, String, Array]
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.label-description-modal
  cursor pointer

.description-container
  margin-bottom 1.5rem

  .description-name
    margin 10px 0
    font-size 18px
    font-weight 700

  .description-content
    margin 10px 0
    font-size 15px
</style>
