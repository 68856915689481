<template>
  <b-overlay :show="loading">
    <div class="post-container">
      <div class="detail-back">
        <a href="javascript:void(0)" @click="onBack"><span class="icon-caret-left"></span>Back</a>
      </div>
      <div class="post-area">
        <div class="post-title">
          {{isActionResponse ? 'My Response' : 'My Post'}}
        </div>
        <div class="post-information">
          <div class="post-student-area">
            <b-avatar class="post-student-avatar" :src="action.thumbnail"/>
          </div>
          <div class="post-detail">
            <div class="post-task-name">{{ action.fullName }}</div>
            <div class="post-task-name">{{ action.taskName }}</div>
            <div class="post-task-date">Posted {{ action.createdAt | dateElapseTime }}</div>
          </div>
        </div>
        <div class="post-video">
          <video-custom :src="action.url"/>
        </div>
      </div>
    </div>
    <div class="responses-container" v-if="actionResponse.id">
      <div class="responses-title">
        {{isActionResponse ? 'Partner Post' : 'Partner Response'}}
      </div>
      <div class="responses-area">
        <div class="response-information">
          <div class="response-image">
            <b-avatar class="photo" :src="actionResponse.thumbnail"/>
          </div>
          <div class="info">
            <div class="user-name">{{ actionResponse.fullName }}</div>
            <div class="user-name">{{ actionResponse.taskName }}</div>
            <div class="date">posted {{ actionResponse.createdAt | dateElapseTime }}</div>
          </div>
        </div>
        <div class="response-video">
          <video-custom :src="actionResponse.url" @on-video-play="playedActionVideo"/>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { ActionsService } from '@/core/Services'
import { VideoCustom } from '@/core/components/Video'

export default {
  name: 'ClassroomSubmissionDetail',
  components: {
    VideoCustom
  },
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    teacherActionId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      action: {
        thumbnail: '',
        fullName: '',
        taskName: '',
        createdAt: '',
        url: '',
      },
      actionResponse: {
        id: null,
        thumbnail: '',
        fullName: '',
        taskName: '',
        createdAt: '',
        url: '',
        videoPlayed: false
      },
      isActionResponse: false
    }
  },
  methods: {
    playedActionVideo() {
      if (!this.actionResponse.videoPlayed && !this.isActionResponse) {
        ActionsService.videoPlayed(this.actionResponse.id).catch((e) => {
          const errorMessage = e.message ? e.message : 'Error update status.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        }).then(() => {
          this.init()
        })
      }
    },
    async init () {
      try {
        let {data} = await ActionsService.getActionItemById(this.teacherActionId)
        this.action = {
          thumbnail: data.teacher.avatar,
          fullName: data.teacher.first_name + ' ' + data.teacher.last_name,
          taskName: data.action.task,
          createdAt: data.created_at,
          url: data.video_url,
        }
        this.isActionResponse = !!data.teacher_action

        const actionResponseData = this.isActionResponse ? data.teacher_action : data.teacher_action_response

        if (actionResponseData) {
            this.actionResponse = {
            thumbnail: actionResponseData.teacher.avatar,
            fullName: actionResponseData.teacher.first_name + ' ' + actionResponseData.teacher.last_name,
            taskName: actionResponseData.action.task,
            createdAt: actionResponseData.created_at,
            url: actionResponseData.video_url,
            id: actionResponseData.id,
            videoPlayed: actionResponseData.video_played
          }
        }
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while loading post.'
        await this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.loading = false
      }
    },
    onBack () {
      this.$router.push({
        name: 'ClassroomPage',
        params: {
          classroomId: this.classroomId
        }
      })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.post-container
  max-width 625px
  margin auto
  padding-top 40px

  .detail-back
    a
      font font-opensans-bold
      font-size 17px
      cursor pointer
      text-decoration none
      color color-3
      display flex
      align-items center

      span
        font-size 0.7em
        padding-right 5px

        &:before
          color color-3

  .post-area
    margin 0 20px
    padding 10px 0

    .post-title
      font-weight bold
      color color-8

      @media (min-width: 992px)
        font-size 20px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 18px
      @media (max-width: 767px)
        font-size 16px

    .post-information
      margin-top 12px
      padding-bottom 7px

      .post-student-area
        display inline-block
        vertical-align top
        width 90px

        .post-student-avatar
          width 73px
          height 73px
          border-radius 50px
          background-repeat no-repeat
          background-size cover
          background-position center

      .post-detail
        display inline-block
        vertical-align top
        color color-8
        padding-top 4px

        .post-student-name
        .post-task-name
          font-weight bold
          word-break break-word

        .post-student-name
        .post-task-name
        .post-task-date
          @media (min-width: 992px)
            font-size 16px
          @media (min-width: 768px) and (max-width: 992px)
            font-size 15px
          @media (max-width: 767px)
            font-size 14px

    .post-video
      padding 10px
      border-top 1px solid #DDDDDD

      .video-overlay
        width 100%
        height 395px
        border-radius 4px

    .post-status
      font-size 14px
      font-weight bold
      text-transform uppercase
      color color-8
      margin-top 5px
      padding 0 10px

.responses-container
  max-width 625px
  margin auto

  .responses-title
    font-weight bold
    color color-8
    margin 0 20px
    padding 10px 0
    padding-top 20px
    border-top 1px solid #DDDDDD
    @media (min-width: 992px)
      font-size 20px
    @media (min-width: 768px) and (max-width: 992px)
      font-size 18px
    @media (max-width: 767px)
      font-size 16px

  .responses-area
    border-bottom 1px solid #DDDDDD
    margin 0 20px
    padding 10px 0

    &:first-child
      border-top none
      border-bottom none

    .response-information
      margin-top 12px
      padding-bottom 7px
      border-bottom 1px solid #DDDDDD

      .response-image
        display inline-block
        vertical-align top
        width 90px

        .photo
          width 73px
          height 73px
          border-radius 50px
          background-repeat no-repeat
          background-size cover
          background-position center

      .info
        display inline-block
        vertical-align top
        color color-8
        padding-top 4px

        .user-name
          font-weight bold
          @media (min-width: 992px)
            font-size 16px
          @media (min-width: 768px) and (max-width: 992px)
            font-size 15px
          @media (max-width: 767px)
            font-size 14px

        .task-name
          font-weight bold
          @media (min-width: 992px)
            font-size 16px
          @media (min-width: 768px) and (max-width: 992px)
            font-size 15px
          @media (max-width: 767px)
            font-size 14px

        .date
          @media (min-width: 992px)
            font-size 16px
          @media (min-width: 768px) and (max-width: 992px)
            font-size 15px
          @media (max-width: 767px)
            font-size 14px

    .response-video
      padding 10px
      border-top 1px solid #DDDDDD

      .video-overlay
        width 100%
        height 395px
        border-radius 4px

    .response-text
      font-size 14px
      color color-8
      padding 20px 20px 0
</style>
