<template>
  <footer class="footer-app" id="footer-app">
    <div class="footer-app-copyright">
      Copyright &copy; {{ getCurrentYear() }} Level Up Village&reg; <span
      @click="$bvModal.show('privacy-policy-modal')">Privacy Policy</span>
    </div>
    <contact-support custom-class="footer-app-copyright"/>
    <div class="footer-app-social-network">
      <a href="https://www.facebook.com/LevelUpVillage" target="_blank"><span class="icon-facebook"></span></a>
      <a href="https://www.linkedin.com/company/level-up-village/" target="_blank"><span
        class="icon-linkedin"></span></a>
      <a href="https://www.instagram.com/levelupvillage/" target="_blank"><span class="icon-instagram"></span></a>
      <a href="https://www.youtube.com/LEVEL%20up%20village" target="_blank"><span class="icon-youtube"></span></a>
      <a href="https://twitter.com/levelupvillage" target="_blank"><span class="icon-twitter"></span></a>
    </div>
    <modal id="privacy-policy-modal" :hide-footer="true" :hide-close-button="true">
      <template #header>
      </template>
      <template #body>
        <iframe :src="privacyPolicyUrl + '#toolbar=0'" width="100%" height="100%"/>
      </template>
    </modal>
  </footer>
</template>
<script>
import moment from "moment"
import { Modal } from '@/core/components/Modal'
import axios from 'axios'
import ContactSupport from './ContactSupport'

export default {
  name: "FooterApp",
  components: {
    Modal,
    ContactSupport
  },
  data () {
    return {
      privacyPolicy: process.env.VUE_APP_LUV_PRIVACY_POLICY,
      privacyPolicyUrl: ''
    }
  },
  methods: {
    getCurrentYear () {
      return moment().year()
    }
  },
  async mounted () {
    let response = await axios.get(this.privacyPolicy, {
      responseType: 'blob',
    })

    this.privacyPolicyUrl = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}))
  }
};
</script>

<style lang="stylus" scoped>
@import "styles/footer.styl"

.footer-app
  .footer-app-copyright
    span
      margin-left 20px
      text-decoration underline
      cursor pointer
      display inline-block

</style>
<style lang="stylus">
#privacy-policy-modal
  .modal-dialog
    max-width 720px !important

    .modal-content
      height 100%

      .modal-body
        overflow hidden !important
        margin-bottom 0

        .body
          height 100%
          padding 0

</style>
