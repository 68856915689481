<template>
  <div class="wrapper-classroom-content__inner"
       :class="{'wrapper-classroom-content__inner--course-library': isCourseLibrary}">
    <div class="container-course-content">
      <div class="container-header-filters">
        <div class="container-item-filter container-filter-by">
          <select-dropdown placeholder="FILTER BY"
                           value-field="id"
                           v-model="filter.filter_by"
                           :options="filters"
                           class-select="custom-dropdown--md custom-dropdown--secondary"/>
          <select-dropdown v-if="isCourseLibrary"
                           placeholder="FILTER BY"
                           value-field="value"
                           v-model="filter.secondary_filter_by"
                           :options="secondaryFilters"
                           class-select="custom-dropdown--md custom-dropdown--secondary"/>
        </div>
        <div class="container-item-filter">

          <select-dropdown v-if="filterBy === 'subject' && !subject_id"
                           @input="getCoursesForClassroom(true)"
                           placeholder="SUBJECTS" v-model="filter.search_string"
                           :options="subjects"
                           class-select="custom-dropdown--md custom-dropdown--secondary"/>
          <select-dropdown v-else-if="filterBy === 'language' || secondaryFilterBy === 'language'"
                           @input="getCoursesForClassroom(true)"
                           placeholder="LANGUAGES" v-model="filter.search_string"
                           :options="languages"
                           class-select="custom-dropdown--md custom-dropdown--secondary"/>
          <select-dropdown v-else-if="filterBy === 'level' || secondaryFilterBy === 'level'"
                           @input="getCoursesForClassroom(true)"
                           placeholder="LEVELS"
                           v-model="filter.search_string"
                           :options="levels"
                           class-select="custom-dropdown--md custom-dropdown--secondary"/>
          <select-dropdown v-else-if="filterBy === 'age_range' || secondaryFilterBy === 'age_range'"
                           @input="getCoursesForClassroom(true)"
                           placeholder="AGE RANGES"
                           v-model="filter.search_string"
                           :options="age_ranges"
                           class-select="custom-dropdown--md custom-dropdown--secondary"/>

          <div v-else class="common-search-bar-container">
            <input v-on:keyup.enter="getCoursesForClassroom(true)" type="text" placeholder="SEARCH"
                   class="common-search-bar" v-model="filter.search_string">
          </div>
        </div>
      </div>
      <level-descriptions-modal v-if="filterBy === 'level'"/>
    </div>
    <div class="separator-filters"></div>
    <div class="container-course-content">
      <div class="container-courses">
        <CourseCard v-for="(course) in courses" :course="course" :key="`course-${course.id}`"
                    :no-add-button="noAddButton"
                    @on-course-detail="seeCourseDetails"
                    @on-add-course-content="openModal">
        </CourseCard>
        <b-overlay no-wrap :show="isLoading"></b-overlay>
      </div>
    </div>
    <modal ref="classroom_content_confirm" modal-class="classroom_content_confirm" :hide-close-button="true">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">ADD COURSE CONTENT</h2>
        <p class="text-center">This will add the course to your classroom, please confirm.</p>
      </template>
      <template #footer>
        <b-button @click="closeModal()" btn-text="Cancel"/>
        <b-button @click="$emit('on-course-add', selectedCourse)" btn-text="Yes"/>
      </template>
    </modal>
    <modal ref="no_results_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">NO COURSES FOUND</h2>
        <p class="text-center">
          No courses were found matching the entered criteria.
        </p>
      </template>
      <template #footer>
        <b-button @click="closeNoResultsModal()" btn-text="OK"/>
      </template>
    </modal>
    <modal id="course-pdf-modal" size="xl" @hidden="course_pdf = ''">
      <template #header></template>
      <template #body>
        <iframe v-if="course_pdf" :src="course_pdf + '#toolbar=0'" width="100%" height="100%"
                @load="course_pdf_loaded = true"/>

        <img v-else-if="course_image" :src="course_image + '#toolbar=0'" width="100%" height="100%"
             @load="course_pdf_loaded = true" :alt="course_image + '#toolbar=0'"/>

        <a v-else-if="course_link" :href="course_link + '#toolbar=0'">{{ course_link + '#toolbar=0' }}</a>

        <div v-else-if="course_pdf_error" class="pdf-error">
          FILE NOT FOUND <br> <br>
          <b>{{ course_pdf_error }}</b>
        </div>

        <b-overlay no-wrap :show="!course_pdf_loaded"></b-overlay>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('course-pdf-modal')" btn-text="CLOSE"/>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal} from "@/core/components/Modal";
import {Button} from "@/core/components/Button";
import {SelectDropdown} from "@/core/components/SelectDropdown";
import {CourseMixins, UserMixins} from "@/modules/Classroom/Mixins";
import CourseCard from "@/modules/Classroom/Components/CourseCard";
import axios from 'axios'
import {UtilsMixins} from "@/core/Mixins";
import {UploadService} from "@/core/Services";
import LevelDescriptionsModal from '../../modules/Classroom/Components/LevelDescriptionsModal.vue';

export default {
  name: "CoursesList",
  mixins: [UtilsMixins, CourseMixins, UserMixins],
  components: {
    Modal,
    'b-button': Button,
    'select-dropdown': SelectDropdown,
    CourseCard,
    LevelDescriptionsModal
  },
  data() {
    return {
      selectedCourse: {},
      filter: {
        filter_by: 'all',
        secondary_filter_by: '',
        search_string: ''
      },
      lastFiltersWithResults: {
        filter_by: 'all',
        search_string: ''
      },
      filterSelected: {},
      isMiscData: true,
      course_pdf: '',
      course_image: '',
      course_link: '',
      course_pdf_error: '',
      course_pdf_loaded: false,
    }
  },
  props: {
    noAddButton: {
      type: Boolean,
      default: false
    },
    classroom_id: {
      type: Number,
    },
    subject_id: {
      type: Number,
    },
    default_filter_by: {
      default: false
    },
    default_search_string: {
      default: false
    },
    isCourseLibrary: {
      default: false
    }
  },
  mounted: function () {
    this.isMiscData = true

    if (this.default_filter_by) {
      this.filter.filter_by = this.default_filter_by
    }
    if (this.default_search_string) {
      this.filter.search_string = this.default_search_string
    }

    this.getCoursesForClassroom({}).then(() => {
      this.isMiscData = false
    })
  },
  methods: {
    getCoursesFilterBy() {
      this.filter.search_string = ''
      this.getCoursesForClassroom(true)
    },
    getCoursesForClassroom: async function (fromSearchBar = false) {
      let filters = {
        classroom_id: this.classroom_id,
        subject_id: this.subject_id,
      }
      if (this.isCourseLibrary) {
        if (this.filter.filter_by !== 'all') {
          filters.subject_id = this.filter.filter_by
        }
        if (this.filter.secondary_filter_by.length !== 0) {
          filters.filter_by = this.filter.secondary_filter_by
        }

        if (this.filter.search_string.length !== 0) {
          filters.search_string = this.filter.search_string
        }

      } else {
        if (this.filter.filter_by && this.filter.search_string) {
          filters.filter_by = this.filter.filter_by
          filters.search_string = this.filter.search_string
        }
      }

      await this.getCourses(filters)
      if (fromSearchBar) {
        if (this.courses && this.courses.length > 0) {
          this.lastFiltersWithResults.filter_by = this.filter.filter_by
          this.lastFiltersWithResults.search_string = this.filter.search_string
        } else {
          this.$refs["no_results_modal"].showModal()
          filters = {}
          await this.getCourses(filters)
        }
      }
    },
    addCourseContent: function () {
      if (!this.isAddingClassroomContent) {
        this.addClassroomContent({
          classroom_id: this.classroom_id,
          course_id: this.selectedCourse.id
        }).then(() => {
          this.$refs["classroom_content_confirm"].hideModal()
        })
      }
    },
    seeCourseDetails: async function (course) {
      this.course_pdf = ''
      this.course_image = ''
      this.course_link = ''
      this.course_pdf_error = ''
      this.course_pdf_loaded = false

      try {
        let response = await axios.get(course.pdf_url, {
          responseType: 'blob', // had to add this one here
        })

        const extension = UploadService.getExtension(course.pdf_url)

        if (extension === 'pdf') {
          this.course_pdf = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}))
        } else if (course.pdf_url.match(/.(jpg|jpeg|png|gif)$/i)) {
          this.course_image = window.URL.createObjectURL(new Blob([response.data], {type: "image/" + extension}))
        } else {
          this.course_link = course.pdf_url
          this.course_pdf_loaded = true
        }
      } catch (e) {
        this.course_pdf_error = course.pdf_url
        this.course_pdf_loaded = true
      }

      this.$bvModal.show('course-pdf-modal')
    },
    filterChanged: function (value) {
      this.filterSelected = value
    },
    openModal: function (course) {
      this.selectedCourse = course
      return this.$refs["classroom_content_confirm"].showModal()
    },
    closeModal: function () {
      this.selectedCourse = {}
      return this.$refs["classroom_content_confirm"].hideModal()
    },
    closeNoResultsModal: function () {
      this.$refs["no_results_modal"].hideModal()
    }
  },
  computed: {
    isLoading() {
      return this.isLoadingClassroom === true || this.isMiscData === true || this.isLoadingCourses === true || this.isAddingClassroomContent === true
    },
    filterBy() {
      return this.filter.filter_by
    },
    secondaryFilterBy() {
      return this.filter.secondary_filter_by
    },
    filters() {
      let filters = []
      if (this.isCourseLibrary) {
        filters = [
          {
            id: 'all',
            name: 'All',
          },
          ...this.subjects
        ]
        return filters
      }

      if(this.subject_id === 1) {
        filters = [
          {
            id: 'all',
            name: 'All'
          },
          {
            id: 'language',
            name: 'Language'
          },
          {
            id: 'level',
            name: 'Level'
          },
          {
            id: 'age_range',
            name: 'Age'
          },
        ]
      } else {
        filters = [
          {
            id: 'all',
            name: 'All'
          },
          {
            id: 'language',
            name: 'Language'
          },
          {
            id: 'age_range',
            name: 'Age'
          },
        ]
      }
      return filters
    },
    secondaryFilters() {
      let filters = {}

      if (this.filter.filter_by === 1) {
        filters = [
          {
            id: 1,
            name: 'Language',
            value: 'language'
          },
          {
            id: 2,
            name: 'Level',
            value: 'level'
          },
          {
            id: 3,
            name: 'Age',
            value: 'age_range'
          },
        ]
        return filters
      }

      filters = [
        {
          id: 1,
          name: 'Language',
          value: 'language'
        },
        {
          id: 2,
          name: 'Age',
          value: 'age_range'
        },
      ]

      return filters
    }
  },
  watch: {
    async 'filter.filter_by'() {
      this.filter.search_string = ''
      if (this.isCourseLibrary) {
        this.filter.secondary_filter_by = ''
      }
      await this.getCoursesFilterBy()
    },
    'filter.secondary_filter_by'() {
      this.filter.search_string = ''
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.security-form-subtitle--normal
  font font-opensans-regular
  font-size 20px
  margin-bottom 30px !important

.modal-title
  margin-top 80px

.container-courses
  position relative

#course-pdf-modal
  iframe
    height calc(100vh - 222px)

.pdf-error
  text-align center
  word-break break-all

.wrapper-classroom-content__inner--course-library
  .container-filter-by
    display flex
    min-width auto !important
    width 100% !important

    ::v-deep
      .dropdown:nth-child(2)
        margin-left 12px

  @media(max-width 500px)
    .container-filter-by
      display block
      width fit-content !important

      ::v-deep
        .dropdown
          max-width 300px

        .dropdown:nth-child(2)
          margin-left 0
          margin-top 10px
</style>
