<template>
  <div class="tab-content-dashboard">
    <div class="container-my-dashboard">
      <my-dashboard :classroom="classroom"/>
    </div>
    <div class="container-global-partner">
      <div class="wrapper-find-global-partner">
        <h2 class="title">MY GLOBAL PARTNER</h2>
        <div v-if="allow_search" class="container-find-global-partner">
          <h3>Find a Global Partner</h3>
          <div class="form-group d-flex justify-content-center">
            <b-button @click="onLetChoose()" btn-text="SEARCH"/>
          </div>
        </div>
        <div v-else class="message-container">
          <h3>WE’RE SEARCHING FOR YOUR GLOBAL PARTNER!</h3>
          <div class="images-container">
            <img class="image-partener" src="~@/core/assets/partner-message-image.png" alt="">
            <img class="image-partener" src="~@/core/assets/partner-message-image.png" alt="">
          </div>
          <p>This is a great time to:</p>
          <ul>
            <li>Complete your action items on the left.</li>
            <li>
              Check out the
              <a href="https://levelupvillage.com/resources/?ppwp_ac=eyJwYXNzd29yZCI6IjYzMWFjMDkzNjIyNTUifQ==" target="_blank" class="link">Teacher Resources.</a>
               Here you will find important information to help you and your students prepare for your upcoming exchanges.</li>
              <li>Check your email for any updates from us and we’ll be in touch as soon as your partnership has been established!</li>
          </ul>
          <span>Have questions? You can reach us here.</span>
          <b-button btn-text="Contact Us" href ="mailto: support@levelupvillage.com"/>
        </div>
      </div>
      <div v-if="allow_search" class="wrapper-find-global-partner">
        <partner-connection-requests-list :classroom_id="classroom.id">
          <template v-slot:header>
            <h3 style="word-break: break-word">My Connection Requests for {{ classroom.name }}</h3>
          </template>
        </partner-connection-requests-list>
      </div>
    </div>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button";
import PartnerConnectionRequestsList
  from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabGlobalPartner/PartnerConnectionRequestsList";
import MyDashboard from './MyDashboard'

export default {
  name: "GlobalPartnerCreateFind",
  components: {
    'b-button': Button,
    PartnerConnectionRequestsList,
    MyDashboard
  },
  data() {
    return {
      allow_search: !!this.$route.query.allow_partner_search
    }
  },
  props: {
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    },
  },
  methods: {
    onLetChoose() {
      this.setComponent('GlobalPartnerCreateChoose')
    },
    setComponent(component, partner) {
      this.$emit('on-change-component', {component: component, params: {partners: partner}})
    }
  },
  mounted() {
    if (this.$route.query.show_partner_search) {
      this.setComponent('GlobalPartnerCreateChoose')
      this.$route.query.show_partner_search = false
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.tab-content-dashboard
  width 100%
  display flex

  .container-my-dashboard
    width 50%

  .container-global-partner
    width 50%

@media (max-width: 1050px)
  .tab-content-dashboard
    display block

    .container-my-dashboard
      width 100%

    .container-global-partner
      width 100%

.wrapper-find-global-partner
  display block
  justify-content center
  padding-left 10px
  padding-right 10px
  min-height: 230px
  width: 100%

  .title
    font font-opensans-bold
    font-size 24px
    line-height 24px
    color color-3
    text-transform uppercase
    text-align left
    padding 20px
    padding-top 40px
    padding-left 30px

  .images-container
    display flex
    justify-content center

@media (min-width: 768px)
  .wrapper-find-global-partner
    padding-left 0
    padding-right 0

.container-find-global-partner
  width 442px
  padding-top 98px
  margin auto

  h3
    font font-opensans-bold
    font-size 24px
    line-height 24px
    color color-8
    margin 0
    letter-spacing .96px
    text-transform uppercase
    margin-bottom 2rem
    text-align center

  p
    font font-opensans-regular
    font-size 16px
    color color-8
    margin-bottom 19px

  ::v-deep
    .btn-common
      text-transform capitalize
      font font-opensans-regular
      letter-spacing 0
      padding 8px 20px

      &:nth-child(1)
        margin-right 20px

.message-container
  padding 10px 80px 0 80px
  font-size 16px
  font-weight 600

  h3
    font font-opensans-bold
    font-size 24px
    line-height 24px
    color color-3
    margin 0
    text-transform uppercase
    margin-bottom 1rem
  .images-container
    .image-partener:nth-child(1)
      margin-right 60px

  p
    margin 25px 0
    font-size 16px
    font-weight 600

  ul
    padding 0
    padding-left 20px

    li
      margin-top 25px

      .text-bolder
        font-weight 700

      .link
        text-decoration underline

      .link--english
        margin-right 20px
        margin-left 6px
  a
    margin 0 auto
    margin-top 20px
    max-width 200px
    margin-bottom 80px



@media (max-width: 1100px)
  .message-container
    padding 10px 50px 0 50px

@media (max-width: 450px)
  .message-container
    padding 10px 0px 0 0px

    .images-container
      .image-partener:nth-child(1)
        margin-right 10px
</style>
