<template>
  <div class="container-let-choose">
    <div class="content-let-choose-back">
      <a href="javascript:void(0)" @click="onBack"><span class="icon-caret-left"></span> Back</a>
    </div>
    <hr>
    <b-container>
      <b-row class="flex-column" align-h="center" align-v="center">
        <b-col>
          <div class="content-form-let-choose-info mb-4">
            <h2 class="title">LET ME CHOOSE</h2>
            <div class="subtitle">Search fields have been personalized to help you find the best partner match for your
              classroom. You may update any of the fields to adjust your search.
            </div>
          </div>
        </b-col>
        <b-col cols="12" xl="5" lg="6" md="8">
          <div class="content-form-let-choose">
            <div>
              <div>
                <div class="container-item-filter container-filter-by">
                  <custom-select v-model="form.subject_id" :options="subjects_options"
                                 label="Subject" custom-class="custom-dropdown--primary"/>
                </div>
                <div class="container-item-filter container-filter-by">
                  <custom-select v-model="form.lang_native_id" :options="languages_options"
                                 label="Students' Primary Language" custom-class="custom-dropdown--primary"/>
                </div>
                <div class="container-item-filter container-filter-by">
                  <custom-select v-model="form.lang_instruction_id" :options="languages_options"
                                 label="Language Students Are Learning" custom-class="custom-dropdown--primary"/>
                </div>
                <div class="container-item-filter container-filter-by">
                  <custom-select v-model="form.age_range_id" :options="age_ranges_options"
                                 label="Student Age Range" custom-class="custom-dropdown--primary"/>
                </div>
              </div>
              <div class="security-form-container-btns c-btns-secondary c-btns-create-classroom">
                <b-button @click="onFindBestMatch()" btn-text="Search"/>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Select from "@/core/components/FormInput/Select";
import {Button} from "@/core/components/Button";
import {ClassroomService} from "@/core/Services";
import {UserMixins} from "@/modules/Classroom/Mixins";
import {ClassroomMixins, UtilsMixins} from "@/core/Mixins";

export default {
  name: "GlobalPartnerCreateChoose",
  mixins: [UtilsMixins, ClassroomMixins, UserMixins],
  components: {
    'b-button': Button,
    'custom-select': Select,
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  methods: {
    onBack () {
      this.$emit('on-change-component', {component: 'GlobalPartnerCreateFind'})
    },
    onFindBestMatch () {
      ClassroomService.partnersList({
        classroom_id: this.$attrs.classroom.id,
        subject_id: this.form.subject_id !== 'all' ? [this.form.subject_id] : null,
        lang_instruction_id: this.form.lang_instruction_id !== 'all' ? [this.form.lang_instruction_id] : null,
        lang_native_id: this.form.lang_native_id !== 'all' ? [this.form.lang_native_id] : null,
        age_range_id: this.form.age_range_id !== 'all' ? [this.form.age_range_id] : null,
      }).then((response) => {
        this.setComponent('GlobalPartnerCreateBestMatch', response.data || [])
      })
    },
    setComponent (component, partner) {
      this.$emit('on-change-component', {component: component, params: {classroomPartners: partner}})
    }
  },
  computed: {
    subjects_options () {
      return [{id: 'all', name: 'All'}, ...this.subjects]
    },
    languages_options () {
      return [{id: 'all', name: 'All'}, ...this.languages]
    },
    age_ranges_options () {
      return [{id: 'all', name: 'All'}, ...this.age_ranges]
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.content-form-let-choose-info
  text-align center

  .title
    font font-opensans-bold
    font-size 24px
    margin-bottom 0

  .subtitle
    font font-opensans-regular
    font-size 16px

.container-let-choose {
  margin-top 10px
  margin-bottom 136px
}

.content-let-choose-back {
  padding-right 10px
  padding-left 10px

  a {
    font font-opensans-bold
    font-size 1.05rem
    cursor pointer
    text-decoration none
    color color-3
    display flex
    align-items center

    span {
      font-size 0.7em
      padding-right 5px

      &:before {
        color color-3
      }
    }
  }
}
.security-form-container-btns
  margin-top 20px

</style>
