import {mapActions, mapGetters} from 'vuex';
export const CourseMixins = {
  computed: {
    ...mapGetters({
      courses: 'CourseModule/getCourses',
      isLoadingCourses: 'CourseModule/isLoadingCourses',
      getClassroomData: 'ClassroomModule/getClassroomData',
    }),
    courseStudentGroupTypeId() {
      return this.getClassroomData.course_student_group_type?.id
    },
    isIndividualGroupType() {
      return this.courseStudentGroupTypeId === 1
    },
    isSmallGroupType() {
      return this.courseStudentGroupTypeId === 2
    },
    isWholeClassGroupType() {
      return this.courseStudentGroupTypeId === 3
    }
  },
  methods: {
    ...mapActions({
      getCourses: 'CourseModule/fetchCourses',
      addClassroomContent: 'ClassroomModule/addCourseContent',
      getClassroomNoOverlay: 'ClassroomModule/getClassroomNoOverlay'
    }),
  },
};
