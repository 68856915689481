const subjectTypes = Object.freeze({
  'languages&Cultures': {
    type: '',
    id: 1
  },
  'arts&Sciences': {
    type: '',
    id: 2
  },
  'playersTrust': {
    type: '',
    id: 4
  }
});

export default subjectTypes
