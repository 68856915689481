<template>
  <div class="partner-detail-wrapper">
    <b-container class="partner-detail" v-if="connection_request">
      <b-row class="partner-detail-row">
        <b-col cols="12">
          <h2>{{ full_name }} would like to connect with you!</h2>
        </b-col>
        <b-col class="partner-detail-message" cols="12">
          <p>"{{ partner_message }}"</p>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row">
        <b-col cols="6" sm="6" md="3" class="partner-detail-picture">
          <b-avatar class="partner-detail-avatar" :src="partner.avatar" square></b-avatar>
        </b-col>
        <b-col cols="6" sm="6" md="4" class="partner-detail-profile">
          <div class="partner-detail-name">{{ full_name }}</div>
          <div class="partner-detail-country">{{ ubigeo }}</div>
          <div class="partner-detail-country-flag"><span :class="flag"></span></div>
        </b-col>
        <b-col cols="12" sm="12" md="5" class="partner-detail-school">
          <div class="partner-detail-school-item"><span>School: </span>{{ partner.school.name }}</div>
          <div class="partner-detail-school-item"><span>Subject(s): </span>{{ instruction_subjects }}</div>
          <div class="partner-detail-school-item"><span>Students’ Primary Language: </span>{{ native_language }}</div>
          <div class="partner-detail-school-item"><span>Students Are Learning: </span>{{ instruction_languages }}</div>
          <div class="partner-detail-school-item"><span>Ages: </span>{{ ages }}</div>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row">
        <b-col cols="12" class="partner-detail-bio">
          <div class="partner-detail-bio-title">About Me:</div>
          <div class="partner-detail-bio-text">{{ partner.about_info }}</div>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row" v-if="is_pending">
        <b-col cols="12" class="partner-detail-classroom">
          <div>Change Classroom</div>
          <custom-select v-model="selected_classroom_id"
                         :options="classrooms_matched"
                         custom-class="custom-dropdown--primary"
                         :disabled="!is_pending"
                         placeholder="Change Classroom"/>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row">
        <b-col cols="12" sm="6" md="6" class="partner-detail-classroom">
          <div class="partner-detail-bio-title my-classroom" v-if="selected_classroom.id && selected_classroom.id !== 'create_new'">My Classroom</div>
          <div class="partner-detail-classroom-wrapper" v-if="selected_classroom.id && selected_classroom.id !== 'create_new'">
            <div class="partner-detail-classroom-item"><span>Classroom Name:</span> {{ selected_classroom.name }}</div>
            <div class="partner-detail-classroom-item" v-if="selected_classroom.level"><span>Level:</span>
              {{ selected_classroom.level.name }}
            </div>
            <div class="partner-detail-classroom-item" v-if="selected_classroom.number_students_range"><span>Number of Students:</span>
              {{ selected_classroom.number_students_range.name }}
            </div>
            <div class="partner-detail-classroom-item" v-if="selected_classroom.lang_native"><span>Students’ Primary Language:</span>
              {{ selected_classroom.lang_native.name }}
            </div>
            <div class="partner-detail-classroom-item" v-if="selected_classroom.lang_instruction"><span>Students Are Learning:</span>
              {{ selected_classroom.lang_instruction.name }}
            </div>
            <div class="partner-detail-classroom-item" v-if="selected_classroom.age_range"><span>Ages:</span>
              {{ selected_classroom.age_range.name }}
            </div>
            <div class="partner-detail-classroom-item"><span>Desired Start Date:</span>
              {{ selected_classroom.start_date | dateMonthDayYear2 }}
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="6" md="6" class="partner-detail-classroom">
          <div class="partner-detail-bio-title partner-classrrom">{{partner.first_name}}'s Classroom</div>
          <div class="partner-detail-classroom-wrapper partner-detail-classroom-match">
            <div class="partner-detail-classroom-item"><span>Classroom Name:</span> {{ partner_classroom.name }}</div>
            <div class="partner-detail-classroom-item" v-if="partner_classroom.level"><span>Level:</span>
              {{ partner_classroom.level.name }}
            </div>
            <div class="partner-detail-classroom-item" v-if="partner_classroom.number_students_range"><span>Number of Students:</span>
              {{ partner_classroom.number_students_range.name }}
            </div>
            <div class="partner-detail-classroom-item" v-if="partner_classroom.lang_native"><span>Students’ Primary Language:</span>
              {{ partner_classroom.lang_native.name }}
            </div>
            <div class="partner-detail-classroom-item" v-if="partner_classroom.lang_instruction"><span>Students Are Learning:</span>
              {{ partner_classroom.lang_instruction.name }}
            </div>
            <div class="partner-detail-classroom-item" v-if="partner_classroom.age_range"><span>Ages:</span>
              {{ partner_classroom.age_range.name }}
            </div>
            <div class="partner-detail-classroom-item"><span>Desired Start Date:</span>
              {{ partner_classroom.start_date | dateMonthDayYear2 }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row">
        <b-col cols="12">
          <p class="partner-detail-info-text">{{ partner.full_name }} would like to partner with you on the following
            course. If
            you accept, your classrooms will collaborate
            on the following tasks. You will be able to work with {{ partner.full_name }} to modify any dates and tasks
            once you have
            accepted the invitation.</p>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row" v-if="partner_classroom.class_pairing">
        <b-col cols="12">
          <teacher-table table-class-content="partner-detail-tasks-table-body"
                         :rows="partner_classroom.class_pairing.tasks" :view="'list'">
            <template #tableHeader>
              <div class="column column-header column-topic">Course Name</div>
              <div class="column column-header column-task-name">{{ partner_classroom.course.name }}</div>
            </template>
            <template v-slot:tableRowList="slotProps">
              <div class="column column-topic">{{ slotProps.row.topic }}</div>
              <div class="column column-task-name">{{ slotProps.row.task_name }}</div>
            </template>
          </teacher-table>
        </b-col>
      </b-row>
      <b-row class="partner-detail-row partner-detail-row-controls justify-content-center">
        <slot name="controls"
              :connection_request="connection_request"
              :selected_classroom_id="selected_classroom_id"
              :pending="is_pending"
              :declined="is_declined"
              :accepted="is_accepted"
              :cancelled="is_cancelled"
        ></slot>
      </b-row>
    </b-container>
    <b-overlay no-wrap :show="sendingRequest"></b-overlay>
  </div>
</template>

<script>
import Select from "@/core/components/FormInput/Select";
import {ClassroomService} from "@/core/Services";
import TeacherTable from "@/modules/Classroom/Components/TeacherTable";

export default {
  name: "PartnerConnectionReceivedDetail",
  components: {
    TeacherTable,
    'custom-select': Select,
  },
  data() {
    return {
      sendingRequest: false,
      selectedInvitation: null,
      connection_request: null,
    }
  },
  props: {
    invitation_id: {
      type: Number,
      required: true
    },
  },
  mounted() {
    this.fetchConnectionRequest()
  },
  methods: {
    async fetchConnectionRequest() {
      try {
        this.sendingRequest = true

        const invitation = await ClassroomService.partnerConnection(this.invitation_id)

        this.connection_request = invitation.data
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error fetching connection request.'
        this.$bvModal.msgBoxOk(errorMessage, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.sendingRequest = false
      }
    },
  },
  computed: {
    invitation_received() {
      return this.user.id === this.connection_request.invited_teacher_id
    },
    is_pending() {
      return this.connection_request.status === 'pending'
    },
    is_declined() {
      return this.connection_request.status === 'declined'
    },
    is_accepted() {
      return this.connection_request.status === 'accepted'
    },
    is_cancelled() {
      return this.connection_request.status === 'cancelled'
    },
    partner() {
      return this.connection_request.teacher
    },
    partner_classroom() {
      return this.connection_request.classroom
    },
    partner_message() {
      return this.connection_request.message
    },
    classrooms_matched() {
      let conn = [
        {id: 'create_new', name: 'Create new classroom'}
      ]
      for (let i = 0; i < this.connection_request.classrooms_matched.length; i++) {
        conn.push(this.connection_request.classrooms_matched[i])
      }

      return conn
    },
    flag() {
      let result = {
        'fi': true
      }

      if (this.partner.country && this.partner.country.iso) {
        result[`fi-${this.partner.country.iso.toLowerCase()}`] = true
      } else {
        result[`fi-xx`] = true
      }

      return result
    },
    full_name() {
      return `${this.partner.first_name} ${this.partner.last_name.charAt(0)}`
    },
    isUsa() {
      return this.partner.country.iso.toLowerCase() === 'us'
    },
    ubigeo() {
      let result = []

      if (this.partner.city) {
        result.push(this.partner.city.name)
      }
      if (this.isUsa) {
        result.push(this.partner.state.name)
      }

      result.push(this.partner.country.name)

      return result.join(', ')
    },
    instruction_subjects() {
      let result = []

      for (let i = 0; i < this.partner.subjects.length; i++) {
        result.push(this.partner.subjects[i].name)
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    native_language() {
      if (!this.partner.native_language) {
        return 'not set'
      }

      return this.partner.native_language.name
    },
    instruction_languages() {
      let result = []

      for (let i = 0; i < this.partner.instruction_languages.length; i++) {
        result.push(this.partner.instruction_languages[i].name)
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    ages() {
      let result = []

      for (let i = 0; i < this.partner.age_ranges.length; i++) {
        result.push(this.partner.age_ranges[i].name)
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    selected_classroom_id: {
      get: function () {
        return this.connection_request.invited_classroom_id ?? null
      },
      set: function (selected_classroom_id) {
        this.connection_request.invited_classroom_id = selected_classroom_id
      }
    },
    selected_classroom() {
      let classroom = this.classrooms_matched.find((classroom) => classroom.id === this.selected_classroom_id)

      return classroom ?? {
        name: null,
        level: null,
        number_students_range: null,
        lang_instruction: null,
        lang_native: null,
        age_range: null,
        start_date: null,
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.partner-detail
  font font-opensans-regular
  font-size 1.15rem
  padding 0 !important

  &-row
    margin-bottom 1rem

    &-controls
      margin 0
      margin-top 3rem

      & > *
        margin 0.25rem

  &-message
    margin 10px 0
    p
      text-indent 40px
      font font-opensans-regular
      font-style italic
      font-size 1.15rem

  &-picture
  &-profile
  &-school
    margin-top 1rem

  &-avatar
    width 100%
    height 13rem

  &-name
    font-weight bold
    margin-bottom 20px
    text-transform capitalize

  &-country
    margin-bottom 17px

    &-flag
      font-size 6rem
      line-height 1

  &-school-item
    span
      font-weight bold

  &-bio
    font-size 1.15rem

    &-title
      font-weight bold

    &-text
      max-height 8rem
      overflow-y auto

  &-classroom
    &-create
      width 179px
      padding 30px
      margin-top 25px
      margin-bottom 50px

    &-wrapper
      padding .5rem

    &-match
      border 2px solid color-45
      background-color color-46

      &-flag
        background color-44
        bottom 0
        width 15rem
        padding 0.2rem 1rem
        vertical-align middle
        margin 1rem 0

        .fa-star
          color color-47

    &-item
      word-break break-word

      span
        font-weight bold

    &-connect
      margin 1rem 0

  &-info-text
    margin-top 2rem
    font-size 1rem
    text-align center
    font-weight bold

  &-tasks-table-body
    width 100%

    ::v-deep
    .table-body-view
      &.list
        .table-row
          padding 6px 5px
          align-items(center)
          margin-bottom 6px
          border 1px solid color-28
          color color-8

          &.header
            background-color rgba(color-27, 0.27)
            margin-bottom 3px
            border 0

          &:not(.header)
            .column
              overflow hidden
              text-overflow ellipsis
              white-space nowrap

          .column
            margin-left 3px
            font font-opensans-regular
            font-size 1rem
            text-align left

            &-header
              font-weight bold
              text-transform capitalize

            &-topic
              flex-basis(30%)

            &-task-name
              flex-basis(70%)

.partner-classrrom,
.my-classroom
  margin-bottom 10px

@media (max-width: 360px)
  .partner-detail
    &-row
      margin-right 0
      margin-left 0
      width 100%

    &-picture
      flex 100%
      max-width 100%

    &-profile
      flex 100%
      max-width 100%

    &-school
      flex 100%
      max-width 100%

  .partner-classrrom
    margin-top 15px
</style>
