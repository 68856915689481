export default {
  props: {
    contentClass: String,
    message: Object,
    id: Number,
    userId: Number,
    userThumbnail: String,
    userFirstName: String,
    userLastInitial: String,
    userType: String,
    messageTitle: String,
    messageText: String,
    postDate: String,
    status: String,
    links: Array,
    media: Array
  }
}
