<template>
  <div :class="classFormInputContent">
    <input
      :id="'form-input-file-' + _uid"
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      @change="onFileChanged"
    >
    <label :for="'form-input-file-' + _uid" class="form-upload-file">
      <span v-if="placeHolder" class="form-file-text">{{ placeHolder }}</span>
      <button type="button" class="btn-common btn-input-file" @click="openUploadFile">
        <b-spinner v-if="loading" label="Spinning" class="mr-2"></b-spinner>
        {{ labelButton }}
      </button>
    </label>
  </div>
</template>

<script>
import { UploadService } from '@/core/Services'
import { Utils } from "@/core/Utils"

export default {
  name: 'UploadFile',
  props: {
    accept: {
      type: String,
      default: 'image/*'
    },
    classFormInput: {
      type: String,
      default: null
    },
    placeHolder: {
      type: String,
      default: null
    },
    labelButton: {
      type: String,
      default: 'Upload File'
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Number,
      default: 262144000
    },
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    submissionId: {
      type: Number,
      required: false
    },
    bucket: {
      required: false
    }
  },
  data () {
    return {
      currentFiles: [],
      loading: false
    }
  },
  methods: {
    openUploadFile () {
      this.$refs.uploader.click()
    },
    clearUploadFile () {
      this.$refs.uploader.value = null
    },
    onFileChanged (e) {
      const fileList = e.target.files || e.dataTransfer.files
      if (!fileList.length) {
        return
      }
      const file = fileList[0]

      if (file.size > this.maxSize) {
        this.$emit('on-file-error', {
          code: 'max_size_exceeded',
          message: `File must not be higher than ${Utils.formatBytes(this.maxSize)}`
        })
        return
      }

      this.currentFiles = [{
        url: URL.createObjectURL(file),
        objectURL: URL.createObjectURL(new Blob([file])),
        file: file,
        index_file: 1,
        type_file: 'media',
        filename: file.name,
        customData: UploadService.createFileNameData(file, this.classroomId, this.taskId, this.submissionId, this.bucket)
      }]

      this.$emit('on-file-select', { files: this.currentFiles })

      if (this.autoUpload) {
        this.onFileUpload()
      }
    },
    onFileUpload () {
      this.loading = true
      this.$emit('on-file-upload-start')

      UploadService.uploadFileVideo(
        this.currentFiles[0].file,
        this.currentFiles[0].customData,
        (progress) => {
          this.$emit('upload-progress', progress)
        }
      ).then(response => {
        this.loading = false
        this.$emit('on-file-upload-success', {
          file: response,
          error: null
        })
      }).catch(error => {
        this.loading = false
        this.$emit('on-file-upload-error', error)
      })
    },
    getSelectedFileData () {
      return this.currentFiles[0] ? this.currentFiles[0].customData : null
    }
  },
  computed: {
    classFormInputContent () {
      const className = {
        'form-upload': true
      }

      className[this.classFormInput] = !!this.classFormInput
      // className['form-upload-error'] = this.hasErrors

      return {
        ...className
      }
    }
  },
  watch: {
    value () {
      this.$emit('input', this.value)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.form-upload
  display block

  &-file
    margin-bottom 0

.btn-input-file
  background-color color-3
  border 3px solid color-7
  border-radius 4px
  font font-opensans-bold
  font-size 14px
  letter-spacing 1.6px
  color #fff
  text-align center
  flexbox(flex)
  align-items(center)
  justify-content(center)
  padding 0 10px
  text-decoration none
  text-transform capitalize

  &:focus,
  &:hover
    box-shadow none

  .icon-upload
    padding-left 5px

</style>
