<template>
  <div></div>
</template>

<script>
import {AppStorage} from '@/core/Utils'
import { MyClassRoomsMixins } from "@/modules/MyClassrooms/Mixins"

export default {
  name: "LoginAdminByTokenPage",
  mixins: [MyClassRoomsMixins],
  async mounted() {
    let admin_token = this.$route.query.admin_token
    let teacher_token = this.$route.query.teacher_token
    let user_type = this.$route.query.user_type

    if (!admin_token || !teacher_token || !user_type) {
      this.goToLoginPage()
    } else {
      AppStorage.clear()
      AppStorage.setUserAdminToken(admin_token, teacher_token, user_type)
      const user = await this.getProfile()

      if (user.first_name) {
        this.goToMyClassroomsPage()
      } else {
        this.goToProfileWizardPage()
      }
    }
  },
};
</script>
