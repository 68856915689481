const type = Object.freeze({
  studentResponseVideo: 'teacher_student_response_to_video',
  studentVideoPosted: 'teacher_student_video_posted',
  studentVideoFail: 'student_video_fail',
  StudentVideoConvertionFailed: 'teacher_student_video_convertion_failed',
  studentTeacherStatusMessage: 'teacher_student_response_to_message',
  TeacherPartnerConnectionRequest: 'teacher_partner_connection_request',
  TeacherPartnerConnectionRequestAccept: 'teacher_partner_connection_request_accept',
  TeacherPartnerConnectionRequestDecline: 'teacher_partner_connection_request_decline',
  teacherPartnerMessage: 'teacher_partner_message',
  teacherAlertCreated: 'teacher_alert_created',
  teacherClassroomExpired: 'teacher_classroom_expired',
  studentPartnerRespondedToTheirVideo: 'student_partner_responded_to_their_video',
  partnerStudentPostVideo: 'partner_student_post_video',
  pairedSmallGroupClassrooms: 'teacher_classroom_group_partnership'
});

export default {type}
