import {MiscService} from '@/core/Services'
import * as labels from "./mutation-types";

const fetchPaymentMethods = ({commit}) => {
  commit(labels.FETCH_PAYMENT_METHODS_START)
  return MiscService.paymentMethods().then(response => {
    commit(labels.FETCH_PAYMENT_METHODS_DATA, response.data)

    return response
  })
}
const fetchLevels = ({commit}) => {
  commit(labels.FETCH_LEVELS_START)
  return MiscService.levels().then(response => {
    commit(labels.FETCH_LEVELS_DATA, response.data)

    return response
  })
}
const fetchSubjects = ({commit}) => {
  commit(labels.FETCH_SUBJECTS_START)
  return MiscService.subjects().then(response => {
    commit(labels.FETCH_SUBJECTS_DATA, response.data)

    return response
  })
}
const fetchLanguages = ({commit}) => {
  commit(labels.FETCH_LANGUAGES_START)
  return MiscService.languages().then(response => {
    commit(labels.FETCH_LANGUAGES_DATA, response.data)

    return response
  })
}
const fetchAgeRanges = ({commit}) => {
  commit(labels.FETCH_AGE_RANGES_START)
  return MiscService.ageRanges().then(response => {
    commit(labels.FETCH_AGE_RANGES_DATA, response.data)

    return response
  })
}
const fetchNumberStudentsRanges = ({commit}) => {
  commit(labels.FETCH_NUMBER_STUDENTS_RANGES_START)
  return MiscService.numberStudentsRanges().then(response => {
    commit(labels.FETCH_NUMBER_STUDENTS_RANGES_DATA, response.data)

    return response
  })
}
const fetchBundles = ({commit}) => {
  commit(labels.FETCH_BUNDLES_START)
  return MiscService.bundles().then(response => {
    commit(labels.FETCH_BUNDLES_DATA, response.data)

    return response
  })
}
const fetchTopics = ({commit}) => {
  commit(labels.FETCH_TOPICS_START)
  return MiscService.topicsRequest().then(response => {
    commit(labels.FETCH_TOPICS_DATA, response.data)

    return response
  })
}
const setPlayersTrust = ({commit}, isPlayers = false) => {
  commit(labels.IS_PLAYERS_TRUST, isPlayers)
}

export const actions = {
  fetchMiscData({commit}) {
    commit(labels.FETCH_MISC_START)

    return Promise.all([
      fetchPaymentMethods({commit}),
      fetchLevels({commit}),
      fetchSubjects({commit}),
      fetchLanguages({commit}),
      fetchAgeRanges({commit}),
      fetchNumberStudentsRanges({commit}),
    ]).then(() => {
      commit(labels.FETCH_MISC_DATA)

      return true
    });
  },
  fetchPaymentMethods: fetchPaymentMethods,
  fetchLevels: fetchLevels,
  fetchSubjects: fetchSubjects,
  fetchLanguages: fetchLanguages,
  fetchAgeRanges: fetchAgeRanges,
  fetchNumberStudentsRanges: fetchNumberStudentsRanges,
  fetchBundles: fetchBundles,
  setPlayersTrust,
  fetchTopics
}

