<template>
  <component v-bind:is="currentComponent" v-bind="{...$props, partner: partner, ...currentComponentParams}"
             @on-change-component="onChangeComponent"></component>
</template>

<script>
import GlobalPartnerCreate from './GlobalPartnerCreate'
import GlobalPartnerDetail from './GlobalPartnerDetail'

export default {
  name: "ClassroomDetailTabGlobalPartner",
  components: {
    GlobalPartnerCreate,
    GlobalPartnerDetail,
  },
  props: {
    classroom: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
  },
  data() {
    return {
      currentComponent: null,
      currentComponentParams: null,
      partner: null,
      sendingStatus: false,
    }
  },
  mounted() {
    if (this.classroom.partner) {
      this.partner = this.classroom.partner
      this.onChangeComponent({component: "GlobalPartnerDetail"}, this.currentComponentParams)
    } else {
      this.onChangeComponent({component: "GlobalPartnerCreate"}, this.currentComponentParams)
    }
  },
  methods: {
    onChangeComponent(data) {
      this.currentComponent = data.component;
      this.currentComponentParams = data.params || {}
    },
  }
}
</script>

<style scoped>

</style>
