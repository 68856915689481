import {http} from './http'

const resource = "/api/teacher/notifications";

export default {
  list(filters = {}) {
    return http.get(`${resource}`, {params:filters}).then(response => {
      return response
    })
  },
  status(notification = {id: 1, status: ''}) {
    return http.put(`${resource}`, notification).then(response => {
      return response
    })
  },
}
