<template>
  <div class="form-group">
    <label v-if="label" :for="`${type}-${_uid}`">{{ label }}</label>

    <editor v-if="richText" api-key="no-api-key" :init="tinymce_init" :initial-value="value"/>

    <b-form-textarea v-else
                     :id="`textarea-${_uid}`" v-model="inputValue" :type="type" :class="customClassInput"
                     :placeholder="placeholder" :disabled="disabled" :state="state" :rows="rows"
                     :aria-describedby="`textarea-live-feedback-${_uid}`" :maxlength="maxlength"
                     @keypress="$emit('keypress', $event)"
                     @blur="$emit('blur', $event)"
                     @input="onInput"/>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="`textarea-live-feedback-${_uid}`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`textarea-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "Textarea",
  data: function () {
    return {
      tinymce_init: {
        link_default_target: '_blank',
        link_context_toolbar: true,
        initialValue: this.value,
        height: 500,
        menubar: false,
        plugins: 'lists link image help wordcount',
        toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link',
        toolbar_mode: 'wrap',
        branding: false,
        init_instance_callback: (editor) => {
          editor.on('input', () => {
            this.$emit('input', editor.getBody().innerHTML)
          });
          editor.on('ExecCommand', () => {
            this.$emit('input', editor.getBody().innerHTML)
          });
        },
      }
    }
  },
  components: {
    'editor': Editor
  },
  props: {
    maxlength: {
      type: [Number, String],
      default: 500
    },
    value: {
      type: String,
      default: ''
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: function () {
        return []
      }
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    customClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 5
    },
    richText: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
  },
  computed: {
    customClassInput() {
      const className = {
        '': true
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    },
    /**
     * false (denotes invalid state) is great for when there's a blocking or required field. A user must fill in this field properly to submit the form.
     * true (denotes valid state) is ideal for situations when you have per-field validation throughout a form and want to encourage a user through the rest of the fields.
     * null Displays no validation state (neither valid nor invalid)
     * @returns {boolean}
     */
    state() {
      return !this.errors.length ? null : false
    },
    inputValue: {
      get: function () {
        return this.value ?? ''
      },
      set: function () {
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

&::v-deep
  .tox-editor-container
    .tox-editor-header
        .tox-toolbar__group
          padding 11px 11px
</style>
