import ClassroomView from "../ClassroomView.vue";
import ClassroomDetailPage from "@/modules/Classroom/Pages/ClassroomDetailPage";
import EditClassroomPage from "../Pages/EditClassroomPage.vue";
import ChooseCourseContentPage from "../Pages/ChooseCourseContentPage.vue";
import CreateNewClassroomPage from "../Pages/CreateNewClassroomPage.vue";
import PartnerConnectionsPage from "../Pages/PartnerConnectionsPage.vue";
import LibraryConnectionsPage from "../Pages/LibraryConnectionsPage.vue";
import ClassroomTaskDetail from "../Pages/ClassroomTaskDetail.vue";
import UploadOrRecordPage from "../Pages/UploadOrRecordPage.vue";
import {VideoRecordPage} from "@/core/components/Video";
import ClassroomSubmissionDetail from "../Components/ClassroomSubmissionDetail.vue";

const routes = [
  {
    path: '/classroom',
    component: ClassroomView,
    children: [
      // {
      //   path: 'create',
      //   name: 'CreateClassroomPage',
      //   meta: { authRequired: true },
      //   component: CreateClassroomPage,
      // },
      {
        path: 'create',
        name: 'CreateClassroomPage',
        meta: { authRequired: true },
        component: CreateNewClassroomPage,
      },
      {
        path: ':classroomId',
        name: 'ClassroomPage',
        meta: { authRequired: true },
        component: ClassroomDetailPage,
      },
      {
        path: ':classroomId/edit',
        name: 'EditClassroomPage',
        meta: { authRequired: true },
        component: EditClassroomPage,
      },
      {
        path: ':classroomId/choose-course',
        name: 'ChooseCourseContentPage',
        meta: { authRequired: true },
        component: ChooseCourseContentPage,
      },
      {
        path: ':classroomId/:teacherActionId/task-detail',
        name: 'ClassroomTaskDetail',
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          teacherActionId: parseInt(route.params.teacherActionId)
        }),
        meta: { authRequired: true },
        component: ClassroomTaskDetail,
      },
      {
        path: ':classroomId/:teacherActionId/record-upload',
        name: 'UploadOrRecordPage',
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          teacherActionId: parseInt(route.params.teacherActionId)
        }),
        meta: { authRequired: true },
        component: UploadOrRecordPage,
      },
      {
        path: ':classroomId/:teacherActionId/video-record',
        name: 'VideoRecordPage',
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          teacherActionId: parseInt(route.params.teacherActionId)
        }),
        meta: { authRequired: true },
        component: VideoRecordPage,
      },
      {
        path: ':classroomId/:teacherActionId/submission-detail',
        name: 'ClassroomSubmissionDetail',
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          teacherActionId: parseInt(route.params.teacherActionId)
        }),
        meta: { authRequired: true },
        component: ClassroomSubmissionDetail,
      }
    ]
  },
  {
    path: '/connections',
    component: ClassroomView,
    children: [
      {
        path: ':invitation_id?',
        name: 'PartnerConnectionsPage',
        props: route => ({
          invitation_id: route.params.invitation_id ? parseInt(route.params.invitation_id) : null
        }),
        meta: { authRequired: true },
        component: PartnerConnectionsPage,
      },
      {
        path: ':invitation_id/create-classroom',
        name: 'PartnerCreateClassroomPage',
        props: route => ({
          invitation_id: route.params.invitation_id ? parseInt(route.params.invitation_id) : null
        }),
        meta: { authRequired: true },
        component: CreateNewClassroomPage,
      },
    ]
  },
  {
    path: '/library-connections',
    component: ClassroomView,
    children: [
      {
        path: '',
        name: 'LibraryConnectionsPage',
        meta: {
          authRequired: true,
          librarian: true,
        },
        component: LibraryConnectionsPage,
      }
    ]
  }
]

export default routes
