<template>
  <div class="tab-content-dashboard">
    <div class="container-my-dashboard">
      <my-dashboard :classroom="classroom"/>
    </div>
    <div class="container-global-partner">
      <h2 class="title">MY GLOBAL PARTNER</h2>
      <b-container class="partner-detail-wrapper">
        <div class="main-container">
          <div class="partner-container">
            <partner-connected-detail :partner="partner"
                                      :classroom="partner.classrooms[0]"
                                      :visible-details="false"
                                      :inGlobalPartnerTab="true"/>
          </div>
          <div class="chat-wrapper">
            <teacher-chat v-if="partner_id" ref="teacher-chat-component"
                          :open_chat="$route.query.open_chat"
                          :teacher_partner_id="teacher_partner_id"
                          :partner="classroom.partner"
                          :classroom_id="classroom_id"
                          :class_pairing_id="class_pairing_id"/>
            <p class="contact-link">Having trouble reaching your Global Partner? <a href="javascript:void(0)" @click="onReport">Contact Support now</a>.</p>
          </div>
        </div>
        <modal modal-class="modal-create-message" :hide-footer="true" ref="modalCreateMessage">
          <template #body>
            <classroom-detail-create-message
              @on-cancel-create-message="onCancelCreateMessage"
              @on-post-message="onPostMessage"></classroom-detail-create-message>
          </template>
        </modal>

        <modal modal-class="modal-create-report" :hide-footer="true" ref="modalCreateReport">
          <template #body>
            <classroomDetailCreateReport @on-cancel-report="cancelReport" @create-report="reportPartner">
            </classroomDetailCreateReport>
          </template>
        </modal>
      </b-container>
    </div>
  </div>
</template>

<script>
import { Modal } from "@/core/components/Modal";
import MyDashboard from './MyDashboard'
import TeacherChat from "@/core/components/Chat/TeacherChat"
import ClassroomDetailCreateMessage
  from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabMessageBoard/ClassroomDetailCreateMessage";
import { ClassroomService, UploadService } from "@/core/Services";
import ClassroomDetailCreateReport
  from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabMessageBoard/ClassroomDetailCreateReport";
import PartnerConnectedDetail
  from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabGlobalPartner/PartnerConnectedDetail";

export default {
  name: "GlobalPartnerDetail",
  components: {
    ClassroomDetailCreateMessage,
    ClassroomDetailCreateReport,
    Modal,
    PartnerConnectedDetail,
    TeacherChat,
    MyDashboard
  },
  props: {
    classroom: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    partner: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
  },
  data () {
    return {
      sendingReport: false
    }
  },
  methods: {
    reportPartner (form) {
      if (!this.sendingReport) {
        this.sendingReport = true

        ClassroomService.partnerReport({
          classroom_id: this.classroom.id,
          partner_id: this.partner.id,
          report: form.report
        }).then((res) => {
          if (!res.success) {
            const errorMessage = res.message ? res.message : 'Error creating report.'

            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          } else {
            this.cancelReport()
            this.$bvModal.msgBoxOk(`Successful created report `, {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          }
        }).catch(e => {
          const errorMessage = e.message ? e.message : 'Error creating report.'

          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        }).finally(() => {
          this.sendingReport = false
        })
      }
    },
    onCreateMessage () {
      this.$refs['modalCreateMessage'].showModal()
    },
    onPostMessage (form) {
      let files = this.getFilesToUpload(form)
      if (files.length > 0) {
        UploadService.uploadMultipleFiles(files).then((result) => {
          for (let i = 0; i < result.length; i++) {
            let index_file = result[i].index_file,
              type_file = result[i].type_file

            form[type_file][index_file].url = result[i].name;
          }

          this.saveMessage(form)
        })
      } else {
        this.saveMessage(form)
      }
    },
    saveMessage (form) {
      ClassroomService.partnerMessageSend({
        classroom_id: this.classroom.id,
        partner_id: this.partner.id,
        form: form
      }).then(() => {
        this.onCancelCreateMessage()
      })
    },
    getFilesToUpload (form) {
      let files = []

      for (let i = 0; i < form['media'].length; i++) {
        if (form['media'][i].file) {
          files.push({
            file: form['media'][i].file,
            index_file: i,
            type_file: 'media'
          })
        }
      }

      return files
    },
    onCancelCreateMessage () {
      this.$refs['modalCreateMessage'].hideModal()
    },
    onReport () {
      this.$refs['modalCreateReport'].showModal()
    },
    cancelReport () {
      this.$refs['modalCreateReport'].hideModal()
    }
  },
  computed: {
    classPartnerAgeRange () {
      if (!this.partner.classrooms[0].age_range) {
        return 'not set'
      }

      return this.partner.classrooms[0].age_range.name
    },
    classPartnerLevel () {
      if (!this.partner.classrooms[0].level) {
        return 'not set'
      }

      return this.partner.classrooms[0].level.name
    },
    class_pairing_id: function () {
      return this.classroom.class_pairing ? this.classroom.class_pairing.id : null
    },
    partner_id: function () {
      return this.classroom.partner_id
    },
    classroom_id: function () {
      return this.classroom.id
    },
    teacher_partner_id: function () {
      return this.classroom.partner ? this.classroom.partner.id : null
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.tab-content-dashboard
  width 100%
  display flex

  .container-my-dashboard
    width 50%

  .container-global-partner
    width 50%

    .title
      font font-opensans-bold
      font-size 24px
      line-height 24px
      color color-3
      text-transform uppercase
      text-align left
      padding 20px
      padding-top 40px
      padding-left 30px

@media (max-width: 1050px)
  .tab-content-dashboard
    display block

    .container-my-dashboard
      width 100%

    .container-global-partner
      width 100%

.partner-detail
  &-wrapper
    padding-left 30px
    padding-right 30px
    padding-bottom 50px

    h1
      font font-opensans-bold
      font-size 18px
      letter-spacing 0.96px

  &-avatar
    width 100%
    height 90px

  &-name
    font font-opensans-bold
    font-size 15px
    margin-bottom 10px
    text-transform capitalize
    overflow-wrap break-word

  &-country
    margin-bottom 4px
    font font-opensans-bold
    font-size 13px

  &-title-bio
    margin-bottom 0
    font font-opensans-bold
    font-size 13px
    margin-top 8px

  &-bio
    font font-opensans-regular
    font-size 12px
    max-width 370px
    letter-spacing 0
    overflow-wrap break-word
    margin-bottom 10px

  &-list
    font font-opensans-bold
    font-size 12px
    list-style none
    padding 0
    margin-bottom 20px
    line-height 20px
    overflow-wrap break-word

  &-location
    width 100%
    display flex
    justify-content center

.main-container
  display block

  .partner-container
    width 100%

  .chat-wrapper
    width 100%
    height 650px
    margin-top 4rem
    justify-content start
    align-items flex-start
    max-width 450px

    ::v-deep
      .teacher-chat
        &.expand
          width 100%

  .contact-link
    margin-top 10px
    text-align center

    a
      font font-opensans-semibold

@media (min-width: 576px)
  .partner-detail
    &-wrapper
      padding-left 50px
      padding-bottom 67px

    &-avatar
      height 90px

@media (min-width: 768px)
  .partner-detail
    &-wrapper
      padding-left 91px

      h1
        font-size 24px

      button
        padding 8px 20px
        font font-opensans-semibold
        font-size 16px
        line-height 22px
        text-transform capitalize
        letter-spacing 0

        &:first-child
          margin-right 10px

    &-avatar
      height 150px

    &-name
      font-size 18px
      margin-bottom 10px

    &-country
    &-title-bio
      font-size 15px

    &-bio
      font-size 14px

    &-list
      font-size 14px
      margin-bottom 30px
      line-height 23px

@media (min-width: 992px)
  .partner-detail
    &-avatar
      height 150px

@media (min-width: 1200px)
  .partner-detail
    &-avatar
      width 196px
      height 188px

@media (min-width: 1400px)
  .partner-detail
    &-right
      padding-left 0

::v-deep
  .modal-dialog
    margin auto

  .modal-message-box .modal-content
    align-items center
    padding 5px

    .modal-body
      margin-bottom 0
      text-align center

      &::first-letter
        text-transform uppercase

    .modal-footer
      margin-bottom 12px

      .btn-primary
        background-color color-3
        border-color color-7
        font font-opensans-semibold
        border-radius 0.25rem

</style>
