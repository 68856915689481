import axios from "axios";
import {AppStorage} from "@/core/Utils";
import moment from "moment";
import { AwsService } from '@/core/Services'

function getExtension (filename) {
  return filename.substr((filename.lastIndexOf('.')) + 1).toLowerCase()
}

const getSignature = async function (key, bucket = process.env.VUE_APP_S3_BUCKET, client_method = 'put_object') {
  return axios.post(process.env.VUE_APP_S3_SIGNED_URL, {
    bucket: bucket,
    key: key, //, "prefix",
    client_method: client_method
  }).then(function (result) {
    const signedUrl = result.data.signed_url

    const signature = {}

    signature['url'] = signedUrl
    signature['inputs'] = {}

    const pairs = signedUrl.substring(signedUrl.indexOf('?') + 1).split('&')
    for (let i = 0; i < pairs.length; i++) {
      if (!pairs[i])
        continue
      const pair = pairs[i].split('=')
      signature['inputs'][decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }

    return signature
  }).catch(function (error) {
    console.error(error)
  })
}

const getS3FormPolicy = function (signature) {
  const s3Form = new FormData()

  s3Form.append('X-Amz-Algorithm', signature['inputs']['X-Amz-Algorithm'])
  s3Form.append('X-Amz-Credential', signature['inputs']['X-Amz-Credential'])
  s3Form.append('X-Amz-Date', signature['inputs']['X-Amz-Date'])
  s3Form.append('X-Amz-Expires', signature['inputs']['X-Amz-Expires'])
  s3Form.append('X-Amz-Signature', signature['inputs']['X-Amz-Signature'])
  s3Form.append('X-Amz-SignedHeaders', signature['inputs']['X-Amz-SignedHeaders'])

  return s3Form;
}

const getProfileMetadata = function () {
  const user = AppStorage.getUser()

  return {
    country: !user.country_name ? '' : user.country_name.replace(/[^a-zA-Z0-9]/g, ''),
    school: !user.school_name ? '' : user.school_name.replace(/[^a-zA-Z0-9]/g, ''),
    teacher: !user.full_name ? '' : user.full_name.replace(/[^a-zA-Z0-9]/g, '')
  };
}

const upload = function (url, file, config) {
  return axios.put(url, file, config)
    .catch(function (error) {
      console.error(error)
    })
}

const generateFileName = function () {
  return (moment().utc().format("YYYYMMDDHHmmssSSS") + Math.round(Math.random() * (999 - 100) + 100))
}

const triggerTranscoder = function (fileS3Url, size) {
  return axios.post(process.env.VUE_APP_S3_TRANSCODER_TRIGGER_URL, {
    FileUrl: fileS3Url,
    size: size
  })
}

const createFilePrefixName = function (classroomId, taskId, submissionId = null) {
  const student = JSON.parse(localStorage.getItem('user'))

  return `${student.id}-${classroomId}-${taskId}` + (submissionId ? `-${submissionId}` : '')
}

const createFileNameData = function (file, classroomId, taskId, submissionId = null, bucket = process.env.VUE_APP_S3_STORAGE) {
  const baseNamePrefix = createFilePrefixName(classroomId, taskId, submissionId)
  const baseName = `${baseNamePrefix}-${generateFileName(file.name)}`
  const extension = getExtension(file.name)
  const fileName = baseName + '.' + extension

  const fileUrl = bucket + '/' + baseName + '.mp4'
  const fileUrlInput = process.env.VUE_APP_S3_IMPUT_STORAGE + '/' + fileName

  return {
    baseNamePrefix,
    baseName,
    extension,
    fileName,
    fileUrl,
    fileUrlInput
  }
}

const uploadFileVideo = async function (file, customData, onUploadProgress) {
  const fileName = customData.fileName
  const fileUrl = customData.fileUrl
  const fileUrlInput = customData.fileUrlInput

  const bucket = process.env.VUE_APP_S3_VIDEO_INPUT_BUCKET
  const key = fileName

  // call the transcoder check
  const responseManifest2 = await triggerTranscoder(fileUrlInput, file.size)
  if (responseManifest2.status !== 200) throw new Error(`Oops, something went wrong. LUV had a problem when trying to render the video: ${fileName}.`)

  let progressFile = 0
  // upload the video file
  await AwsService.s3Upload(file, key, bucket, (progress) => {
    progressFile = Math.round((progress * 90) / 100)
    onUploadProgress(progressFile)
  })

  // upload the meta file
  const metaContent = { key: key, metadata: getProfileMetadata() }
  const metaFile = new File([JSON.stringify(metaContent)], fileName + '.json', {
    type: 'application/json'
  })
  const keyMeta = fileName + '.json'
  await AwsService.s3Upload(metaFile, keyMeta, bucket, (progress) => {
    const progressJson = Math.round((progress * 10) / 100)
    onUploadProgress(progressFile + progressJson)
  })

  return {
    name: fileUrl
  }
}

const uploadFile = async function (file, index_file = null, type_file = null) {
  const baseName = generateFileName(file.name)
  const extension = getExtension(file.name)
  const fileName = baseName + '.' + extension
  const key = 'resources/' + fileName
  const fileUrl = process.env.VUE_APP_S3_STORAGE + '/' + fileName

  return getSignature(key).then((signature) => {
    return upload(signature['url'], file, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      return {name: fileUrl, index_file: index_file, type_file: type_file, originalFile: file.name}
    })
  })
}

const uploadMultipleFiles = function (files = []) {
  let promises = []

  for (let i = 0; i < files.length; i++) {
    promises.push(uploadFile(files[i].file, files[i].index_file, files[i].type_file))
  }

  return Promise.all(promises).then(function (results) {
    let data = []

    results.forEach(function (file) {
      data.push(file)
    });

    return data
  });
}

const getVideo = async function (key) {
  const bucket = process.env.VUE_APP_S3_STUDENT_VIDEO_OUTPUT_BUCKET
  const signature = await getSignature('resources/' + key, bucket, 'get_object')

  return signature.url
}
const getVideoThumbnail = async function (key) {
  const bucket = process.env.VUE_APP_S3_STUDENT_VIDEO_OUTPUT_BUCKET
  const signatureThumbnail = await getSignature('resources/MedResVideo/thumbnails/' + key + '-00001.png', bucket, 'get_object')

  return signatureThumbnail.url
}

export default {
  getSignature: getSignature,
  getExtension: getExtension,
  getS3FormPolicy: getS3FormPolicy,
  getProfileMetadata: getProfileMetadata,
  upload: upload,
  uploadFile: uploadFile,
  uploadMultipleFiles: uploadMultipleFiles,
  getVideo: getVideo,
  getVideoThumbnail: getVideoThumbnail,
  createFileNameData,
  uploadFileVideo
}
