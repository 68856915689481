<template>
  <div class="dashboard-content">
    <my-action-items :classroom="classroom"/>
    <student-activity :classroom="classroom"/>
  </div>
</template>

<script>
import MyActionItems from './MyActionItems';
import StudentActivity from './StudentActivity';

export default {
  name: "MyDashboard",
  props: {
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    },
  },
  components: {
    MyActionItems,
    StudentActivity
  },
  methods: {

  },
  computed: {
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.dashboard-content
  padding-bottom 50px

</style>
