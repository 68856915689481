<template>
  <fragment>
    <b-button @click="$bvModal.show('import-students-modal')"
              btn-text="IMPORT STUDENT LIST"
              :disabled="classroomExchangeExpired"
              outline
              small
              class="size-text-btn"/>
    <modal id="import-students-modal" @hidden="file = null">
      <template #body>
        <b-overlay :show="sendingData" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <p>Please wait...</p>
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </template>

          <h2 class="modal-title">IMPORT STUDENT LIST</h2>
          <p class="modal-subtitle text-center">
            The system will generate a unique username for each student when they are added
          </p>
          <p class="modal-subtitle text-center">
            Click below to download a template.
            <a :href="VUE_APP_API_BASE_URL + '/Student_Template.xlsx'">Student_Template.xlsx</a>
          </p>

          <b-form-checkbox class="custom-checkbox-primary"
                           v-model="ignore_first_row">
            Ignore first row labels
          </b-form-checkbox>
          <upload-file place-holder="Xlsx File *"
                       :currentFile="nameFile"
                       accept-types=".xlsx"
                       @File:loaded="onFileLoaded"></upload-file>
        </b-overlay>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('import-students-modal')" btn-text="Cancel" :disabled="sendingData"/>
        <b-button @click="uploadXlsx()" btn-text="Send" :disabled="sendingData || !file"/>
      </template>
    </modal>
    <modal id="import-students-success-modal">
      <template #body>
        <h2 class="modal-title">IMPORT STUDENT LIST</h2>
        <p class="modal-subtitle text-center">
          Successfully imported {{ imported_students_count }} students.
        </p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('import-students-success-modal')" btn-text="OK"/>
      </template>
    </modal>
    <modal id="import-students-error-modal">
      <template #body>
        <h2 class="modal-title">No students found</h2>
        <p class="modal-subtitle text-center">
          Make sure the students are in the first spreadsheet with the student’s first name in column A and the last
          initial in column B.
        </p>
        <p class="modal-subtitle text-center">
          Click below to download a template.
          <a :href="VUE_APP_API_BASE_URL + '/Student_Template.xlsx'">Student_Template.xlsx</a>
        </p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('import-students-error-modal')" btn-text="OK"/>
      </template>
    </modal>
  </fragment>
</template>

<script>
import {Modal} from "@/core/components/Modal";
import UploadFile from "@/core/components/UploadFileAttachLink/UploadFile";
import {ClassroomService} from "@/core/Services";
import {Button} from "@/core/components/Button";
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"

export default {
  name: "ClassroomDetailStudentUploadTemplate",
  mixins: [ExchangesMixins],
  components: {
    UploadFile,
    Modal,
    'b-button': Button,
  },
  data() {
    return {
      error: null,
      students: [],
      sendingData: false,
      file: null,
      ignore_first_row: true,
      imported_students_count: 0,
      VUE_APP_API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
    }
  },
  props: {
    classroom: Object,
  },
  methods: {
    onOpenUploadTemplate() {
      this.students = []
      this.$refs['uploadTemplateStudent'].showModal()
    },
    onFileLoaded(files) {
      if (files.length > 0) {
        const file = files[0];

        if ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' !== file.type.toLowerCase()) {
          this.error = 'File must be .xsls'
        } else {
          this.file = file
        }
      }
    },
    uploadXlsx() {
      this.sendingData = true
      ClassroomService.importStudents({
        classroom_id: this.classroom.id,
        file: this.file,
        ignore_first_row: this.ignore_first_row
      }).then((response) => {
        this.$emit('Student:Imported:success', response.data)
        this.imported_students_count = response.data.length;

        this.$bvModal.hide('import-students-modal')

        if (this.imported_students_count) {
          this.$bvModal.show('import-students-success-modal')
        } else {
          this.$bvModal.show('import-students-error-modal')
        }

        this.file = null
        this.sendingData = false
      }).catch(() => {
        this.$bvModal.hide('import-students-modal')
        this.$bvModal.show('import-students-error-modal')

        this.sendingData = false
      })
    }
  },
  computed: {
    nameFile() {
      return this.file ? this.file.name : null
    }
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.modal-title
.modal-subtitle
  margin-bottom 10px

.modal-subtitle
  font font-opensans-regular
  font-size 14px
  color color-18
</style>
