<template>
  <classroom-detail-tab-message :id="message.id"
                                :user-id="message.user_id"
                                :user-thumbnail="message.user.teacher.avatar"
                                :user-first-name="message.user.teacher.full_name"
                                :user-last-initial="''"
                                :user-type="message.user.user_type.name"
                                :message-title="message.message_title"
                                :message-text="message.message_text"
                                :post-date="message.created_at"
                                :status="message.status"
                                :links="message.links"
                                :media="message.media"
                                content-class="message-content-teacher">
    <template v-slot:messageActions>
      <span class="number-comments">{{ commentsCount }} {{ commentsCount === 1 ? 'comment':'comments' }}</span>
      <a v-if="hasComments" href="javascript:void(0)" class="link-see-comments" @click="selected=!selected">
        <span class="link-item" v-if="!selected">See {{ commentLabel }}</span>
        <span class="link-item" v-else>Hide {{ commentLabel }}</span>
        <span class="icon-chevron-left" :class="{'icon-rotate': selected}"></span>
      </a>
    </template>
    <template v-if="selected" v-slot:messageChild>
      <classroom-detail-tab-message-student
        :key="'comment-' + _uid + index" v-for="(comment, index) in message.comments"
        :message="comment"
        :ref="'student-id-'+comment.id"
        @on-update-message-comment="onUpdateMessageComment"></classroom-detail-tab-message-student>
    </template>
  </classroom-detail-tab-message>
</template>

<script>
import MessageBoardMixin from './ClassroomDetailTabMessageMixins'
import ClassroomDetailTabMessage from "./ClassroomDetailTabMessage";
import ClassroomDetailTabMessageStudent from "./ClassroomDetailTabMessageStudent";

export default {
  name: "ClassroomDetailTabMessageTeacher",
  components: {ClassroomDetailTabMessageStudent, ClassroomDetailTabMessage},
  mixins: [MessageBoardMixin],
  props: {
    numberComments: {
      type: Number,
      default: 4
    },
    isSelected: {
      type: Boolean
    }
  },
  mounted() {
    if (this.message.comments.length) {
      const highlightedComment = this.message.comments.find((comment) => {
        return comment.highlightedComment;
      })

      if(highlightedComment) {
        this.selected = true
      }
    }
  },
  computed: {
    hasComments() {
      return this.commentsCount > 0
    },
    commentsCount() {
      if (this.message.comments) {
        const comments = this.message.comments.filter(comment => comment.status !== 'deleted');

        return comments.length
      } else {
        return 0
      }
    },
    commentLabel() {
      return this.message.comments.length === 1 ? 'Comment' : 'Comments'
    }
  },
  watch: {
    isSelected: {
      immediate: true,
      handler(newVal) {
        this.selected = newVal
      }
    }
  },
  data() {
    return {
      selected: false,
      students: [
        {
          fullName: "First Name, Last Initial",
          date: "Date",
          subject: "SUBJECT TITLE",
          description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. (...)"
        },
        {
          fullName: "First Name, Last Initial",
          date: "Date",
          subject: "SUBJECT TITLE",
          description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. (...)"
        }
      ]
    }
  },
  methods: {
    onUpdateMessageComment(comment) {
      this.$emit('on-update-message-comment', {
        message: this.message,
        ...comment
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.message-content-teacher
  ::v-deep
    .footer
      display flex
      justify-content flex-end
      padding 5px 0
      font font-opensans-semibold
      color color-8
      font-size 14px
      flex-direction column

      .number-comments
        padding-right 17px
        color color-8
        font-size 14px

      .link-see-comments
        color color-8
        font-size 14px
        text-decoration none

        .icon-chevron-left
          display inline-block
          background-color color-3
          padding 3px 4px
          font-size 0.6em
          border-radius 2px
          vertical-align middle

          &:before
            color color-1

        .link-item
          margin-right 10px

        .icon-rotate
          transform rotate(270deg)

@media (min-width: 768px)
  .message-content-teacher
    ::v-deep
      .footer
        flex-direction row
</style>
