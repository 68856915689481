import {http} from './http'

const resource = "/api/teacher/courses";

export default {
  list(filters) {
    return http.get(`${resource}`, {params: filters}).then(response => {
      return response
    })
  },
  orderTask (classroom_id, data) {
    return http.put(`/api/teacher/classrooms/${classroom_id}/tasks-order`, data).then(response => {
      return response
    })
  },
}
