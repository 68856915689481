import AccountView from "../AccountView.vue";
import ChangePasswordPage from "../Pages/ChangePasswordPage";
import TeacherProfilePage from "../Pages/TeacherProfilePage";
import MyAccount from "../Pages/MyAccount";
import ProfileWizardPage from "../Pages/ProfileWizardPage";

const routes = [
  {
    path: '/account',
    component: AccountView,
    children: [
      {
        path: 'change-password',
        name: 'AccountChangePassword',
        meta: {authRequired: true},
        component: ChangePasswordPage,
      },
      {
        path: 'profile',
        name: 'TeacherProfilePage',
        meta: { authRequired: true },
        component: TeacherProfilePage,
      },
      {
        path: 'my-account',
        name: 'MyAccount',
        meta: { authRequired: true },
        component: MyAccount,
      },
      {
        path: 'new-profile',
        name: 'ProfileWizardPage',
        meta: { authRequired: true },
        component: ProfileWizardPage,
      },
    ]
  }
]

export default routes
