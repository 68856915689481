<template>
  <fragment>
    <div :class="classFormInputContent">
      <input
        :id="'form-input-file-' + _uid"
        ref="uploader"
        class="d-none"
        type="file"
        :accept="acceptTypes"
        @change="onFileChanged"
      >
      <label :for="'form-input-file-' + _uid" class="form-upload-file">
        <span v-if="!currentFile" class="form-file-text">{{ placeHolder }}</span>
        <span v-else class="uploaded-file"><a target="_blank" :href="nameFile">{{ nameFile }}</a></span>
        <button type="button" class="btn-common btn-input-file" @click="openUploadFile" v-if="!hideButton">
          {{ labelButton }}<span class="icon-upload"></span>
        </button>
      </label>
    </div>
    <div class="invalid-feedback" v-if="showErrors">
      <ul class="error-content" v-if="hasErrors">
        <li class="error-text" v-for="(error, index) in errors" :key="`file-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </div>
  </fragment>
</template>

<script>
import { Utils } from "@/core/Utils";

export default {
  name: "UploadFile",
  props: {
    classFormInput: {
      type: String,
      default: null
    },
    placeHolder: {
      type: String,
      default: null
    },
    maxSize: {
      type: Number,
      default: 262144000
    }, // 250Mb
    labelButton: {
      type: String,
      default: 'Upload File'
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentFile: {
      type: [String, Object, FileList],
      default: ''
    },
    acceptTypes: {
      type: String,
      default: '*'
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openUploadFile () {
      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      const file = files[0]

      if (file.size > this.maxSize) {
        this.$emit('error', {
          code: 'max_size_exceeded',
          message: `File must not be higher than ${Utils.formatBytes(this.maxSize)}`
        })
        return
      }

      this.$emit('File:loaded', files)
    },
  },
  computed: {
    classFormInputContent () {
      const className = {
        'form-upload': true
      }

      className[this.classFormInput] = !!this.classFormInput
      className['form-upload-error'] = this.hasErrors

      return {
        ...className
      }
    },
    hasErrors () {
      return this.errors.length > 0
    },
    nameFile () {
      return typeof this.currentFile === 'string' ? this.currentFile : this.currentFile[0].name
    }
  },
  watch: {
    value () {
      this.$emit('input', this.value);
    }
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.invalid-feedback
  display block !important

.btn-input-file
  background-color color-3
  border 3px solid color-7
  border-radius(4px)
  font font-opensans-bold
  font-size 12px
  letter-spacing 1.6px
  color color-9
  text-align center
  flexbox(flex)
  align-items(center)
  justify-content(center)
  padding 0 10px
  min-height unset
  height 25px
  text-decoration none
  text-transform capitalize

  &:focus,
  &:hover
    box-shadow none

  .icon-upload
    padding-left 5px

.form-upload
  margin-bottom 10px

  &-file
    display flex
    justify-content space-between
    align-items center
    width 100%
    border: 2px solid color-16
    background-color: color-20
    padding: 0 8px 0 20px
    min-height: 40px
    border-radius 7px

    .form-file-text
      font font-opensans-regular
      font-size 14px
      color color-8

  &-error .form-upload-file
    border: 2px solid color-38

    &:focus
      box-shadow(transparent-shadow !important)

  .uploaded-file
    font font-opensans-bold
    font-size 14px
    color #1968ff
    text-transform uppercase
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
    width 50%
    @media (min-width 475px)
      width 60%
    @media (min-width 768px)
      width 60%
</style>
