<template>
  <b-card class="course-card card-scratch">
    <b-card-text class="course-card__item course-card__title">{{ classroom.name }} - {{ classroom.subject }}</b-card-text>
    <b-card-text class="course-card__item course-card__subtitle">Status: <span class="course-card__status">{{ classroom.status }}</span></b-card-text>

    <b-row align-h="start">
      <b-col cols="auto">
        <b-card-text class="course-card__item course-card__tasks">Number of Tasks : <span>{{ classroom.tasks }}</span></b-card-text>
      </b-col>
      <b-col cols="auto">
        <b-card-text class="course-card__item course-card__posts">Student Posts : <span>{{ classroom.posts }}</span></b-card-text>
      </b-col>
    </b-row>

    <b-card-text class="course-card__item course-card__dates">Dates : <span>{{ classroom.start_date }}</span> - <span>{{ classroom.end_date }}</span></b-card-text>
    <b-card-text class="course-card__item course-card__code">Code : <span>{{ classroom.code }}</span></b-card-text>

    <b-card-text class="course-card__completion course-card__item">
      Course Completion
      <b-progress :value="classroom.completion" :max="100" show-progress variant="success"></b-progress>
    </b-card-text>

    <div class="course-card__btns">
      <b-row align-h="center">
        <b-col md="auto">
          <b-button @click="goToClassroomEditPage(classroom.id)" btn-text="Edit Classroom" outline/>
        </b-col>
        <b-col md="auto" class="p-0">
          <b-button @click="goToClassroomEditPage(classroom.id)" btn-text="Show Details" outline/>
        </b-col>
        <b-col md="auto" class="p-0">
          <b-button @click="goToClassroomPage(classroom.id)" btn-text="Enter Classroom" outline/>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {Button} from "@/core/components/Button";

export default {
  name: "CourseCard",
  components: {
    'b-button': Button,
  },
  data: function (){
    return {}
  },
  props: {
    classroom: {
      type: Object,
      default: function () {
        return {
          id: 123,
          name: 'CLASSROOM NAME',
          subject: 'Subject',
          status: 'Active',
          tasks: 1,
          posts: 1,
          start_date: 'mm/yy',
          end_date: 'mm/yy',
          code: 'classroom_code',
          completion: 45,
          course_content: {
            course_id: 1234567890,
            course_name: "COURSE NAME 1",
            total_tasks: 6,
          }
        }
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
@import "styles/card.styl";
</style>
