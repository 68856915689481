import Vue from 'vue'
import VueEcho from 'vue-echo-laravel';
// eslint-disable-next-line no-unused-vars
import Pusher from "pusher-js"

const user = JSON.parse(localStorage.getItem('user'));
let token = 'none'
if (user && user.token) {
  token = user.token
}

Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHR_API_KEY,
  cluster: process.env.VUE_APP_PUSHR_API_CLUSTER,
  encrypted: true,
  authEndpoint: process.env.VUE_APP_API_BASE_URL + "/broadcasting/auth",
  auth: {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  },
});
