import * as labels from "./mutation-types";

export const
  mutations = {
    [labels.FETCH_MISC_START](state) {
      state.loadingMiscData = true
    },
    [labels.FETCH_MISC_DATA](state) {
      state.loadingMiscData = false
    },
    [labels.FETCH_PAYMENT_METHODS_START](state) {
      state.loadingPaymentMethods = true
    },
    [labels.FETCH_PAYMENT_METHODS_DATA](state, payment_methods) {
      state.loadingPaymentMethods = false
      state.payment_methods = payment_methods
    },
    [labels.FETCH_LEVELS_START](state) {
      state.loadingLevels = true
    },
    [labels.FETCH_LEVELS_DATA](state, levels) {
      state.loadingLevels = false
      state.levels = levels
    },
    [labels.FETCH_SUBJECTS_START](state) {
      state.loadingSubjects = true
    },
    [labels.FETCH_SUBJECTS_DATA](state, subjects) {
      state.loadingSubjects = false
      state.subjects = subjects
    },
    [labels.FETCH_LANGUAGES_START](state) {
      state.loadingLanguages = true
    },
    [labels.FETCH_LANGUAGES_DATA](state, languages) {
      state.loadingLanguages = false
      state.languages = languages
    },
    [labels.FETCH_AGE_RANGES_START](state) {
      state.loadingAgeRanges = true
    },
    [labels.FETCH_AGE_RANGES_DATA](state, age_ranges) {
      state.loadingAgeRanges = false
      state.age_ranges = age_ranges
    },
    [labels.FETCH_NUMBER_STUDENTS_RANGES_START](state) {
      state.loadingNumberStudentsRanges = true
    },
    [labels.FETCH_NUMBER_STUDENTS_RANGES_DATA](state, number_students_ranges) {
      state.loadingNumberStudentsRanges = false
      state.number_students_ranges = number_students_ranges
    },
    [labels.FETCH_BUNDLES_START](state) {
      state.loadingBundles = true
    },
    [labels.FETCH_BUNDLES_DATA](state, bundles) {
      state.loadingBundles = false
      state.bundles = bundles
    },
    [labels.FETCH_TOPICS_START](state) {
      state.loadingTopics = true
    },
    [labels.FETCH_TOPICS_DATA](state, topics) {
      state.loadingTopics = false
      state.topics = topics
    },
    [labels.IS_PLAYERS_TRUST](state, isPlayers) {
      state.isPlayersTrustClassroom = isPlayers
    },
  }
