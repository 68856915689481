import { http } from './http'
import { AppStorage } from "@/core/Utils";

const resource = "/api/teacher/profile";

export default {
  get () {
    return http.get(`${resource}`).then((response) => {
        if (response.success) {
          // set new user info
          let profile = response.data || {}

          return AppStorage.setUser(profile)
        } else {
          return null
        }
      }
    );
  },
  update (form) {
    return http.put(`${resource}`, form).then(response => {
        // return response
        if (response.success) {
          // set new user info
          let profile = response.data || {}

          return AppStorage.setUser(profile)
        } else {
          return null
        }
      }
    );
  },
  checkTeacherAgreement () {
    return http.get(`${resource}/teacher-agreement-status`).then(response => {
        return response.success
      }
    );
  },
  registerTeacherPhoneNumber (data = {}) {
    return http.post(`/api/teacher/preferred-communication`, data).then(response => {
      return response
    })
  },
  confirmTeacherPhoneNumber (data = {}) {
    return http.put(`/api/teacher/preferred-communication`, data).then(response => {
      return response
    })
  },
}
