<template>
  <PageBase :is-security-page="true">
    <router-view></router-view>
  </PageBase>
</template>

<script>
  import { PageBase } from "@/core/components"
  export default {
    name: "SecurityView",
    components: {
      PageBase
    },
    data(){
      return {}
    },
  };
</script>

<style scoped>
</style>
