<template>
  <div class="record-container" :class="isRecorded ? 'recorded' : ''">
    <div class="timer-container-bg">
      <div class="timer-container">
        <div class="timer">
          <div class="timer-title">count down timer</div>
        </div>
        <div class="timer-bar">
          <div class="bar">
            <div class="fill" :style="{width: progressWidth + '%'}"></div>
          </div>
          <div class="timer-counter">{{ timeLabel }}</div>
        </div>
      </div>
    </div>
    <div class="video-container">
      <video id="myVideo" playsinline class="video-js vjs-default-skin" controls>
        <p class="vjs-no-js">
          To view this video please enable JavaScript, or consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video.
          </a>
        </p>
      </video>
    </div>
    <div v-if="!isRecorded" class="video-buttons-bar">
      <div class="btn-row">
        <router-link class="btn-cell cancel"
                     :to="{ name: 'UploadOrRecordPage', params:{ classroomId, teacherActionId, submissionId }}">
          Cancel
        </router-link>
        <div class="btn-cell rec">
          <button class="rec-btn" role="button" v-if="!isRecording" @click.prevent="startRecording"></button>
          <button class="stop-btn" role="button" v-else @click.prevent="stopRecording"
                  :disabled="elapseTime < 1"></button>
        </div>
        <div v-if="!isRecording" @click.prevent="reviewPrompt" class="btn-cell review">
          Review Prompt
        </div>
      </div>
    </div>
    <div v-else class="submit-recording" :class="isRecorded ? 'recorded' : ''">
      <div role="button" class="submit" @click.prevent="submitVideo">
        SUBMIT
      </div>
      <div role="button" class="submit delete" @click.prevent="downloadVideo">
        SAVE VIDEO ON DEVICE
      </div>
      <div role="button" class="submit delete" @click.prevent="retakeVideo">
        DELETE & TRY AGAIN
      </div>
    </div>
    <DownloadRecordedVideoModal :file-name="downloadedFileName"/>
    <TaskPromptModal :prompt="actionDetails"
                     :response-prompt="responseDetails"
                     :is-response-action="isResponseACtion"/>
    <b-overlay no-wrap :show="loading"/>
    <VideoUploadModal :uploaded="uploadedVideo"
                      :upload-progress="uploadProgress"
                      @on-video-upload-modal-closed="goToTaskDetailPage"/>
  </div>
</template>

<script>
import DownloadRecordedVideoModal from './DownloadRecordedVideoModal'
import { ActionsService, UploadService } from '@/core/Services'
import TaskPromptModal from './TaskPromptModal'
import VideoUploadModal from './VideoUploadModal'
import { UploadVideo } from '@/core/Mixins'

export default {
  name: 'VideoRecordPage',
  components: {
    DownloadRecordedVideoModal,
    TaskPromptModal,
    VideoUploadModal
  },
  mixins: [UploadVideo],
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    teacherActionId: {
      type: Number,
      required: true
    },
    submissionId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      time: {
        videoTimeLimit: 0,
        maxLength: 0,
        currentTime: 0,
        minutes: 0,
        seconds: 0
      },
      isRecording: false,
      isRecorded: false,
      player: null,
      loading: true,
      actionDetails: null,
      responseDetails: null,
      downloadedFileName: '',
      isResponseACtion: false,
      uploadProgress: 0
    }
  },
  computed: {
    timeLabel () {
      return `${this.strPadLeft(this.time.minutes, '0', 2)}:${this.strPadLeft(this.time.seconds, '0', 2)}`
    },
    progressWidth () {
      return this.time.currentTime ? 100 - this.time.currentTime / this.time.maxLength * 100 : 0
    },
    elapseTime () {
      return this.time.seconds
    }
  },
  methods: {
    async init () {
      try {
        let { data } = await ActionsService.getActionItemById(this.teacherActionId)
        this.actionDetails = data.action.details
        this.responseDetails = data.action.details_response
        this.isResponseACtion = !!data.teacher_action
        this.setPlayerVideo()
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Information not found.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
      } finally {
        this.loading = false
      }
    },
    destroyPlayer () {
      if (this.player) {
        this.pauseProgress()
        this.retakeVideo()
        this.player.dispose()
      }
    },
    setPlayerVideo () {
      /* eslint-disable */
      // destroy if the player was already initialized
      this.destroyPlayer()

      const options = {
        controls: false,
        bigPlayButton: true,
        fluid: true,
        controlBar: {
          volumePanel: false
        },
        plugins: {
          record: {
            audio: true,
            video: true,
            maxLength: this.time.videoTimeLimit || 120,
            displayMilliseconds: false,
            debug: false
          }
        }
      }

      this.player = videojs('#myVideo', options, () => {
        videojs.log('Using video.js', videojs.VERSION,
          'with videojs-record', videojs.getPluginVersion('record'),
          'and recordrtc', RecordRTC.version)
      })

      this.player.ready(() => {
        this.player.record().getDevice()
      })

      // error handling
      this.player.on('deviceError', () => {
        this.isRecording = false
        this.isRecorded = false
        this.$emit('error', { error: 'User doesn\'t allow the browser to access the webcam and/or microphone' })
        videojs.log('Device error video.js', this.player.deviceErrorCode)
      })

      this.player.on('error', (error) => {
        this.isRecording = false
        this.isRecorded = false
        this.stopProgress()

        this.$emit('error', { error: 'An error occurred while loading the media' })

        videojs.log('Error video.js', error)
      })

      // user clicked the record button and started recording
      this.player.on('startRecord', () => {
        this.isRecording = true
        this.isRecorded = false
        this.startProgress()
      })

      // converter started processing
      this.player.on('startConvert', () => {
        console.log('started converting!')
      })

      // user completed recording and stream is available
      this.player.on('finishRecord', () => {
        this.isRecording = false
        this.isRecorded = true
        this.pauseProgress()

        setTimeout(() => {
          this.player.play()
        }, 700)
      })
    },
    startRecording () {
      console.log(this.player, 'this.player');
      this.player.record().start()
    },
    stopRecording () {
      this.player.record().stop()
    },
    reviewPrompt () {
      this.$bvModal.show('task-prompt-modal')
    },
    retakeVideo () {
      this.isRecording = false
      this.isRecorded = false
      this.stopProgress()
    },
    async submitVideo () {
      const data = this.player.recordedData
      const formData = new FormData()
      formData.append('video', data, data.name)

      this.player.record().stopDevice()
      this.$bvModal.show('video-upload-modal')
      this.$emit('on-uploading-video-start')

      try {
        const fileData = UploadService.createFileNameData(data, this.classroomId, this.teacherActionId, this.submissionId)

        await ActionsService.saveActionVideo(this.teacherActionId, fileData.fileUrl)

        await UploadService.uploadFileVideo(
          data,
          fileData,
          (progress) => {
            this.uploadProgress = progress
          }
        )

        this.$emit('on-uploading-video-success')
      } catch (e) {
        this.$emit('on-uploading-video-error', e)

        const videoErrorAlreadySent = e.message && e.message.slice(0, 18) === "You already sent a"

        let errorMessage = e.message ? `${e.message} Please try again.` : 'Error while submitting response.'

        videoErrorAlreadySent && (errorMessage = 'You already submitted a video for this task.')

        await this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        }).then(() => {
          if (videoErrorAlreadySent) {
            this.$router.push({ name: 'ClassroomTaskDetail', params: {classroomId: this.classroomId, teacherActionId: this.teacherActionId} })
          }
        })

        this.$bvModal.hide('video-upload-modal')
      } finally {
        this.loading = false
      }
    },
    downloadVideo () {
      this.loading = true
      const data = this.player.recordedData

      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', data.name)
      link.click()

      this.loading = false
      this.downloadedFileName = data.name;
      this.$bvModal.show('download-recorded-video-modal')
    },
    strPadLeft (string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length)
    },
    start () {
      if (this.player) {
        this.time.currentTime = this.time.maxLength - parseInt(this.player.record().getDuration(), 10)
      }
    },
    startProgress () {
      this.timer = setInterval(this.start, 1000)
    },
    stopProgress () {
      this.time = Object.assign({}, { ...this.time }, {
        currentTime: this.time.maxLength,
        minutes: 0,
        seconds: 0
      })
    },
    pauseProgress () {
      clearInterval(this.timer)
    },
    goToTaskDetailPage () {
      this.$router.push({
        name: 'ClassroomPage',
        params: {
          classroomId: this.classroomId
        }
      })
    },
  },
  beforeDestroy () {
    this.destroyPlayer()
  },
  mounted () {
    this.init()

    this.$on('error', ({ error }) => {
      this.$bvModal.msgBoxOk(error, {
        centered: true,
        okVariant: 'danger'
      })
    })
  },
  watch: {
    classroomId () {
      this.init()
    },
    teacherActionId () {
      this.init()
    },
    'time': {
      deep: true,
      immediate: true,
      handler: function ({ currentTime }) {
        this.time.minutes = Math.floor(currentTime / 60)
        this.time.seconds = currentTime - this.time.minutes * 60
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.myVideo-dimensions.vjs-fluid
  padding-top unset

.video-js
  height 100%
  width 100%

.record-container
  height calc(100% - 120px)
  position absolute
  width 100%

  &.recorded
    height calc(100% - 213px)

  .timer-container-bg
    background-color color-13

  .timer-container
    max-width 720px
    margin auto
    height 60px
    color color-3
    font-family "Open Sans"
    font-size 12px
    font-weight bolder
    padding-top 15px

    .timer
      text-align center

    .timer-title
      display inline-block
      margin-right 10px

    .timer-counter
      display inline-block
      vertical-align middle
      width 25px
      margin-left 8px

  .bar
    border 2px solid
    height 12px
    margin-left 30px
    padding 2px
    border-radius 3px
    width calc(100% - 90px)
    display inline-block
    vertical-align middle

    .fill
      border-radius 3px
      border solid 2px
      height 4px
      width 80%

  .video-container
    max-width 720px
    height 100%
    margin auto

  .video-buttons-bar
    width 100%
    height 60px
    background-color color-13

    .btn-row
      max-width 720px
      margin auto

    .btn-cell
      cursor pointer
      width 33%
      display inline-block
      vertical-align middle
      color color-8
      text-decoration underline
      font-size 12px
      font-weight bold

      &.rec
        text-align center

    .cancel
      padding-left 17px

    .review
      text-align right
      padding-right 17px

    .rec-btn
      cursor pointer
      background-color red
      height 26px
      width 26px
      margin auto
      border-radius 25px
      margin-top 17px
      margin-bottom 17px
      border solid 1px #DB6161

    .stop-btn
      cursor pointer
      background-color red
      height 26px
      width 26px
      margin auto
      margin-top 17px
      margin-bottom 17px
      border solid 1px #DB6161

      &:disabled
        background-color grey
        border solid 1px grey

  .submit-recording
    width 100%
    height auto
    background-color color-13
    text-align center
    padding-top 20px
    padding-bottom 20px

    .submit
      cursor pointer
      margin auto
      width 114px
      height 31px
      line-height 31px
      background-color color-3
      color white
      font-size 12px
      font-weight bold
      border-radius 4px
      letter-spacing 2px

      &.delete
        width 226px
        margin-top 10px
</style>
