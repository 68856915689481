<template>
  <div class="student-profile-responses">
    <div class="student-profile-responses-actions">
      <classroom-detail-tab-filter v-model="filters" :filters-by="filtersBy"
                                   default-filters-by="all" default-view="grid"
                                   :field-search-show="false"></classroom-detail-tab-filter>
      <div class="action-right">
        <select-dropdown v-if="actions.length"
                         v-model="action"
                         @input="showModalAction"
                         value-field="value"
                         placeholder="Action"
                         :options="actions"
                         class-select="custom-dropdown--md custom-dropdown--secondary"/>
      </div>
    </div>
    <div class="action-left">
      <b-button @click="selectAllVideos" btn-text="select all" outline small/>
    </div>

    <teacher-table table-class-content="table-body-responses"
                   :rows="taskVideos"
                   :view="filters.view.value"
                   :loading-teacher-table="loadingTeacherTable">
      <template #tableHeader>
        <div class="column column-select">Select</div>
        <div class="column column-select-mobile"></div>
        <div class="column column-image"></div>
        <div class="column column-status">
          <div class="order">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          <span class="text">Status</span>
        </div>
        <div class="column column-task">
          <div class="order">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          <span class="text">Task Name</span>
        </div>
        <div class="column column-date">
          <div class="order">
            <span class="icon-caret-top order-top"></span>
            <span class="icon-caret-bottom order-bottom"></span>
          </div>
          <span class="text">Date</span>
        </div>
        <div class="column play"></div>
      </template>
      <template v-slot:tableRowList="slotProps">
        <div class="column column-select">
          <b-form-checkbox class="custom-checkbox-primary"
                           v-model="selectedVideos['task_'+slotProps.row.id]"></b-form-checkbox>
        </div>
        <div class="column column-select-mobile">
          <b-form-checkbox class="custom-checkbox-primary"
                           v-model="selectedVideos['task_'+slotProps.row.id]"></b-form-checkbox>
        </div>
        <div class="column column-image">
          <div class="content-image">
            <submission-thumbnail :submission="slotProps.row"></submission-thumbnail>
          </div>
        </div>
        <div class="column column-status">
          <span class="label">Submitted</span>
          <span class="label-desc">({{ slotProps.row.status }})</span>
        </div>
        <div class="column column-task">{{ slotProps.row.task_name }}</div>
        <div class="column column-date">{{ slotProps.row.created_at | dateformatDDMMYYYY }}</div>
        <div class="column column-play" @click="showModal(slotProps.row)">
          <span class="icon-play-circle"></span>
        </div>
      </template>
      <template v-slot:tableRowGrid="slotProps">
        <div class="column">
          <SubmissionsGridViewItem :submission="slotProps.row" :classroom_id="classroom.id"
                                   :selectedVideo="selectedVideos['task_'+slotProps.row.id] || 0"
                                   @on-show-submission-modal="showModal"
                                   @on-selected-video="onSelectedVideo"/>
        </div>
      </template>
    </teacher-table>

    <modal modal-class="modal-student-response"
           :hide-footer="true"
           ref="modal-student-response"
           id="modal-student-response"
           @hide="closeModalDetailSubmission">
      <template #body>
        <StudentResponseModal :classroom_id="classroom.id"
                              :selectedVideo="submissionSelected"
                              :loading="loadingSubmissionSelected"
                              :disabled-next-button="disabledNextButton"
                              ref="studentResponseModal"
                              @save-comment="getSubmissionSelected"
                              @on-close-submission-modal="hideStudentSubmissionModal"
                              @next-submission="onNextSubmission"
                              @applyAction="updateStatusVideo"/>
      </template>
    </modal>

    <modal modal-class="modal-action-confirmation-submissiones" ref="modalActionConfirmation">
      <template #body>
        <div>The selected {{ selectedVideosLength }} videos will be changed to {{ action }} status. Would you like to continue?</div>
      </template>
      <template #footer>
        <b-button @click="applyAction"
                  class="action-submissions-button"
                  btn-text="Ok"/>
        <b-button @click="onHideActionModal"
                  class="action-submissions-button"
                  btn-text="Cancel"/>
      </template>
    </modal>
  </div>
</template>

<script>
import ClassroomDetailTabFilter from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabFilter";
import TeacherTable from "@/modules/Classroom/Components/TeacherTable";
import SubmissionsGridViewItem from "@/modules/Classroom/Components/SubmissionsGridViewItem";
import SubmissionThumbnail from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabMyStudents/SubmissionThumbnail";
import {SelectDropdown} from "@/core/components/SelectDropdown";
import {ClassroomService, TrackingActivityService} from "@/core/Services";
import {Modal} from "@/core/components/Modal";
import StudentResponseModal from "@/modules/Classroom/Components/StudentResponseModal";
import {Button} from "@/core/components/Button"
import {Notifications, AppStorage, TrackingTypes} from "@/core/Utils";

export default {
  name: "StudentProfileResponses",
  components: {
    StudentResponseModal,
    Modal,
    TeacherTable,
    SubmissionsGridViewItem,
    ClassroomDetailTabFilter,
    'select-dropdown': SelectDropdown,
    'b-button': Button,
    SubmissionThumbnail,
  },
  data() {
    return {
      filters: {
        filterBy: null,
        view: {},
      },
      selectedVideoIndex: 0,
      submissionSelected: null,
      submissionSelectedId: null,
      selectedVideos: {},
      action: '',
      actions: [
        {name: "Approve", value: "approved"},
        {name: "Unapprove", value: "unapproved"},
        {name: "Block", value: "blocked"},
        {name: "Delete", value: "deleted"},
      ],
      filtersBy: [
        {name: "All", value: "all"},
        {name: "Approved", value: "approved"},
        {name: "Unapproved", value: "unapproved"},
        {name: "Blocked", value: "blocked"},
        {name: "Deleted", value: "deleted"},
      ],
      videos: [],
      interval: null,
      loadingSubmissions: false,
      loadingSubmissionSelected: false,
      loadingTeacherTable: false
    }
  },
  props: {
    student: Object,
    tasks: {
      type: Array,
      default: function () {
        return []
      }
    },
    classroom: Object,
  },
  mounted() {
    const taskOptions = this.tasks.map(t => {
      return {
        name: t.task_name,
        value: t.id
      }
    })

    this.filtersBy = [...this.filtersBy, ...taskOptions]

    this.getVideoList(true).then(() => {
      this.interval = setInterval(() => {
        if (!this.loadingSubmissions) {
          this.getVideoList()
        }
      }, 15000)
    })
  },
  methods: {
    onHideActionModal() {
      this.action = ''
      this.$refs.modalActionConfirmation.hideModal()
    },
    async showModal(video) {
      this.loadingTeacherTable = true
      this.submissionSelectedId = video.id
      this.selectedVideoIndex = await this.videos.indexOf(video)
      await this.getSubmissionSelected()
      this.$refs['modal-student-response'].showModal()
      this.loadingTeacherTable = false
    },
    showModalAction() {
      this.selectedVideosLength !== 0 && this.$refs.modalActionConfirmation.showModal()
    },
    closeModal() {
      this.$refs['modal-student-response'].hideModal()
    },
    async closeModalDetailSubmission(e) {
      e.trigger !== "event" && e.preventDefault()

      const validationResponse = await this.$refs.studentResponseModal.validateSavedComment(true)
      if (validationResponse === undefined) {
        this.hideStudentSubmissionModal()
      }
    },
    hideStudentSubmissionModal() {
      this.$bvModal.hide('modal-student-response')
    },
    getVideoList(openNotification) {
      this.loadingSubmissions = true
      let filter_by = 'all'

      if (this.filters.filterBy && this.filters.filterBy.value) {
        filter_by = this.filters.filterBy.value
      }

      return ClassroomService.getStudentResponses({
        classroom_id: this.classroom.id,
        student_id: this.student.id,
        filter_by: filter_by
      }).then((response) => {
        if (typeof response.data[Symbol.iterator] === 'function') {
          this.videos = response.data

          let newSelection = {}

          for (let i = 0; i < this.videos.length; i++) {
            newSelection['task_' + this.videos[i].id] = false
          }

          this.videos.map((video) => {
            for (const [key, value] of Object.entries(this.selectedVideos)) {
              let video_id = Number(key.split('_')[1])
              if (video.id === video_id && value) {
                newSelection['task_' + video.id] = true
              }
          }})

          this.selectedVideos = newSelection

          if (openNotification) {
            this.openNotification()
          }
        } else {
          this.videos = []
          this.selectedVideos = {}
        }
        this.loadingSubmissions = false
      })
    },
    selectAllVideos() {
      let newSelection = {}

      if (!Object.keys(this.selectedVideos).length) {
        this.onCleanSelection()
      }

      if (Object.keys(this.selectedVideos).every((k) => this.selectedVideos[k])) {
        for (let i = 0; i < this.videos.length; i++) {
          newSelection['task_' + this.videos[i].id] = false
        }
      } else {
        for (let i = 0; i < this.videos.length; i++) {
          newSelection['task_' + this.videos[i].id] = true
        }
      }

      this.selectedVideos = newSelection
    },
    applyAction() {
      let selectedVideos = []

      for (const [key, value] of Object.entries(this.selectedVideos)) {
        if (value === true) {
          let video_id = key.split('_')[1]
          selectedVideos.push(video_id)
        }
      }

      if (this.action && selectedVideos.length) {
        this.updateStatusVideo({action: this.action, videos: selectedVideos})
      }
      this.onCleanSelection()
      this.action = ''
      this.$refs.modalActionConfirmation && this.$refs.modalActionConfirmation.hideModal()
    },
    onCleanSelection () {
      let cleanSelection = {}
      for (let i = 0; i < this.videos.length; i++) {
        cleanSelection['task_' + this.videos[i].id] = false
      }
      this.selectedVideos = cleanSelection
    },
    updateStatusVideo(params) {
      this.loadingSubmissionSelected = true
      ClassroomService.getStudentVideosStatus({
        classroom_id: this.classroom.id,
        student_id: this.student.id,
        form: params
      }).then(() => {
        this.getVideoList().finally(async () => {
          await this.getSubmissionSelected()
          this.loadingSubmissionSelected = false
        })
        this.$emit('on-submission-status-changed')
      })
      TrackingActivityService.trackingActivityTeachers(TrackingTypes.changeStudentStatus)
    },
    async getSubmissionSelected() {
      const response = await ClassroomService.getSubmission({
        classroom_id: this.classroom.id,
        submission_id: this.submissionSelectedId
      })
      this.submissionSelected = response.data;
    },
    openNotification() {
      if (this.objectId && this.videos.length) {
        const currentVideoResponse = this.videos.find(obj => obj.id === this.objectId)

        if (currentVideoResponse) {
          this.showModal(currentVideoResponse)
        }
      }
    },
    async onNextSubmission() {
      this.loadingSubmissionSelected = true
      if (this.videos.find((video) => video.id === this.submissionSelected.id)) {
        this.selectedVideoIndex += 1
      }
      if (this.selectedVideoIndex === this.videos.length) {
        this.selectedVideoIndex = 0
      }
      this.submissionSelectedId = await this.videos.at(this.selectedVideoIndex).id
      await this.getSubmissionSelected()
      this.isResponseVideo = this.submissionSelected.is_response
      this.loadingSubmissionSelected = false
    },
    onSelectedVideo(video) {
      const newSelectionVideo = this.selectedVideos
      newSelectionVideo['task_' + video.id] = !!video.value

      this.selectedVideos = newSelectionVideo
    }
  },
  computed: {
    taskVideos: function () {
      return this.videos
    },
    selectedVideosLength: function () {
      return Object.values(this.selectedVideos).filter((video) => video === true).length
    },
    disabledNextButton () {
      if (!this.videos.find((video) => video.id === this.submissionSelected.id) && this.videos.length === 1) return false
      return this.videos.length <= 1
    }
  },
  watch: {
    async 'filters.filterBy'() {
      await this.getVideoList()

      const newSelection = {}

      for (let i = 0; i < this.videos.length; i++) {
        newSelection['task_' + this.videos[i].id] = false
      }
      this.selectedVideos = newSelection
    },
    '$route.query.notification_id': {
      handler: function (notification_id) {
        if (notification_id) {
          const notificationOpen = AppStorage.getNotificationOpen(notification_id);

          if (notificationOpen.type === Notifications.type.studentResponseVideo) {
            if (notificationOpen.object_id) {
              if (typeof notificationOpen.object_id === 'string') {
                this.objectId = parseInt(notificationOpen.object_id)
              } else {
                this.objectId = notificationOpen.object_id
              }
            }

            AppStorage.removeNotificationOpen(notification_id);
          }
        }
      },
      immediate: true
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.student-profile-responses-actions
  flexbox(flex)
  align-items(center)
  margin-top 8px
  margin-bottom 30px
  flex-direction(column)

  .action-right
    width 85%
    flexbox(flex)
    flex-direction(column)
    align-items(center)
    flex 1

    ::v-deep
      .btn-common--small
        margin-top 10px
        font-size 12px
        line-height 15px
        padding 8px 30px

    .custom-dropdown
      padding-right 10px

    @media (min-width: 768px)
      flex-direction(row)
      .custom-dropdown
        flex-basis 35%
        margin-right 8px
        min-width 165px

  ::v-deep
    .task-filter .custom-dropdown span
      font-size 14px

  @media (min-width: 768px)
    flex-direction(row)
    .action-right
      ::v-deep
        .btn-common--small
          margin-top 0
          font-size 10.5px
          line-height 15px
          padding 4px 10px

  @media (min-width: 992px)
    .action-right
      ::v-deep
        .btn-common--small
          font-size 11px
          padding 8px 18px

  @media (min-width: 1200px)
    .action-right
      ::v-deep
        .btn-common--small
          font-size 12px
          padding 8px 30px

  @media (min-width: 1400px)
    .action-right
      justify-content(end)

.action-left
  margin-bottom 10px
  margin-left 30px
  @media (min-width: 768px)
    margin-left 0

::v-deep
  .task-filter
    flex-direction column
    margin 0
    width 85%

    .custom-dropdown
      padding-right 8px
      height 40px

    @media (min-width: 768px)
      flex-direction(row)
      .custom-dropdown
        flex-basis 30%
        padding-right 8px
        width 165px
    @media (min-width: 992px)
      width 50%

.table-body-responses
  ::v-deep
    .table-body-view
      &.list
        .table-row
          padding 6px 10px
          align-items(center)
          margin-bottom 6px
          border 1px solid color-28
          color color-8

          &.header
            margin-bottom 3px
            border 0

            .column
              font font-opensans-bold
              font-size 0.5rem
              display flex
              align-items(center)

              .order
                display flex
                flex-direction(column)
                align-items(center)
                justify-content(center)
                width 16px

                &-bottom
                &-top
                  font-size 0.6rem

              &-select
                display none

          .column
            margin-left 3px
            padding-top 0

            &-select
              display none

            &-select-mobile
              width 20px
              margin-left 0

            &-image
              width 40px
              margin-right 13px
              margin-left 13px

            &-status
              flex-basis(20%)
              font font-opensans-bold
              font-size 0.5rem

              .label
                display block
                width 100%

              .label-desc
                display block
                width 100%
                font font-opensans-regular
                font-style italic
                font-size 0.5rem

            &-task
            &-date
              flex-basis(20%)
              font font-opensans-regular
              font-size 0.5rem

            &-task
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
              width 45px

            &-play
              width 20px

              span
                &:before
                  color color-3

          &:not(.header)
            .column
              &-status
              &-task
              &-date
              &-student
              &-date
                padding-left 0.9rem

      &.grid
        padding-left 50px
        padding-right 50px

        .table-row
          background-color rgba(color-29, 0.35)
          padding 0
          flex-direction(column)
          border-radius 4px
          overflow hidden
          position relative
          margin-bottom 15px

          .column
            padding-top 120%

    @media (min-width: 469px)
      .column-play
        flex(1)
        text-align right

    @media (min-width: 768px)
      .table-body-view
        &.grid
          grid-template-columns: 1fr 1fr 1fr
          column-gap 20px
          row-gap 1em
          padding-left 0
          padding-right 0

        &.list
          .table-row
            &.header
              .column
                font-size 0.9rem

                &-select
                  display block

                &-select-mobile
                  display none

            .column
              &-select
                display block
                width 50px

              &-select-mobile
                display none

              &-image
                width 60px

                .content-image
                  width 48px

              &-status
              &-task
              &-date
              &-student
                font-size 0.8rem

              &-status .label-desc
                font-size 0.8rem

              &-play
                flex(1)
                text-align right

                span
                  font-size 1.5rem


.student-video-information
  width 1248px
</style>
