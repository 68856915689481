<template>
  <div class="wrapper-security-page">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col cols="12" xl="4" lg="6" md="8">
          <div class="wrapper-security-form">
            <h2 class="security-form-subtitle mb-1">RECOVER PASSWORD</h2>
            <div class="security-form-page-info mb-4">
              <div class="required-fields">all fields required *</div>
            </div>
            <form @submit.prevent="" class="mt-2">
              <custom-input @blur="validateFieldEvent" v-model="form.password" :errors="errors.password"
                            placeholder="New Password *" type="password"/>
              <custom-input @blur="validateFieldEvent" v-model="form.passwordConfirm" :errors="errors.passwordConfirm"
                            placeholder="Confirm New Password *" type="password"/>
              <div class="security-form-container-btns c-btns-secondary">
                <b-button @click="goToLoginPage()" btn-text="CANCEL"/>
                <b-button @click="createRecoveryPassword" btn-text="APPLY"/>
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <modal ref="account_recovery_password_success" >
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title"></h2>
        <p>Password update successful, now you can log in with your new password.</p>
      </template>
      <template #footer>
        <b-button @click="closeModal()" btn-text="OK"/>
      </template>
    </modal>
    <modal ref="account_recovery_password_error" >
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">ERROR</h2>
        <p class="text-center">An error occurred while trying to recover the password, please contact an administrator.</p>
      </template>
      <template #footer>
        <b-button @click="closeErrorModal()" btn-text="OK"/>
      </template>
    </modal>
  </div>
</template>

<script>
import {AuthService} from '@/core/Services'
import validateForms from "@/core/Mixins/validate-forms";
import {Modal} from "@/core/components/Modal";
import {Button} from "@/core/components/Button";
import Input from "@/core/components/FormInput/Input";

export default {
  name: "RecoverPasswordPage",
  mixins: [validateForms],
  components: {
    Modal,
    'b-button': Button,
    'custom-input': Input,
  },
  data() {
    return {
      form: {
        password: '',
        passwordConfirm: '',
      },
      validations: {
        password: {
          validate: 'required|password',
        },
        passwordConfirm: {
          validate: 'required|passwordConfirm',
        }
      }
    }
  },
  methods: {
    createRecoveryPassword: function () {
      const {password} = this.form
      const token = this.$route.params.token
      const email = this.$route.query.email

      if (this.isValidForm()) {
        AuthService.passwordRecovery(password, email, token).then((response) => {
          if (response.success === true) {
            this.showSuccessModal()
          } else {
            this.showErrorModal()
          }
        }).catch(() => {
          this.clearForm()
        })
      }
    },
    showSuccessModal: function () {
      return this.$refs["account_recovery_password_success"].showModal()
    },
    closeModal: function () {
      return this.$refs["account_recovery_password_success"].hideModal().then(() => {
        this.goToLoginPage()
      })
    },
    showErrorModal: function () {
      return this.$refs["account_recovery_password_error"].showModal()
    },
    closeErrorModal: function () {
      return this.$refs["account_recovery_password_error"].hideModal().then(() => {
        this.clearForm()
      })
    },
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/security.styl";
</style>
