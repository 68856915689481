<template>
  <div class="wrapper-classroom-detail-page">
    <b-container class="classroom-detail-page-container" v-if="!isLoadingClassroom && typeof classroom !== 'undefined'">
      <b-row>
        <b-col cols="12">
          <classroom-detail-header :classroom="classroom"></classroom-detail-header>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <classroom-detail-information :is-collapse="collapseInformation"
                                        :classroom="classroom"></classroom-detail-information>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <classroom-detail-tabs v-bind="{tabs: tabs, classroom: classroom}"></classroom-detail-tabs>
        </b-col>
      </b-row>
    </b-container>

    <modal ref="classroom_first_time_enter_modal" modal-class="classroom_first_time_enter_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">INSIDE YOUR CLASSROOM</h2>
        <p class="first-text text-center">
          INSIDE your classroom you can search for a global partner, create and check your tasks, add students and much
          more.
        </p>
        <p class="second-text text-center">
          First connect with a global partner.
        </p>
      </template>
      <template #footer>
        <b-button @click="closeClassroomFirstTimeModal()" btn-text="OK"/>
      </template>
    </modal>
  </div>
</template>

<script>
import ClassroomDetailHeader from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailHeader";
import ClassroomDetailInformation from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailInformation";
import ClassroomDetailTabs from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabs";
import {ClassroomMixins} from "@/modules/Classroom/Mixins";
import {Modal} from "@/core/components/Modal";
import {Button} from "@/core/components/Button";
import {Notifications, AppStorage, TabValues} from "@/core/Utils";

export default {
  name: "ClassroomDetailPage",
  mixins: [ClassroomMixins],
  components: {
    Modal,
    'b-button': Button,
    ClassroomDetailTabs,
    ClassroomDetailInformation,
    ClassroomDetailHeader,
  },
  data() {
    const queryTab = this.$route.query.tab

    const isDefaultTab = (tabValue) => !(queryTab === tabValue)

    return {
      classroomId: null,
      collapseInformation: false,
      tabs: [
        {
          name: "My Dashboard",
          icon: "icon-global",
          component: "ClassroomDetailTabGlobalPartner",
          disabled: true
        },
        {
          name: "My Tasks",
          icon: "icon-task",
          component: "ClassroomDetailTabMyTasks",
          disabled: isDefaultTab(TabValues.myTasks)
        },
        {
          name: "My Students",
          icon: "icon-student-edit",
          component: "ClassroomDetailTabMyStudents",
          disabled: isDefaultTab(TabValues.myStudents)
        },
        {
          name: "Submissions",
          icon: "icon-submissions",
          component: "ClassroomDetailTabSubmissions",
          disabled: isDefaultTab(TabValues.submissions)
        },
        {
          name: "Message Board",
          icon: "icon-message-board",
          component: "ClassroomDetailTabMessageBoard",
          disabled: isDefaultTab(TabValues.messageBoard)
        }
      ],
      chat_component: null,
    }
  },
  mounted() {
    const notification_id = this.$route.query.notification_id

    let currentTabIndex = 0

    if (notification_id) {
      history.pushState(
        {},
        null,
        this.$route.path
      )

      const notificationOpen = AppStorage.getNotificationOpen(notification_id);
      if (notificationOpen) {
        switch (notificationOpen.type) {
          case Notifications.type.studentVideoPosted: {
            // currentTabIndex = this.tabs.findIndex((tab) => tab.component === 'ClassroomDetailTabSubmissions')
            currentTabIndex = this.tabs.findIndex((tab) => tab.component === 'ClassroomDetailTabMyStudents')
            break;
          }
          case Notifications.type.studentResponseVideo: {
            currentTabIndex = this.tabs.findIndex((tab) => tab.component === 'ClassroomDetailTabMyStudents')
            break
          }
          case Notifications.type.studentTeacherStatusMessage: {
            currentTabIndex = this.tabs.findIndex((tab) => tab.component === 'ClassroomDetailTabMessageBoard')
            break
          }
        }
      }
    }

    this.tabs[currentTabIndex].disabled = false
  },
  methods: {
    showClassroomFirstTimeModal: function () {
      return this.$refs["classroom_first_time_enter_modal"].showModal()
    },
    closeClassroomFirstTimeModal: function () {
      return this.$refs["classroom_first_time_enter_modal"].hideModal()
    },
  },
  watch: {
    isLoadingClassroom(value) {
      if (!value && this.classroom && !this.classroom.partner) {
        // this.showClassroomFirstTimeModal()
      }
    },
    '$route.query.collapseInformation': {
      handler: function (collapse) {
        if (typeof collapse === 'string') {
          this.collapseInformation = collapse === 'true'
        } else {
          this.collapseInformation = collapse
        }
      },
      immediate: true
    },
    classroom: {
      handler: function (value) {
        if (value && Object.keys(value).length && !value.course_id) {
          //this.goToChooseCourseContentPage(value.id)
        }
        console.log(value)
      },
      immediate: true
    }
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/common.styl"

.wrapper-classroom-detail-page
  padding-top 55px
  padding-bottom 44px


.classroom_first_time_enter_modal

  .first-text
    max-width 402px
    padding-bottom 23px

  .second-text
    max-width 435px
    font-weight bold

@media (min-width: 1400px)
  .container
    max-width 1347px


</style>
