<template>
  <div :class="classroom_create_class">
    <div v-if="currentStep < 6" class="classroom-step-wrapper">
      <b-list-group class="steps-list" horizontal>
        <b-list-group-item
          v-for="step in steps"
          :key="'step-' + step.num"
          class="step-item"
          :class="{'step-item-active': (currentStep >= step.num ) }">
          <!--            {{ step.num }}-->

          <b-icon v-if="currentStep >= step.num" icon="check2" class="step-item-active-icon"
                  aria-hidden="true"></b-icon>
        </b-list-group-item>
      </b-list-group>

      <div class="classroom-step-item classroom-step-1" v-if="currentStep === 1">
        <div class="classroom-step-title">What do you want to call this Classroom?</div>
        <form-input v-model="$v.classroom.name.$model" :validator="$v.classroom.name"/>

        <div class="classroom-step-title classroom-step-title--second">Subject</div>
        <form-select v-model="$v.classroom.subject_id.$model"
                     :validator="$v.classroom.subject_id"
                     :options="subjects"
                     custom-class="custom-dropdown--md custom-dropdown--secondary"/>

        <b-button type="button" class="button primary --sm" :disabled="$v.step_1.$invalid" @click="nextStep(2)"
                  btn-text="Next"/>
      </div>

      <div class="classroom-step-item classroom-step-2" v-if="currentStep === 2">
        <div class="classroom-step-title">My students’ primary language is:</div>
        <form-select v-model="$v.classroom.lang_native_id.$model"
                     :validator="$v.classroom.lang_native_id"
                     :options="languages"
                     custom-class="custom-dropdown--md custom-dropdown--secondary"/>


        <div v-if="isWorldLanguage" class="classroom-step-title classroom-step-title--second">My students are
          learning:
        </div>
        <form-select v-if="isWorldLanguage" v-model="$v.classroom.lang_instruction_id.$model"
                     :validator="$v.classroom.lang_instruction_id"
                     :options="languages"
                     custom-class="custom-dropdown--md custom-dropdown--secondary"/>

        <div v-if="isWorldLanguage" class="classroom-step-title classroom-step-title--second">Please select your students’ level:</div>
        <level-descriptions-modal v-show="isWorldLanguage" class="mb-2" modal-class="modal-description-center"/>
        <form-select v-model="$v.classroom.level_id.$model" :validator="$v.classroom.level_id" :options="levels"
                     placeholder="Level *"
                     v-if="isWorldLanguage"
                     custom-class="custom-dropdown--md custom-dropdown--secondary"/>

        <div v-if="isWorldLanguage" class="classroom-step-subtitle">
          Student tasks alternate between both languages so all students have an opportunity
          to practice with the language they are learning.
        </div>

        <b-button type="button" class="button primary --sm back" @click="nextStep(1)"
                  btn-text="Back"/>

        <b-button type="button" class="button primary --sm" :disabled="$v.step_2.$invalid" @click="nextStep(3)"
                  btn-text="Next"/>
      </div>

      <div class="classroom-step-item classroom-step-3" v-if="currentStep === 3">
        <div class="classroom-step-title">When would you like this exchange to begin?</div>
        <div class="classroom-step-subtitle">Most exchanges last 4-6 weeks.</div>
        <form-date-picker v-model="$v.classroom.start_date.$model" :validator="$v.classroom.start_date"
                          :min="minStartDate"/>

        <b-button type="button" class="button primary --sm back" @click="nextStep(2)"
                  btn-text="Back"/>

        <b-button type="button" class="button primary --sm" :disabled="$v.step_3.$invalid" @click="nextStep(4)"
                  btn-text="Next"/>
      </div>

      <div class="classroom-step-item classroom-step-4" v-if="currentStep === 4">
        <div class="classroom-step-title">How many students will be in this classroom?</div>
        <div class="classroom-step-subtitle">You may have up to 30 students in each classroom.</div>
        <form-select v-model="$v.classroom.number_students_range_id.$model"
                     :validator="$v.classroom.number_students_range_id"
                     :options="number_students_ranges"
                     custom-class="custom-dropdown--md custom-dropdown--secondary"/>

        <div class="classroom-step-title classroom-step-title--second">How old are your students?</div>
        <div class="classroom-step-subtitle">Please select the approximate age range.</div>
        <form-select v-model="$v.classroom.age_range_id.$model"
                     :validator="$v.classroom.age_range_id"
                     :options="age_ranges"
                     custom-class="custom-dropdown--md custom-dropdown--secondary"/>


        <div v-if="!isWorldLanguage" class="classroom-step-title classroom-step-title--second">
          Click NEXT to create this Classroom and select your course materials. You may SKIP the next step if you are
          open to discussing any topic.
        </div>

        <b-button type="button" class="button primary --sm back"
                  @click="nextStep(3)"
                  btn-text="Back"/>

        <b-button type="button" class="button primary --sm" :disabled="$v.step_4.$invalid"
                  @click="showModalAgreeAction('next')"
                  btn-text="Next"/>
      </div>

      <b-overlay no-wrap :show="creatingClassroom || loadingProfile|| sendingRequest"></b-overlay>
    </div>
    <div v-else class="classroom-page-container">
      <b-row>
        <b-col cols="12">
          <h2 class="security-form-subtitle security-form-subtitle--primary mb-4">SELECT A COURSE FOR YOUR CLASSROOM</h2>
          <h2 class="security-form-subtitle security-form-subtitle--secondary mb-1">Click 'See Details' to learn more about each course. You may also use the filters to search for more options.</h2>
          <h2 class="security-form-subtitle security-form-subtitle--secondary mb-4">You may SKIP this step for now if you are open to discussing any topic or are not yet sure which course you want choose.</h2>
          <h2 class="security-form-subtitle security-form-subtitle--normal mb-1">{{ classroom.name }}</h2>
          <div class="wrapper-classroom-content">
            <courses-list :default_filter_by="filter_by"
                          :default_search_string="search_string"
                          :classroom_id="classroom.id"
                          :subject_id="classroom.subject_id"
                          @on-course-add="onCourseAdd"></courses-list>
          </div>

          <div class="security-form-container-btns c-btns-secondary mt-0">
            <b-button @click="saveClassroom" btn-text="Skip"/>
          </div>

        </b-col>
      </b-row>
    </div>

    <modal id="first-classroom-modal" modal-class="classroom_first_classroom_modal" :hide-close-button="true">
      <template #header></template>
      <template #body>
        <h2 class="modal-title">WELCOME</h2>
        <p class="text-center">
          <b>Let’s set up your first Classroom Exchange!</b><br/>
          Click next to get started.
        </p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('first-classroom-modal')" btn-text="NEXT"/>
      </template>
    </modal>

    <TeacherAgreementModal
      @teacher-agreement-success="handleModalAgreeAction"
    />

    <purchase-bundles-modal id="purchase_bundles_modal"
                            ref="purchase_bundles_modal"
                            :subject-id="classroom.subject_id"
                            :show-not-yet-message="true"
                            @on-purchase-success="onPurchaseSuccess"/>
  </div>
</template>

<script>
import FormDatePicker from "@/core/components/Form/DatePicker";
import FormInput from "@/core/components/Form/Input";
import FormSelect from "@/core/components/Form/Select";
import { Button } from "@/core/components/Button";
import { ClassroomMixins, UtilsMixins } from "@/core/Mixins";
import { Modal } from "@/core/components/Modal";
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'
import { ClassroomService } from "@/core/Services";
import CoursesList from "@/core/components/CoursesList";
import { PurchaseBundlesModal } from "@/core/components/PurchaseBundlesModal";
import moment from "moment";
import TeacherAgreementModal from "@/modules/Classroom/Components/TeacherAgreementModal";
import LevelDescriptionsModal from '../Components/LevelDescriptionsModal.vue';

export default {
  name: "CreateNewClassroomPage",
  components: {
    Modal,
    'b-button': Button,
    'form-input': FormInput,
    'form-select': FormSelect,
    'form-date-picker': FormDatePicker,
    CoursesList,
    PurchaseBundlesModal,
    TeacherAgreementModal,
    LevelDescriptionsModal
  },
  mixins: [UtilsMixins, ClassroomMixins],
  data () {
    return {
      currentStep: 1,
      minStartDate: new Date(),
      skipCourseContent: false,
      connection_request: null,
      filter_by: null,
      search_string: null,
      buttonAction: null,
      sendingRequest: false,
    }
  },
  props: {
    invitation_id: {
      type: Number,
      required: false
    }
  },
  validations () {
    return {
      classroom: {
        name: {
          required,
          minLength: minLength(3)
        },
        subject_id: {
          required,
        },
        age_range_id: {
          required
        },
        number_students_range_id: {
          required
        },
        lang_native_id: {
          required
        },
        lang_instruction_id: {
          required: requiredIf(() => {
            return this.isWorldLanguage
          })
        },
        start_date: {
          required
        },
        end_date: {
          required
        },
        level_id: {
          required: requiredIf(() => {
            return this.isWorldLanguage
          })
        }
      },
      step_1: ['classroom.name', 'classroom.subject_id'],
      step_2: ['classroom.lang_instruction_id', 'classroom.lang_native_id', 'classroom.level_id'],
      step_3: ['classroom.start_date', 'classroom.end_date'],
      step_4: ['classroom.number_students_range_id', 'classroom.age_range_id']
    }
  },
  mounted () {
    this.initClassroom();
    this.init()
    // this.$bvModal.show('teacher-agreement-modal')
  },
  methods: {
    async init () {
      this.loading = true

      if (!this.classrooms.length) {
        await this.getClassrooms({})

        if (!this.classrooms.length) {
          this.$bvModal.show('first-classroom-modal')
        }
      }

      if (this.invitation_id) {
        const invitation = await ClassroomService.partnerConnection(this.invitation_id)
        this.connection_request = invitation.data
      }

      if (this.user.subjects.length) {
        let world_languages = this.user.subjects.find((subject) => subject.type === 'world_languages')

        if (world_languages) {
          this.classroom.subject_id = world_languages.id
        } else {
          this.classroom.subject_id = this.user.subjects[0].id
        }
      }

      if (this.user.native_language_id) {
        let native_language = this.languages.find((lang) => lang.id === this.user.native_language_id)

        if (native_language) {
          this.classroom.lang_native_id = native_language.id
        }
      }

      if (this.isWorldLanguage) {
        if (this.user.instruction_languages.length) {
          this.classroom.lang_instruction_id = this.user.instruction_languages[0].id
        } else {
          this.classroom.lang_instruction_id = this.languages[0].id
        }
      }

      let default_age_range = this.number_students_ranges.find((range) => (range.min === 21 && range.max === 30) || (range.name === '21-30'))
      if (default_age_range) {
        this.classroom.number_students_range_id = default_age_range.id
      }

      if (this.user.age_ranges.length) {
        const ages = [
          '15+ years old',
          '11-14 years old',
          '7-10 years old',
          '4-6 years old',
        ]

        for (let i = 0; i < ages.length; i++) {
          let range = this.user.age_ranges.find((range) => range.name === ages[i])

          if (range) {
            this.classroom.age_range_id = range.id
            break
          }
        }
      }

      if (!this.classroom.age_range_id) {
        let range = this.age_ranges.find((range) => range.name === '15+ years old')

        if (range) {
          this.classroom.age_range_id = range.id
        }
      }

      this.loading = false
    },
    async onPurchaseSuccess () {
      this.$bvModal.hide('purchase_bundles_modal')
      await this.saveClassroom()
    },
    onCourseAdd (selectedCourse) {
      this.setCourseId(selectedCourse.id)

      this.saveClassroom()
    },
    async saveClassroom () {

      this.$v.classroom.$touch()
      if (this.$v.classroom.$anyError) {
        return
      }

      if (this.sendingRequest) {
        return
      }

      this.sendingRequest = true

      if (!this.isWorldLanguage) {
        this.classroom.lang_instruction_id = this.classroom.lang_native_id
      }

      let data = {
        name: this.classroom.name,
        subject_id: this.classroom.subject_id,
        age_range_id: this.classroom.age_range_id,
        number_students_range_id: this.classroom.number_students_range_id,
        lang_instruction_id: this.classroom.lang_instruction_id,
        lang_native_id: this.classroom.lang_native_id,
        start_date: this.classroom.start_date,
        end_date: this.classroom.end_date,
        level_id: this.classroom.level_id,
      }

      if (this.classroom.course_id) {
        data.course_id = this.classroom.course_id
      }

      if (this.connection_request) {
        data.connection_request = this.connection_request.id
      }

      try {
        // refresh user data to get the current available_tokens_count
        await this.getProfile()

        if (!await this.showPurchaseBundlesModal()) {
          this.showCreateClassroomBox().then(async (res) => {
            if (res) {
              const response = await this.createClassroom(data)

              if (response.success === true) {
                this.$bvModal.show('classroom-create-success-modal')
                //go to global partner with the search engine displayed
                await this.goToClassroomPage(response.data.id, { collapseInformation: 'true' })
              } else {
                this.$bvModal.msgBoxOk(`${response.message}, Please try again.`, {
                  size: 'sm',
                  dialogClass: "modal-message-box",
                  noCloseOnBackdrop: true,
                  centered: true
                })
              }
            } else {
              this.sendingRequest = false
            }
          })
        }
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error fetching connection request'

        this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.sendingRequest = false
      }
    },
    availableTokens () {
      const exchangeTokensBalance = this.user.exchange_tokens_balance.find((exchange) => exchange.id === this.classroom.subject_id)
      return exchangeTokensBalance ? exchangeTokensBalance.balance_tokens : 0
    },
    async classroomTokens () {
      if (!this.selected_subject) {
        this.selected_subject = await this.getSubject(this.classroom.subject_id)
      }

      return this.selected_subject.number_of_tokens
    },
    partnerClassroomTokens () {
      return this.connection_request.classroom.number_of_tokens ?? 0
    },
    async classroomAdditionalTokens () {
      return await this.classroomTokens() - this.availableTokens()
    },
    partnerClassroomAdditionalTokens () {
      return this.partnerClassroomTokens() - this.availableTokens()
    },
    requiredTokens () {
      return this.connection_request ? this.partnerClassroomTokens() : this.classroomTokens()
    },
    async showPurchaseBundlesModal () {
      let message = null

      if (this.connection_request) {
        if (this.partnerClassroomTokens() > this.availableTokens()) {
          let classroom_additional = await this.partnerClassroomAdditionalTokens()

          message = `You do not have enough tokens to accept this partnership. Please purchase at least ${classroom_additional} additional tokens to continue.`
        }
      } else {
        if (!this.availableTokens()) {
          const classroomSubject  = this.subjects.find(subject => subject.id === this.classroom.subject_id)

          message = `This classroom requires a ${classroomSubject.name} exchange. Please select the number of exchanges you would like to purchase to continue.`
        }
      }

      if (message) {
        this.$bvModal.msgBoxOk(
          message,
          {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }
        ).then(() => {
          this.$refs["purchase_bundles_modal"].showPurchaseBundlesModal()
        })

        return true
      } else {
        return false
      }
    },
    async showCreateClassroomBox () {
      let message = null

      let remaining_token = this.availableTokens() - 1
      const classroomSubject  = this.subjects.find(subject => subject.id === this.classroom.subject_id)

      if (this.connection_request) {
        message = `This partnership connection, requires a ${classroomSubject.name} exchange. You will have ${remaining_token} remaining token(s) in your account.`
      } else {
        message = `This classroom requires a ${classroomSubject.name} exchange. You will have ${remaining_token} ${remaining_token === 0 ? '' : 'unused'} ${classroomSubject.name} exchange(s) remaining in your account`
      }

      return this.$bvModal.msgBoxConfirm(
        message,
        {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        }
      )
    },
    nextStep (next) {
      this.currentStep = next
    },
    ageAlertMessage () {
      return this.$bvModal.msgBoxOk(`Please note that you will be required to review and approve each video from your students before they will be shared with your partner classroom.`, {
        size: 'sm',
        dialogClass: "modal-message-box",
        noCloseOnBackdrop: true,
        centered: true
      })
    },
    selectCourseContent () {
      // If the classroom subject is World Languages, we would like to default to the Level filter in this view for selecting a course.
      // Otherwise, the default filter should be Subject
      if (this.isWorldLanguage) {
        this.filter_by = 'level'
        // if the classroom subject is World Languages, we would like the filter to show the courses based on the level selected in the prior step.
        // Otherwise, the filter should show courses matching the subject selected in the wizard
        this.search_string = this.classroom.level_id
      } else {
        this.filter_by = 'all'
        this.search_string = ''
      }

      this.currentStep = 6;
    },
    handleModalAgreeAction () {
      if (this.selectedAge.min_age < 13 && this.currentStep === 4) {
        this.ageAlertMessage().then(() => {
          this.handleActionSelected()
        })
        return
      }
      this.handleActionSelected()
    },
    handleActionSelected () {
      switch (this.buttonAction) {
        case 'next':
          this.selectCourseContent()
          break
        case 'skip':
          this.saveClassroom()
         break
      }
    },
    showModalAgreeAction (action) {
      this.buttonAction = action

      if (!this.user.teacher_agreement_completed_at) {
        this.$bvModal.show('teacher-agreement-modal')
      } else {
        this.handleModalAgreeAction();
      }
    },
  },
  computed: {
    steps () {
      let steps = [
        { num: 1 },
        { num: 2 },
        { num: 3 },
        { num: 4 },
      ]

      return steps
    },
    classroom_create_class () {


      return {

        'wrapper-create-classroom-page': this.currentStep < 6,
        'wrapper-classroom-page': this.currentStep === 6
      }
    },
    selectedAge () {
      return this.age_ranges.find((val) => val.id === this.classroom.age_range_id)
    }
  },
  watch: {
    '$route.params.classroomId': {
      handler: function (classroomId) {
        if (!classroomId) {
          return
        }

        if (typeof classroomId === 'string') {
          this.classroomId = parseInt(classroomId);
        } else {
          this.classroomId = classroomId;
        }

        this.getClassroom(this.classroomId).then((response) => {
          if (!response.success) {
            if (this.$route.query['partner-invitation']) {
              this.$EventBus.$emit("on-wrong-teacher-partner-invitation", response);
            } else {
              this.$EventBus.$emit("on-classroom-error", response);
            }
          }
        })
      },
      deep: true,
      immediate: true
    },
    'classroom.start_date' (start_date) {
      if (start_date) {
        let date = moment(start_date + 'T00:00:00')
        let end_date = date.add(90, 'days')

        this.classroom.end_date = end_date.format('YYYY-MM-DD')
      } else {
        this.classroom.end_date = null
      }
    },
    'isWorldLanguage' (isWorldLanguage) {
      if (isWorldLanguage) {
        this.classroom.lang_instruction_id = this.user.instruction_languages.length ? this.user.instruction_languages[0].id : null
      } else {
        this.classroom.lang_instruction_id = this.languages[0].id ?? null
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.wrapper-create-classroom-page
  min-height inherit
  display flex
  align-items center

.classroom-step
  &-wrapper
    max-width 875px
    min-width 320px
    width 100%
    margin 0 auto
    padding 25px

    .form-group
      max-width 470px
      margin 0 auto

    @media (max-width 414px)
      padding 2rem
      min-height: calc(100vh - (80px + 115px));

  &-item
    text-align center

    .button
      display inline
      margin-top 2rem

      &.back
        margin-right 4rem

      &:disabled
        border-width 0 !important

    @media (max-width 350px)
      .button
        margin 0 5px
        margin-top 2rem

        &.back
          margin-right 0

  &-title
    font-size 1.5rem
    margin-bottom 18px

  &-title--second
    margin-top 18px

  &-subtitle
    margin 18px 0
    font-size 1rem

.steps-list
  margin-bottom 2rem

  .step-item
    background-color color-4
    margin-right 2rem
    border-radius 50% !important
    height 2rem
    width 2rem
    padding 0
    border 2px solid color-7 !important
    text-align center

    &:not(:last-child):before
      content ''
      border-bottom .1rem solid color-3
      position absolute
      transform translateY(-50%)
      top 50%
      right -1.5rem
      width 1rem

    &-active
      background-color color-5

      &-icon
        font-size 1.5rem
        height 100%


.button-agreement
  padding-right 77px
  padding-left 77px

.modal-title--agreement
  text-transform capitalize

.security-form-subtitle--primary
  font-size 16px

.security-form-subtitle--secondary
  font-size 14px
  padding 0 20px
  text-transform none

::v-deep
  .wrapper-level-descriptions-modal
    margin-top 10px

  .modal-description-center
    max-width 468px
    margin auto
    text-align left

  .modal-message-box
    margin 0 auto !important
</style>

<style lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.first-classroom-modal
  .modal-dialog
    .modal-body
      max-width: 506px
      margin 21px auto

#teacher-agreement-modal
  overflow-y scroll !important
  overflow-x visible !important

  .modal-dialog
    max-width 700px
    min-height auto !important
    max-height none !important
    height auto !important
    margin 5.5rem auto !important

    .modal-body
      overflow-y visible !important
      font-size 14px

      .modal-list-agreement
        li
          margin-bottom 6px
</style>
