import { render, staticRenderFns } from "./StudentSubmissionModal.vue?vue&type=template&id=ffdd1e8c&scoped=true&"
import script from "./StudentSubmissionModal.vue?vue&type=script&lang=js&"
export * from "./StudentSubmissionModal.vue?vue&type=script&lang=js&"
import style0 from "./StudentSubmissionModal.vue?vue&type=style&index=0&id=ffdd1e8c&lang=stylus&scoped=true&"
import style1 from "./StudentSubmissionModal.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffdd1e8c",
  null
  
)

export default component.exports