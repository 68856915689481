import {http} from './http'
const resource = "/api/teacher";

export default {
  getActionsItems(filters) {
    return http.get(`${resource}/actions`, {params: {filters: filters}}).then(response => {
        return response
      }
    )
  },
  getActionItemById(teacherActionId) {
    return http.get(`${resource}/actions/${teacherActionId}`).then(response => {
        return response
      }
    )
  },
  getActionsVideos(filters) {
    return http.get(`${resource}/actions-videos`, {params: {filters: filters}}).then(response => {
        return response
      }
    )
  },
  updateActionStatus(actionId, status) {
    return http.put(`${resource}/actions/${actionId}`, status).then(response => {
        return response
      }
    )
  },
  videoPlayed(teacherActionId) {
    return http.put(`${resource}/actions-videos/${teacherActionId}/played`, {video_played: true}).then(response => {
        return response
      }
    )
  },
  saveActionVideo(actionId, url) {
    return http.post(`${resource}/actions-videos/${actionId}`, {video_url: url}).then(response => {
        return response
      }
    )
  }
}
