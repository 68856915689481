import {http} from './http'
const resource = "/api/teacher";

export default {
  studentActivity(classroomId) {
    return http.get(`${resource}/classrooms/${classroomId}/student-activity`).then(response => {
        return response
      }
    )
  }
}
