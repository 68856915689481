<template>
  <div class="container-attach-link">
    <div class="form-group">
      <div class="attach-name">
        <custom-input type="text" v-model="form.label" custom-class="form-control-attach-link"
                      placeholder="Link Title"/>
      </div>
      <div class="attach-url">
        <custom-input type="url" v-model="form.url" custom-class="form-control-attach-link" placeholder="Link" :errors="errors.url"/>
      </div>
    </div>
    <div class="form-group-action">
      <b-button @click="onAddLink" btn-text="Add" class="btn-attach" small>
        <template #icon-after>
          <span class="icon-attach"></span>
        </template>
      </b-button>
    </div>
    <div class="form-group-links">
      <ul>
        <li :key="'attach-link-'+index" v-for="(link, index) in value">
          <a target="_blank" class="text-normal " :href="link.url">{{ link.label }}
          </a>
          <button type="button" @click="onDeleteLink(index)"><span class="icon-delete"></span></button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Input from "@/core/components/FormInput/Input";
import {Button} from "@/core/components/Button";
import validateForms from "@/core/Mixins/validate-forms";
import {Utils} from "@/core/Utils";

export default {
  name: "AttachLink",
  components: {
    'custom-input': Input,
    'b-button': Button,
  },
  mixins: [validateForms],
  props: {
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      form: {
        label: null,
        url: null
      },
      validations: {
        url: {
          validate: 'url',
        }
      }
    }
  },
  methods: {
    onAddLink() {
      if (this.form.label && this.form.url && this.isValidForm()) {
        this.form.url = Utils.httpUrl(this.form.url)
        this.value.push(this.form)
        this.form = {label: '', url: ''}
      }
    },
    onDeleteLink(index) {
      this.value.splice(index, 1);
    }
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.container-attach-link
  ::v-deep
    .form-control-attach-link
      border-color color-16
      padding 7px 20px
      max-height unset
      height unset
      background-color color-20

      placeholder-styles(@block{
        font font-opensans-regular
        font-size 14px
        color color-8
      })

.container-attach-link
  .form-group
    display flex
    flex-direction column

    &-action
      display flex
      justify-content(end)

      .btn-common
        font font-opensans-bold
        padding 2px 5px
        font-size 12px
        text-transform capitalize
        min-height unset

        span
          display inline-block
          transform rotate(45deg)
          padding-left 5px
          vertical-align middle

    &-links
      ul
        padding 0
        margin 0
        list-style-type none
        display block

        & > li
          border 0
          padding 0
          display block
          color color-3
          width: 90%
          word-break break-all

          a
            font font-opensans-bold
            font-size 14px
            color color-34
            text-transform uppercase

          button
            border 0
            background-color transparent

            span
              font-size 0.7rem

              &:before
                color color-3

  @media (min-width: 768px)
    .form-group
      flex-direction(row)

      .attach-name
        flex-basis(193px)

      .attach-url
        padding-left 10px
        flex 1
        .form-group
          flex-direction column



</style>
