<template>
  <modal id="video-player-popup" hideFooter>
    <template #body>
      <p class="label">{{label}}</p>
      <video-custom v-if="true"
                    ref="video-custom"
                    :src="src"/>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/core/components/Modal"
import VideoCustom from '../Video/VideoCustom.vue'

export default {
  name: 'videoPlayerPopup',
  components: {
    Modal,
    VideoCustom
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#video-player-popup
  .label
    font font-opensans-bold
    font-size 15px
    padding 0
    margin 0
    margin auto
    margin-bottom 16px
    text-align center
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    max-width 280px

  .video-overlay
    width 100%
    border-radius 4px
    @media (min-width 500px)
      height 665px

</style>
<style lang="stylus">
#video-player-popup
  padding-left 0

  .modal-dialog
    max-width 95%
    margin 1.75rem auto
    @media (min-width 500px)
      max-width 80%

    .close
      position relative
      z-index 1

    .body
      padding 20px
</style>
