<template>
  <div :class="profileSectionClass">
    <h3 v-if="number" class="profile-title">
      <span>{{ number }} </span> {{ title }}
    </h3>
    <div class="profile-body">
      <h3 v-if="!number" class="profile-title">
        {{ title }}
      </h3>
      <div v-if="subTitle" class="profile-body-subttitle">
        {{ subTitle }}
      </div>
      <div v-if="textInformation" class="profile-body-information">
        {{ textInformation }}
      </div>
      <div v-if="textRequired" class="profile-body-required">{{ textRequired }}</div>
      <div class="profile-body-form">
        <slot name="profile-form"></slot>
      </div>
      <div v-if="showButton" class="profile-body-submit">
        <b-button @click="$emit('button-click')" :disabled="disableForm"
                  :btn-text="textButton"></b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button";

export default {
  name: "TeacherProfileSection",
  components: {
    'b-button': Button,
  },
  props: {
    number: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false
    },
    textInformation: {
      type: String,
      default: null
    },
    textRequired: {
      type: String,
      required: false
    },
    disableForm: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: false
    },
    textButton: {
      type: String,
      default: null
    }
  },
  computed:{
    profileSectionClass() {
      let classs = {
        'teacher-profile-content': true
      }

      classs['teacher-profile-content__disabled'] = !!this.disableForm

      return classs
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.teacher-profile-content
  .profile
    &-title
      color color-8
      font-size 20px !important
      text-transform uppercase
      font font-opensans-regular
      display flex
      align-items center
      margin 0
      letter-spacing 0.2px

      span
        width 32px
        height 32px
        border 2px solid color-19
        border-radius 50%
        display flex
        justify-content center
        align-items center
        margin-right 10px

    &-body
      padding 0 0 0 38px

      &-subttitle
        margin-top 20px
        font font-opensans-bold
        color color-8
        font-size 14px
        text-transform capitalize

      &-required
      color color-8
        font-size 14px
        opacity 0.6

      &-information
        color color-8
        font-size 14px
        opacity 0.6

      &-form
        margin-top 20px
        margin-bottom 50px

        &::v-deep
          .form-group
            .custom-dropdown
              .dropdown-toggle
                &:disabled
                &[disabled]
                .disabled
                  opacity 1 !important
                  .custom-dropdown-button-icon
                    &:before
                      color color-11

      &-submit
        display flex
        justify-content flex-end
        margin-top 15px

        .common-btn
          min-width unset
          padding-left 30px
          padding-right 30px
          text-transform uppercase
  &__disabled
    .profile
      &-title
        span
          color   color-13
          border-color   color-11
          background-color color-11
          font-weight 900


      &-body
        &-form
          &::v-deep
            .form-group
              .custom-dropdown
                .dropdown-toggle
                  opacity 1 !important
                  .custom-dropdown-button-icon
                    &:before
                      color color-11

        &-submit
          &::v-deep
            .btn-common:disabled, .btn-common[disabled], .btn-common .disabled
              opacity: 1
</style>
