const FETCH_PROFILE_START = 'FETCH_COURSE_LIST_START'
const FETCH_PROFILE_DATA = 'FETCH_COURSE_LIST_DATA'
const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START'
const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA'
const READ_NOTIFICATION = 'READ_NOTIFICATION'

export {
  FETCH_PROFILE_START,
  FETCH_PROFILE_DATA,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_DATA,
  READ_NOTIFICATION
}
