<template>
  <b-container>
    <b-row align-h="center" align-v="center">
      <b-col cols="12" xl="4" lg="6" md="8">
        <div class="wrapper-security-form">
          <h2 class="security-form-subtitle mb-1">CHANGE PASSWORD</h2>
          <div class="security-form-page-info mb-4">
            <div class="required-fields">all fields required *</div>
          </div>
          <form @submit.prevent="" class="mt-2">
            <custom-input @blur="validateFieldEvent" v-model="form.passwordOld" :errors="errors.passwordOld"
                          placeholder="Old Password *" type="password"/>
            <custom-input @blur="validateFieldEvent" v-model="form.password" :errors="errors.password"
                          placeholder="New Password *" type="password"/>
            <custom-input @blur="validateFieldEvent" v-model="form.passwordConfirm" :errors="errors.passwordConfirm"
                          placeholder="Confirm New Password *" type="password"/>
            <div class="security-form-container-btns c-btns-secondary">
              <b-button @click="onCancel" btn-text="Cancel"/>
              <b-button @click="onChangePassword" btn-text="APPLY"/>
            </div>
          </form>
        </div>
        <b-overlay no-wrap :show="loading"></b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CustomInput from "@/core/components/FormInput/Input";
import validateForms from "@/core/Mixins/validate-forms";
import {Button} from "@/core/components/Button";
import {AccountService} from "@/core/Services";

export default {
  name: "ChangePasswordPage",
  mixins: [validateForms],
  components: {
    CustomInput,
    'b-button': Button,
  },
  data() {
    return {
      loading: false,
      form: {
        passwordOld: '',
        password: '',
        passwordConfirm: ''
      },
      validations: {
        passwordOld: {
          validate: 'required|passwordOld',
        },
        password: {
          validate: 'required|password',
        },
        passwordConfirm: {
          validate: 'required|passwordConfirm',
        }
      }
    }
  },
  methods: {
    onCancel() {
      this.goToMyClassroomsPage()
    },
    onChangePassword() {
      const {password, passwordOld} = this.form

      if (!this.loading && this.isValidForm()) {
        this.loading = true
        AccountService.passwordUpdate(password, passwordOld).then(() => {
          this.loading = false
          this.$bvModal.msgBoxOk('the password was updated successfully.', {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }).then(value => {
            if (value) {
              this.goToMyClassroomsPage()
            }
          })
        }).catch(error => {
          this.$bvModal.msgBoxOk(error.message, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
          this.loading = false
        })
      }
    },
    validateFieldEvent(field) {
      this.validateField(field)
    },
    validateField(field) {
      if (this.validations[field]) {
        let validations = this.validations[field].validate.split('|')

        this.errors[field] = []

        validations.forEach(type => {
          if (type === 'required') {
            if (!this.form[field]) {
              this.errors[field].push('Field required.')
            }
          } else if (type === 'password') {
            this.validatePassword()
          } else if (type === 'passwordConfirm') {
            this.confirmedPassword()
          }
        });
      }

    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

::v-deep
  .modal-dialog 
    margin auto
    
  .modal-message-box .modal-content 
    align-items center
    padding 5px

    .modal-body 
      margin-bottom 0
      text-align center
      &::first-letter
        text-transform uppercase

    .modal-footer 
      margin-bottom 12px 
      .btn-primary 
        background-color color-3
        border-color color-7
        font font-opensans-semibold
        border-radius 0.25rem

</style>