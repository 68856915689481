const CREATE_CLASSROOM_START = 'FETCH_CLASSROOM_START'
const CREATE_CLASSROOM_DATA = 'FETCH_CLASSROOM_DATA'
const FETCH_CLASSROOM_START = 'FETCH_CLASSROOM_START'
const FETCH_CLASSROOM_DATA = 'FETCH_CLASSROOM_DATA'
const CLASSROOM_EDIT_START = 'CLASSROOM_EDIT_START'
const CLASSROOM_EDIT_SAVE = 'CLASSROOM_EDIT_SAVE'
const CLASSROOM_ADD_CONTENT_START = 'CLASSROOM_ADD_CONTENT_START'
const CLASSROOM_ADD_CONTENT_SAVE = 'CLASSROOM_ADD_CONTENT_SAVE'
const FETCH_CLASSROOM_CHAT_MESSAGES_START = 'FETCH_CLASSROOM_CHAT_MESSAGES_START'
const FETCH_CLASSROOM_CHAT_MESSAGES_DATA = 'FETCH_CLASSROOM_CHAT_MESSAGES_DATA'

// task
const FETCH_TASK_LIST_START = 'FETCH_TASK_LIST_START'
const FETCH_TASK_LIST_DATA = 'FETCH_TASK_LIST_DATA'
const CREATE_TASK_START = 'FETCH_TASK_START'
const CREATE_TASK_DATA = 'FETCH_TASK_DATA'
const FETCH_TASK_START = 'FETCH_TASK_START'
const FETCH_TASK_DATA = 'FETCH_TASK_DATA'
const TASK_EDIT_START = 'TASK_EDIT_START'
const TASK_EDIT_SAVE = 'TASK_EDIT_SAVE'
const TASK_EDIT_VISIBILITY_START = 'TASK_EDIT_VISIBILITY_START'
const TASK_EDIT_VISIBILITY_END = 'TASK_EDIT_VISIBILITY_END'

export {
  CREATE_CLASSROOM_START,
  CREATE_CLASSROOM_DATA,
  FETCH_CLASSROOM_START,
  FETCH_CLASSROOM_DATA,
  CLASSROOM_EDIT_START,
  CLASSROOM_EDIT_SAVE,
  CLASSROOM_ADD_CONTENT_START,
  CLASSROOM_ADD_CONTENT_SAVE,
  FETCH_CLASSROOM_CHAT_MESSAGES_START,
  FETCH_CLASSROOM_CHAT_MESSAGES_DATA,
  // task
  FETCH_TASK_LIST_START,
  FETCH_TASK_LIST_DATA,
  CREATE_TASK_START,
  CREATE_TASK_DATA,
  FETCH_TASK_START,
  FETCH_TASK_DATA,
  TASK_EDIT_START,
  TASK_EDIT_SAVE,
  TASK_EDIT_VISIBILITY_START,
  TASK_EDIT_VISIBILITY_END,
}
