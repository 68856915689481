import Vue from 'vue'
import moment from 'moment';

Vue.filter('dateMonthDayYear', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});
Vue.filter('dateMonthDayYearHour', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY H:m:s')
  }
});
Vue.filter('dateMonthYear', function(value) {
  if (value) {
    return moment(String(value)).format('MM/YYYY')
  }
});
Vue.filter('dateElapseTime', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
})
Vue.filter('dateformatDDMMYYYY', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});
Vue.filter('dateElapseTime', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
})
Vue.filter('dateMonthDayYear2', function (value) {
  if (value) {
    return moment(String(value)).format('MMMM DD, YYYY')
  }
})
