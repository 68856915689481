<template>
  <div class="tab-container-task">
    <div class="task-actions">
      <b-button @click="onLoadTaskCreate"
                :disabled="classroomExchangeExpired"
                btn-text="CREATE TASK +"
                outline
                small/>
    </div>
    <classroom-detail-tab-filter
      v-model="filters" @Tasks:Search="searchTaskLIst(true)" default-view="list" />
    <hr>
    <p class="task-description">
      Click on a task to view the task details.
    </p>
    <div class="wrapper-table">
      <tasks-table :tasks="tasks"
                   @on-click-task="clickTask"
                   @on-click-block-status="clickBlockStatus"
                   @on-task-detail="onTaskDetail"
                   v-if="isWholeClassGroupType"/>
      <teacher-table-draggable @on-table-row="onTableRow"
                               table-class-content="table-body-task"
                               @update-list-order="updateOrder"
                               :view="filters.view.value"
                               :rows="tasks"
                               handle=".handle"
                               draggable
                               v-else>
        <template #tableHeader>
          <div class="column column-image"></div>
          <div class="column column-title">Task Name</div>
          <div class="column column-subject">STUDENT PROMPT</div>
          <div class="column column-tags">Create Due</div>
          <div class="column column-tags-response">Response Due</div>
          <div class="column column-show">SHOW</div>
          <div class="column column-order">Order</div>
        </template>
        <template v-slot:tableRowListDraggable="slotProps">
          <div class="column column-image">
            <div class="content-picture" :style="`background-image: url(${slotProps.row.task_thumbnail})`" />
          </div>
          <div class="column column-title" :class="isHidden(slotProps.row.is_hidden)">{{ slotProps.row.task_name }}</div>
          <div class="column column-subject"
            :class="isHidden(slotProps.row.is_hidden)"
            v-html="setStudentPrompt(slotProps.row.create_prompt, 'list')"/>
          <div class="column column-tags" :class="isHidden(slotProps.row.is_hidden)">
            <div class="status" :class="[createDueColor(slotProps.row), createTaskExpired(slotProps.row), isActivityOrReflectionTask(slotProps.row)]">
              <div class="status-text status-text--due">{{createDueLabel(slotProps.row)}}</div>
              <div class="status-text status-text--mm" v-if="slotProps.row.due_date">{{setDueDate(slotProps.row.due_date,'MMM')}}</div>
              <div class="status-text status-text--dd" v-if="slotProps.row.due_date">{{setDueDate(slotProps.row.due_date,'DD')}}</div>
            </div>
          </div>
          <div class="column column-tags-response" :class="isHidden(slotProps.row.is_hidden)">
            <div class="status" v-if="!(slotProps.row.task_category === introductionTask)" :class="[responseDueColor(slotProps.row), responseTaskExpired(slotProps.row), isActivityOrReflectionTask(slotProps.row)]">
              <div class="status-text status-text--due">{{responseDueLabel(slotProps.row)}}</div>
              <div class="status-text status-text--mm" v-if="slotProps.row.response_due_date">{{setDueDate(slotProps.row.response_due_date,'MMM')}}</div>
              <div class="status-text status-text--dd" v-if="slotProps.row.response_due_date">{{setDueDate(slotProps.row.response_due_date,'DD')}}</div>
            </div>
          </div>
          <div class="column column-show" @click.stop="">
            <img v-if="slotProps.row.is_hidden"
                 src="@/core/assets/x-mark.png"
                 alt="x-mark"
                 class="icon-mark">
            <img v-else
                 src="@/core/assets/checkmark.png"
                 alt="checkmark"
                 class="icon-mark">
          </div>
          <div class="column column-order">
            <b-icon-grip-horizontal class="icon-grip" :class="{'handle': !classroomExchangeExpired}"/>
          </div>
        </template>
        <template v-slot:tableRowGridDraggable="slotProps">
          <div class="column column-show">
            <img v-if="slotProps.row.is_hidden"
                 src="@/core/assets/x-mark.png"
                 alt="x-mark"
                 class="icon-mark">
            <img v-else
                 src="@/core/assets/checkmark.png"
                 alt="checkmark"
                 class="icon-mark">
          </div>
          <div class="wrapper" :class="{'handle': !classroomExchangeExpired}" @click="onShowTaskDetails(slotProps.row)">
            <div class="column">
              <div class="column-picture" :style="`background-image: url(${slotProps.row.task_thumbnail})`" />
            </div>
            <div class="wrapper column-attributes">
              <div class="column column-attribute-title">{{ slotProps.row.task_name }}</div>
              <div class="column subject">
                <span v-html="setStudentPrompt(slotProps.row.create_prompt, 'grid')"/>
              </div>
              <div class="column column-attribute-tags">
                <span v-if="slotProps.row.due_date">Create Due: {{ convertDueDate(slotProps.row.due_date) }}</span>
              </div>
              <div class="column column-attribute-tags">
                <span v-if="slotProps.row.response_due_date">Response Due: {{ convertDueDate(slotProps.row.response_due_date) }}</span>
              </div>
            </div>
          </div>
        </template>
      </teacher-table-draggable>
      <b-overlay no-wrap :show="isLoading || isLoadingTasks"/>
    </div>
    <modal ref="no_results_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">NO TASKS FOUND</h2>
        <p class="text-center">
          No tasks were found matching the entered criteria.
        </p>
      </template>
      <template #footer>
        <b-button @click="closeNoResultsModal()" btn-text="OK"/>
      </template>
    </modal>
    <submission-detail-modal :task-id="rowSelected.taskId"
                             :is-create="rowSelected.isCreate"
                             :create-prompt="rowSelected.task && rowSelected.task.create_prompt"
                             :response-prompt="rowSelected.task && rowSelected.task.respond_prompt"
                             ref="submission-detail-modal"
                             @upload-video-success="uploadVideoSuccess"/>
  </div>
</template>

<script>
import moment from "moment"
import ClassroomDetailTabFilter from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabFilter";
import TeacherTableDraggable from "@/modules/Classroom/Components/TeacherTableDraggable"
import {TasksMixins} from "@/modules/Classroom/Mixins";
import {Button} from "@/core/components/Button";
import {Modal} from "@/core/components/Modal";
import {CoursesService} from '@/core/Services'
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"
import {TaskMixins} from "@/modules/MyClassrooms/Mixins"
import TasksTable from "@/modules/Classroom/Components/TasksTable"
import SubmissionDetailModal from "@/modules/Classroom/Components/SubmissionDetailModal"
import {taskCategories} from "@/core/Utils"

export default {
  name: "ClassroomDetailTabMyTasksList",
  mixins: [TasksMixins, ExchangesMixins, TaskMixins],
  components: {
    ClassroomDetailTabFilter,
    TeacherTableDraggable,
    'b-button': Button,
    Modal,
    TasksTable,
    SubmissionDetailModal
  },
  data() {
    return {
      filters: {
        view: {},
        search_string: '',
      },
      lastSearchStringWithResults: '',
      tableRows: [],
      windowWidth: window.innerWidth,
      isLoading: false,
      orderError: null,
      rowSelected: {}
    }
  },
  mounted() {
    this.searchTaskLIst()
  },
  computed: {
    introductionTask () {
      return taskCategories.introduction
    }
  },
  methods: {
    isActivityOrReflectionTask (task) {
      return task.task_category === taskCategories.activity || task.task_category === taskCategories.reflection ? 'status-completed-color' : ''
    },
    uploadVideoSuccess () {
      this.getTasksList({classroom_id: this.classroom.id}).finally(() => this.isLoading = false)
    },
    onTaskDetail(task) {
      this.$emit('on-change-component', 'ClassroomDetailTabMyTasksCreate', task)
    },
    clickTask (rowData) {
      this.rowSelected = rowData
      this.$emit('on-change-component', 'ClassroomDetailTabMyTaskDetail', {...rowData.task, isCreate: rowData.isCreate})
    },
    clickBlockStatus (rowData) {
      this.rowSelected = rowData
      this.$nextTick(() => this.$refs['submission-detail-modal'].openSubmissionDetailModal())
    },
    isHidden (hidden) {
      return hidden ? 'is-hidden' : ''
    },
    convertDueDate(dueDate) {
      return dueDate ? moment(dueDate).format('M/D/YYYY') : ''
    },

    dueDateListView(dueDate) {
      return dueDate ? moment(dueDate).format('MMM-D') : ''
    },
    setDueDate (date, string) {
      switch (string) {
        case 'MMM':
          return moment(date).format('MMM')
        case 'DD':
          return moment(date).format('DD')
      }
    },
    async searchTaskLIst(fromSearchBar = false) {
      await this.getTasksList({classroom_id: this.classroom.id, search_string: this.filters.search_string})
      if (fromSearchBar) {
        if (this.tasks && this.tasks.length > 0) {
          this.lastSearchStringWithResults = this.filters.search_string
        } else {
          this.$refs["no_results_modal"].showModal()
        }
      }
    },
    onTableRow(task) {
      if (this.filters.view.value !== "grid") {
        this.onShowTaskDetails(task.row)
      }
    },
    updateOrder(data) {
      if (data.order <= 0) {
        return this.$bvModal.msgBoxOk(`Invalid value must be greater than or equal to 1`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }
      if (data.order > this.tasks.length) {
        return this.$bvModal.msgBoxOk(`Invalid value must be less than or equal to ${this.tasks.length}`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }
      this.isLoading = true

      const newTaskOrder = data.map((row, idx) => ({id: row.id, order: idx + 1}))

      CoursesService.orderTask(this.classroom.id, {tasks: newTaskOrder})
        .catch((e) => {
          const errorMessage = e.message ? e.message : 'Error while change order.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        })
        .finally(() => {
          this.getTasksList({classroom_id: this.classroom.id}).finally(() => this.isLoading = false)
        })
    },
    onLoadTaskLibrary() {
      this.$emit('on-change-component', 'ClassroomDetailTabMyTasksLibrary')
    },
    onLoadTaskCreate() {
      this.$emit('on-change-component', 'ClassroomDetailTabMyTasksCreate')
    },
    onShowTask(task) {
      task.is_hidden = !task.is_hidden
      this.setTaskVisibility({classroom_id: this.classroom.id, task_id: task.id, is_hidden: task.is_hidden})
    },
    onShowTaskDetails(task) {
      this.$emit('on-change-component', 'ClassroomDetailTabMyTaskDetail', task)
    },
    closeNoResultsModal: function () {
      this.$refs["no_results_modal"].hideModal()
      this.filters.search_string = this.lastSearchStringWithResults
      this.getTasksList({classroom_id: this.classroom.id, search_string: this.filters.search_string})
    },
    setStudentPrompt(str, view) {
      let num
      switch (view) {
        case 'list':
          num = 80
          this.windowWidth < 1400 && (num = 50)
          this.windowWidth < 800 && (num = 30)
          break
        case 'grid':
          num = 60
          this.windowWidth < 1400 && (num = 40)
          this.windowWidth < 400 && (num = 20)
          break
      }
      return str ? (str.length > num ? str.slice(0, num) + "<span>...</span>" : str) : ''
    },
    onResize () {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.tab-container-task
  padding-left 10px
  padding-right 10px
  padding-bottom 13px
  padding-top 41px

@media (min-width: 992px)
  .tab-container-task
    padding-left 112px
    padding-right 112px

.task-actions
  flexbox(flex)
  justify-content(end)
  padding-bottom 17px

  .btn
    margin-left 5px
    border-radius: 6px


.task-description
  font-size 13px
  color color-8
  margin-top: 15px

.wrapper-table
  position relative

.table-body-task
  overflow-x auto
  ::v-deep
    .table-body-view
      min-width 820px

      &.list
        .table-row
          align-items(center)
          margin-bottom 6px
          border 1px solid color-28
          color color-8
          padding 0

          &.header
            background-color rgba(color-27, 0.27)
            margin-bottom 3px
            border 0
            text-align center

            .column
              font font-opensans-bold
              font-size 0.9rem
              text-transform uppercase

          .column
            font font-opensans-regular
            font-size 0.8rem

            &-show
              width 60px
              cursor auto
              display flex
              flex-direction column
              text-align center

              span
                margin-left 10px
                font-size 1rem

            .icon-mark
              width 38px
              margin auto

            &-order
              width 80px
              text-align center

              .icon-grip
                font-size 2.4rem

            &-image
              width 80px
              height 80px

              .content-picture
                width 100%
                height 100%
                border-radius 4px
                background-repeat no-repeat
                background-size cover
                background-position center

            &-title
              width 45px
              font font-opensans-bold
              font-size 0.8rem
              text-transform uppercase
              padding-left 20px
              flex-basis(20%)
              @media(max-width 1400px)
                max-width 130px

            &-subject
              width 60px
              flex-basis(30%)
              margin 0 5px
              display flex
              align-items center
              justify-content center
              flex-direction column
              white-space normal !important
              padding 0 20px

              & > *
                margin 0
                padding 0
                text-align center

            &-topic
              width 45px
              flex-basis(20%)
              flex-wrap wrap
              margin 0 5px
              text-align center
              flex-basis(30%)

            &-tags, &-tags-response
              display flex
              justify-content center
              text-align center

              .status
                background #c5c7fe
                width 95px
                height 80px
                display flex
                flex-direction column
                justify-content center

                .status-text
                  color #414D94
                  text-transform uppercase
                  font-weight bolder
                  font-family "Open Sans"
                  text-align center
                  font-size 11px

                .status-text--due
                  white-space normal !important

                .status-text--mm
                  color white
                  font-size 15px
                  margin-top 0

                .status-text--dd
                  color white
                  font-size 19px
                  margin-top 0
                  line-height 16px

              .create-box-color,
              .response-box-color
                background color-54

              .status--expired
                background color-38

                .status-text--due
                  color color-1

              .status-completed-color
                background color-54

                .status-text--due
                  color color-7

            &-tags
              flex-basis(12%)

            &-tags-response
              flex-basis(14%)

          .is-hidden
            color color-52

            .status
              background color-52

              .status-text--due
                color color-33

        .table-row:not(.header)
          box-shadow 0 2px 4px #00000029
          border 1px solid color-15
          border-radius 6px
          overflow hidden
          cursor pointer

          .column
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
      &.grid
        .table-row
          width calc(100% - 36px)
          height 111px
          overflow hidden
          margin 0 18px 15px 18px
          box-shadow 0 2px 4px #00000029
          border 1px solid color-15
          border-radius 6px
          padding 0
          position relative
          cursor pointer

          &.header
            display none

          .wrapper
            width calc(100% - 110px)

          .column-attributes
            font-size 13px
            flex-direction(column)
            padding 10px 25px 10px 8px
            width 100%
            justify-content center
            flexbox(flex)

            .column
              text-overflow ellipsis
              overflow hidden
              white-space nowrap
              height 20px

          .column-show
            position absolute
            cursor pointer
            padding 4px 12px
            right 0

          .icon-mark
            width 20px
            margin auto

          .column-picture
            width 110px
            height 103px
            background-repeat no-repeat
            background-size cover
            background-position center
            margin: 3px
            border-top-left-radius: 6px
            border-bottom-left-radius: 6px

          @media(min-width 992px)
            .column-picture
              margin 0
            img
              width 110px
              height 100%
              object-fit cover

          .column-attribute-subject
          .column-attribute-tags
            flex-wrap(wrap)
            font-size 13px

          .column-attribute-title
            font font-opensans-bold
            font-size 15px
            text-transform uppercase

        @media(min-width 992px)
          .table-row
            height: 103px

    @media (min-width: 992px)
      .table-body-view
        &.grid
          grid-template-columns: 1fr 1fr

          & > .table-row:not(.header)
            width calc(100% - 36px)
            margin-bottom 15px
            margin-top 0

            &:nth-child(odd)
              margin-left 18px

            &:nth-child(even)
              margin-right 18px

</style>
