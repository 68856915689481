<template>
  <div class="action-items">
    <div class="header-container">
      <div>
        <h2 class="title">MY ACTION ITEMS</h2>
        <div class="subtitle">
          Mark
          <span class="icon-check-action icon-check-action--completed icon-check-action--header">
            <b-icon icon="check2"
                    class="check"
                    aria-hidden="true"/>
          </span>
          the items that you complete
        </div>
      </div>
      <select-dropdown v-model="statusSelected"
                       @input="handleStatusDropdown"
                       value-field="id"
                       :options="statusOptions"
                       class-select="custom-dropdown--md custom-dropdown--secondary"/>
    </div>
    <div class="action-items-content" v-if="actions.length">
      <div class="action-list">
        <div class="action-list-header">
          <p class="label-task">Task</p>
          <p class="label-todo">To Do</p>
        </div>
        <div class="action-item" v-for="item in actions" :key="item.id">
          <div class="action-item-wrapper">
            <div class="content">
              <div class="item-content" @click="handleArrowBtn(item.id)">
                <div class="icon-check-action"
                    @click.stop="updateStatus(item)"
                    :class="[{'icon-check-action--completed': item.completed}, {'icon-check-action--disabled': item.action.is_task}]">
                  <b-icon icon="check2"
                          class="check"
                          aria-hidden="true"/>
                </div>
                <p class="item-content__task">{{ taskLabel(item) }}</p>
                <p class="item-content__todo">{{ todoLabel(item) }}</p>
                <div class="icon-arrow">
                  <span class="icon-dropdown" :class="{'collapsed': item.isCollapse}"/>
                </div>
              </div>
              <b-collapse :id="'collapse-action-item' + item.id"
                          :visible="item.isCollapse"
                          class="collapse-item">
                <div v-if="!item.teacher_action" v-html="replaceClassroomId(item.action.details)" class="action-detail"/>
                <div class="collapse-action-item">
                  <a @click="goTo(item)"
                     v-if="item.action.is_task"
                     class="info-link">Go</a>
                  <a :href="item.action.more_info_link"
                     v-if="!item.action.is_task && item.action.more_info_link"
                     target="_blank"
                     class="info-link">More Info</a>
                  <a @click="onMasquerade(item)"
                     v-if="showMasqueradeButton(item)"
                     class="info-link ml-2">Go</a>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action-items-content" v-if="showMessage">
      <p class="action-items-content__message">Congratulations! You have completed all of your current action items.</p>
    </div>
    <b-overlay no-wrap :show="isLoading"/>
    <masquerade-whole-class ref="masqueradeActionModal"/>
  </div>
</template>

<script>
import { ActionsService, TrackingActivityService } from '@/core/Services'
import { SelectDropdown } from "@/core/components/SelectDropdown"
import {CourseMixins} from "@/modules/Classroom/Mixins"
import MasqueradeWholeClass from '@/core/components/MasqueradeWholeClass/MasqueradeWholeClass'
import {TrackingTypes} from "@/core/Utils"

export default {
  name: "MyActionItems",
  mixins: [CourseMixins],
  components: {
    SelectDropdown,
    MasqueradeWholeClass
  },
  props: {
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    },
  },
  data () {
    return {
      actions: [],
      isLoading: true,
      statusOptions: [
        { name: 'All', id: 'all' },
        { name: 'Pending', id: 'pending' },
        { name: 'Completed', id: 'completed' }
      ],
      statusSelected: 'pending'
    }
  },
  mounted () {
    this.getActions()
  },
  computed: {
    showMessage() {
      return !this.actions.length && (this.statusSelected === 'pending') && !this.isLoading
    }
  },
  methods: {
    replaceClassroomId (details) {
      return details?.replace(/classroom-id/g, this.classroom.id)
    },
    taskLabel (myAction = {}) {
      if (myAction.action.is_task) {
        if (myAction.action.use_student_task_name) {
          if (myAction.teacher_action) {
            return (myAction.teacher_action.class_task && myAction.teacher_action.class_task.task_name)
          }
          return (myAction.class_task && myAction.class_task.task_name)
        }

        return myAction.action.task
      } else {
        return myAction.action.use_student_task_name ? (myAction.class_task && myAction.class_task.task_name) : myAction.action.task
      }
    },
    todoLabel (action = {}) {
      if (action.teacher_action) return 'Respond to video received from your partner'
      if (action.teacher_action_response) return 'View video response from your partner'
      return action.action.to_do
    },
    async getActions () {
      this.isLoading = true
      const data = {
        "status": this.statusSelected,
        "classroom_id": this.classroom.id
      }
      try {
        await ActionsService.getActionsItems(data).then((response) => {
          this.actions = response.data
        })
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error get actions'
        this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.isLoading = false
      }
    },
    updateStatus (item) {
      this.isLoading = true
      ActionsService.updateActionStatus(item.id, { completed: !item.completed }).then((res) => {
        if (!res.success) {
          const errorMessage = res.message ? res.message : 'Error updating status'
          this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        }
        this.getActions()
      }).finally(() => this.isLoading = false)
      if (!item.completed) {
        TrackingActivityService.trackingActivityTeachers(TrackingTypes.completeActionItem)
      }
    },
    handleArrowBtn (id) {
      this.actions = this.actions.map((item) => {
        if (item.id === id) {
          item.isCollapse = !item.isCollapse
        }
        return item
      })
    },
    handleStatusDropdown () {
      this.getActions()
    },
    goTo (action) {
      if (action.teacher_action_response) {
        this.goToClassroomSubmissionDetail(action.classroom_id, action.id)
        return
      }
      this.goToClassroomTaskDetail(action.classroom_id, action.id)
    },
    onMasquerade (actionItem) {
      this.$refs.masqueradeActionModal.onMasquerade({
        classroomId: this.classroom.id,
        taskId: actionItem.class_task_id,
        trigger: actionItem.action?.trigger
      })
    },
    showMasqueradeButton(item) {
      const triggers = [
        "partner_student_create_trigger",
        "partner_student_respond_trigger"
      ]
      return !item.action.is_task && triggers.includes(item.action.trigger) && this.isWholeClassGroupType
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
.action-items
  position relative

  .icon-check-action
    width 25px
    height 25px
    border-radius 50%
    border 2px solid color-3
    color color-3
    display flex
    justify-content center
    align-items center
    margin-top 10px
    margin-right 15px
    cursor pointer
    min-width 25px

    .check
      display none

    &:hover
      background-color color-51

      .check
        display block

  .icon-check-action--header
    display inline-flex
    margin 0 2px
    width 18px
    height 18px
    min-width auto
    cursor auto

    .check
      font-size 12px
      display block

  .icon-check-action--completed
    background-color color-51

    .check
      display block

  .icon-check-action--disabled
    pointer-events none


  .header-container
    display flex
    justify-content space-between
    padding 20px
    padding-top 40px
    padding-left 30px

    .title
      font font-opensans-bold
      font-size 24px
      line-height 24px
      color color-3
      text-transform uppercase
      text-align left

    .subtitle
      font-size 14px

    .custom-dropdown--md
      max-width 200px

  .action-items-content
    padding 10px 10px 0 40px
    @media (max-width 1050px)
      padding 10px 20px 0 20px

    .action-list
      width 100%

      .action-list-header
        width 100%
        display flex
        justify-content end

        .label-task,
        .label-todo
          display flex
          align-items center
          width 50%
          height 40px
          margin 0
          font font-opensans-semibold
          font-size 22px
          border-bottom 5px solid color-51
          padding-left 5px

      .action-item
        width 100%
        margin-top 6px

        .action-item-wrapper
          border-bottom 5px solid color-3

          .content
            padding-bottom 2px

            .item-content
              width 100%
              display flex
              cursor pointer
              border-radius 5px

              .item-content__task,
              .item-content__todo
                display block
                width 50%
                margin 0
                font font-opensans-semibold
                font-size 18px
                text-overflow ellipsis
                overflow hidden
                padding 10px 0
                height auto
                white-space normal
                word-break break-word

              .item-content__todo
                color color-3
                padding-left 5px

              .icon-arrow
                margin-top 8px

                .icon-dropdown
                  cursor pointer
                  display inline-block
                  transform rotate(90deg)
                  transition 0.1s transform

                  &:before
                    color color-3
                    font-size 25px

                .collapsed
                  transform none
                  transition 0.1s transform

            &:hover
              background-color row-color-hover

            .action-detail
              word-break break-word
              margin-top 8px
              padding-left 40px

            .collapse-action-item
              width 100%
              display flex
              justify-content end
              margin 10px 0

              .info-link
                display inline-block
                background-color color-51
                border 2px solid #a7c583
                color white
                padding 8px 20px
                border-radius 15px
                cursor pointer

  .action-items-content__message
    text-align center
</style>
