<template>
  <div class="packages-carousel-item"
       :class="{'packages-carousel-item--selected': isSelectedCard}">
    <div class="packages-carousel-item__number">
      <input v-model="numberExchanges"
             type="number"
             class="number-input"
             @input="limiter">
    </div>
    <div class="packages-carousel-item__number">${{formatNumber(exchangeTokens.price)}}</div>
    <div class="packages-carousel-item__number">${{formatNumber(totalPrice)}}</div>
    <b-overlay no-wrap :show="isLoading"/>
  </div>
</template>

<script>
import {ExchangesService} from '@/core/Services'
export default {
  name: 'BundleCard',
  props: {
    selectedSubjectId: {
      type: Number
    },
    bundleId: {
      type: [String, Number]
    },
    isSelectedCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      numberExchanges: 0,
      exchangeTokens: {
        price: 0
      },
      isLoading: false
    }
  },
  computed: {
    totalPrice() {
      if (typeof this.numberExchanges === 'number' && typeof this.exchangeTokens.price === 'number') {
        return this.numberExchanges * this.exchangeTokens.price
      }
      return ''
    }
  },
  methods: {
    formatNumber(num) {
      return num.toLocaleString('en-US')
    },
    limiter() {
      this.numberExchanges = Number(this.numberExchanges)
      if (this.numberExchanges < 0) this.numberExchanges = 0
      if (this.numberExchanges > 100) this.numberExchanges = 100
      if (this.numberExchanges.length > 3) this.numberExchanges = this.numberExchanges.slice(0, 3)
    }
  },
  mounted() {
    this.selectedSubjectId && (this.numberExchanges = 1)
  },
  watch: {
    async selectedSubjectId(newSubjectId) {
      newSubjectId && (this.numberExchanges = 1)
      if (!newSubjectId) return
      this.isLoading = true
      await ExchangesService.getExchangeTokens(newSubjectId).then((resp) => {
        if (resp.success) {
          if (!resp.data) {
            this.$bvModal.msgBoxOk('No token exchange price for this country.', {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
            this.exchangeTokens = { price: 0 }
          } else {
            this.exchangeTokens = resp.data
          }
        }
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error loading exchange'
        this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }).finally(() => {
        this.isLoading = false
      })
      this.$emit('on-exchange-selected', {...this.exchangeTokens})
    },
    numberExchanges(newValue) {
      this.$emit('amount-tokens', newValue)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.packages-carousel-item
  width 20vw
  min-width 100px
  max-width 190px
  margin 0 10px
  scroll-snap-align center
  position relative

  .packages-carousel-item__number
    width 100%
    height 60px
    background #d3d3eb
    border-top-left-radius 10px
    border-top-right-radius 10px
    margin-bottom 2px
    padding 20px 40px
    font font-opensans-bold
    text-align right

    &:nth-child(1)
      font-size 20px
      padding 16px
      text-align center

    &:nth-child(2)
      border-radius 0

    &:nth-child(3)
      border-top-left-radius 0
      border-top-right-radius 0
      border-bottom-left-radius 10px
      border-bottom-right-radius 10px

    .number-input
      width 100%
      max-width 110px
      background #fff
      text-align right
      border none
      font font-opensans-bold
      font-size 16px

.packages-carousel-item--selected
  position relative

  &:before
    content ""
    position absolute
    width 100%
    height 100%
    background-color #000
    opacity 0.08
    border-radius 10px
    z-index 1

::v-deep
  .custom-radio
    padding-left 2.4rem !important

    .custom-control-input
      &:checked
        ~.custom-control-label::before
          background-color transparent !important

      &:not(:disabled):active
        ~.custom-control-label::before
          background-color color-14 !important

</style>
