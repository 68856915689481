<template>
  <div class="step-item">
    <div class="step-item-title">Avatar</div>
    <div class="step-item-subtitle">Choose an avatar or upload a picture</div>
    <upload-image-avatar v-model="$v.form.avatar.$model"
                         :validator="$v.form.avatar"
                         place-holder="Select Avatar *"
                         label="Select Avatar"
                         custom-class="form-upload-avatar"
                         ref="upload-image-avatar"
                         class="avatar-image"
                         @click-on-avatar="showAvatarsModal"
                         @File:loaded="onFileLoaded"/>
    <div class="step-item">
      <b-button type="button" class="button primary --sm back"
                @click="changeStep(1)"
                btn-text="Back"/>
      <b-button type="button" class="button primary --sm"
                @click="changeStep(3)"
                :disabled="$v.stepValid.$invalid"
                btn-text="Next"/>
    </div>
    <avatar-list-modal @select-avatar="(path) => (form.avatar = path)"
                       @upload-avatar="openUploadFile"
                       ref="avatarListModal"/>
  </div>
</template>

<script>
import { Button } from "@/core/components/Button"
import UploadImageAvatar from "@/core/components/UploadFileAttachLink/UploadImageAvatar"
import AvatarListModal from '@/core/components/AvatarListModal/AvatarListModal'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Step2',
  components: {
    UploadImageAvatar,
    'b-button': Button,
    AvatarListModal
  },
  props: {
    profileData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        avatar: ''
      }
    }
  },
  validations () {
    return {
      form: {
        avatar: {
          required,
        },
      },
      stepValid: ['form.avatar']
    }
  },
  methods: {
    changeStep (step) {
      this.$emit('change-step', {step, form: {...this.form}})
    },
    showAvatarsModal() {
      this.$refs.avatarListModal.open()
    },
    validImageType(file) {
      const fileTypes = [
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
        "image/x-icon"
      ];
      return fileTypes.includes(file.type);
    },
    onFileLoaded(files) {
      if (files.length > 0) {
        const file = files[0]

        if (!this.validImageType(file)) {
          this.errors.avatar.push('File must be png, jpg, jpeg or gif.')
          return
        }
        this.form.avatar = URL.createObjectURL(file)
      }
    },
    openUploadFile () {
      this.$refs["upload-image-avatar"].openUploadFile()
    },
  },
  async mounted() {
    this.form = {...this.form, ...this.profileData}
  },
  watch: {
    'form.avatar'() {
      this.$bvModal.hide('avatar-list-modal')
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.step-item
  text-align center

  .button
    display inline
    margin-top 2rem

    &.back
      margin-right 4rem

    &:disabled
      border-width 0 !important

  &-title
    font-size 1.5rem
    margin-bottom 18px

  &-subtitle
    margin 18px 0
    font-size 1rem

  .avatar-image
    max-width 500px
    margin 0 auto

  @media (max-width 350px)
    .button
      margin 0 5px
      margin-top 2rem

      &.back
        margin-right 0

</style>
