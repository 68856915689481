const avatars = [
  {
    id: 'avatar-1',
    src: '/assets/avatars/1.svg'
  },
  {
    id: 'avatar-2',
    src: '/assets/avatars/2.svg'
  },
  {
    id: 'avatar-3',
    src: '/assets/avatars/3.svg'
  },
  {
    id: 'avatar-4',
    src: '/assets/avatars/4.svg'
  },
  {
    id: 'avatar-5',
    src: '/assets/avatars/5.svg'
  },
  {
    id: 'avatar-6',
    src: '/assets/avatars/6.svg'
  },
  {
    id: 'avatar-7',
    src: '/assets/avatars/7.svg'
  },
  {
    id: 'avatar-8',
    src: '/assets/avatars/8.svg'
  },
  {
    id: 'avatar-9',
    src: '/assets/avatars/9.svg'
  },
  {
    id: 'avatar-10',
    src: '/assets/avatars/10.svg'
  },
  {
    id: 'avatar-11',
    src: '/assets/avatars/11.svg'
  },
  {
    id: 'avatar-12',
    src: '/assets/avatars/12.svg'
  },
  {
    id: 'avatar-13',
    src: '/assets/avatars/13.svg'
  },
  {
    id: 'avatar-14',
    src: '/assets/avatars/14.svg'
  },
  {
    id: 'avatar-15',
    src: '/assets/avatars/15.svg'
  },
  {
    id: 'avatar-16',
    src: '/assets/avatars/16.svg'
  },
  {
    id: 'avatar-17',
    src: '/assets/avatars/17.svg'
  },
  {
    id: 'avatar-18',
    src: '/assets/avatars/18.svg'
  },
  {
    id: 'avatar-19',
    src: '/assets/avatars/19.svg'
  },
  {
    id: 'avatar-20',
    src: '/assets/avatars/20.svg'
  },
  {
    id: 'avatar-21',
    src: '/assets/avatars/21.svg'
  },
  {
    id: 'avatar-22',
    src: '/assets/avatars/22.svg'
  },
  {
    id: 'avatar-23',
    src: '/assets/avatars/23.svg'
  },
  {
    id: 'avatar-24',
    src: '/assets/avatars/24.svg'
  },
  {
    id: 'avatar-25',
    src: '/assets/avatars/25.svg'
  },
  {
    id: 'avatar-26',
    src: '/assets/avatars/26.svg'
  },
  {
    id: 'avatar-27',
    src: '/assets/avatars/27.svg'
  },
  {
    id: 'avatar-28',
    src: '/assets/avatars/28.svg'
  },
  {
    id: 'avatar-29',
    src: '/assets/avatars/29.svg'
  },
  {
    id: 'avatar-30',
    src: '/assets/avatars/30.svg'
  },
  {
    id: 'avatar-31',
    src: '/assets/avatars/31.svg'
  },
  {
    id: 'avatar-32',
    src: '/assets/avatars/32.svg'
  },
  {
    id: 'avatar-33',
    src: '/assets/avatars/33.svg'
  },
  {
    id: 'avatar-34',
    src: '/assets/avatars/34.svg'
  },
  {
    id: 'avatar-35',
    src: '/assets/avatars/35.svg'
  },
  {
    id: 'avatar-36',
    src: '/assets/avatars/36.svg'
  },
  {
    id: 'avatar-37',
    src: '/assets/avatars/37.svg'
  },
  {
    id: 'avatar-38',
    src: '/assets/avatars/38.svg'
  },
  {
    id: 'avatar-39',
    src: '/assets/avatars/39.svg'
  },
  {
    id: 'avatar-40',
    src: '/assets/avatars/40.svg'
  },
  {
    id: 'avatar-41',
    src: '/assets/avatars/41.svg'
  },
  {
    id: 'avatar-42',
    src: '/assets/avatars/42.svg'
  },
  {
    id: 'avatar-43',
    src: '/assets/avatars/43.svg'
  },
  {
    id: 'avatar-44',
    src: '/assets/avatars/44.svg'
  },
  {
    id: 'avatar-45',
    src: '/assets/avatars/45.svg'
  },
  {
    id: 'avatar-46',
    src: '/assets/avatars/46.svg'
  },
  {
    id: 'avatar-47',
    src: '/assets/avatars/47.svg'
  },
  {
    id: 'avatar-48',
    src: '/assets/avatars/48.svg'
  },
  {
    id: 'avatar-49',
    src: '/assets/avatars/49.svg'
  },
  {
    id: 'avatar-50',
    src: '/assets/avatars/50.svg'
  },
  {
    id: 'avatar-51',
    src: '/assets/avatars/51.svg'
  },
  {
    id: 'avatar-52',
    src: '/assets/avatars/52.svg'
  },
  {
    id: 'avatar-53',
    src: '/assets/avatars/53.svg'
  },
  {
    id: 'avatar-54',
    src: '/assets/avatars/54.svg'
  },
  {
    id: 'avatar-55',
    src: '/assets/avatars/55.svg'
  },
  {
    id: 'avatar-56',
    src: '/assets/avatars/56.svg'
  },
  {
    id: 'avatar-57',
    src: '/assets/avatars/57.svg'
  },
  {
    id: 'avatar-58',
    src: '/assets/avatars/58.svg'
  },
  {
    id: 'avatar-59',
    src: '/assets/avatars/59.svg'
  },
  {
    id: 'avatar-60',
    src: '/assets/avatars/60.svg'
  },
  {
    id: 'avatar-61',
    src: '/assets/avatars/61.svg'
  },
  {
    id: 'avatar-62',
    src: '/assets/avatars/62.svg'
  },
  {
    id: 'avatar-63',
    src: '/assets/avatars/63.svg'
  },
  {
    id: 'avatar-64',
    src: '/assets/avatars/64.svg'
  },
  {
    id: 'avatar-65',
    src: '/assets/avatars/65.svg'
  },
  {
    id: 'avatar-66',
    src: '/assets/avatars/66.svg'
  },
  {
    id: 'avatar-68',
    src: '/assets/avatars/68.svg'
  },
  {
    id: 'avatar-70',
    src: '/assets/avatars/70.svg'
  },
  {
    id: 'avatar-71',
    src: '/assets/avatars/71.svg'
  },
  {
    id: 'avatar-72',
    src: '/assets/avatars/72.svg'
  },
  {
    id: 'avatar-73',
    src: '/assets/avatars/73.svg'
  },
  {
    id: 'avatar-74',
    src: '/assets/avatars/74.svg'
  },
  {
    id: 'avatar-75',
    src: '/assets/avatars/75.svg'
  },
  {
    id: 'avatar-76',
    src: '/assets/avatars/76.svg'
  },
  {
    id: 'avatar-77',
    src: '/assets/avatars/77.svg'
  },
  {
    id: 'avatar-78',
    src: '/assets/avatars/78.svg'
  },
  {
    id: 'avatar-79',
    src: '/assets/avatars/79.svg'
  },
  {
    id: 'avatar-80',
    src: '/assets/avatars/80.svg'
  },
  {
    id: 'avatar-81',
    src: '/assets/avatars/81.svg'
  },
  {
    id: 'avatar-82',
    src: '/assets/avatars/82.svg'
  },
  {
    id: 'avatar-83',
    src: '/assets/avatars/83.svg'
  },
  {
    id: 'avatar-84',
    src: '/assets/avatars/84.svg'
  },
  {
    id: 'avatar-85',
    src: '/assets/avatars/85.svg'
  },
  {
    id: 'avatar-86',
    src: '/assets/avatars/86.svg'
  },
  {
    id: 'avatar-87',
    src: '/assets/avatars/87.svg'
  },
  {
    id: 'avatar-88',
    src: '/assets/avatars/88.svg'
  },
  {
    id: 'avatar-89',
    src: '/assets/avatars/89.svg'
  },
  {
    id: 'avatar-90',
    src: '/assets/avatars/90.svg'
  },
  {
    id: 'avatar-91',
    src: '/assets/avatars/91.svg'
  },
  {
    id: 'avatar-92',
    src: '/assets/avatars/92.svg'
  },
  {
    id: 'avatar-93',
    src: '/assets/avatars/93.svg'
  },
  {
    id: 'avatar-94',
    src: '/assets/avatars/94.svg'
  },
  {
    id: 'avatar-95',
    src: '/assets/avatars/95.svg'
  },
  {
    id: 'avatar-96',
    src: '/assets/avatars/96.svg'
  },
  {
    id: 'avatar-97',
    src: '/assets/avatars/97.svg'
  },
  {
    id: 'avatar-98',
    src: '/assets/avatars/98.svg'
  },
  {
    id: 'avatar-99',
    src: '/assets/avatars/99.svg'
  },
  {
    id: 'avatar-100',
    src: '/assets/avatars/100.svg'
  },
  {
    id: 'avatar-101',
    src: '/assets/avatars/101.svg'
  },
  {
    id: 'avatar-102',
    src: '/assets/avatars/102.svg'
  },
  {
    id: 'avatar-103',
    src: '/assets/avatars/103.svg'
  },
  {
    id: 'avatar-105',
    src: '/assets/avatars/105.svg'
  },
  {
    id: 'avatar-106',
    src: '/assets/avatars/106.svg'
  },
  {
    id: 'avatar-107',
    src: '/assets/avatars/107.svg'
  },
  {
    id: 'avatar-108',
    src: '/assets/avatars/108.svg'
  },
  {
    id: 'avatar-110',
    src: '/assets/avatars/110.svg'
  }
]

export {
  avatars
}
