<template>
  <div>
    <input type="checkbox"
           v-model="inputValue"
           class="input-switch"
           id="custom-switch"/>
    <label for="custom-switch" class="switch-label">
      <div class="toggle">
        <b-icon icon="check" v-if="inputValue" class="toggle__check"/>
        <b-icon icon="x" v-else class="toggle__x"/>
      </div>
    </label>
  </div>
</template>

<script >
export default {
  name: 'CustomSwitch',
  props: {
    value: {
      type: [Boolean, Number],
      require: true
    }
  },
  computed: {
    inputValue: {
      get () {
        return !this.value
      },
      set (newValue) {
        this.$emit('input', !newValue)
      }
    }
  }
}
</script>

<style lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
color-switch-green = #9ae483
color-switch-purple = #7962ce

.input-switch[type=checkbox]
  height 0
  width 0
  display none

.switch-label
  cursor pointer
  width 50px
  height 25px
  background color-switch-purple
  display block
  border-radius 100px
  position relative

  .toggle
    position absolute
    top 3px
    left 3px
    width 40%
    height 78%
    background white
    border-radius 90px
    transition 0.3s
    display flex
    justify-content center
    align-items center
    font-size 22px

    .toggle__check
      color color-switch-green

    .toggle__x
      color color-switch-purple

.input-switch:checked + .switch-label
  background color-switch-green

.input-switch:checked + .switch-label .toggle
  left calc(100% - 3px)
  transform translateX(-100%)
</style>
