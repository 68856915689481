import { http } from './http'
import qs from 'qs'

const resource = "/api/teacher";

export default {
  getActiveClassrooms () {
    return http.get(`${resource}/last-active-classrooms`).then((response) => {
        return response
    })
  },
  createNewRequest (data = {}) {
    return http.post(`${resource}/librarian-connection-request`, data).then(response => {
        return response
    })
  },
  getMyRequests () {
    return http.get(`${resource}/librarian-connection-request`).then(response => {
        return response
    })
  },
  updateRequest (data = {}) {
    return http.put(`${resource}/librarian-connection-request/${data.requestId}`, data.requestData).then(response => {
        return response
    })
  },
  getRequestById (requestId) {
    return http.get(`${resource}/librarian-connection-request/${requestId}`).then(response => {
        return response
    })
  },
  deleteRequest (requestId) {
    return http.delete(`${resource}/librarian-connection-request/${requestId}`).then(response => {
        return response
    })
  },
  getConnectionsRequestNotHidden() {
    return http.get(`${resource}/librarian-connection-request/not-hidden`).then(response => {
      return response
    })
  },
  getConnectionsRequestHidden() {
    return http.get(`${resource}/librarian-connection-request/hidden`).then(response => {
      return response
    })
  },
  hideRequest (requestId) {
    return http.post(`${resource}/librarian-connection-request/${requestId}/create-hidden`).then(response => {
      return response
    })
  },
  showRequest (requestId) {
    return http.delete(`${resource}/librarian-connection-request/${requestId}/delete-hidden`).then(response => {
      return response
    })
  },
  trackingRequestViews(requestId) {
    return http.put(`${resource}/librarian-connection-request/${requestId}/views-number`).then(response => {
      return response
    })
  },
  connectWithLibrarian(requestId) {
    return http.post(`${resource}/librarian-connection-request/${requestId}/connect`).then(response => {
      return response
    })
  },
  getLibrarianCourses(filters) {
    const queryString = qs.stringify({ filters: filters })
    return http.get(`${resource}/librarian-connection-request/courses?${queryString}`).then(response => {
      return response
    })
  },
  addTeacherToClassroom(classroomId, data) {
    return http.post(`${resource}/classrooms/${classroomId}/teacher-assistant`, data).then(response => {
      return response
    })
  }
}
