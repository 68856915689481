<template>
  <div>
    <modal ref="modal"
           id="purchase-packages-modal"
           :hideCloseButton="true">
      <template #body>
        <div class="main-body">
          <div class="first-content">
            <div class="content-description">
              <h2 class="content-description__title">Purchase Exchanges*</h2>
            </div>
          </div>
          <div class="second-content">
            <div class="account-balance-wrapper">
              <div class="account-balance">
                <p class="account-balance__text">Current Balance</p>
                <p class="account-balance__token-number" v-if="selectedSubject.id">{{ subjectBalance.balance_tokens }}</p>
                <p class="account-balance__token-text" v-if="selectedSubject.id">{{selectedSubject.name}} Exchanges(s)</p>
              </div>
            </div>
            <div class="exchange">
              <b-nav-item-dropdown>
                <template #button-content>
                  {{selectedSubject.id ? selectedSubject.name : 'Select Subject...'}}
                </template>
                <b-dropdown-item v-for="subject in subjects"
                                 :key="subject.id"
                                 @click="selectedSubject = subject">
                  {{subject.name}}
                </b-dropdown-item>
              </b-nav-item-dropdown>
              <div class="exchange-content">
                <div class="label-wrapper">
                  <div class="label-content">
                    <p class="label-content__text">Number of Exchanges</p>
                    <p class="label-content__text">Price per Exchange</p>
                    <p class="label-content__text">Total Purchase</p>
                  </div>
                </div>
                <div class="packages-carousel">
                  <div class="packages-content">
                    <bundle-card @input="onPackageSelected"
                                :selected-subject-id="selectedSubject.id"
                                @on-exchange-selected="(val) => exchangeSelected = val"
                                @amount-tokens="(val) => amountTokens = val"
                                :bundle-id="1"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <div class="footer-actions">
            <b-button class="button button--cancel"
                      @click="hidePurchaseBundlesModal"
                      btn-text="Cancel"/>
            <b-button class="button button--purchase"
                      :disabled="!selectedSubject.id || amountTokens === 0"
                      btn-text="Purchase"
                      @click="onShowFormPayment"/>
          </div>
          <div class="footer-information">
            <p>*All prices are in U.S. Dollars</p>
            <p>All tokens expire 2 years from purchase</p>
            <p>If you are interested in buying 10 or more exchanges, you may be eligible for a discount. </p>
            <p>Please <a href="mailto: support@levelupvillage.com" style="text-decoration: underline">contact</a> your sales representative.</p>
          </div>
        </div>
        <b-overlay no-wrap :show="loading">
          <template #overlay>
            <p class="overlay-text">Purchase in progress...</p>
          </template>
        </b-overlay>
      </template>
    </modal>
    <form-payment id="form-payment" ref="form-payment" @on-to-pay="onPurchase"/>
  </div>
</template>

<script>
import {Modal} from "../Modal"
import {Button} from "@/core/components/Button"
import BundleCard from "./BundleCard.vue"
import {UtilsMixins, ClassroomMixins} from "@/core/Mixins"
import {ExchangesService} from '@/core/Services'
import FormPayment from './FormPayment.vue'

export default {
  name: 'PurchaseBundlesModal',
  mixins: [UtilsMixins, ClassroomMixins],
  components: {
    Modal,
    BundleCard,
    FormPayment,
    'b-button': Button
  },
  props: {
    subjectId: {
      type: Number,
    },
    showNotYetMessage: {
      type: Boolean,
      default: false
    },
    reloadAfterPurchase: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      bundleSelectedId: null,
      selectedSubjectInit: {
        id: null,
        name: '',
        number_of_tokens: '',
        type: ''
      },
      exchangeSelected: {},
      selectedSubject: {},
      subjectBalance: {},
      amountTokens: 0,
      noYetMessage: `Click OK to cancel setting up this classroom exchange.
      You will still have access to the LUV community resources.
      When you are ready to connect with a global partner,
      create a new classroom exchange by using the My Classrooms menu.`
    }
  },
  computed: {
  },
  methods: {
    onPackageSelected(bundleSelected) {
      this.bundleSelectedId = bundleSelected
    },
    async showPurchaseBundlesModal() {
      await this.$refs.modal.showModal()
      if (this.subjectId) {
        this.selectedSubject = this.subjects.find((subject) => subject.id === this.subjectId)
      }
    },
    hidePurchaseBundlesModal() {
      if (this.showNotYetMessage) {
        this.$bvModal.msgBoxConfirm(this.noYetMessage, {
          size: 'sm',
          dialogClass: "modal-message-box purchase-center",
          noCloseOnBackdrop: true,
          centered: true,
          cancelTitle: 'Back',
        }).then((res) => {
          if (res) {
            return this.goToMyClassroomsPage()
          }
        })
        return
      }
      this.selectedSubject = this.selectedSubjectInit
      this.bundleSelectedId = null
      this.$refs.modal.hideModal()
    },
    onShowFormPayment() {
      this.$bvModal.show('form-payment')
    },
    onHideFormPayment() {
      this.$bvModal.hide('form-payment')
    },
    async onPurchase(form) {
      this.loading = true

      const data = {
        ...form,
        amount_tokens: this.amountTokens,
        exchange_token: {
          id: this.exchangeSelected.id,
          subject_id: this.exchangeSelected.subject_id,
          country_id: this.exchangeSelected.country_id,
          price: this.exchangeSelected.price
        }
      }
      try {
        await ExchangesService.purchaseExchangeTokens(data).then(res => {
          if (res.success) {
            const message = data.payment_method_id === 2 ? 'Thank you for your purchase. A receipt has been sent to your email.'
              : 'Thank you for your order. An invoice will be sent to the contact information provided.'
            this.$bvModal.msgBoxOk(message, {
              size: 'sm',
              dialogClass: "modal-message-box purchase-center",
              noCloseOnBackdrop: true,
              centered: true
            }).then(async () => {
              this.$emit('on-purchase-success')
              await this.onHideFormPayment()
              if (this.reloadAfterPurchase) {
                window.location.reload()
              }
            })
          } else {
            this.$refs["form-payment"].init_form()
            let errorMessage
            errorMessage = res.message ? res.message : 'Error fetching connection request'
            errorMessage = res.message === 'The cradict card number is not valid' ?
              'The credit card information entered is not valid' : errorMessage

            this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          }
        })
      } catch (e) {
        this.$refs["form-payment"].init_form()
        const errorMessage = e.message ? e.message : 'Error fetching connection request'

        this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    selectedSubject(newSubject) {
      this.subjectBalance = this.user.exchange_tokens_balance.find((exchange) => exchange.id === newSubject.id)
    }
  }
}
</script>

<style lang="stylus">
@import "~@/core/styles/variables.styl"

.overlay-text
  font font-opensans-bold
  font-size 24px
  color color-8
  text-transform uppercase

#purchase-packages-modal
  .first-content
    padding 20px 0px 0px 0px

    .content-description
      .content-description__title
        font font-opensans-bold
        font-size 24px
        color color-8
        text-transform uppercase

  .second-content
    display flex
    justify-content center
    align-items center
    flex-direction column
    @media (min-width 600px)
      flex-direction row

    .account-balance-wrapper
      padding 10px
      display flex
      justify-content flex-end
      // max-width 400px
      @media (min-width 600px)
        order 2
        width 40%

      .account-balance
        background-color #fff
        width 220px
        max-width 220px
        min-height 160px
        text-align center
        border-radius 6px
        box-shadow 0 3px 6px rgba(0,0,0,0.161)

        .account-balance__text
          font font-opensans-bold
          padding 10px 15px
          border-bottom 1px black solid

        .account-balance__token-number
          font font-opensans-bold
          font-size 36px
          padding 10px

        .account-balance__token-text
          font font-opensans-semibold
          font-size 14px
          padding-bottom 10px

    .exchange
      .nav-item
        list-style none
        box-shadow 0 3px 6px #00000029
        width 280px
        margin auto
        margin-bottom 50px
        margin-top 50px
        border-radius 6px

        @media (min-width 600px)
          margin-top 0
          position relative
          left 80px

        .dropdown-toggle
          color #000
          font font-opensans-semibold
          font-size 14px

        .nav-link
          text-overflow ellipsis
          white-space nowrap
          overflow hidden
          max-width 280px

          &::after
            position absolute
            right 0
            margin-right 10px
            margin-top 8px

        .dropdown-menu
          background-color color-1
          box-shadow 0 3px 6px #00000029
          border 2px solid color-16
          border-radius 6px
          padding 0
          margin 0
          width 280px
          top 4px !important

          & > li
            border-bottom 2px solid color-16

            &:last-child
              border 0

            a
              color #262626
              font font-opensans-semibold
              font-size 14px
              padding 0.5rem 1rem
              text-overflow ellipsis
              white-space nowrap
              overflow hidden
              max-width 280px

              &:focus
              &:hover
              &:active
                background-color white

      .exchange-content
        display flex
        justify-content center

        .label-wrapper
          // width 40%
          padding-top 15px
          padding-right 25px
          display flex
          flex-direction column
          align-items flex-end

          .label-content
            width 100%

            .label-content__text
              font font-opensans-bold
              font-size 14px
              margin 0
              margin-bottom 40px

        .packages-carousel
          .packages-content
            display flex

  .footer
    .footer-actions
      display flex
      flex-direction column
      justify-content center
      max-width 400px
      @media (min-width 350px)
        flex-direction row
        max-width none
        width 100%

      .button
        margin 6px 6px
        color black
        @media (min-width 450px)
          margin 6px 40px

      .button--cancel
        font-size 14px
        background-color color-12
        border-color color-12

      .button--purchase
        background-color color-31
        border-color color-31

    .footer-information
      text-align center
      margin-top 40px

      p
        font font-opensans-semibold
        font-size 12px
        margin-bottom 0
        @media (min-width 600px)
          font-size 14px


#purchase-packages-modal
  .modal-dialog
    width 90%
    max-width 1100px
    margin 0 auto

  .modal-footer
    margin-bottom 10px

.purchase-center
  margin 0 auto !important

#purchase-packages-modal___BV_modal_outer_
  .modal-backdrop
    min-width 480px
    height 100%

#purchase-packages-modal
  @media (min-width 600px)
    .first-content
      display flex
      margin-bottom 20px

      .content-description
        width 50%

  @media (min-width 470px)
    .second-content
      .label-wrapper
        .label-content
          width 80%

  @media (min-width 705px)
    .modal-dialog
      .body
        padding-left 40px
        padding-right 40px

      .modal-footer
        width 100%
        justify-content normal !important
        padding-left 40px
        padding-right 40px

    .second-content
      .label-wrapper
        .label-content
          .label-content__text
            font-size 18px

    .footer
      width 100%

      .footer-information
        text-align left

  @media (min-width 1000px)
    .second-content
      .label-wrapper
        .label-content
          width 60%

  @media (min-width 1070px)
    .second-content
      .packages-carousel
        .packages-content
          &::-webkit-scrollbar
            display none
</style>
