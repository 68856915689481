<template>
  <modal modal-class="library-request-detail"
         :hide-footer="true"
         @hide="hideModal"
         id="library-request-detail"
         ref="library-request-detail">
    <template #body>
      <b-avatar class="avatar"
                size="10rem"
                :src="avatar"/>
      <p class="subtitle">About Me</p>
      <p class="text">{{aboutMe}}</p>
      <p class="subtitle">Request Description</p>
      <p class="text">{{requestText}}</p>
      <p class="subtitle">Number of Students: <span class="number-student">{{requestDetail.numberStudent}}</span></p>
      <p class="subtitle">Student age range: <span class="student-ages">{{studentAges}}</span></p>
      <p class="subtitle">Course: <span class="student-ages">{{course}}</span></p>
      <p class="subtitle">Topics of interest</p>
      <p class="text" v-for="topic in topics" :key="topic.id">- {{topic.name}}</p>
      <div class="container-buttons">
        <b-button :disabled="false"
                  class="button"
                  @click="$bvModal.hide('library-request-detail')"
                  btn-text="Close"/>
        <b-button class="button button--connect"
                  @click="connect"
                  btn-text="Connect"/>
      </div>
      <b-overlay no-wrap :show="loading"/>
    </template>
  </modal>
</template>

<script>
import {Button} from "@/core/components/Button"
import Modal from '@/core/components/Modal/Modal'
import {LibrarianService} from '@/core/Services'

export default {
  name: 'LibraryRequestDetail',
  components: {
    'b-button': Button,
    Modal
  },
  props: {
    requestDetail: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  data() {
    return {
      avatar: '',
      aboutMe: '',
      requestText: '',
      studentAges: '',
      course: '',
      topics: [],
      loading: false
    }
  },
  methods: {
    open () {
      this.loading = true
      LibrarianService.getRequestById(this.requestDetail.id).then((res) => {
        if (res.success) {
          const request = res?.data
          this.avatar = request.avatar
          this.aboutMe = request.about_me
          this.requestText = request.text_request
          this.course = request.course?.name
          this.topics = request.topics.length ? request.topics : [{name: 'Any'}]
          this.studentAges = request?.age_range?.name
        } else {
          const errorMessage = res.message ? res.message : 'Error get request.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      }).finally(() => this.loading = false)
      this.$bvModal.show('library-request-detail')
    },
    hideModal () {
    },
    connect () {
      this.$emit('connect-librarian', this.requestDetail)
      this.$bvModal.hide('library-request-detail')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#library-request-detail
  .avatar-list-container
    overflow-y scroll
    padding 5px
    padding-bottom 15px
    max-height 65vh

  .title
    font font-opensans-bold
    font-size 22px
    text-align center

  .subtitle
    font font-opensans-semibold
    margin 0
    margin-bottom 5px
    margin-top 12px

  .student-ages, .number-student
    font font-opensans-regular
    font-size 14px

  .container-buttons
    width 100%
    display flex
    align-items flex-end
    height 100%
    justify-content space-between
    flex-wrap wrap
    margin-top 60px

    .button
      font font-opensans-semibold
      text-transform capitalize
      height fit-content

    .button--connect
      margin 0

::v-deep
  .custom-checkbox-primary
    column-count 1 !important

    .custom-control-inline
      display flex

      .custom-control-label
        font-size 15px
</style>
<style lang="stylus">
.library-request-detail
  .modal-body
    .body
      padding 3rem
      @media (max-width 400px)
        padding 1rem
</style>
