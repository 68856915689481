<template>
  <div>
    <div class="form-group-attach">
      <div v-if="attachments.length === 0" class="form-group-attach-list">
        <span v-if="placeholder" class="placeholder">{{ placeholder }}</span>
      </div>

      <div v-else class="form-group-attach-links">
        <b-list-group v-if="thumbnail" horizontal class="attachment-list-group">
          <b-list-group-item
            v-for="(attachment,index) in attachments"
            :key="`attach-file-${_uid}_${index}`"
            target="_blank">
            <span v-if="attachment.type === 'transcoder'" @click="onClickVideo(attachment)" class="attachment-item attachment-item--video">
              <thumbnail-attachment :data="attachment"/>
              <b-tooltip :target="`attach-file-${_uid}_${index}`" triggers="hover" placement="bottom">
                {{ attachment.label }}
              </b-tooltip>
              <div class="attachment-item__label" :id="`attach-file-${_uid}_${index}`">{{ truncateString(attachment.label, 20) }}</div>
            </span>
            <a v-else :href="attachment.url" target="_blank" class="attachment-item">
              <thumbnail-attachment :data="attachment"/>
              <b-tooltip :target="`attach-file-${_uid}_${index}`" triggers="hover" placement="bottom">
                {{ attachment.label }}
              </b-tooltip>
              <div class="attachment-item__label" :id="`attach-file-${_uid}_${index}`">{{ truncateString(attachment.label, 20) }}</div>
            </a>
            <span v-if="attachment.type !== 'transcoder'" class="icon-delete" @click.self="onDeleteAttachment(attachment)"/>
          </b-list-group-item>
        </b-list-group>
        <ul v-else>
          <li :key="`attach-file-${_uid}_${index}`" v-for="(attachment, index) in attachments">
            <a target="_blank" class="text-normal" :href="attachment.url">{{ attachment.label }}</a>
            <button type="button" @click="onDeleteAttachment(attachment)"><span class="icon-delete"></span></button>
          </li>
        </ul>
      </div>

      <div :class="classGroupActions">
        <upload-file v-if="!onlyAttach"
                     :errors="errors"
                     :show-errors="showErrors"
                     @error="onFileError"
                     @File:loaded="onFileChanged"></upload-file>
        <b-button @click="onCreateAttachLink" btn-text="Attach " class="btn-attach" small>
          <template #icon-after>
            <span class="icon-attach"></span>
          </template>
        </b-button>
      </div>

      <modal modal-class="modal-attach-link" ref="modalAttachLink">
        <template #body>
          <div class="modal-attach-link-body">
            <h3 class="title">Attach Link</h3>
            <attach-link v-model="links"></attach-link>
          </div>
        </template>
        <template #footer>
          <b-button @click="onSaveLinks" :disabled="!links.length" btn-text="Save"/>
          <b-button @click="onCloseLinksModal" btn-text="Cancel"/>
        </template>
      </modal>
    </div>
    <div class="invalid-feedback" v-if="hasErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`file-group-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import UploadFile from "./UploadFile";
import {Modal} from "@/core/components/Modal";
import AttachLink from "@/core/components/UploadFileAttachLink/AttachLink";
import {Button} from "@/core/components/Button";
import ThumbnailAttachments from "@/core/components/Thumbnail/ThumbnailAttachments";


export default {
  name: "UploadFileAttachGroup",
  components: {
    AttachLink,
    UploadFile,
    Modal,
    'b-button': Button,
    'thumbnail-attachment': ThumbnailAttachments,
  },
  data () {
    return {
      links: [],
      errors: []
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    positionButtons: {
      type: String,
      default: 'end'
    },
    placeholder: {
      type: String,
      default: null
    },
    onlyAttach: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    thumbnail: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onClickVideo(attachment) {
      this.$emit('click-video', attachment)
    },
    onCreateAttachLink () {
      this.$refs.modalAttachLink.showModal()
    },
    onCloseLinksModal () {
      this.$refs.modalAttachLink.hideModal()
    },
    onDeleteAttachment (attachment) {
      this.$emit('Attachment:delete', attachment)
    },
    onSaveLinks () {
      if (this.links.length > 0) {
        this.links = this.links.map((link) => {
          link.type = 'file',
          link.original_file = link.label
          return link
        })
        this.attachments = [...this.value, ...this.links]
        this.onCloseLinksModal()
        this.links = []
      }
    },
    onFileError ({message}) {
      //this.errors = []
      this.errors.push(message)
    },
    onFileChanged (files) {
      const file = {
        url: URL.createObjectURL(files[0]),
        label: files[0].name,
        file: files[0]
      }

      this.attachments.push(file)
    },
    truncateString(str, num) {
      return str.length > num ? str.slice(0, num) + "..." : str
    },
  },
  computed: {
    classGroupActions () {
      const clazz = {
        'form-group-attach-actions': true
      }

      if (this.positionButtons) {
        clazz[`align-items-${this.positionButtons}`] = !!this.positionButtons
      }

      return {...clazz}
    },
    attachments: {
      get () {
        return this.value ?? []
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    hasErrors () {
      return this.errors.length > 0
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.invalid-feedback
  display block !important

.modal-attach-link-body

  h3
    font font-opensans-bold
    color color-8
    font-size 24px
    text-align center

.form-group-attach
  margin-bottom 1rem
  display flex
  flex-direction column
  border 2px solid color-16
  min-height 90px
  padding 10px 10px 10px 15px
  border-radius 4px

  &-list
    flex 1

    .placeholder
      font font-opensans-regular
      font-size 14px
      color color-8

  &-links
    flex 1

    ul
      padding 0
      margin 0
      list-style-type none
      display block

      & > li
        border 0
        padding 0
        display block
        color color-3

        a
          font font-opensans-bold
          font-size 14px
          color color-34
          text-transform uppercase

        button
          border 0
          background-color transparent

          span
            font-size 0.7rem

            &:before
              color color-17

  &-actions
    display flex
    align-self flex-end
    margin-top 10px

    ::v-deep
      .form-upload
        margin-bottom 0
        margin-right 10px

        .form-upload-file
          border 0
          padding 0
          margin 0
          min-height unset
          margin-right 5px

    .btn-attach
      display inline-block
      font-size 12px
      padding-bottom 1px
      padding-top 1px
      min-height unset
      height unset
      text-transform capitalize

.attachment-list-group
  overflow-x auto

  .list-group-item
    border 2px solid color-3 !important
    padding 0
    margin-right 10px

    .attachment-item
      width unset
      display flex
      flex-direction column
      align-items center
      position relative
      padding 0.75rem 1.25rem

      .attachment-item__label
        margin-top 6px
        font font-opensans-semibold
        font-size 14px
        color black
        text-transform uppercase

    .attachment-item--video
      cursor pointer

    .icon-delete
      position absolute
      font-size 0.8rem
      right 6px
      top 8px
      cursor pointer

      &:before
        color color-17

</style>
