<template>
  <div class="wrapper-classroom-page">
    <b-container class="classroom-page-container">
      <b-row>
        <b-col cols="12" v-if="!loading">
          <h2 class="security-form-subtitle mb-4">SELECT A COURSE FOR YOUR CLASSROOM</h2>
          <h2 class="security-form-subtitle security-form-subtitle--normal mb-1">{{ classroom.name }}</h2>
          <div class="wrapper-classroom-content">
            <courses-list :default_filter_by="filter_by"
                          :default_search_string="search_string"
                          :classroom_id="classroom.id" @on-course-add="addCourseContent"></courses-list>
          </div>

          <div class="security-form-container-btns c-btns-secondary mt-0">
            <b-button @click="goToMyClassroomsPage" btn-text="Skip"/>
          </div>

        </b-col>
      </b-row>
      <b-overlay no-wrap :show="loading || sendingCourse"></b-overlay>
    </b-container>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button";
import CoursesList from "@/core/components/CoursesList";
import {UserMixins} from "@/modules/Classroom/Mixins";
import {ClassroomMixins, UtilsMixins} from "@/core/Mixins";

export default {
  name: "ChooseCourseContentPage",
  mixins: [UtilsMixins, ClassroomMixins, UserMixins],
  components: {
    'b-button': Button,
    CoursesList
  },
  data() {
    return {
      loading: true,
      sendingCourse: false,
      filter_by: 'all',
      search_string: '',
    }
  },
  mounted: async function () {
    await this.getClassroom(this.classroomId)
    // If the classroom subject is World Languages, we would like to default to the Level filter in this view for selecting a course.
    // Otherwise, the default filter should be Subject
    if (this.isWorldLanguage) {
      this.filter_by = 'level'
      // if the classroom subject is World Languages, we would like the filter to show the courses based on the level selected in the prior step.
      // Otherwise, the filter should show courses matching the subject selected in the wizard
      this.search_string = this.classroom.level_id
    } else {
      this.filter_by = 'subject'
      this.search_string = this.classroom.subject_id
    }

    this.loading = false
  },
  methods: {
    async addCourseContent(selectedCourse) {
      if (!this.sendingCourse) {
        this.sendingCourse = true
        try {
          await this.setCourse({
            classroom_id: this.classroom.id,
            course_id: selectedCourse.id
          })

          this.goToClassroomPage(this.classroom.id)
        } catch(e) {
          const errorMessage = e.message ? e.message : 'Error adding course content.'
          this.$bvModal.msgBoxOk(errorMessage, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          })
        } finally {
          this.sendingCourse = false
        }
      }
    },
  },
  watch: {
    '$route.params.classroomId': {
      handler: function (classroomId) {
        if (typeof classroomId === 'string') {
          this.classroomId = parseInt(classroomId);
        } else {
          this.classroomId = classroomId;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.security-form-subtitle--normal
  font font-opensans-regular
  font-size 20px
  margin-bottom 30px !important

.modal-title
  margin-top 80px

.container-courses
  position relative

#course-pdf-modal
  iframe
    height calc(100vh - 222px)
</style>
