import Vue from 'vue'
import Vuex from 'vuex'
import MyClassroomsModule from "@/modules/MyClassrooms/Storage";
import ClassroomModule from "@/modules/Classroom/Storage";
import CourseModule from "@/core/Store/Course";
import UserModule from "@/core/Store/User";
import MiscModule from "@/core/Store/Misc";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    MyClassroomsModule: MyClassroomsModule,
    ClassroomModule: ClassroomModule,
    CourseModule: CourseModule,
    UserModule: UserModule,
    MiscModule: MiscModule,
  }
})
