import {ClassroomService} from '@/core/Services'
import {
  CREATE_CLASSROOM_START,
  CREATE_CLASSROOM_DATA,
  FETCH_CLASSROOM_START,
  FETCH_CLASSROOM_DATA,
  CLASSROOM_EDIT_START,
  CLASSROOM_EDIT_SAVE,
  CLASSROOM_ADD_CONTENT_START,
  CLASSROOM_ADD_CONTENT_SAVE,
  FETCH_CLASSROOM_CHAT_MESSAGES_START,
  FETCH_CLASSROOM_CHAT_MESSAGES_DATA,
  FETCH_TASK_LIST_START,
  FETCH_TASK_LIST_DATA,
  TASK_EDIT_VISIBILITY_START,
  TASK_EDIT_VISIBILITY_END,
  CREATE_TASK_START,
  CREATE_TASK_DATA,
  TASK_EDIT_START,
  TASK_EDIT_SAVE
} from "@/modules/Classroom/Storage/mutation-types";

export const actions = {
  createClassroom({commit}, form = {}) {
    commit(CREATE_CLASSROOM_START)

    return ClassroomService.create(form).then(response => {
      commit(CREATE_CLASSROOM_DATA, response.data)

      return response
    })
  },
  getClassroom({commit}, id = Number) {
    commit(FETCH_CLASSROOM_START)

    return ClassroomService.get(id).then(response => {
      commit(FETCH_CLASSROOM_DATA, response.data)

      return response
    })
  },
  getClassroomNoOverlay({commit}, id = Number) {
    return ClassroomService.get(id).then(response => {
      commit(FETCH_CLASSROOM_DATA, response.data)

      return response
    })
  },
  updateClassroom({commit}, augments = {id: 0, form: {}}) {
    commit(CLASSROOM_EDIT_START)

    return ClassroomService.update(augments).then(response => {
      commit(CLASSROOM_EDIT_SAVE, response.data)

      return response
    })
  },
  addCourseContent({commit}, augments = {classroom_id: 0, course_id: 0}) {
    commit(CLASSROOM_ADD_CONTENT_START)

    return ClassroomService.addCourseContent(augments).then(response => {
      commit(CLASSROOM_ADD_CONTENT_SAVE)

      return response
    })
  },
  createTask({commit}, augments = {classroom_id: 0, form: {}}) {
    commit(CREATE_TASK_START)

    return ClassroomService.createTask(augments).then(response => {
      commit(CREATE_TASK_DATA, response.data)

      return response
    })
  },
  updateTask({commit}, augments = {classroom_id: 0, task_id: 0, form: {}}) {
    commit(TASK_EDIT_START)

    return ClassroomService.updateTask(augments).then(response => {
      commit(TASK_EDIT_SAVE, response.data)

      return response
    })
  },
  getTasksList({commit}, augments = {classroom_id: Number, search_string: String}) {
    commit(FETCH_TASK_LIST_START)

    return ClassroomService.tasksList(augments).then(response => {
      commit(FETCH_TASK_LIST_DATA, response.data)

      return response
    })
  },
  setTaskVisibility({commit}, augments = {classroom_id: Number, task_id: Number, is_hidden: Boolean}) {
    commit(TASK_EDIT_VISIBILITY_START)

    return ClassroomService.setTaskVisibility(augments).then(() => {
      commit(TASK_EDIT_VISIBILITY_END)
    })
  },
  getSubject({commit}, subject_id) {
    commit(TASK_EDIT_VISIBILITY_START)

    return ClassroomService.getSubject(subject_id).then((response) => {
      commit(TASK_EDIT_VISIBILITY_END)

      return response.data
    })
  },
  fetchClassroomChatMessages({commit}, classroom_id = Number) {
    commit(FETCH_CLASSROOM_CHAT_MESSAGES_START)

    return ClassroomService.getChatMessages(classroom_id).then(response => {
      commit(FETCH_CLASSROOM_CHAT_MESSAGES_DATA, response.data)

      return response
    })
  },
  initClassroom ({ commit }) {
    commit('initClassroom')
  },
  setCourseId ({ commit }, course_id) {
    commit('setCourseId',course_id)
  },
}

