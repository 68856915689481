<template>
  <div>
    <label v-if="label" :for="`select-multiple-${_uid}`">{{ label }}</label>
    <b-form-group>
      <b-form-radio v-for="(option, index) in options"
                    :disabled="disabled"
                    :name="name"
                    :key="`${_uid}-${index}`"
                    :value="option.id"
                    v-model="optionSelected"
                    @click="optionSelected = option.id">{{ option.name }}
      </b-form-radio>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "Radio",
  data: function () {
    return {}
  },
  props: {
    value: {
      type: [Number, String],
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: function () {
        return []
      }
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
    },
    customClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'some-radios'
    }
  },
  computed: {
    /**
     * false (denotes invalid state) is great for when there's a blocking or required field. A user must fill in this field properly to submit the form.
     * true (denotes valid state) is ideal for situations when you have per-field validation throughout a form and want to encourage a user through the rest of the fields.
     * null Displays no validation state (neither valid nor invalid)
     * @returns {boolean}
     */
    state() {
      return !this.errors.length ? null : false
    },
    optionSelected: {
      get: function () {
        return this.value ?? ''
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
