<template>
  <div class="step-item">
    <div class="step-item-title">My School</div>
    <div class="step-item-subtitle">Please tell us about your school</div>
    <form-input-data-list v-if="showSchoolSelect"
                          id="dropdown-school-list"
                          v-model="$v.form.schoolName.$model"
                          :validator="$v.form.schoolName"
                          label="Select Your School"
                          :options="schools"
                          ref="schoolName"
                          @on-option-selected="onSchoolSelected"
                          class="step-item-input"/>
    <section v-else>
      <form-input v-model="$v.form.schoolName.$model"
                  :validator="$v.form.schoolName"
                  label="School Name"
                  class="step-item-input"/>
      <form-textarea v-model.trim="$v.form.schoolAddress.$model"
                     :validator="$v.form.schoolAddress"
                     label="School Address"
                     class="step-item-input step-item-input--area"/>
      <form-input v-model="$v.form.schoolWebsite.$model"
                  :validator="$v.form.schoolWebsite"
                  label="School Website"
                  class="step-item-input"/>
      <form-input v-model="$v.form.schoolPhoneNumber.$model"
                  :validator="$v.form.schoolPhoneNumber"
                  label="School Phone"
                  class="step-item-input"/>
    </section>

    <div class="step-item">
      <b-button type="button" class="button primary --sm back"
                @click="changeStep(2)"
                btn-text="Back"/>
      <b-button type="button" class="button primary --sm"
                @click="changeStep(form.schoolDpaSigned ? 5 : 4)"
                :disabled="$v.stepValid.$invalid"
                btn-text="Next"/>
    </div>
    <b-overlay no-wrap :show="isLoading"></b-overlay>
  </div>
</template>

<script>
import { Button } from "@/core/components/Button"
import {minLength, maxLength, requiredIf, required} from "vuelidate/lib/validators"
import FormInput from "@/core/components/Form/Input"
import FormInputDataList from "@/core/components/Form/InputDataList"
import FormTextarea from "@/core/components/Form/Textarea"
import {MiscService} from '@/core/Services'

export default {
  name: 'Step3',
  components: {
    'b-button': Button,
    'form-input': FormInput,
    'form-input-data-list': FormInputDataList,
    'form-textarea': FormTextarea,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        schoolName: '',
        schoolId: '',
        schoolAddress: '',
        schoolWebsite: '',
        schoolPhoneNumber: '',
        parentOrganizacionId: '',
        schoolDpaSigned: false
      },
      schools: [],
      countries: [],
      isLoading: false
    }
  },
  validations () {
    if (this.isSchoolNotFind) {
      return {
        form: {
          schoolName: {
            required: requiredIf(() => {
              return this.isSchoolNotFind
            }),
            minLength: minLength(2),
            maxLength: maxLength(250),
          },
          schoolAddress: {
            required: requiredIf(() => {
              return this.isSchoolNotFind
            }),
            minLength: minLength(2),
            maxLength: maxLength(250),
          },
          schoolWebsite: {
            required: requiredIf(() => {
              return this.isSchoolNotFind
            }),
            maxLength: maxLength(250),
            validateDomainExtension: () => {
              const domainParts = this.form.schoolWebsite?.split(".")
              return domainParts?.length >= 2 && domainParts[domainParts?.length - 1].length >= 2
            }
          },
          schoolPhoneNumber: {
            required: requiredIf(() => {
              return this.isSchoolNotFind
            }),
            minLength: minLength(6),
            maxLength: maxLength(100),
          },
        },
        stepValid: ['form.schoolName', 'form.schoolAddress', 'form.schoolWebsite', 'form.schoolPhoneNumber']
      }
    } else {
      return {
        form: {
          schoolName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(250)
          },
          schoolId: {
            required
          },
          schoolAddress: {},
          schoolWebsite: {},
          schoolPhoneNumber: {}
        },
        stepValid: ['form.schoolName', 'form.schoolId']
      }
    }
  },
  computed: {
    isUSA() {
      const country = this.countries.find(country => country.id === this.profileData.country)
      return (country && country.iso.toLowerCase() === 'us')
    },
    isSchoolNotFind() {
      return this.form.schoolId === 'no_figure'
    },
    showSchoolSelect() {
      return this.schools.length > 0 && !this.isSchoolNotFind
    },
    showStateSelect() {
      return this.profileData.country && this.isUSA
    }
  },
  methods: {
    changeStep (step) {
      this.$emit('change-step', {step, form: {...this.form}})
    },
    async onSchoolSelected(school) {
      this.form.schoolId = school['id']
      this.form.schoolAddress = school['address'] ?? ''
      this.form.schoolWebsite = school['website'] ?? ''
      this.form.schoolPhoneNumber = school['phone_number'] ?? ''
      this.form.parentOrganizacionId = school['parent_organization_id'] ?? ''
      if (school.id) {
        this.checkSchoolDpaSigned(school?.parent_organization_id, school?.id)
      }
      this.$v.$reset()
    },
    async checkSchoolDpaSigned (parentOrganizationId, schoolId) {
      this.isLoading = true
      await MiscService.checkSchoolDpaSigned(parentOrganizationId, schoolId).then((response) => {
        this.form.schoolDpaSigned = response.data
      }).catch(e => console.error(e)).finally(() => this.isLoading = false)
      this.$emit('school-dpa-signed', this.form.schoolDpaSigned)
    },
  },
  async mounted() {
    try {
      this.isLoading = true
      await MiscService.countries().then((response) => {
        this.countries = response.data
      })
      if (this.profileData.country) {
        if (this.isUSA) {
          await MiscService.schools(this.profileData.country, this.profileData.state).then((response) => {
            this.$nextTick(() => this.schools = [...response.data])
          })
        } else {
          await MiscService.allCountriesSchools(this.profileData.country, this.profileData.state).then((response) => {
            this.$nextTick(() => this.schools = [...response.data])
          })
        }
      }
      this.form = {...this.form, ...this.profileData}
      if (this.profileData.schoolId) {
        this.checkSchoolDpaSigned(this.form.parentOrganizacionId, this.form.schoolId)
      } else {
        this.form.schoolDpaSigned = false
        this.$emit('school-dpa-signed', this.form.schoolDpaSigned)
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  },
  watch: {
    'form.schoolName'(schoolName) {
      if (!schoolName) {
        this.form.schoolId = null

        setTimeout(() => {
          this.$refs['schoolName'].$refs['input-data-list'].focus()
        }, 30)
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.step-item
  text-align center

  .button
    display inline
    margin-top 2rem

    &.back
      margin-right 4rem

    &:disabled
      border-width 0 !important

  &-title
    font-size 1.5rem
    margin-bottom 18px

  &-subtitle
    margin 18px 0
    font-size 1rem

  &-input
    max-width 470px
    margin 0 auto
    margin-bottom 12px

    ::v-deep
      label
        font-size 1.5rem
        margin-bottom 18px

      .list-group
        top 6.2rem !important

  &-input--area
    ::v-deep
      .form-control
        resize none

  @media (max-width 350px)
    .button
      margin 0 5px
      margin-top 2rem

      &.back
        margin-right 0
</style>
