<template>
  <fragment>
    <b-button @click="$bvModal.show('add-new-student-modal')"
              :disabled="classroomExchangeExpired"
              btn-text="Add New Student"
              outline
              small
              class="size-text-btn"/>
    <modal id="add-new-student-modal">
      <template #body>
        <b-overlay :show="sendingData" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">Please wait...</p>
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </template>

          <h2 class="modal-title">ADD NEW STUDENT</h2>
          <p class="modal-subtitle text-center">
            The system will generate a unique username for each student when they are added
          </p>
          <b-input type="text" maxlength="25" v-model="form.first_name" :errors="errors.first_name"
                   custom-class="common-input common-input--primary"
                   placeholder="First Name"/>
          <b-input type="text" maxlength="1" v-model="form.last_initial" :errors="errors.last_initial"
                   custom-class="common-input common-input--primary"
                   placeholder="Last Initial"/>
        </b-overlay>
      </template>
      <template #footer>
        <b-button @click="addStudent(true)" btn-text="SAVE & ADD ANOTHER" :disabled="sendingData"/>
        <b-button @click="addStudent()" btn-text="Save" :disabled="sendingData"/>
      </template>
    </modal>
  </fragment>
</template>

<script>
import {ClassroomService} from "@/core/Services";
import {Modal} from "@/core/components/Modal";
import BInput from "@/core/components/FormInput/Input";
import {Button} from "@/core/components/Button";
import validateForms from "@/core/Mixins/validate-forms";
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"

export default {
  name: "ClassroomDetailStudentAddNew",
  components: {
    Modal,
    BInput,
    'b-button': Button,
  },
  mixins: [validateForms, ExchangesMixins],
  data() {
    return {
      form: {
        first_name: '',
        last_initial: '',
      },
      validations: {
        first_name: {
          validate: 'required|letter',
        },
        last_initial: {
          validate: 'required|letter',
        },
      },
      sendingData: false
    }
  },
  props: {
    classroom: Object,
  },
  methods: {
    addStudent(addMore) {
      if (!this.sendingData && this.isValidForm()) {
        this.sendingData = true
        ClassroomService.createStudent({classroom_id: this.classroom.id, form: {...this.form}}).then((response) => {
          this.$emit('Student:Created:success', response.data)
          if (!addMore) {
            this.$bvModal.hide('add-new-student-modal')
          }
          this.form = {
            first_name: '',
            last_initial: '',
          }
          this.$emit('on-add-student')
          this.sendingData = false
        })
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@media (max-width 495px)
  .size-text-btn
    font-size 10px

</style>
