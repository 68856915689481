<template>
  <fragment>
    <div class="column column-type">{{ connection_way }}</div>
    <div class="column column-partner">
      <a href="javascript:void(0)" @click="onShowRequestDetail">
        {{ partner_full_name }}
      </a>
    </div>
    <div class="column column-country">{{ connection.teacher.country.name }}</div>
    <div class="column column-date">{{ connection.created_at | dateMonthDayYear }}</div>
    <div class="column column-classroom">{{ classroom_name }}</div>
    <div class="column column-status">{{ connection.status }}</div>
  </fragment>
</template>

<script>
import {UtilsMixins} from "@/core/Mixins";

export default {
  name: "PartnerConnectionRequestsListRow",
  mixins: [UtilsMixins],
  data() {
    return {
      selected_invitation_id: null,
      sendingRequest: true,
      rows: []
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    }
  },
  methods: {
    onShowRequestDetail() {
      if (this.invitation_received) {
        this.$emit('on-show-connection-received-detail', this.connection)
      } else {
        this.$emit('on-show-connection-sent-detail', this.connection)
      }
    }
  },
  computed: {
    invitation_received() {
      return this.user.id === this.connection.invited_teacher_id
    },
    partner() {
      return this.invitation_received ? this.connection.teacher : this.connection.invited_teacher
    },
    partner_full_name() {
      return `${this.partner.first_name} ${this.partner.last_name.charAt(0)}`
    },
    classroom() {
      return this.invitation_received ? this.connection.invited_classroom : this.connection.classroom
    },
    classroom_name() {
      return this.classroom ? this.classroom.name : ''
    },
    connection_way() {
      return this.invitation_received ? 'RECEIVED' : 'SENT'
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
