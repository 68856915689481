<template>
  <b-overlay
    id="overlay-teacher-profile"
    :show="loadingSources || isSendingData"
    variant="light"
    opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <div class="wrapper-teacher-profile">
      <b-container v-if="!loadingSources" class="teacher-profile-page-container">
        <b-row :no-gutters="true">
          <b-col cols="12">
            <b-container>
              <b-row :no-gutters="true">
                <b-col cols="12">
                  <h2 class="security-form-subtitle mb-1">TEACHER PROFILE</h2>
                  <div class="security-form-page-info">
                    <div class="required-fields">Please set up your profile so we can find a global partner for you.
                    </div>
                  </div>
                </b-col>
                <!-- <b-col cols="6" class="text-right">
                  <b-button @click="updateProfileData" btn-text="SAVE" class="float-right"/>
                </b-col> -->
              </b-row>
            </b-container>
            <div class="wrapper-teacher-profile-content">
              <div class="wrapper-teacher-profile-content-left">
                <teacher-profile-section title="ABOUT ME" text-required="All fields required *" number="1">
                  <template v-slot:profile-form>
                    <upload-image-avatar v-model="$v.profile.avatar.$model"
                                         :validator="$v.profile.avatar"
                                         place-holder="Select Avatar *"
                                         label="Select Avatar"
                                         custom-class="form-upload-avatar"
                                         ref="upload-image-avatar"
                                         @click-on-avatar="showAvatarsModal"
                                         @File:loaded="onFileLoaded"/>

                    <form-input v-model="$v.profile.first_name.$model" :validator="$v.profile.first_name"
                                label="First Name *" custom-class="common-input common-input--primary"/>

                    <form-input v-model="$v.profile.last_name.$model" :validator="$v.profile.last_name"
                                label="Last Name *" custom-class="common-input common-input--primary"/>

                    <form-textarea v-model.trim="$v.profile.about_info.$model" :validator="$v.profile.about_info"
                                   label="About You *"
                                   custom-class="common-input common-input--primary"/>

                    <form-input-data-list id="dropdown-contries-list"
                                          v-model="$v.profile.country_id.$model"
                                          :validator="$v.profile.country_id"
                                          value-field="id"
                                          label="Country *"
                                          :options="countries"
                                          custom-class="common-input common-input--primary"/>

                    <form-select v-if="showStateSelect"
                                 v-model="$v.profile.state_id.$model" :validator="$v.profile.state_id"
                                 label="State *"
                                 :options="states"
                                 custom-class="custom-dropdown--md custom-dropdown--secondary"/>

                    <form-input v-if="showCityInput"
                                v-model="$v.profile.city_name.$model" :validator="$v.profile.city_name"
                                label="City *" custom-class="common-input common-input--primary"/>

                    <form-input-data-list v-if="showSchoolSelect"
                                          id="dropdown-school-list"
                                          v-model="$v.profile.school_name.$model" :validator="$v.profile.school_name"
                                          label="Select Your School *"
                                          :options="schools"
                                          ref="school_name"
                                          custom-class="common-input common-input--primary"
                                          @on-option-selected="onSchoolSelected"/>

                    <form-input v-if="showSchoolDataInputs"
                                v-model="$v.profile.school_name.$model" :validator="$v.profile.school_name"
                                label="School Name *" custom-class="common-input common-input--primary"/>

                    <form-textarea v-if="showSchoolDataInputs"
                                   v-model.trim="$v.profile.school_address.$model"
                                   :validator="$v.profile.school_address"
                                   label="School Address *"
                                   custom-class="common-input common-input--primary"/>

                    <form-input v-if="showSchoolDataInputs"
                                v-model="$v.profile.school_website.$model" :validator="$v.profile.school_website"
                                label="School Website *" custom-class="common-input common-input--primary"/>

                    <form-input v-if="showSchoolDataInputs"
                                v-model="$v.profile.school_phone_number.$model"
                                :validator="$v.profile.school_phone_number"
                                label="School Phone Number (Not Used For Marketing) *"
                                custom-class="common-input common-input--primary"/>
                  </template>
                </teacher-profile-section>
                <teacher-profile-section title="MY PREFERRED COMMUNICATION METHOD" v-if="false">
                  <template v-slot:profile-form>
                    <form-radio v-model="$v.profile.preferred_communication.$model"
                                :options="communicationMethods"
                                name="comunication-methods"
                                class="custom-form-my-instruction"
                                custom-class="common-input"/>

                    <div class="container-mobile-number">
                      <form-input v-model="$v.profile.phone_country_code.$model"
                                  :validator="$v.profile.phone_country_code"
                                  label="Country Code *"
                                  :disabled="!isMobileNumber"
                                  custom-class="common-input common-input--primary common-input--phone_number"/>

                      <form-input v-model="$v.profile.phone_number.$model"
                                  :validator="$v.profile.phone_number"
                                  label="Mobile Number *"
                                  :disabled="!isMobileNumber"
                                  custom-class="common-input common-input--primary common-input--phone_number"/>
                    </div>
                  </template>
                </teacher-profile-section>
                <teacher-profile-section title="MY INSTRUCTION">
                  <template v-slot:profile-form>

                    <form-checkbox-group v-model="$v.profile.subjects.$model"
                                         :validator="$v.profile.subjects"
                                         v-if="!isLibrarian"
                                         label="Subjects I Teach *"
                                         :is-object-array="true"
                                         value-field="id"
                                         text-field="name"
                                         class="custom-form-my-instruction"
                                         :options="subjectsList"
                                         name="age_ranges"
                                         custom-class="custom-checkbox-primary custom-checkbox--one-column"/>

                    <form-radio v-model="$v.profile.native_language_id.$model"
                                :options="languages"
                                name="primary-language"
                                class="custom-form-my-instruction"
                                label="My Student's Primary Language *"
                                custom-class="common-input"/>

                    <form-checkbox-group v-model="$v.profile.instruction_languages.$model"
                                         :validator="$v.profile.instruction_languages"
                                         label="My Students Are Learning *"
                                         v-if="isSubjectWorldLanguages"
                                         :is-object-array="true"
                                         value-field="id"
                                         text-field="name"
                                         class="custom-form-my-instruction"
                                         :options="languages"
                                         name="age_ranges"
                                         custom-class="custom-checkbox-primary custom-checkbox--one-column"/>

                    <form-checkbox-group v-model="$v.profile.age_ranges.$model"
                                         :validator="$v.profile.age_ranges"
                                         label="Student Age Range *"
                                         :is-object-array="true"
                                         value-field="id"
                                         text-field="name"
                                         class="custom-form-my-instruction"
                                         :options="age_ranges"
                                         name="age_ranges"
                                         custom-class="custom-checkbox-primary custom-checkbox--two-columns"/>
                  </template>
                </teacher-profile-section>

                <teacher-profile-section title="MY SCHOOL IS IN SESSION"
                                         v-if="false"
                                         text-information="Select the months that you are in school and would be open to running a LUV program. *">
                  <template v-slot:profile-form>
                    <form-checkbox-group v-model="$v.profile.months_available.$model"
                                         :validator="$v.profile.months_available"
                                         :options="months" name="months_available"
                                         custom-class="custom-checkbox-primary"/>
                  </template>
                </teacher-profile-section>

                <teacher-profile-section
                  v-if="!isEmailValidated"
                  title="Verification"
                  :sub-title="'Email: ' + user.email"
                  text-information="We did not recognize your email address as being associated with an educational institution. Please describe your educational affiliation.">
                  <template v-slot:profile-form>
                    <form-textarea v-model.trim="$v.profile.educational_affiliation.$model"
                                   :validator="$v.profile.educational_affiliation"
                                   label="About Your Educational Affiliation. *"
                                   custom-class="common-input common-input--primary"/>
                  </template>
                </teacher-profile-section>
                <teacher-profile-section
                  v-else
                  title="Verification"
                  :sub-title="'Email: ' + user.email">
                  <template v-slot:profile-form>
                    <p>Verified Email</p>
                  </template>
                </teacher-profile-section>

                <b-button @click="validateTeacherProfile" btn-text="SAVE" class="btn-save"/>
              </div>

              <div class="wrapper-teacher-profile-content-right">
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <modal ref="profile_update_modal">
        <template #header></template>
        <template #body>
          <h2 class="modal-title">{{ modal.title }}</h2>
          <p class="text-center" v-html="modal.message"></p>
        </template>
        <template #footer>
          <b-button @click="closeModal()" btn-text="OK"/>
        </template>
      </modal>

      <modal id="verifying-account-modal" @hidden="goToLogoutPage">
        <template #header></template>
        <template #body>
          <h2 class="modal-title">VERIFYING ACCOUNT</h2>
          <p class="text-center">Our team is verifying your account. Once verified you can begin setting up your
            classrooms and connecting with partners around the world. Watch your email for more information.</p>
        </template>
        <template #footer>
          <b-button @click="$bvModal.hide('verifying-account-modal')" btn-text="OK"/>
        </template>
      </modal>

      <modal id="verified-account-modal" @hidden="isLibrarian ? goToLibraryConnectionsPage : goToCreateClassroomPage">
        <template #header></template>
        <template #body>
          <h2 class="modal-title">AMAZING!</h2>
          <p class="text-center">You have been verified and can continue <br/> to be matched with another class.</p>
        </template>
        <template #footer>
          <b-button @click="$bvModal.hide('verified-account-modal')" btn-text="OK"/>
        </template>
      </modal>

      <modal id="error-modal-modal">
        <template #header></template>
        <template #body>
          <h2 class="modal-title">AN ERROR OCCURS</h2>
          <p class="text-center">An error happen, If you would like help, please contact us at
            support@levelupvillage.com</p>
        </template>
        <template #footer>
          <b-button @click="$bvModal.hide('error-modal-modal')" btn-text="OK"/>
        </template>
      </modal>

      <modal id="error-modal-filds">
        <template #body>
          <h2 class="modal-title">PROFILE ERROR</h2>
          <p class="text-center">Please correct the following field(s) in order to save your profile:</p>
          <ul class="error-list">
            <li v-for="(error, idx) in errorsLabelsList" :key="error + idx">{{error}}</li>
          </ul>
        </template>
        <template #footer>
          <b-button @click="$bvModal.hide('error-modal-filds')" btn-text="OK"/>
        </template>
      </modal>

      <avatar-list-modal @select-avatar="(path) => (profile.avatar = path)"
                         @upload-avatar="openUploadFile"
                         ref="avatarListModal"/>

      <modal id="validation-code-modal" @hidden="hideValidationCodeModal">
        <template #body>
          <h2 class="modal-title">VALIDATION CODE</h2>
          <p class="text-center">Please enter the verification number that was sent to your mobile number</p>
          <form-input v-model="validationCode"
                      custom-class="common-input common-input--primary"/>
        </template>
        <template #footer>
          <b-button @click="hideValidationCodeModal" btn-text="CANCEL"/>
          <b-button @click="validatePhoneNumber" :disabled="!validationCode" btn-text="OK"/>
        </template>
      </modal>
    </div>
  </b-overlay>
</template>

<script>
import FormInput from "@/core/components/Form/Input"
import FormInputDataList from "@/core/components/Form/InputDataList"
import FormSelect from "@/core/components/Form/Select"
import FormTextarea from "@/core/components/Form/Textarea"
import FormCheckboxGroup from "@/core/components/Form/CheckboxGroup"
import TeacherProfileSection from "@/modules/Classroom/Components/TeacherProfileSection"
import Radio from "@/core/components/FormInput/Radio"
import UploadImageAvatar from "@/core/components/UploadFileAttachLink/UploadImageAvatar"
import validateForms from "@/core/Mixins/validate-forms"
import {Button} from "@/core/components/Button"
import {MiscService, UploadService, ProfileService} from '@/core/Services'
import {Modal} from "@/core/components/Modal"
import {MyClassRoomsMixins} from "@/modules/MyClassrooms/Mixins"
import {UserMixins} from "@/modules/Classroom/Mixins"
import {minLength, maxLength, required, requiredIf} from "vuelidate/lib/validators"
import AvatarListModal from '@/core/components/AvatarListModal/AvatarListModal'
import {userTypeMixins} from "@/core/Mixins"

export default {
  name: "TeacherProfilePage",
  mixins: [validateForms, UserMixins, MyClassRoomsMixins, userTypeMixins],
  components: {
    Modal,
    UploadImageAvatar,
    TeacherProfileSection,
    'b-button': Button,
    'form-input': FormInput,
    'form-select': FormSelect,
    'form-textarea': FormTextarea,
    'form-checkbox-group': FormCheckboxGroup,
    'form-input-data-list': FormInputDataList,
    'form-radio': Radio,
    AvatarListModal
  },
  data: function () {
    return {
      profile: {
        // user data
        avatar: null,
        first_name: null,
        last_name: null,
        about_info: null,
        // geo data
        country_id: null,
        state_id: null,
        city_name: null,
        school_id: null,
        // school data
        school_address: null,
        school_name: null,
        school_website: null,
        school_phone_number: null,
        preferred_communication: 'email',
        phone_number: null,
        educational_affiliation: null,
        subjects: [],
        native_language_id: null,
        instruction_languages: [],
        age_ranges: [],
        // teacher available months
        months_available: null,
        phone_country_code: null
      },
      monthsSelected: [], // Must be an array reference!
      months: [
        {text: 'January', value: '1'},
        {text: 'February', value: '2'},
        {text: 'March', value: '3'},
        {text: 'April', value: '4'},
        {text: 'May', value: '5'},
        {text: 'June', value: '6'},
        {text: 'July', value: '7'},
        {text: 'August', value: '8'},
        {text: 'September', value: '9'},
        {text: 'October', value: '10'},
        {text: 'November', value: '11'},
        {text: 'December', value: '12'},
      ],
      errorsLabels: {
        about_info: 'About You',
        age_ranges: 'Student Age Range',
        avatar: 'Avatar',
        city_name: 'City',
        country_id: 'Country',
        educational_affiliation: 'Educational Affiliation',
        first_name: 'First Name',
        instruction_languages: 'My Students Are Learning',
        last_name: 'Last Name',
        months_available: 'Months Available',
        native_language_id: "My Student's Primary Language",
        school_address: 'School Address',
        school_name: 'Select Your School',
        school_phone_number: 'School Phone Number',
        school_website: 'School Website',
        state_id: 'State',
        subjects: 'Subjects I Teach',
        phone_number: 'Mobile Number',
        phone_country_code: 'Country Code'
      },
      errorsLabelsList: [],
      countries: [],
      states: [],
      schools: [],
      success: true,
      avatarFile: null,
      uploadingFile: false,
      loadingSources: true,
      isSendingData: false,
      newPhoneNumber: null,
      communicationMethods: [
        {name: 'Email', id: 'email', value: 'email'},
        {name: 'Text Message', id: 'text_message', value: 'text_message'},
        {name: 'WhatsApp', id: 'whatsapp', value: 'whatsapp'}
      ],
      mainProps: { blank: true, width: 100, height: 100, class: 'm-2' },
      validationCode: null
    }
  },
  validations() {
    if (this.showSchoolDataInputs) {
      return {
        profile: {
          avatar: {
            required,
            minLength: minLength(3)
          },
          first_name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(100),
          },
          last_name: {
            required,
            maxLength: maxLength(100),
          },
          about_info: {
            required,
            maxLength: maxLength(500),
          },
          country_id: {
            required
          },
          state_id: {
            required: requiredIf(() => {
              return this.profile.country_id && this.isUSA
            })
          },
          city_name: {
            required
          },
          school_name: {
            required: requiredIf(() => {
              return this.showSchoolDataInputs
            }),
            minLength: minLength(2),
            maxLength: maxLength(250),
          },
          school_address: {
            required: requiredIf(() => {
              return this.showSchoolDataInputs
            }),
            minLength: minLength(2),
            maxLength: maxLength(250),
          },
          school_website: {
            required: requiredIf(() => {
              return this.showSchoolDataInputs
            }),
            maxLength: maxLength(250),
            validateDomainExtension: () => {
              const domainParts = this.profile.school_website?.split(".")
              return domainParts?.length >= 2 && domainParts[domainParts?.length - 1].length >= 2
            }
          },
          school_phone_number: {
            required: requiredIf(() => {
              return this.showSchoolDataInputs
            }),
            minLength: minLength(6),
            maxLength: maxLength(100),
          },
          preferred_communication: {
            required
          },
          phone_number: {
            required: requiredIf(() => {
              return this.isMobileNumber
            })
          },
          phone_country_code: {
            required: requiredIf(() => {
              return this.isMobileNumber
            })
          },
          educational_affiliation: {
            required: requiredIf(() => {
              return !this.isEmailValidated
            }),
            maxLength: maxLength(500),
          },
          subjects: {
            required
          },
          native_language_id: {
            required
          },
          instruction_languages: {
            required: requiredIf(() => {
              return this.isSubjectWorldLanguages
            }),
          },
          age_ranges: {
            required
          },
          months_available: {
          },
        }
      }
    } else {
      return {
        profile: {
          avatar: {
            required,
            minLength: minLength(3)
          },
          first_name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(100),
          },
          last_name: {
            required,
            maxLength: maxLength(100),
          },
          about_info: {
            required,
            maxLength: maxLength(500),
          },
          country_id: {
            required
          },
          state_id: {
            required: requiredIf(() => {
              return this.profile.country_id && this.isUSA
            })
          },
          city_name: {
            required
          },
          school_name: {},
          school_address: {},
          school_website: {},
          school_phone_number: {},
          preferred_communication: {
            required
          },
          phone_number: {
            required: requiredIf(() => {
              return this.isMobileNumber
            })
          },
          phone_country_code: {
            required: requiredIf(() => {
              return this.isMobileNumber
            })
          },
          educational_affiliation: {
            required: requiredIf(() => {
              return !this.isEmailValidated
            }),
            maxLength: maxLength(500),
          },
          subjects: {
            required
          },
          native_language_id: {
            required
          },
          instruction_languages: {
            required: requiredIf(() => {
              return this.isSubjectWorldLanguages
            }),
          },
          age_ranges: {
            required
          },
          months_available: {
          },
        }
      }
    }
  },
  mounted() {
    this.loadingSources = true

    Promise.all([
      this.getProfile(),
      this.getClassrooms({}),
      MiscService.countries().then((response) => {
        this.countries = response.data
      })
    ]).then(() => {
      this.profile = Object.assign({}, this.profile, {
        avatar: this.user.avatar,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        about_info: this.user.about_info,
        country_id: this.user.country_id,
        state_id: this.user.state_id,
        city_name: this.user.city_name,
        school_id: this.user.school_id,
        school_address: this.user.school_address,
        school_name: this.user.school_name,
        school_website: this.user.school_website,
        school_phone_number: this.user.school_phone_number,
        educational_affiliation: this.user.educational_affiliation,
        subjects: this.user.subjects,
        native_language_id: this.user.native_language_id || 1,
        instruction_languages: this.user.instruction_languages,
        age_ranges: this.user.age_ranges,
        // months_available: this.user.months_available ? this.user.months_available.split(',') : [],
        preferred_communication: this.user.preferred_communication,
        phone_country_code: String(this.user.phone_country_code),
        phone_number: String(this.user.phone_number),
        teacher_phone_to_confirm_code: this.user.teacher_phone_to_confirm_code
      })

      if (!this.user.is_email_validated) {
        this.validations.educational_affiliation = {validate: 'required'}
      }

      this.setValidators()

      this.loadingSources = false

      const isVerified = localStorage["verified_user_" + this.user.id]

      if (
        this.user.is_email_validated && this.user.is_teacher_validated
        && this.user.school_id
        && !this.classrooms.length
        && !isVerified) {
        this.$bvModal.show('verified-account-modal')
        localStorage["verified_user_" + this.user.id] = this.user.id
      }
    })

  },
  methods: {
    hideValidationCodeModal() {
      this.validationCode = null
      this.$bvModal.hide('validation-code-modal')
    },
    showAvatarsModal() {
      this.$refs.avatarListModal.open()
    },
    openUploadFile () {
      this.$refs["upload-image-avatar"].openUploadFile()
    },
    validImageType(file) {
      const fileTypes = [
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
        "image/x-icon"
      ];
      return fileTypes.includes(file.type);
    },
    onFileLoaded(files) {
      if (files.length > 0) {
        const file = files[0]

        if (!this.validImageType(file)) {
          this.errors.avatar.push('File must be png, jpg, jpeg or gif.')
          return
        }

        this.avatarFile = {
          file: file,
          index_file: 0,
          type_file: 'avatar'
        }

        this.profile.avatar = URL.createObjectURL(file)
      }
    },
    async validatePhoneNumber() {
      let isConfirmed

      await ProfileService.confirmTeacherPhoneNumber({
        phone_number: this.profile.phone_country_code + this.profile.phone_number,
        preferred_communication: this.profile.preferred_communication,
        code: this.validationCode
      }).then((response) => {
        isConfirmed = response.success
      }).catch((e) => {
        isConfirmed = e.success
      })

      if (isConfirmed) {
        this.$bvModal.hide('validation-code-modal')
        this.$bvModal.msgBoxOk(`Successful profile update.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          centered: true
        }).then(() => {
          this.updateProfileData()
        })
        return
      } else {
        this.$bvModal.msgBoxConfirm('Incorrect verification number', {
          size: 'sm',
          dialogClass: "modal-message-box purchase-center",
          centered: true,
          cancelTitle: 'Cancel',
          okTitle: 'Resend Code'
        }).then((res) => {
          if (res) {
            this.validationCode = null
            this.validateTeacherProfile()
            this.$bvModal.msgBoxOk(`New verification number has been sent to your mobile number.`, {
              size: 'sm',
              dialogClass: "modal-message-box",
              centered: true
            }).then(() => {

            })
          } else {
            this.hideValidationCodeModal()
          }
        })
      }
    },
    async validateTeacherProfile() {
      this.$v.$touch()

      if (this.$v.profile.$invalid) {
        const dataErrors = []
        for (const [key, value] of Object.entries(this.$v.profile)) {
          if (value.$anyError) {
            dataErrors.push(this.errorsLabels[key])
          }
        }
        this.errorsLabelsList = dataErrors
        this.$bvModal.show('error-modal-filds')
        return
      }

      if (this.profile.preferred_communication !== 'email') {
        const isConfirmedPhoneNumber = [
          this.filterNumbers(this.profile.phone_country_code + this.profile.phone_number) === String(this.profile.teacher_phone_to_confirm_code?.phone_number),
          this.profile.preferred_communication === this.profile.teacher_phone_to_confirm_code?.preferred_communication,
          !!this.profile.teacher_phone_to_confirm_code?.confirmed
        ]

        if (!isConfirmedPhoneNumber.every(v => v === true)) {
          ProfileService.registerTeacherPhoneNumber({
            phone_number: this.profile.phone_country_code + this.profile.phone_number,
            preferred_communication: this.profile.preferred_communication
          }).then(() => {
            this.$bvModal.show('validation-code-modal')
          }).catch((e) => {
            const errorMessage = e.message ? e.message : 'Error creating report.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            })
          })
        }
        return
      }
      this.updateProfileData()
    },
    async updateProfileData() {
      if (this.avatarFile) {
        this.isSendingData = true
        let {file, index_file, type_file} = this.avatarFile

        const result = await UploadService.uploadFile(file, index_file, type_file)

        this.profile.avatar = result.name
      }

      await this.sendForm()
    },
    async sendForm() {
      const user = {...this.user}
      const profile = {...this.profile}

      this.isSendingData = true

      try {
        const response = await this.updateProfile({
          avatar: profile.avatar,
          first_name: profile.first_name,
          last_name: profile.last_name,
          about_info: profile.about_info,
          country_id: profile.country_id,
          state_id: profile.state_id,
          city_name: profile.city_name,
          school_id: profile.school_id === 'no_figure' ? null : profile.school_id,
          school_address: profile.school_address,
          school_name: profile.school_name,
          school_website: profile.school_website,
          school_phone_number: profile.school_phone_number,
          educational_affiliation: profile.educational_affiliation,
          subjects: profile.subjects,
          native_language_id: profile.native_language_id,
          instruction_languages: profile.instruction_languages,
          age_ranges: profile.age_ranges,
          // months_available: profile.months_available.join(),
          preferred_communication: profile.preferred_communication,
          phone_number: this.filterNumbers(profile.phone_number),
          phone_country_code: this.filterNumbers(profile.phone_country_code)
        })

        if (response) {
          if (!user.is_email_validated || !user.is_teacher_validated) {
            this.$bvModal.show('verifying-account-modal')
          } else {
            if (this.isLibrarian) {
              this.goToLibraryConnectionsPage()
            } else {
              const withClassroom = this.classrooms.length
              withClassroom ? this.goToMyClassroomsPage() : this.goToCreateClassroomPage()
            }
          }
        } else {
          this.$bvModal.show('error-modal-modal')
        }

        this.isSendingData = false
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error creating report.'

        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })

        this.isSendingData = false
      }
    },
    showModal() {
      this.$refs["profile_update_modal"].showModal()
    },
    closeModal() {
      this.$refs["profile_update_modal"].hideModal()
    },
    handleValidateForm() {
      this.validateForm()
    },
    onSchoolSelected(school) {
      this.profile.school_id = school['id']
      this.profile.school_address = school['address'] ?? ''
      this.profile.school_website = school['website'] ?? ''
      this.profile.school_phone_number = school['phone_number'] ?? ''
    },
    filterNumbers(string) {
      return string?.split(/ /)[0].replace(/[^\d]/g, '')
    },
  },
  computed: {
    isUSA() {
      const country = this.countries.find(country => country.id === this.profile.country_id)

      return (country && country.iso.toLowerCase() === 'us')
    },
    showStateSelect() {
      return this.profile.country_id && this.isUSA
    },
    showCityInput() {
      return this.profile.country_id
    },
    showSchoolSelect() {
      return this.showStateSelect && this.profile.state_id && this.schools.length > 0 && !this.isSchoolNotFind
    },
    showSchoolDataInputs() {
      return (this.profile.country_id && !this.isUSA)
        || (this.profile.country_id && this.isUSA && this.profile.state_id && this.isSchoolNotFind)
        || (this.profile.country_id && this.isUSA && this.profile.state_id && this.schools.length === 0)
    },
    isSchoolNotFind() {
      return this.profile.school_id === 'no_figure'
    },
    isEmailValidated() {
      return !!this.user.is_email_validated
    },
    isTeacherValidated() {
      return !!this.user.is_teacher_validated
    },
    isTeacherProfileFilled() {
      return !!this.user.is_teacher_profile_filled
    },
    isMobileNumber() {
      return this.profile.preferred_communication !== 'email'
    },
    isSubjectWorldLanguages() {
      let isWorldLanguages = null
      this.profile.subjects.map(subject => {
        if (subject.type === 'world_languages') {
          return isWorldLanguages = subject.type
        }
      })
      return isWorldLanguages
    },
    subjectsList() {
      if (!this.isLibrarian) {
        return this.subjects.filter((subject) => subject.name !== 'Library')
      }
      return this.subjects
    }
  },
  watch: {
    'profile.country_id'(country_id) {
      if (typeof country_id === 'string') return
      MiscService.states(country_id).then((response) => {
        this.states = response.data
      })
    },
    'profile.state_id'(state_id) {
      MiscService.schools(this.profile.country_id, state_id).then((response) => {
        this.schools = [...response.data]
      })
    },
    'profile.school_name'(school_name) {
      if (!school_name) {
        this.profile.school_id = null

        setTimeout(() => {
          this.$refs['school_name'].$refs['input-data-list'].focus()
        }, 30)
      }
    },
    'profile.avatar'() {
      this.$bvModal.hide('avatar-list-modal')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#overlay-teacher-profile
  min-height calc(100vh - (80px + 50px))

.wrapper-teacher-profile
  padding-top 70px
  padding-bottom 110px

  .security-form-page-info,
  .security-form-subtitle
    text-align left
    margin-bottom 0

  &-content
    padding-top 50px
    display flex
    flex-direction column

    &-left
      border 0

  .btn-save
    float right
    margin-bottom 35px
    margin-top -10px

  .payment-message
    font font-opensans-bold
    font-size 14px
    margin 4px 0 0 23.5px

  @media (min-width: 768px)
    ::v-deep
      .container
        max-width 720px

  @media (min-width: 992px)
    ::v-deep
      .container
        max-width 960px

    &-content
      flex-direction row

      &-right,
      &-left
        width 50%

        .form-group
          margin-bottom 8px

      &-left
        border-right 2px solid #E8ECEE
        padding-right 38px

        .btn-save
          margin-left 38px
          float left
          margin-bottom 0

      &-right
        padding-left 38px

  @media (min-width: 1200px)
    ::v-deep
      .container
        max-width 1140px

.error-list
  margin-top 12px
  font-size 14px

.container-mobile-number
  display flex

  ::v-deep
    .form-group
      margin-bottom 0

      &:first-child
        max-width 70px
        margin-right 10px

      .error-content
        padding-left 20px

    .form-group:nth-child(2)
      width 100%
      margin-top 22px

    .common-input--phone_number
      margin-bottom 0

::v-deep
  .dropdown-toggle
    color color-8 !important
    font-size 14px
    font font-opensans-regular
    padding 7px 20px
    background-color color-20 !important

    span
      &:first-letter
        text-transform: uppercase

    &:after
      width 29px
      height auto
      top 5px
      bottom 5px
      right 6px

  .dropdown-menu
    width 100%
    background-color color-20 !important

  .custom-form-my-instruction
    margin-top 20px

    .custom-checkbox-primary
      margin-top 4px

  .custom-checkbox-primary
    display grid
    grid-template-columns 1fr 1fr 1fr
    gap 10px 10px
    grid-gap 10px 10px

  .custom-checkbox--two-columns
    grid-template-columns 1fr 1fr

  .custom-checkbox--one-column
    grid-template-columns 1fr

  .custom-checkbox
    .custom-control-label
      &::before
        width 1.5rem
        height 1.5rem
        top 0

      span
        margin-left 7px

    .custom-control-input
      width 24px
      height 24px

    .custom-control-input:checked ~ .custom-control-label::after
      font-size 1.5rem
      top 0

#dropdown-school-list, #dropdown-contries-list
  ::v-deep
    .list-group
      top 4rem !important

::v-deep
  .form-upload-avatar
    width 160px
    height 160px

    .form-upload-file
      padding 20px 0
      display flex
      flex-direction column
      height 100%
      justify-content end

      .form-file-text
        padding-bottom 25px

      .btn-input-file
        border-radius(4px)
        letter-spacing 0
        line-height 17px


  .b-form-datepicker
    max-width 217px
</style>

<style lang="stylus">
#avatar-list-modal
  .modal-dialog
    max-width 750px !important

    .modal-body
      padding 1rem !important
</style>
