<template>
  <div>
    <b-button @click="showAddTeacherModal"
              :disabled="!!hasTeacherAssistant"
              btn-text="Add Teacher"
              class="add-teacher-button"
              v-if="!hasTeacherAssistant"/>
    <modal id="add-new-teacher-modal">
      <template #body>
        <b-overlay :show="sendingData" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">Please wait...</p>
              <b-spinner label="Spinning"/>
            </div>
          </template>

          <h2 class="modal-title">Add Teacher to Classroom</h2>
          <p class="modal-subtitle">
            Enter the school email address for the teacher you would like to add to this classroom.
          </p>
          <form-input v-model="$v.email.$model"
                      :validator="$v.email"
                      label="Teacher email"
                      custom-class="common-input common-input--primary"/>
        </b-overlay>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('add-new-teacher-modal')" btn-text="Cancel" :disabled="sendingData"/>
        <b-button @click="addTeacher()" btn-text="Ok" :disabled="sendingData"/>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal} from "@/core/components/Modal"
import {Button} from "@/core/components/Button"
import FormInput from "@/core/components/Form/Input"
import {required, email} from "vuelidate/lib/validators"
import {ClassroomMixins} from "@/core/Mixins"
import {SubjectTypes} from "@/core/Utils"
import {LibrarianService} from '@/core/Services'

export default {
  name: "ClassroomDetailTeacherAddNew",
  mixins: [ClassroomMixins],
  components: {
    Modal,
    'b-button': Button,
    'form-input': FormInput
  },
  data() {
    return {
      email: '',
      sendingData: false
    }
  },
  validations() {
    return {
      email: {
        required,
        email
      },
    }
  },
  computed: {
    hasTeacherAssistant() {
      return this.classroom.teacher_assistants?.length
    }
  },
  methods: {
    showAddTeacherModal() {
      this.$v.$reset()
      this.email = ''
      this.$bvModal.show('add-new-teacher-modal')
    },
    addTeacher() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.sendingData = true
      LibrarianService.addTeacherToClassroom(this.classroom.id, {email: this.email}).then((response) => {
        if (response.success) {
          this.$bvModal.msgBoxOk('Successful', {
            size: 'sm',
            dialogClass: "modal-message-box",
            centered: true
          }).then(() => {
            this.$bvModal.hide('add-new-teacher-modal')
            this.$nextTick(() => {
              this.getClassroomNoOverlay(this.classroom.id).then((response) => {
              const classroom = response.data
                this.setPlayersTrust(classroom?.course?.subject_id === SubjectTypes.playersTrust.id)
              })
            })
          })
        }
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Error, Please try again.'

        this.$bvModal.msgBoxOk(errorMessage, {
          size: 'md',
          dialogClass: "modal-message-box modal-size-md",
          noCloseOnBackdrop: true,
          centered: true
        })
      }).finally(() => this.sendingData = false)
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-subtitle
  text-align center
  margin-bottom 20px

@media (max-width 495px)
  .add-teacher-button
    font-size 10px

</style>
