const statusOptions = [
  {name: "All", value: "all"},
  {name: "Approved", value: "approved"},
  {name: "Pending", value: "pending"},
  {name: "Unapproved", value: "unapproved"},
  {name: "Blocked", value: "blocked"},
  {name: "Deleted", value: "deleted"},
]
const actionOptions = [
  {name: "Approve", value: "approved"},
  {name: "Unapprove", value: "unapproved"},
  {name: "Block", value: "blocked"},
  {name: "Delete", value: "deleted"},
]

export {
  statusOptions,
  actionOptions
}
