<template>
  <fragment>
    <h2 class="modal-title">CREATE MESSAGE</h2>
    <p class="modal-subtitle">
      fields required *
    </p>
    <form>
      <b-input v-model="form.subject" :errors="errors.subject" :maxlength="150" type="text" placeholder="Subject *" custom-class="common-input common-input--primary"/>
      <b-textarea v-model="form.description" :errors="errors.description" :maxlength="255" placeholder="Text *" custom-class="common-input common-input--primary"></b-textarea>
      <hr>
      <div class="form-group">
        <label>Upload File (optional)</label>
        <upload-file label-button="Select File" @File:loaded="onFileLoaded" @error="showErrors"></upload-file>
      </div>
      <div class="form-group-links">
        <ul>
          <li :key="'attach-file-'+index" v-for="(file, index) in form.media">
            <a target="_blank" class="text-normal" :href="file.url">{{ file.label }}
            </a>
            <button type="button" @click="onDeleteFile(index)"><span class="icon-delete"></span></button>
          </li>
        </ul>
      </div>
      <hr>
      <div class="form-group form-group-links">
        <label>Add Link (Optional)</label>
        <attach-link v-model="form.links"></attach-link>
      </div>
      <hr>
      <div class="form-group form-group-actions">
        <b-button @click="postMessage()" btn-text="Post" small/>
        <b-button @click="onCancel()" btn-text="Cancel" small/>
      </div>
    </form>
  </fragment>
</template>

<script>
import UploadFile from "@/core/components/UploadFileAttachLink/UploadFile";
import AttachLink from "@/core/components/UploadFileAttachLink/AttachLink";
import BInput from "@/core/components/FormInput/Input";
import BTextarea from "@/core/components/FormInput/Textarea";
import {Button} from "@/core/components/Button";
import validateForms from "@/core/Mixins/validate-forms";


export default {
  name: "ClassroomDetailCreateMessage",
  mixins: [validateForms],
  components: {
    AttachLink,
    UploadFile,
    BTextarea,
    BInput,
    'b-button': Button,
  },
  data() {
    return {
      form: {
        subject: '',
        description: '',
        media: [],
        links: []
      },
      validations: {
        subject: {
          validate: 'required',
        },
        description: {
          validate: 'required',
        },
      },
    }
  },
  methods: {
    onCancel() {
      this.$emit('on-cancel-create-message')
    },
    postMessage(){
      if(this.isValidForm()) {
        this.$emit('on-post-message', this.form)
      }
    },
    onFileLoaded(files = []){
      if(files.length > 0 ) {

        const file = {
          url: URL.createObjectURL(files[0]),
          label: files[0].name,
          file: files[0]
        }

        this.form.media.push(file)
      }
    },
    onDeleteFile(index) {
      this.form.media.splice(index, 1);
    },
    showErrors (err) {
      this.$bvModal.msgBoxOk(`${err.message}, Please try again`)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.form-group {
  &-links {
    margin-top 15px

    ul {
      padding 0
      margin 0
      list-style-type none
      display block

      & > li {
        border 0
        padding 0
        display block
        color color-3

        a {
          font font-opensans-bold
          font-size 14px
          color color-34
          text-transform uppercase
          word-break break-all
        }

        button {
          border 0
          background-color transparent

          span {
            font-size 0.7rem

            &:before {
              color color-3
            }
          }
        }
      }
    }
  }

  &-actions {
    display flex
    justify-content center

    .btn-common {
      padding 10px 30px
      font-size 14px
      line-height 19px
      letter-spacing 1.4px
      &:first-child {
        margin-right 10px
      }
    }
  }

  label {
    font font-opensans-regular
    font-size 14px
    color color-8
    margin-bottom 8px
  }
}

hr {
  margin-bottom 15px
  margin-top 20px
}

.modal-subtitle {
  text-align center
}

::v-deep
  .container-attach-link
    .form-group
    input
      margin-bottom 0

  .form-upload-file
    border none
    padding 0
    .btn-input-file
      font-size 12px
      line-height 12px
      text-transform capitalize
      letter-spacing 0
      padding 4px 10px
      border-radius 4px
</style>
