import {http} from './http'
import {AppStorage} from '@/core/Utils'

const resource = "/api/teacher";

export default {
  login(username, password) {
    return http.post(`${resource}/login`, {
      email: username,
      password: password
    }).then(response => {
        if (response.success === true && response.data.email_verified_at) {
          const data = response.data
          const token = data.token
          AppStorage.setUser(data)
          AppStorage.setUserToken(token)
        }

        return AppStorage.getUser()
      }
    );
  },

  passwordRecoveryEmail(email) {
    return http.post(`${resource}/password/forgot`, {
      email: email
    }).then(response => {
        return response
      }
    );
  },
  passwordRecovery(password, email, token) {
    return http.post(`${resource}/password/recover`, {
      password: password,
      email: email,
      token: token
    }).then(response => {
        return response
      }
    );
  },
  loginWithToken(token) {
    return http.post(`${resource}/token/refresh`, {
      refreshToken: token
    }).then(response => {
      AppStorage.setUserToken(response.data.token)
    });
  },
  logout() {
    // remove all data from local storage (including user) to log out
    AppStorage.clear()
  },
}
