<template>
  <div class="wrapper-security-page">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col cols="12" xl="4" lg="6" md="8">
          <div class="wrapper-security-form">
            <h2 class="security-form-subtitle">Verifying Account</h2>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <modal ref="account_verification_success">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">SUCCESSFUL VERIFICATION</h2>
        <p class="text-center">Account successfully verified.<br/>Now you can log in with your credentials</p>
      </template>
      <template #footer>
        <b-button @click="closeSuccessModal()" btn-text="OK"/>
      </template>
    </modal>
    <modal ref="account_verification_error">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">ERROR</h2>
        <p class="text-center">{{errorDescription}}</p>
        <custom-input v-model="email"
                      class="input-email"
                      autocomplete="off"
                      :errors="errorInputDescription"
                      placeholder="Email"
                      type="email"/>
      </template>
      <template #footer>
        <b-button v-if="!verificationEmailButton" @click="closeErrorModal()" btn-text="OK"/>
        <b-button v-if="verificationEmailButton" @click="onVerificationEmail()" btn-text="Resend verification email"/>
      </template>
    </modal>
    <modal ref="account_create_error"></modal>
  </div>
</template>

<script>
import {Modal} from '@/core/components/Modal'
import {Button} from '@/core/components/Button'
import {http} from "@/core/Services/http"
import {AccountService} from '@/core/Services'
import Input from "@/core/components/FormInput/Input"

export default {
  name: "VerificationAccountPage",
  components: {
    Modal,
    'b-button': Button,
    'custom-input': Input,
  },
  mounted() {
    this.verifyAccount()
  },
  data() {
    return {
      errorDescription: 'An error occurred while trying to verify the account, please contact an administrator.',
      verificationEmailButton: false,
      email: '',
      errorInputDescription: [],
      isPlayersTrust: false
    }
  },
  methods: {
    verifyAccount: function () {
      const URL = this.$route.query.url
      const isPlayers = this.$route.query['players-trust']
      this.isPlayersTrust = isPlayers ? Number(isPlayers) : false
      return http.get(URL).then(response => {
        return response
      }).then((response) => {
        if (response.success === true) {
          this.showSuccessModal()
        } else {
          this.showErrorModal()
          if (response.error_code === 24) {
            this.errorDescription = response.message
            this.verificationEmailButton = true
            return
          }
          if (response.error_code === 23) {
            this.errorDescription = 'The verification link has expired. Click the button below to send a new verification link. The new link will expire in 24 hours.',
            this.verificationEmailButton = true
            return
          }
          if (response.message) {
            this.errorDescription = response.message
          }
        }
      })
    },
    showSuccessModal: function () {
      return this.$refs["account_verification_success"].showModal()
    },
    closeSuccessModal: function () {
      return this.$refs["account_verification_success"].hideModal().then(() => {
        if (this.isPlayersTrust) {
          this.goToPlayersTrustApp()
        } else {
          this.goToLoginPage()
        }
      })
    },
    showErrorModal: function () {
      return this.$refs["account_verification_error"].showModal()
    },
    closeErrorModal: function () {
      return this.$refs["account_verification_error"].hideModal().then(() => {
        if (this.isPlayersTrust) {
          this.goToPlayersTrustApp()
        } else {
          this.goToLoginPage()
        }
      })
    },
    onVerificationEmail: function () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (pattern.test(this.email)) {
        AccountService.verificationResend(this.email)
        return this.$refs["account_verification_error"].hideModal().then(() => {
          if (this.isPlayersTrust) {
            this.goToPlayersTrustApp()
          } else {
            this.goToLoginPage()
          }
        })
      }
      this.errorInputDescription = ['Field required']
    },
    goToPlayersTrustApp() {
      let redirectTo
      if (process.env.NODE_ENV === 'production') {
        redirectTo = 'https://players.tp.levelupvillageclassroom.com'
      }
      if (process.env.NODE_ENV === 'dev') {
        redirectTo = 'https://players.tp.dev.levelupvillageclassroom.com'
      }
      return window.location.href = redirectTo ? redirectTo : ''
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/security.styl";

.input-email
  margin-top 15px

</style>
