<template>
  <PageBase>
    <router-view></router-view>
  </PageBase>
</template>

<script>
  import { PageBase } from "@/core/components"
  export default {
    name: "ClassroomView",
    components: {
      PageBase
    },
    data(){
      return {}
    },
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/classroom.styl";
</style>
