<template>
  <b-table :responsive="true"
           sort-icon-left
           :fields="fields"
           :items="taskList"
           table-variant="ligth"
           head-variant="ligth"
           no-local-sorting
           class="custom-table"
           ref="table">
    <template #cell(name)="data">
      <div class="task-container task-container--name" @click="rowClick(true, data.item.id, data.item)" :class="{'task-container--introduction': data.item.isIntroductionTask}">
        <p class="task-name">{{data.item.task_name}}</p>
        <div class="picture-container">
          <div class="content-picture" :style="`background-image: url(${data.item.task_thumbnail})`" />
          <p class="message-label">Step 1: Upload video for your partner</p>
        </div>
      </div>

      <div class="task-container task-container--name task-container--respond" @click="rowClick(false, data.item.id, data.item)" v-if="!data.item.isIntroductionTask">
        <div class="picture-container">
          <div class="content-picture" :style="`background-image: url(${data.item.task_thumbnail})`" />
          <p class="message-label">Step 2: Respond to your partner</p>
        </div>
      </div>
    </template>
    <template #cell(create)="data">
      <div class="task-container task-container--create" @click="rowClick(true, data.item.id, data.item)" :class="{'task-container--introduction': data.item.isIntroductionTask}">
        <div class="column column-tags" :class="isHidden(data.item.is_hidden)">
          <div class="status create-box-color" :class="[createTaskExpired(data.item), data.item.createStatusColor]" @click="clickBlockStatus($event, true, data.item.id, data.item)">
            <div class="status-text status-text--due">My video</div>
            <span class="icon-check-action" v-if="data.item.isCreateTaskCompleted">
              <b-icon icon="check2"
                      class="check"
                      aria-hidden="true"/>
            </span>
            <div v-else>
              <div class="status-text status-text--mm" v-if="data.item.due_date">{{setDueDate(data.item.due_date,'MMM')}}</div>
              <div class="status-text status-text--dd" v-if="data.item.due_date">{{setDueDate(data.item.due_date,'DD')}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="task-container task-container--create task-container--respond task-container--bottom" @click="rowClick(false, data.item.id, data.item)"  v-if="!data.item.isIntroductionTask">
        <div class="column column-tags" :class="isHidden(data.item.is_hidden)">
          <div class="status" @click="clickBlockStatus($event, false, data.item.id, data.item)">
            <div class="status-text status-text--due">Partner Video</div>
            <span class="icon-check-action" v-if="data.item.isPartnerResponseTaskCompleted">
              <b-icon icon="check2"
                      class="check"
                      aria-hidden="true"/>
            </span>
            <div v-else>
              <div class="status-text status-text--mm" v-if="data.item.due_date">{{setDueDate(data.item.due_date,'MMM')}}</div>
              <div class="status-text status-text--dd" v-if="data.item.due_date">{{setDueDate(data.item.due_date,'DD')}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #cell(response)="data">
      <div class="task-container task-container--response" @click="rowClick(true, data.item.id, data.item)" :class="{'task-container--introduction': data.item.isIntroductionTask}">
        <p class="edit-label" @click="taskDetail(true, data.item)">Edit</p>
        <div class="column column-tags-response" :class="isHidden(data.item.is_hidden)" @click="rowClick(true, data.item.id, data.item)">
          <div class="status" @click="clickBlockStatus($event, true, data.item.id, data.item)">
            <div class="status-text status-text--due">Partner Video</div>
            <span class="icon-check-action" v-if="data.item.isPartnerCreateTaskCompleted">
              <b-icon icon="check2"
                      class="check"
                      aria-hidden="true"/>
            </span>
            <div v-else>
              <div class="status-text status-text--mm" v-if="data.item.response_due_date">{{setDueDate(data.item.response_due_date,'MMM')}}</div>
              <div class="status-text status-text--dd" v-if="data.item.response_due_date">{{setDueDate(data.item.response_due_date,'DD')}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="task-container task-container--response task-container--respond task-container--bottom" @click="rowClick(false, data.item.id, data.item)"  v-if="!data.item.isIntroductionTask">
        <div class="column column-tags-response" :class="isHidden(data.item.is_hidden)" @click="rowClick(false, data.item.id, data.item)">
          <div class="status create-box-color" :class="[responseTaskExpired(data.item), data.item.responseStatusColor]" @click="clickBlockStatus($event, false, data.item.id, data.item)">
            <div class="status-text status-text--due">My video</div>
            <span class="icon-check-action" v-if="data.item.isResponseTaskCompleted">
              <b-icon icon="check2"
                      class="check"
                      aria-hidden="true"/>
            </span>
            <div v-else>
              <div class="status-text status-text--mm" v-if="data.item.response_due_date">{{setDueDate(data.item.response_due_date,'MMM')}}</div>
              <div class="status-text status-text--dd" v-if="data.item.response_due_date">{{setDueDate(data.item.response_due_date,'DD')}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-table>
</template>

<script>
import {TaskMixins} from "@/modules/MyClassrooms/Mixins"
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"
import {taskCategories} from "@/core/Utils"
import moment from "moment"

export default {
  name: 'TasksTable',
  mixins: [TaskMixins, ExchangesMixins],
  components: {
  },
  props: {
    tasks: {
      type: Array
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'TASK NAME'
        },
        {
          key: 'create',
          label: 'ORIGINAL POST'
        },
        {
          key: 'response',
          label: 'RESPONSE'
        }
      ]
    }
  },
  mounted() {

  },
  computed: {
    taskList() {
      return this.tasks.map(task => {
        const submission = task.teacher_submissions?.find((submission) => submission.classroom_id === this.myClassroom.id)

        task.isCreateTaskCompleted = !!submission?.id
        task.isPartnerCreateTaskCompleted = !!task.teacher_responses?.find((response) => response.classroom_id !== this.myClassroom.id)?.id

        const partnerSubmission = task.teacher_submissions?.find((submission) => submission.classroom_id !== this.myClassroom.id)
        const response = task.teacher_responses?.find((response) => response.classroom_id === this.myClassroom.id)
        task.isResponseTaskCompleted = !!(partnerSubmission?.id && response?.id)
        task.isPartnerResponseTaskCompleted = !!partnerSubmission?.id

        task.createStatusColor = task.isCreateTaskCompleted || (task.task_category === taskCategories.activity || task.task_category === taskCategories.reflection) ? 'status-completed-color' : ''
        task.responseStatusColor = (partnerSubmission?.id && !response?.id) || (task.task_category !== taskCategories.activity && task.task_category !== taskCategories.reflection) ? '' : 'status-completed-color'
        task.taskCompleted = task.isCreateTaskCompleted && task.isResponseTaskCompleted
        task.isIntroductionTask = task.task_category === taskCategories.introduction
        return task
      })
    }
  },
  methods: {
    isHidden (hidden) {
      return hidden ? 'is-hidden' : ''
    },
    rowClick (isCreate, taskId, task) {
      if (!this.classroomExchangeExpired) {
        this.$emit('on-click-task', {isCreate, taskId, task})
      }
    },
    clickBlockStatus (event, isCreate, taskId, task) {
      event.stopPropagation()
      if (!this.classroomExchangeExpired) {
        this.$emit('on-click-block-status', {isCreate, taskId, task})
      }
    },
    taskDetail (isCreate, task) {
      if (!this.classroomExchangeExpired) {
        this.$emit('on-task-detail', {isCreate, ...task})
      }
    },
    setDueDate (date, string) {
      switch (string) {
        case 'MMM':
          return moment(date).format('MMM')
        case 'DD':
          return moment(date).format('DD')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.icon-check-action
  width 28px
  height 28px
  border-radius 50%
  border 2px solid color-3
  color color-3
  display flex
  justify-content center
  align-items center
  margin 0 auto
  margin-top 6px
  min-width 25px

  .check
    font-size 20px

.custom-table
  ::v-deep
    thead
      th
        padding-left 0

      th:first-child
        padding-left .75rem

    tbody
      td
        padding 0

.task-container, .task-container--create, .task-container--response
  background-color #fff
  border 1px solid color-2
  padding 7px 8px 20px 8px
  border-right none
  border-top-left-radius 4px

  .task-name
    text-overflow ellipsis
    max-width 400px
    overflow hidden
    font font-opensans-semibold
    text-wrap nowrap

  .picture-container
    display flex
    border solid 1px #8d91c8
    border-radius 4px
    border-right none
    border-top-right-radius 0
    border-bottom-right-radius 0

  .content-picture
    width 80px
    height 80px
    background-repeat no-repeat
    background-size cover
    background-position center

  .message-label
    margin-left 10px
    margin-top 28px
    text-decoration underline
    cursor pointer

.task-container--name
  padding-right 0

.task-container--create, .task-container--response
  border-right none
  border-left none
  padding-top 45px
  border-radius 0
  padding-left 0

  .is-hidden
    color color-52

    .status
      background color-52

      .status-text--due
        color color-33

.task-container--create
  padding-right 0

.task-container--response
  border-right 1px solid color-2
  border-top-right-radius 4px
  position relative

  .edit-label
    position absolute
    color color-link
    cursor pointer
    text-decoration underline
    right 10px
    top 10px

.task-container
  border-bottom none

.task-container--introduction
  border-bottom 1px solid color-2
  margin-bottom 10px

.task-container--respond
  border-top none
  border-top-right-radius 0
  border-top-left-radius 0
  border-bottom 1px solid color-2
  border-bottom-right-radius 4px
  border-bottom-left-radius 4px
  margin-bottom 10px

.task-container--bottom
  padding-top 7px

.column-tags-response
  border-right solid 1px #8d91c8
  border-top-right-radius 4px
  border-bottom-right-radius 4px

.column-tags, .column-tags-response
  display flex
  text-align center
  border-top solid 1px #8d91c8
  border-bottom solid 1px #8d91c8

  .status
    background #c5c7fe
    width 95px
    height 80px
    display flex
    flex-direction column
    justify-content center

    .status-text
      color #414D94
      text-transform uppercase
      font-weight bolder
      font-family "Open Sans"
      text-align center
      font-size 11px

    .status-text--due
      white-space normal !important
      text-decoration underline
      cursor pointer

    .status-text--mm
      color white
      font-size 15px
      margin-top 0

    .status-text--dd
      color white
      font-size 19px
      margin-top 0
      line-height 16px

  .create-box-color,
  .response-box-color
    background color-54

  .status--expired
    background color-38

    .status-text--due
      color color-1

  .status-completed-color
    background color-54

    .status-text--due
      color color-7
</style>
