<template>
  <div class="content-image">
    <div class="content-image-top" @click="$emit('on-show-submission-modal', submission)">
      <b-avatar :src="thumbnail" icon="people-fill" square/>
      <div class="play">
        <span class="icon-play-video"></span>
      </div>
    </div>

    <div class="task-name">{{ submission.task_name }}</div>
    <div class="submission-type">{{ submissionType }}</div>

    <div v-if="submission.status === 'approved' " class="status status-approved">
      <span class="icon-status-approved"></span>
      <span class="label">{{ submission.status }}</span>
    </div>
    <div v-else class="status">
      <span class="icon-status-submitted"></span>
      <span class="label">Submitted</span>
      <span class="label-desc">({{ submission.status }})</span>
    </div>
    <div class="actions" @click.stop="">
      <b-form-checkbox class="custom-checkbox-primary"
                       @input="onInputSelectedVideo"
                       v-model="selectedVideoValue"/>
    </div>
  </div>
</template>

<script>
import { UploadService } from "@/core/Services";

export default {
  name: "SubmissionsGridViewItem",
  data () {
    return {
      thumbnail: null,
    }
  },
  props: {
    submission: {
      type: Object,
      required: true
    },
    selectedVideo: {
      type: [Boolean, Number],
      required: true
    }
  },
  async mounted () {
    if (!this.isTextResponse && this.submission.url) {
      const url = this.submission.url.split('/')
      const key = url[url.length - 1]

      this.thumbnail = await UploadService.getVideoThumbnail(key)
    }
  },
  methods: {
    onInputSelectedVideo (value) {
      this.$emit('on-selected-video', { id: this.submission.id, value: value })
    }
  },
  computed: {
    selectedVideoValue: {
      get: function () {
        return this.selectedVideo ?? 0
      },
      set: function () {
      }
    },
    submissionType: function () {
      return this.submission.is_response ? 'Response' : 'Submission'
    },
    isTextResponse: function () {
      return this.submission.is_response && this.submission.response_type === 'text'
    },
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

task-name-height = 20px
submission-type-height = 20px
status-height = 42px
content-image-top-height = "calc(100% -  %s)" % (task-name-height + submission-type-height + status-height)

.content-image
  position absolute
  bottom 0
  width 100%
  height 100%

  &-top
    position relative
    height content-image-top-height
    cursor pointer

    .b-avatar
      border-radius 6px !important
      width 100%
      height 100%

    .play
      position absolute
      width 45px
      height 45px
      top 0
      left 0
      bottom 0
      right 0
      margin auto

      span
        font-size 2.7em
        vertical-align middle

        &:before
          color white

  .actions
    position absolute
    top 0
    left 0
    padding 5px
    width 100%
    display flex
    justify-content space-between

    .btn-tooltip-more-info
      background-color transparent
      padding 0
      color color-10
      font font-opensans-regular
      font-size 21px
      border 0
      line-height 0.8em
      margin-top: -10px;

  .task-name
    bottom 62px
    height task-name-height
    width 100%
    text-align center
    font-size 0.8rem
    background-color color-1
    overflow hidden
    text-overflow ellipsis
    white-space nowrap

  .submission-type
    bottom 42px
    height submission-type-height
    width 100%
    display flex
    justify-content center
    align-items center
    font-size 0.8rem
    background-color color-1

  .status
    bottom 0
    height status-height
    background-color rgba(color-30, 0.95)
    width 100%
    border: 1px solid color-30;
    border-radius: 0 0 4px 4px;
    display flex
    justify-content center
    align-items center
    font-size 0.8rem

    .icon-status-approved
    .icon-status-submitted
      padding-right .1em
      font-size 1.5em

      &:before
        color color-3

    &-approved
      background-color rgba(color-35, 0.95)
      border none

    .label
      font font-opensans-bold
      font-size 0.8rem
      text-transform capitalize

    .label-desc
      font font-opensans-regular
      font-size 0.8rem
      font-style italic
      text-transform capitalize
</style>
