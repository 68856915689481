<template>
  <div class="form-group">
    <label v-if="label" :for="`${type}-${_uid}`">{{ label }}</label>

    <b-form-input :id="`input-${type}-${_uid}`" v-model="inputValue" :type="type" :class="customClassInput"
                  :placeholder="placeholder" :disabled="disabled" :state="state"
                  :aria-describedby="`input-live-feedback-${_uid}`"
                  :maxlength="maxlength"
                  :min="min"
                  :max="max"
                  @change="$emit('change', $event)"
                  @keyup="$emit('keyup', $event)"
                  @keypress="$emit('keypress', $event)"
                  @input="onInput" @blur="$emit('blur')"/>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="`input-live-feedback-${_uid}`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`input-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: "Input",
  data: function () {
    return {}
  },
  props: {
    maxlength: {
      type: [Number,String],
      default: 150
    },
    min: {
      type: [Number,String],
      default: null
    },
    max: {
      type: [Number,String],
      default: null
    },
    value: {
      type: [Number,String],
      default: ''
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: function () {
        return []
      }
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    customClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  },
  computed: {
    customClassInput() {
      const className = {
        '': true
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    },
    /**
     * false (denotes invalid state) is great for when there's a blocking or required field. A user must fill in this field properly to submit the form.
     * true (denotes valid state) is ideal for situations when you have per-field validation throughout a form and want to encourage a user through the rest of the fields.
     * null Displays no validation state (neither valid nor invalid)
     * @returns {boolean}
     */
    state() {
      return !this.errors.length ? null : false
    },
    inputValue: {
      get: function () {
        return this.value ?? ''
      },
      set: function () {
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
