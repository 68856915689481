<template>
  <div class="step-item">
    <div class="step-item-title step-item-title-primary">My Students</div>
    <div class="step-item-title">Student Age Range</div>
    <form-checkbox-group v-model="$v.form.ageRanges.$model"
                         :validator="$v.form.ageRanges"
                         :is-object-array="true"
                         value-field="id"
                         text-field="name"
                         class="custom-form-my-instruction step-item-input"
                         :options="age_ranges"
                         name="ageRanges"
                         custom-class="custom-checkbox-primary custom-checkbox--two-columns"/>

    <div class="step-item-title" v-if="isSubjectWorldLanguages">My Students' Primary Language</div>
    <form-radio v-model="$v.form.nativeLanguageId.$model"
                v-if="isSubjectWorldLanguages"
                :options="languages"
                name="primary-language"
                class="custom-form-my-instruction step-item-input form-radio"
                custom-class="common-input"/>

    <div class="step-item-title" v-if="isSubjectWorldLanguages">My Students Are Learning</div>
    <form-checkbox-group v-model="$v.form.instructionLanguages.$model"
                         :validator="$v.form.instructionLanguages"
                         v-if="isSubjectWorldLanguages"
                         :is-object-array="true"
                         value-field="id"
                         text-field="name"
                         class="custom-form-my-instruction step-item-input"
                         :options="languages"
                         name="instructionLanguages"
                         custom-class="custom-checkbox-primary custom-checkbox--one-column"/>

    <div class="step-item">
      <b-button type="button" class="button primary --sm back"
                @click="$emit('change-step', {step: 5, form})"
                btn-text="Back"/>
      <b-button type="button" class="button primary --sm"
                @click="saveData"
                :disabled="$v.stepValid.$invalid"
                btn-text="Finish"/>
    </div>
    <modal id="verifying-account-modal" @hidden="goToLogoutPage">
      <template #body>
        <h2 class="modal-title">VERIFYING ACCOUNT</h2>
        <p class="text-center">Our team is verifying your account. Once verified you can begin setting up your
          classrooms and connecting with partners around the world. Watch your email for more information.</p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('verifying-account-modal')" btn-text="OK"/>
      </template>
    </modal>
    <modal id="profile-error-modal">
      <template #body>
        <h2 class="modal-title">AN ERROR OCCURS</h2>
        <p class="text-center">An error happen, If you would like help, please contact us at
          support@levelupvillage.com</p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('error-modal-modal')" btn-text="OK"/>
      </template>
    </modal>
    <b-overlay no-wrap :show="isSendingData"></b-overlay>
  </div>
</template>

<script>
import { Button } from "@/core/components/Button"
import { required, requiredIf } from "vuelidate/lib/validators"
import { MyClassRoomsMixins } from "@/modules/MyClassrooms/Mixins"
import FormCheckboxGroup from "@/core/components/Form/CheckboxGroup"
import Radio from "@/core/components/FormInput/Radio"
import { Modal } from "@/core/components/Modal"
import {userTypeMixins} from "@/core/Mixins"

export default {
  name: 'Step6',
  mixins: [MyClassRoomsMixins, userTypeMixins],
  components: {
    Modal,
    'b-button': Button,
    'form-checkbox-group': FormCheckboxGroup,
    'form-radio': Radio,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        nativeLanguageId: 1,
        instructionLanguages: [],
        ageRanges: [],
        preferredCommunication: 'email',
      },
      isSendingData: false
    }
  },
  validations () {
    return {
      form : {
        nativeLanguageId: {
          required: requiredIf(() => {
            return this.isSubjectWorldLanguages
          })
        },
        instructionLanguages: {
          required: requiredIf(() => {
            return this.isSubjectWorldLanguages
          }),
        },
        ageRanges: {
          required
        }
      },
      stepValid: ['form.nativeLanguageId', 'form.instructionLanguages', 'form.ageRanges']
    }
  },
  computed: {
    isSubjectWorldLanguages() {
      let isWorldLanguages = null
      this.profileData?.subjects.map(subject => {
        if (subject.type === 'world_languages') {
          return isWorldLanguages = subject.type
        }
      })
      return isWorldLanguages
    }
  },
  methods: {
    filterNumbers(string) {
      return string?.split(/ /)[0].replace(/[^\d]/g, '')
    },
    async saveData () {
      const user = {...this.user}
      const profile = {...this.profileData, ...this.form}

      this.isSendingData = true
      try {
        const response = await this.updateProfile({
          avatar: profile.avatar,
          first_name: profile.firstName,
          last_name: profile.lastName,
          about_info: profile.aboutInfo,
          country_id: profile.country,
          state_id: profile.state,
          city_name: profile.city,
          school_id: profile.schoolId === 'no_figure' ? null : profile.schoolId,
          school_address: profile.schoolAddress,
          school_name: profile.schoolName,
          school_website: profile.schoolWebsite,
          school_phone_number: profile.schoolPhoneNumber,
          subjects: profile.subjects,
          native_language_id: this.isSubjectWorldLanguages ? profile.nativeLanguageId : '',
          instruction_languages: this.isSubjectWorldLanguages ? profile.instructionLanguages : '',
          age_ranges: profile.ageRanges,
          preferred_communication: profile.preferredCommunication
        })

        if (response) {
          if (!user.is_email_validated || !user.is_teacher_validated) {
            this.$bvModal.show('verifying-account-modal')
          } else {
            if (this.isLibrarian) {
              this.goToLibraryConnectionsPage()
            } else {
              const withClassroom = this.classrooms.length
              withClassroom ? this.goToMyClassroomsPage() : this.goToCreateClassroomPage()
            }
          }
        } else {
          this.$bvModal.show('profile-error-modal')
        }
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error creating report.'

        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.isSendingData = false
      }
    }
  },
  async mounted() {
    this.form = {...this.form, ...this.profileData}
  },
  watch: {
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.step-item
  text-align center

  .button
    display inline
    margin-top 2rem

    &.back
      margin-right 4rem

    &:disabled
      border-width 0 !important

  &-title
    font-size 1.5rem
    margin-top 25px

  &-title-primary
    margin-top 0

  &-subtitle
    margin 18px 0
    font-size 1rem
    margin-top 25px

  &-input
    max-width 470px
    margin 0 auto
    margin-bottom 12px

  @media (max-width 350px)
    .button
      margin 0 5px
      margin-top 2rem

      &.back
        margin-right 0

  .form-radio
    text-align left

  ::v-deep
    .custom-form-my-instruction
      margin-top 15px

      .custom-checkbox-primary
        margin-top 4px

    .custom-checkbox-primary
      display grid
      grid-template-columns 1fr 1fr 1fr
      gap 10px 10px
      grid-gap 10px 10px

    .custom-checkbox--two-columns
      grid-template-columns 1fr 1fr

    .custom-checkbox--one-column
      grid-template-columns 1fr
</style>
