<template>
  <b-container class="partner-detail">
    <b-row class="partner-detail-row">
      <b-col cols="6" sm="6" md="3" class="partner-detail-picture">
        <b-avatar class="partner-detail-avatar" :src="partner.avatar" square></b-avatar>
      </b-col>
      <b-col cols="6" sm="6" md="4" class="partner-detail-profile">
        <div class="partner-detail-name">{{ full_name }}</div>
        <div class="partner-detail-country">{{ ubigeo }}</div>
        <div class="partner-detail-country-flag"><span :class="flag"></span></div>
      </b-col>
      <b-col cols="12" sm="12" md="5" class="partner-detail-school">
        <div class="partner-detail-school-item"><span>School: </span>{{ partner.school.name }}</div>
        <div class="partner-detail-school-item"><span>Subject(s): </span>{{ instruction_subjects }}</div>
        <div class="partner-detail-school-item"><span>Students’ Primary Language: </span>{{ native_language }}</div>
        <div class="partner-detail-school-item"><span>Students Are Learning: </span>{{ instruction_languages }}</div>
        <div class="partner-detail-school-item"><span>Ages: </span>{{ ages }}</div>
      </b-col>
    </b-row>
    <b-row class="partner-detail-row">
      <b-col cols="12" class="partner-detail-bio">
        <div class="partner-detail-bio-title">About Me:</div>
        <div class="partner-detail-bio-text">{{ partner.about_info }}</div>
      </b-col>
    </b-row>
    <b-row class="partner-detail-row">
      <b-col cols="12" class="partner-detail-classroom" :class="{'partner-detail-classroom-match': !index}"
             :key="`partner-detail-classroom-${index}`" v-for="(classroom, index) in partner.classrooms">

        <div v-if="!index" class="partner-detail-classroom-match-flag">
          <i class="fa fa-star"></i><span>Classroom Match</span>
        </div>

        <div class="partner-detail-classroom-item"><span>Classroom Name:</span> {{ classroom.name }}</div>
        <div class="partner-detail-classroom-item" v-if="classroom.level"><span>Level:</span> {{ classroom.level.name }}
        </div>
        <div class="partner-detail-classroom-item" v-if="classroom.number_students_range">
          <span>Number of Students:</span> {{ classroom.number_students_range.name }}
        </div>
        <div class="partner-detail-classroom-item" v-if="classroom.lang_native"><span>Students’ Primary Language:</span>
          {{ classroom.lang_native.name }}
        </div>
        <div class="partner-detail-classroom-item" v-if="classroom.lang_instruction"><span>Students Are Learning:</span>
          {{ classroom.lang_instruction.name }}
        </div>
        <div class="partner-detail-classroom-item" v-if="classroom.age_range"><span>Ages:</span>
          {{ classroom.age_range.name }}
        </div>
        <div class="partner-detail-classroom-item"><span>Desired Start Date:</span>
          {{ classroom.start_date | dateMonthDayYear2 }}
        </div>

        <b-button @click="connectClassroom(classroom)" class="partner-detail-classroom-connect"
                  btn-text="CONNECT THIS CLASSROOM"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {Button} from "@/core/components/Button";

export default {
  name: "PartnerDetail",
  props: {
    partner: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
  },
  components: {
    'b-button': Button,
  },
  methods: {
    connectClassroom(classroom) {
      this.$emit('on-show-connect-classroom', classroom)
    }
  },
  computed: {
    flag() {
      let result = {
        'fi': true
      }

      if (this.partner.country && this.partner.country.iso) {
        result[`fi-${this.partner.country.iso.toLowerCase()}`] = true
      } else {
        result[`fi-xx`] = true
      }

      return result
    },
    full_name() {
      return `${this.partner.first_name} ${this.partner.last_name.charAt(0)}`
    },
    isUsa() {
      return this.partner.country.iso.toLowerCase() === 'us'
    },
    ubigeo() {
      let result = []

      if (this.partner.city) {
        result.push(this.partner.city.name)
      }
      if (this.isUsa) {
        result.push(this.partner.state.name)
      }

      result.push(this.partner.country.name)

      return result.join(', ')
    },
    instruction_subjects() {
      let result = []

      for (let i = 0; i < this.partner.subjects.length; i++) {
        result.push(this.partner.subjects[i].name)
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    native_language() {
      if (!this.partner.native_language) {
        return 'not set'
      }

      return this.partner.native_language.name
    },
    instruction_languages() {
      let result = []

      for (let i = 0; i < this.partner.instruction_languages.length; i++) {
        result.push(this.partner.instruction_languages[i].name)
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
    ages() {
      let result = []

      for (let i = 0; i < this.partner.age_ranges.length; i++) {
        result.push(this.partner.age_ranges[i].name)
      }

      if (!result.length) {
        return 'not set'
      }

      return result.join(', ')
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.partner-detail
  font font-opensans-regular
  font-size 1.15rem
  padding 0 !important

  &-row
    margin-bottom 1rem

  &-picture
  &-profile
  &-school
    margin-top 1rem

  &-avatar
    width 100%
    height 13rem

  &-name
    font-weight bold
    margin-bottom 20px
    text-transform capitalize

  &-country
    margin-bottom 17px

    &-flag
      font-size 6rem
      line-height 1

  &-school-item
    span
      font-weight bold

  &-bio
    font-size 1.15rem

    &-title
      font-weight bold

    &-text
      max-height 8rem
      overflow-y auto

  &-classroom
    &-match
      border 2px solid color-45
      background-color color-46

      &-flag
        background color-44
        bottom 0
        width 15rem
        padding 0.2rem 1rem
        vertical-align middle
        margin 1rem 0

        .fa-star
          color color-47

    &-item
      word-break break-word

      span
        font-weight bold

    &-connect
      margin 1rem 0

@media (max-width: 425px)
  .partner-detail
    &-row
      margin-right 0
      margin-left 0
      width 100%

@media (max-width: 360px)
  .partner-detail
    &-row
      margin-right 0
      margin-left 0
      width 100%

    &-picture
      flex 100%
      max-width 100%

    &-profile
      flex 100%
      max-width 100%

    &-school
      flex 100%
      max-width 100%

</style>

<style lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#partner_detail_modal
  @media (max-width: 360px)
    .modal-dialog
      margin 0

</style>
