<template>
  <modal modal-class="course-list-modal"
         :hide-footer="true"
         @hide="hideModal"
         id="course-list-modal"
         ref="course-list-modal"
         size="xl">
    <template #body>
      <div class="wrapper-classroom-content">
        <div class="wrapper-classroom-content__inner wrapper-classroom-content__inner--course-library">
          <div class="container-course-content" v-if="false">
            <div class="container-header-filters">
              <div class="container-item-filter">
                <div class="common-search-bar-container">
                  <input v-on:keyup.enter="getCoursesForClassroom(true)" type="text" placeholder="SEARCH"
                         class="common-search-bar" v-model="searchString">
                </div>
              </div>
            </div>
          </div>
          <div class="separator-filters"></div>
          <div class="container-course-content">
            <div class="container-courses">
              <CourseCard v-for="(course) in courses" :course="course" :key="`course-${course.id}`"
                          :no-add-button="noAddButton"
                          @on-course-detail="seeCourseDetails"
                          @on-add-course-content="selectCourse"
                          :is-librarian="true">
              </CourseCard>
              <b-overlay no-wrap :show="isLoading"/>
            </div>
          </div>
          <modal ref="no_results_modal">
            <template #header>
            </template>
            <template #body>
              <h2 class="modal-title">NO COURSES FOUND</h2>
              <p class="text-center">
                No courses were found matching the entered criteria.
              </p>
            </template>
            <template #footer>
              <b-button @click="closeNoResultsModal()" btn-text="OK"/>
            </template>
          </modal>
          <course-detail-popup :course-pdf-url="coursePdf" ref="course-detail-popup"/>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/core/components/Modal/Modal'
import CourseCard from "@/modules/Classroom/Components/CourseCard"
import CourseDetailPopup from './CourseDetailPopup'

export default {
  name: "CourseListModal",
  components: {
    Modal,
    CourseCard,
    CourseDetailPopup
  },
  data() {
    return {
      selectedCourse: {},
      coursePdf: '',
      isLoading: false,
      searchString: ''
    }
  },
  props: {
    noAddButton: {
      type: Boolean,
      default: false
    },
    isCourseLibrary: {
      default: false
    },
    courses: {
      required: true
    }
  },
  methods: {
    hideModal () {
      this.$emit('close-course-list', {noCourseSelected: !this.selectedCourse.id})
    },
    showModal () {
      this.selectedCourse = {}
      this.$bvModal.show('course-list-modal')
    },
    getCoursesForClassroom () {
      if (!this.courses.length) {
        this.$refs["no_results_modal"].showModal()
        this.getCourses()
      }
    },
    async seeCourseDetails (course) {
      this.coursePdf = course?.pdf_url
      await this.$nextTick()
      this.$refs["course-detail-popup"].openPopup()
    },
    selectCourse: function (course) {
      this.selectedCourse = course
      this.$emit('on-select-course', course)
    },
    closeNoResultsModal: function () {
      this.$refs["no_results_modal"].hideModal()
    }
  },
  computed: {
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
.wrapper-classroom-content
  border-top 2px solid color-13
  background-color rgba(color-14, 0.8)
  padding 0 15px 70px 15px
  margin-bottom 25px

  .wrapper-classroom-content__inner
    margin 0px auto
    max-width 1345px
    width 100%
    .container-course-content
      max-width 1190px
      width 100%
      margin 0px auto

    .separator-filters
      width 100%
      height 3px
      background-color color-1
      margin 23px 0px 35px 0px

    .container-courses
      display grid
      grid-template-columns repeat(3, 1fr)
      gap 40px 48px
      grid-gap 40px 48px

      ::v-deep
        .course-card
          min-height 200px
          background-color color-1
          border-radius(4px)
          border 1px solid color-15
          box-shadow(course-card-shadow)
          &.card-scratch
            flexbox(flex)
            align-items(center)
            justify-content(center)
            flex-direction(column)
            padding 15px
            .course-card__btns
              button
                width 140px

          &__title
            text-transform uppercase
            color color-8
            letter-spacing 0.75px
            font-weight 700
            font-size 15px

          &__btns
            margin-top 25px
            flexbox(flex)
            align-items(center)
            justify-content(center)
            padding 0px 10px
            button
              margin-right 12px
              min-width auto
              width 50%
              &:last-child
                margin-right 0px

          &__header
            background-color color-14
            border-radius(4px 4px 0px 0px)
            padding 15px 17px
          &__body
            padding 15px 19px 30px 19px
            flexbox(flex)
            flex-direction(column)
            justify-content(space-between)
          &__info
            &-item
              font-size 13px
              color color-8
              margin-bottom 7px
              &:last-child
                margin-bottom 0px

  @media (max-width: 1200px)
    .wrapper-classroom-content
      &__inner
        .container-courses
          grid-template-columns: repeat(2, 1fr);
          gap: 30px 30px;
          grid-gap: 30px 30px;

  @media (max-width: 992px)
    .wrapper-classroom-content
      &__inner
        .container-courses
          grid-template-columns: repeat(1, 1fr);
</style>
<style lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
#course-list-modal
  .modal-dialog
    .modal-content
      .modal-body
        background-color rgba(color-14, 0.8)
</style>
