import {http} from './http'
const resource = "/api/teacher";

export default {
  getExchangeTokens(subject_id) {
    return http.get(`${resource}/exchange-tokens`, {params: {filters: {subject_id}}}).then(response => {
      return response
    })
  },
  purchaseExchangeTokens( data = []) {
    return http.post(`/api/teacher/exchange-tokens-purchases`, data).then(response => {
      return response
    });
  },
}
