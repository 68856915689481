<template>
  <div class="flag-inappropiate-wrapper">
    <b-nav-item-dropdown class="flag-inappropriate-menu"
                         :no-caret="true">
      <template #button-content>
        <b-icon icon="three-dots-vertical"
                class="flag-inappropriate__icon"/>
      </template>
      <b-dropdown-item @click="showFlagInappropriateModal">
        Flag as Inappropriate
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <flag-inappropriate-modal ref="flag-inappropriate-modal"
                              :classroom-id="classroomId"
                              :submission-id="submissionId"/>
  </div>
</template>

<script>
import FlagInappropriateModal from './FlagInappropriateModal.vue'

export default {
  name: "FlagAsInappropiate",
  components: {
    FlagInappropriateModal
  },
  data: function () {
    return {}
  },
  props: {
    classroomId: {
      type: [Number, String]
    },
    submissionId: {
      type: [Number, String]
    }
  },
  methods: {
    showFlagInappropriateModal () {
      this.$emit('show-flag-inappropriate-modal')
      this.$refs['flag-inappropriate-modal'].showModal()
    }
  },
  computed: {

  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.flag-inappropriate__icon
  font-size 32px !important
  color color-8

.flag-inappropriate-menu
  list-style none
  right 8px

  ::v-deep
    .nav-link
      padding 0
      outline none
      color color-8

    .dropdown-menu
      left 30px !important
      top -10px !important
      font font-opensans-bold
      @media (max-width: 767px)
        font-size 12px
</style>
