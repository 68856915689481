<template>
  <div class="wrapper-my-classrooms-page">
    <b-container class="my-classrooms-page-container">
      <b-row>
        <b-col cols="12">
          <div class="wrapper-my-classrooms-content notifications-header">
            <div class="wrapper-my-classrooms-content__inner">
              <div class="container-course-content">
                <div class="container-courses">
                  <h2 class="security-form-subtitle page-name mb-1">NOTIFICATION LIST</h2>
                  <div>Direct messages and notifications</div>
                  <div class="separator-filters"></div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="12">
          <div class="wrapper-my-classrooms-content">
            <div class="wrapper-my-classrooms-content__inner">
              <div class="container-course-content">
                <div class="container-header-filters">
                  <div class="container-item-filter container-filter-by">
                    <select-dropdown
                      placeholder="FILTER BY"
                      v-model="form.order_by"
                      :options="filters"
                      @input="getNotifications" class-select="custom-dropdown--md custom-dropdown--secondary"/>
                  </div>
                  <div class="container-item-filter">
                    <div class="common-search-bar-container">
                      <input @keydown.enter="getNotifications" v-model="form.search_string" type="text"
                             placeholder="SEARCH" class="common-search-bar">
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator-filters"></div>
              <div class="container-course-content">
                <teacher-table table-class-content="table-body-submission" :rows="notifications" view="list">
                  <template #tableHeader>
                    <div class="column column-bell"></div>
                    <div class="column column-from">
                      <div class="order">
                        <span class="icon-caret-top order-top"></span>
                        <span class="icon-caret-bottom order-bottom"></span>
                      </div>
                      <span class="text">FROM</span>
                    </div>
                    <div class="column column-subject">
                      <div class="order">
                        <span class="icon-caret-top order-top"></span>
                        <span class="icon-caret-bottom order-bottom"></span>
                      </div>
                      <span class="text">SUBJECT</span>
                    </div>
                    <div class="column column-user">
                      <div class="order">
                        <span class="icon-caret-top order-top"></span>
                        <span class="icon-caret-bottom order-bottom"></span>
                      </div>
                      <span class="text">USER</span>
                    </div>
                    <div class="column column-date">
                      <div class="order">
                        <span class="icon-caret-top order-top"></span>
                        <span class="icon-caret-bottom order-bottom"></span>
                      </div>
                      <span class="text">DATE</span>
                    </div>
                    <div class="column column-open"></div>
                  </template>
                  <template v-slot:tableRowList="slotProps">
                    <div class="column column-bell">
                      <span v-if="slotProps.row.status === 'unread'" class="icon-notification-dark"></span>
                    </div>
                    <div class="column column-from">{{ removeComma(slotProps.row.from) }}</div>
                    <div class="column column-subject">{{ notificationSubject(slotProps.row) }}</div>
                    <div class="column column-user">{{ notificationUserName(slotProps.row) }}</div>
                    <div class="column column-date">{{ notificationDate(slotProps.row) }}</div>
                    <div class="column column-open">
                      <a href="javascript:void(0)" @click="openNotification(slotProps.row)">Open</a>
                    </div>
                  </template>
                </teacher-table>
                <!-- Use text in props -->
                <b-pagination v-if="total_rows > 0" v-model="form.page" :total-rows="total_rows" :per-page="10" align="center"
                              first-text="First" prev-text="Prev" next-text="Next" last-text="Last"/>
                <b-overlay :show="isLoadingNotifications" no-wrap/>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <alert-modal ref="alert-modal" :alert-id="alertId"/>
    <modal id="video-fail-modal"
           size="xl"
           modal-class="modal--alert"
           :hide-footer="true">
      <template #body>
        <p v-if="!video.is_response">{{video.student_first_name}} {{video.student_last_initial}}'s video for {{video.task_name}} submitted on {{video.created_at}} failed to process correctly. If they have not already done so, please invite the student to resubmit their video.</p>
        <p v-else>{{video.student_first_name}} {{video.student_last_initial}}'s video response to {{video.partner_first_name}} {{video.partner_last_initial}} for {{video.task_name}} submitted on {{video.created_at}} failed to process correctly. If they have not already done so, please invite the student to resubmit their video response.</p>
        <b-overlay no-wrap :show="videoLoading"/>
      </template>
    </modal>
    <masquerade-whole-class ref="masqueradeModal"/>
  </div>
</template>

<script>
import {SelectDropdown} from "@/core/components/SelectDropdown";
// import {AppStorage, Notifications, Utils} from "@/core/Utils";
import {AppStorage, Notifications} from "@/core/Utils";
import {NotificationsService} from "@/core/Services";
import TeacherTable from "@/modules/Classroom/Components/TeacherTable";
import AlertModal from '@/core/components/Alert/AlertModal.vue';
import {UserMixins} from "@/modules/Classroom/Mixins"
import { Modal } from '@/core/components/Modal'
import {ClassroomService} from "@/core/Services"
import MasqueradeWholeClass from '@/core/components/MasqueradeWholeClass/MasqueradeWholeClass';
import moment from 'moment'

export default {
  name: "NotificationsPage",
  mixins: [UserMixins],
  components: {
    'select-dropdown': SelectDropdown,
    TeacherTable,
    AlertModal,
    MasqueradeWholeClass,
    Modal
  },
  data() {
    return {
      user: AppStorage.getUser(),
      form: {
        order_by: '',
        search_string: '',
        page: 1
      },
      total_rows: 0,
      filters: [
        {id: 'newest', name: 'Newest'},
        {id: 'oldest', name: 'Oldest'},
        {id: 'unread', name: 'Unread'},
      ],
      notifications: [],
      isLoadingNotifications: true,
      interval: null,
      alertId: null,
      userTeacher: JSON.parse(localStorage.getItem('user')),
      video: {},
      videoLoading: false,
    }
  },
  mounted() {
    this.getNotifications().then(() => {
      this.interval = setInterval(() => {
        this.getNotifications(false)
      }, 15000)
    })
  },
  methods: {
    removeComma(name) {
      return name ? name.split('').filter((char) => char !== ',').join('') : 'Unknown'
    },
    notificationSubject: function (notification) {
      const subjects = {
        'teacher_student_video_posted': 'Video Posted',
        'teacher_student_response_to_video': 'Response Posted',
        'teacher_student_response_to_message': 'Message Reply Posted',
        'student_partner_responded_to_their_video': 'You have received a response',
        'teacher_classroom_expired': 'Classroom Exchange Expired',
      }

      // return subjects.hasOwnProperty(notification.type) ? subjects[notification]: notification.subject
      return notification.type in subjects ? subjects[notification.type] : notification.subject
    },
    notificationDate(notification) {
      const dates = {
        'teacher_classroom_expired': notification?.subject.split('|')[1]
      }
      if (notification.type in dates) {
        return moment(String(dates[notification.type])).format('M/D/YYYY')
      }
      return moment(String(notification.created_at)).fromNow()
    },
    notificationUserName: function (notification) {
      if ([Notifications.type.teacherAlertCreated,
           Notifications.type.studentVideoFail,
           Notifications.type.teacherClassroomExpired,
           Notifications.type.pairedSmallGroupClassrooms
          ].includes(notification.type)) {
        return this.removeComma(notification.from)
      }
      if (notification.trigger_user?.user_type.slug === 'teacher') {
        return this.removeComma(notification.trigger_user?.teacher?.full_name)
      } else if (notification.trigger_user?.user_type.slug === 'student') {
        return this.removeComma(notification.trigger_user?.student?.full_name)
      }

      return 'Unknown'
    },
    getNotifications: function (showOverlay = true) {
      if (showOverlay) {
        this.isLoadingNotifications = true
        return NotificationsService.list(this.form).then((response) => {
          this.notifications = response.data.notifications
          // this.total_rows = response.data.total_rows
          this.isLoadingNotifications = false
        })
      } else {
        if (!this.isLoadingNotifications) {
          return NotificationsService.list(this.form).then((response) => {
            if (!this.isLoadingNotifications) {
              this.notifications = response.data.notifications
            }
          })
        }
      }
    },
    getVideo(notification) {
      this.videoLoading = true
      ClassroomService.getSubmission({
        classroom_id: notification.classroom_id,
        submission_id: notification.object_id
      }).then(reponse => {
        if (reponse.success && !reponse.message) {
          const data = reponse.data
          this.video = {
            student_first_name: data.student_first_name,
            student_last_initial: data.student_last_initial,
            task_name: data.class_task?.task_name,
            created_at: moment(data.created_at).format('MM-DD-YYYY HH:mm'),
            partner_first_name: data.responding_to?.student_first_name,
            partner_last_initial: data.responding_to?.student_last_initial,
            is_response: data.is_response
          }
        } else {
          const errorMessage = reponse.message ? reponse.message : 'Error loading notification'

          this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
            size: 'sm',
            dialogClass: "modal-message-box",
            centered: true
          }).then(() => this.$bvModal.hide('video-fail-modal'))
        }
      }).finally(() => this.videoLoading = false)
    },
    async openNotification(notification) {
      if (notification.status === 'unread') {
        const lastNotification = JSON.parse(localStorage["lastNotification" + "user" + this.userTeacher.id] || '{}')

        localStorage["lastNotification" + "user" + this.userTeacher.id] = JSON.stringify({
          createdAt: lastNotification.createdAt,
          read: true
        })
        this.readNotification(true)
      }
      if (notification.type === Notifications.type.teacherPartnerMessage) {
        const queryString = {
          open_chat: 1,
        }

        this.goToClassroomPage(notification.classroom_id, {...queryString}).then(() => {
          NotificationsService.status({id: notification.id, status: 'read'})
        })
      } else if (notification.type.startsWith(Notifications.type.TeacherPartnerConnectionRequest)) {
        AppStorage.setNotificationOpen(notification.id, notification)

        this.goToPartnerConnectionsPage(notification.object_id).then(() => {
          NotificationsService.status({id: notification.id, status: 'read'})
        })
      } else {
        AppStorage.setNotificationOpen(notification.id, notification)
        const queryString = {
          notification_id: notification.id,
        }

        switch (notification.type) {
          case Notifications.type.studentVideoPosted: {
            queryString.objectId = notification.object_id
            break;
          }
          case Notifications.type.studentResponseVideo: {
            queryString.studentId = notification.trigger_user_id
            queryString.objectId = notification.object_id
            break
          }
          case Notifications.type.studentTeacherStatusMessage: {
            queryString.comment_id = notification.object_id
            break
          }
          case Notifications.type.teacherPartnerMessage: {
            queryString.open_chat = 1
            break
          }
          case Notifications.type.teacherAlertCreated: {
            this.alertId = notification.object_id
            NotificationsService.status({id: notification.id, status: 'read'})
            this.$refs['alert-modal'].showModal()
            this.getNotifications(false)
            return
          }
          case Notifications.type.studentVideoFail: {
            this.$bvModal.show('video-fail-modal')
            this.getVideo(notification)
            NotificationsService.status({id: notification.id, status: 'read'})
            this.getNotifications(false)
            return
          }
          case Notifications.type.partnerStudentPostVideo:
          case Notifications.type.studentPartnerRespondedToTheirVideo: {
            let isWholeClassroom
            await ClassroomService.get(notification.classroom_id).then(({data}) => {
              isWholeClassroom = data.course_student_group_type_id === 3
            })
            if (isWholeClassroom) {
              this.$refs.masqueradeModal.onMasquerade({
                classroomId: notification.classroom_id,
                submissionId: notification.object_id
              })
              NotificationsService.status({id: notification.id, status: 'read'})
              this.getNotifications(false)
              return
            }
            break
          }
          case Notifications.type.StudentVideoConvertionFailed: {
            NotificationsService.status({id: notification.id, status: 'read'})
            return this.goToClassroomDetailPage(notification.classroom_id, {submissionId: notification.object_id, tab: 'submissions'})
          }
          case Notifications.type.pairedSmallGroupClassrooms: {
            NotificationsService.status({id: notification.id, status: 'read'})
            return this.goToClassroomDetailPage(notification.classroom_id, {tab: 'my-students'})
          }
        }
        this.goToClassroomPage(notification.classroom_id, {...queryString}).then(() => {
          NotificationsService.status({id: notification.id, status: 'read'})
        })
      }
    }
  },
  watch: {
    'form.page': {
      handler: function () {
        this.getNotifications()
      },
      immediate: true
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.container-course-content
  position relative

.first-text
  max-width 247px
  padding-bottom 23px
  font-weight bold

.second-text
  max-width 435px

.wrapper-my-classrooms-content.notifications-header
  border-top none
  background-color #fff
  padding 0
  margin-bottom 0

  .page-name
    text-align left !important

  .container-courses
    display block !important

.container-filter-by
  max-width 197px

@media (min-width: 1200px)
  .tab-container-submissions
    padding-left 155px
    padding-right 155px

.table-body-submission
  ::v-deep
    .table-body-view
      &.list
        .table-row
          padding 6px 10px
          align-items(center)
          margin-bottom 6px
          border 1px solid color-28
          color color-8

          &:not(:first-child)
            background-color color-20

          &.header
            margin-bottom 3px
            border 0

            .column
              font font-opensans-bold
              font-size 0.5rem
              display flex
              align-items(center)

              .order
                display flex
                flex-direction(column)
                align-items(center)
                justify-content(center)
                width 16px

                &-bottom
                &-top
                  font-size 0.6rem

              &-select
                display none

          .column
            margin-left 3px
            padding-top 0

            &-from
            &-user
              flex-basis(17%)
              text-transform capitalize
              font font-opensans-regular
              font-size 0.5rem

            &-subject
              flex-basis(20%)
              font font-opensans-regular
              font-size 0.5rem
              padding-right 4px

            &-bell
              flex-basis(30px)

              span.icon-notification-dark:before
                color color-3

            &-date
              flex-basis(16%)
              font font-opensans-regular
              font-size 0.5rem

            &-open
              flex-basis(18%)
              font font-opensans-bold
              font-size 0.5rem
              text-align center

              a
                color color-3
                text-decoration underline


    @media (min-width: 768px)
      .table-body-view
        &.list
          .table-row
            &:not(:first-child)
              padding 16px 20px

            &.header
              padding 10px 20px

              .column
                font-size 14px

                &-select
                  display block

                &-select-mobile
                  display none

            .column
              &-bell
                font-size 15px
                flex-basis(50px)

              &-from
              &-subject
              &-user
              &-date
              &-open
                font-size 13px

              &-open
                flex-basis(15%)

              &-subject
                flex-basis(26%)


::v-deep
  .page-link
    color color-3

  .page-item.active .page-link
    z-index 3
    color color-1
    background-color color-3
    border-color color-3

  .page-link:focus
    z-index 3
    outline 0
    box-shadow(transparent-shadow-color-3 !important)

</style>
