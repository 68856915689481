<template>
  <div class="wrapper-security-page">
    <b-container>
      <b-row class="d-block form-container">
        <b-col cols="12" xl="4" lg="6" md="8" class="form-container-center">
          <div class="wrapper-security-form">
            <img v-if="isPlayerTrust" src="~@/core/assets/PlayersTrust_V_LBG_RGB.png" alt="LUV" class="security-form-img logo-player-trust">
            <img v-else src="~@/core/assets/luv-logo-standard.png" alt="LUV" class="security-form-img">
            <h1 class="security-form-title">TEACHER LOGIN</h1>
            <div class="separator-luv-bar"></div>
            <form @submit.prevent="login">
              <custom-input @blur="validateFieldEvent" @keyup.enter.native="login" v-model="form.email"
                            :errors="errors.email" placeholder="Email" type="email"/>
              <custom-input @blur="validateFieldEvent" @keyup.enter.native="login" v-model="form.password"
                            :errors="errors.password" placeholder="Password" type="password"/>
              <div class="security-form-extra-fields">
                <b-form-checkbox class="custom-checkbox">
                  <div class="common-checkbox-label">Keep me signed in</div>
                </b-form-checkbox>
                <div>
                  <router-link :to="{name: 'ForgotPasswordPage'}" class="link-forgot-pass">Forgot Password?
                  </router-link>
                </div>
              </div>
              <div class="security-form-container-btns">
                <b-button @click="login" btn-text="TEACHER LOGIN"/>
                <b-button @click="goToCreateAccountPage" btn-text="CREATE TEACHER ACCOUNT" outline/>
                <a href="https://cert.privo.com/#/companies/languageTestingInternational"
                   target="_blank"
                   class="logo-coppa-mobile">
                  <img src="~@/core/assets/logo-coppa.png" alt="">
                </a>
              </div>
              <div class="wrapper-extra-info">
                  Are You A Student? Visit <a :href="$options.urlStudentSite" class="wrapper-extra-info--link">{{isPlayerTrust ? 'players.sp.levelupvillageclassroom.com' : 'sp.levelupvillageclassroom.com'}}</a>
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <modal ref="account_unverified_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">EMAIL NOT VERIFIED</h2>
        <p class="text-center">
          Please verify your email address. <br/>
          If you can not find the email, please check on the junk/spam folder. <br/>
          If you did not receive the verification email, please click on the resend button
        </p>
      </template>
      <template #footer>
        <b-button @click="sendVerificationEmail()" btn-text="RESEND"/>
      </template>
    </modal>
    <modal ref="account_wrong_credentials">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">LOGIN ERROR</h2>
        <p class="text-center">Wrong email or password</p>
      </template>
      <template #footer>
        <b-button @click="closeWrongCredentialsModal" btn-text="ok"/>
      </template>
    </modal>

    <modal id="first-time-login-modal" @hidden="goToProfileWizardPage">
      <template #header></template>
      <template #body>
        <h2 class="modal-title">WELCOME TO LUV!</h2>
        <p class="text-center">Please complete your teacher profile so we can find the best partner schools for you!</p>
      </template>
      <template #footer>
        <b-button @click="goToProfileWizardPage" btn-text="OK"/>
      </template>
    </modal>

    <modal id="student_link_modal">
      <template #body>
        <p class="text-center text-center--modal-link">It looks like you may be trying to log into a student account. The student portal is at:</p>
        <a :href="$options.urlStudentSite" class="wrapper-extra-info--link link-modal">{{isPlayerTrust ? 'players.sp.levelupvillageclassroom.com' : 'sp.levelupvillageclassroom.com'}}</a>
      </template>
      <template #footer>
        <b-button @click="hideStudentLinkModal" btn-text="CLOSE"/>
      </template>
    </modal>
  </div>
</template>

<script>
import {AuthService, AccountService, TrackingActivityService} from '@/core/Services'
import validateForms from '@/core/Mixins/validate-forms'
import {Button} from '@/core/components/Button'
import Input from "@/core/components/FormInput/Input";
import {Modal} from "@/core/components/Modal";
import {TrackingTypes, userTypes} from "@/core/Utils"

export default {
  urlStudentSite: process.env.VUE_APP_BASE_URL_STUDENT_PORTAL,
  name: "LoginPage",
  mixins: [validateForms],
  components: {
    Modal,
    'b-button': Button,
    'custom-input': Input,
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      validations: {
        email: {
          validate: 'required',
        },
        password: {
          validate: 'required',
        }
      },
    }
  },
  mounted() {
    this.checkBrowserCompatibility()
  },
  computed: {
    isPlayerTrust () {
      const currentUrl = window.location.href
      const containsPlayers = /players/i.test(currentUrl);
      return containsPlayers
    }
  },
  methods: {
    login: function () {
      if (!this.form.email.split('').includes('@') && this.form.email.length !== 0) {
        this.$bvModal.show('student_link_modal')
        return
      }

      if (this.isValidForm()) {
        const {email, password} = this.form

        AuthService.login(email, password).then((user) => {
          if (user.email_verified_at) {
            if(!user.last_time_login && !user.first_name){
              this.$bvModal.show('first-time-login-modal')
            } else {
              if(this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
              } else {
                if (user?.user_type.slug === userTypes.librarian) {
                  return this.goToLibraryConnectionsPage()
                } else {
                  this.goToMyClassroomsPage()
                }
              }
              TrackingActivityService.trackingActivityTeachers(TrackingTypes.login)
            }
          } else {
            this.openUnverifiedAccountModal()
          }
        }).catch((e) => {
          if (e !== 'teacher-is-disabled') {
            this.openWrongCredentialsModal()
          }
        })
      }
    },
    sendVerificationEmail: function () {
      const {email} = this.form
      AccountService.verificationResend(email).then(() => this.closeUnverifiedAccountModal())
    },
    openUnverifiedAccountModal: function () {
      return this.$refs["account_unverified_modal"].showModal()
    },
    closeUnverifiedAccountModal: function () {
      return this.$refs["account_unverified_modal"].hideModal().then(() => {
        this.clearForm()
      })
    },
    openWrongCredentialsModal: function () {
      return this.$refs["account_wrong_credentials"].showModal()
    },
    closeWrongCredentialsModal: function () {
      return this.$refs["account_wrong_credentials"].hideModal().then(() => {
        this.clearForm()
      })
    },
    hideStudentLinkModal: function () {
      this.$bvModal.hide('student_link_modal')
    },
    checkBrowserCompatibility() {
      const supportedBrowsers = {
        windows: {
          id: 'win',
          browser: "Chrome"
        },
        macOs:{
          id: 'mac',
          browser: "Chrome or Safari"
        },
        android: {
          id: 'android',
          browser: "Chrome"
        },
        ios: {
          id: [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ],
          browser: "Safari"
        }
      }

      // Get the user's operating system
      const operatingSystem = navigator.platform.toLowerCase()

      const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
      const isEdge = isChrome && (navigator.userAgent.indexOf("Edg") != -1)
      const isBrave = navigator?.brave?.isBrave?.name === "isBrave"
      const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                       navigator.userAgent &&
                       navigator.userAgent.indexOf('CriOS') === -1 &&
                       navigator.userAgent.indexOf('FxiOS') === -1

      if (operatingSystem.includes(supportedBrowsers.windows.id) && !isChrome || isEdge || isBrave) {
        this.showPopupBrowserCompatibility(supportedBrowsers.windows.browser)
        return
      }
      if (operatingSystem.includes(supportedBrowsers.macOs.id) && !(isChrome || isSafari) || isEdge) {
        this.showPopupBrowserCompatibility(supportedBrowsers.macOs.browser)
        return
      }
      if (operatingSystem.includes(supportedBrowsers.android.id) && !isChrome || isEdge) {
        this.showPopupBrowserCompatibility(supportedBrowsers.android.browser)
        return
      }
      if (supportedBrowsers.ios.id.includes(navigator.platform) && !isSafari) {
        this.showPopupBrowserCompatibility(supportedBrowsers.ios.browser)
        return
      }
    },
    showPopupBrowserCompatibility(browser) {
      this.$bvModal.msgBoxOk(`In order to have the best experience on Level Up Village, please switch to ${browser}.`, {
        size: 'sm',
        dialogClass: 'modal-message-box',
        centered: true
      })
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/security.styl";
@import "~@/core/styles/mixins.styl"

.wrapper-security-page
  position relative

.form-container
  .form-container-center
    margin auto
    padding-top 80px

.text-center--modal-link
  margin-bottom 20px

.link-modal
  display block
  text-align center

.logo-coppa-mobile
  margin-bottom 5px

.logo-player-trust
  object-fit contain

@media (min-width: 1200px)
  .col-xl-4
    flex(0 0 39.333333%)
    max-width: 39.333333%

::v-deep
  .btn-common
    border-radius(8px)
    min-width 204px
    margin-bottom 16px
    line-height 22px
    padding 8px 20px
    letter-spacing 1.6px
</style>
