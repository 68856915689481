<template>
  <div class="btn-container">
    <div class="btn-cell">
      <b-overlay :show="loading" rounded="sm">
        <div v-if="false" class="responding-to">Responding to {{ name }}</div>
        <p v-if="isDisabledRecordVideo" class="text-danger font-weight-bold">If you would like to record a video, please
           use the Safari browser, or you can upload a video here.</p>
        <router-link class="action-icon" :to="goToVideoRecordPage()" :class="{'hidden': showUploadedVideo}" v-if="recordNewVideoButton">
          <button type="button" :disabled="isDisabledRecordVideo" class="button-video-record">RECORD NEW VIDEO</button>
        </router-link>
        <div>
          <upload-file
            @on-file-select="onFileSelect"
            @on-file-error="onFileSelectError"
            @on-file-upload-start="onFileUploadStart"
            @on-file-upload-success="onFileUploadSuccess"
            @on-file-upload-error="onFileUploadError"
            @upload-progress="onUploadProgress"
            :auto-upload="false"
            :classroom-id="classroomId"
            :task-id="taskId"
            ref="upload-video"
            class="form-upload-task"
            :class="{'hidden':showUploadedVideo}"
            accept="video/*"/>
          <video-uploaded v-if="showUploadedVideo"
                          :uploaded-file="uploadedFile"
                          :classroomId="classroomId"
                          :taskId="taskId"
                          :review-prompt-button="reviewPromptButton"
                          @on-upload-try-again="onUploadTryAgain"
                          @on-upload-video="onUploadVideo"
                          @on-video-error="onVideoError"/>
          <VideoUploadModal :uploaded="uploadedVideo"
                            :upload-progress="uploadProgress"
                            @on-video-upload-modal-closed="$emit('close-video-upload-modal')"/>
        </div>
        <div class="upload-file-message" v-if="isInsufficientSpeed && !showUploadedVideo">Please connect your device to a high-speed network. <span v-if="recordNewVideoButton">If a high-speed network is not available, we suggest that you record your video here using the 'Record New Video' button.</span></div>
        <div class="upload-file-message" v-if="!showUploadedVideo">Your video must not be larger than 256MB.</div>
        <div class="invalid-feedback" v-if="errorVideoType">{{ errorVideoType }}</div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { UploadFile, VideoUploaded, VideoUploadModal } from '@/core/components/Video'
import { UploadVideo } from '@/core/Mixins'
import { Utils } from "@/core/Utils"

export default {
  name: 'UploadOrRecordVideo',
  components: {
    UploadFile,
    VideoUploaded,
    VideoUploadModal
  },
  mixins: [UploadVideo],
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    recordNewVideoButton: {
      type: Boolean,
      default: false
    },
    reviewPromptButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      name: null,
      showUploadedVideo: false,
      errorVideoType: null,
      taskIsComplete: null,
      responseIsComplete: null,
      uploadProgress: 0,
      speed: 25
    }
  },
  methods: {
    goToVideoRecordPage () {
      return {
        name: 'VideoRecordPage',
        params: {
          classroomId: this.classroomId,
          teacherActionId: this.taskId
        }
      }
    },
    goToTaskDetailPage () {
      this.$router.push({
        name: 'ClassroomPage',
        params: {
          classroomId: this.classroomId
        }
      })
    },
    validVideoType (file) {
      const fileTypes = [
        'video/webm',
        'video/avi',
        'video/m4v',
        'video/x-matroska',
        'video/mpeg',
        'video/3gpp',
        'video/vob',
        'video/mp4',
        'video/mxf',
        'video/x-ms-wmv',
        'video/quicktime'
      ]
      return fileTypes.includes(file.type)
    },
    onFileSelect ({ files }) {
      const validVodFormat = files[0].file.name.indexOf('vob') === -1
      const validMxfFormat = files[0].file.name.indexOf('mxf') === -1
      if (!this.validVideoType(files[0].file) && validVodFormat && validMxfFormat) {
        this.errorVideoType = 'This file type is not supported on Level Up Village. Please convert it to a supported video file (.3gp, .avi, .m4v, .mkv, .mov, .mp4, .mpg, .mxf, .vob, .webm, or .wmv) and upload it again.'
        return
      }
      this.errorVideoType = null
      this.uploadedFile = files[0]
      this.showUploadedVideo = true
    },
    onFileSelectError (err) {
      this.$bvModal.msgBoxOk(`${err.message}, Please try again`, {
        size: 'sm',
        dialogClass: 'modal-message-box',
        noCloseOnBackdrop: true,
        centered: true
      })
    },
    onFileUploadStart () {
      this.$bvModal.show('video-upload-modal')
      this.$emit('on-uploading-video-start')
    },
    onFileUploadSuccess () {
      const fileData = this.getSelectedFileData()

      this.$emit('upload-video-success', fileData.fileUrl)
      this.$emit('on-uploading-video-success')
    },
    async onFileUploadError (e) {
      this.uploadedFile = {}
      this.showUploadedVideo = false
      this.$refs['upload-video'].clearUploadFile()
      this.$bvModal.hide('video-upload-modal')

      this.$emit('on-uploading-video-error')

      const errorMessage = e.message ? e.message : 'Error while submitting response.'
      await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
        size: 'sm',
        dialogClass: "modal-message-box",
        noCloseOnBackdrop: true,
        centered: true
      })
    },
    onUploadTryAgain () {
      this.showUploadedVideo = false
      this.uploadedFile = {}

      this.$refs['upload-video'].clearUploadFile()
      this.$refs['upload-video'].openUploadFile()
    },
    onVideoError () {
      this.showUploadedVideo = false
      this.uploadedFile = {}

      this.$refs['upload-video'].clearUploadFile()
    },
    getSelectedFileData () {
      const customData = this.$refs['upload-video'].getSelectedFileData()

      if (!customData) {
        throw new Error('You have not selected a file.')
      }

      return customData
    },
    async onUploadVideo () {
      this.showUploadedVideo = false

      this.loading = true
      try {
        this.$refs['upload-video'].onFileUpload()
      } catch (e) {
        this.$emit('on-uploading-video-error')
        const errorMessage = e.message ? e.message : 'Error while submitting response.'
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.loading = false
      }
    },
    onUploadProgress (progress) {
      this.uploadProgress = progress
    }
  },
  async created () {
    this.speed = await Utils.runSpeedTest()
  },
  computed: {
    isInsufficientSpeed () {
      return this.speed <= 10
    },
    isDisabledRecordVideo () {
      const devices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ]
      const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                       navigator.userAgent &&
                       navigator.userAgent.indexOf('CriOS') === -1 &&
                       navigator.userAgent.indexOf('FxiOS') === -1

      return devices.includes(navigator.platform) && !isSafari
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
.btn-container
  background-color white
  display flex
  position absolute
  justify-content center
  align-items center
  height 100%
  width 100%
  text-align center

  .btn-cell
    width 100%
    vertical-align middle

    .responding-to
      font-size 16px
      font-weight bold
      margin-bottom 15px

    .button-video-record
      color white
      letter-spacing 1px
      font font-opensans-semibold
      font-weight 800
      font-size 14px
      width 200px
      height 31px
      border-radius 4px
      background-color color-3
      border 2px solid color-7
      text-transform uppercase
      margin 5px
      padding 0

      &:disabled
      &[disabled]
      .disabled
        background-color color-11 !important
        border-color color-11 !important
        border-width 3px !important

.form-upload-task
  &::v-deep
    .btn-input-file
      width 200px
      padding-bottom 3px
      padding-top 3px
      text-transform uppercase

  &.hidden
    display none

.action-icon.hidden
  display none

.invalid-feedback, .upload-file-message
  display block
  margin auto
  margin-top 10px
  font font-opensans-semibold
  font-size 14px
  max-width 500px
  padding 0 15px

.upload-file-message
  color color-8
</style>
