import {TaskTypes} from "@/core/Utils"
import {CourseMixins} from "@/modules/Classroom/Mixins"

import moment from "moment"

export const TaskMixins = {
  mixins: [CourseMixins],
  computed: {
    labels () {
      return {
        myClassroom: this.isWholeClassGroupType ? 'MY VIDEO' : 'MY CLASSROOM',
        partnerClassroom: this.isWholeClassGroupType ? 'PARTNER  VIDEO' : 'PARTNER CLASSROOM',
        bothClassrooms: 'BOTH CLASSROOMS'
      }
    },
    isMainClassroom () {
      return this.getClassroomData.id === this.classroom?.partnership?.classroom1_id
    },
    studentPromptLabel () {
      if (this.task?.id) {
        if ((this.task.task_type === TaskTypes.classroomA && this.isMainClassroom) || (this.task.task_type === TaskTypes.partnerB && !this.isMainClassroom)) {
          return this.labels.myClassroom
        }
        if ((this.task.task_type === TaskTypes.partnerB && this.isMainClassroom) || (this.task.task_type === TaskTypes.classroomA && !this.isMainClassroom)) {
          return this.labels.partnerClassroom
        }
      }
      return this.labels.bothClassrooms
    },
    responsePromptLabel () {
      if (this.task?.id) {
        if ((this.task.task_type === TaskTypes.partnerB && this.isMainClassroom) || (this.task.task_type === TaskTypes.classroomA && !this.isMainClassroom)) {
          return this.labels.myClassroom
        }
        if ((this.task.task_type === TaskTypes.partnerB && !this.isMainClassroom) || (this.task.task_type === TaskTypes.classroomA && this.isMainClassroom)) {
          return this.labels.partnerClassroom
        }
      }
      return this.labels.bothClassrooms
    }
  },
  methods: {
    createDueLabel (task) {
      if ((task.task_type === TaskTypes.partnerB && this.isMainClassroom) || (task.task_type === TaskTypes.classroomA && !this.isMainClassroom)) {
        return this.labels.partnerClassroom
      }
      return this.labels.myClassroom
    },
    responseDueLabel (task) {
      if ((task.task_type === TaskTypes.partnerB && !this.isMainClassroom) || (task.task_type === TaskTypes.classroomA && this.isMainClassroom)) {
        return this.labels.partnerClassroom
      }
      return this.labels.myClassroom
    },
    createDueColor(task) {
      return this.createDueLabel(task) === this.labels.myClassroom ? 'create-box-color' : ''
    },
    responseDueColor(task) {
      return this.responseDueLabel(task) === this.labels.myClassroom ? 'response-box-color' : ''
    },
    createTaskExpired (task) {
      const expirationDate = task.due_date
      const currentTimestamp = moment()
      return (moment(currentTimestamp).isAfter(expirationDate) && this.createDueLabel(task) === this.labels.myClassroom) ? 'status--expired' : ''
    },
    responseTaskExpired (task) {
      const expirationDate = task.response_due_date
      const currentTimestamp = moment()
      return (moment(currentTimestamp).isAfter(expirationDate) && this.responseDueLabel(task) === this.labels.myClassroom) ? 'status--expired' : ''
    },
  }
};
