<template>
  <b-modal id="avatar-list-modal" centered hide-footer hide-header size="lg">
    <div class="avatar-list-container">
      <div class="avatar-list">
        <div class="avatar-img"
            :class="{'avatar-img-selected': avatarSelected === avatar.id}"
            v-for="avatar in $options.avatars"
            @click="avatarSelected = avatar.id"
            :key="avatar.id">
          <b-img-lazy v-bind="mainProps" :src="$options.baseUrl + getAvatarImageById(avatar.id)" :alt="avatar.id"/>
        </div>
      </div>
    </div>
    <div class="avatar-list-buttons">
      <div class="list-buttons-first">
        <b-button @click="$bvModal.hide('avatar-list-modal')"
                  class="avatar-button"
                  btn-text="CANCEL"/>

        <b-button :disabled="!avatarSelected"
                  class="avatar-button"
                  @click="$emit('select-avatar', $options.baseUrl + getAvatarImageById(avatarSelected))"
                  btn-text="SUBMIT"/>
      </div>
      <b-button @click="$emit('upload-avatar')"
                class="avatar-button-upload"
                btn-text="UPLOAD AVATAR"/>
    </div>
  </b-modal>
</template>

<script>
import {Button} from "@/core/components/Button"
import {avatars} from '@/core/Utils'

export default {
  name: 'AvatarListModal',
  baseUrl: process.env.VUE_APP_BASE_URL,
  avatars: avatars,
  components: {
    'b-button': Button,
  },
  data() {
    return {
      avatarSelected: null,
      mainProps: { blank: true, width: 100, height: 100, class: 'm-2' },
    }
  },
  methods: {
    getAvatarImageById (id) {
      const img = avatars.find((img) => img.id === id)
      if (img) return img.src
      return ''
    },
    open () {
      this.$bvModal.show('avatar-list-modal')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#avatar-list-modal
  .avatar-list-container
    overflow-y scroll
    padding 5px
    padding-bottom 15px
    max-height 65vh

    &::-webkit-scrollbar
      width 8px
      height 10px
      background color-41
      border-radius 4px

    &::-webkit-scrollbar-thumb
      background color-48
      border-radius 4px

    .avatar-list
      display flex
      flex-wrap wrap

      .avatar-img
        overflow visible
        cursor pointer

      .avatar-img-selected
        outline solid 2px color-48
        transform scale(0.9)

    @media (max-width 750px)
      .avatar-list
        min-width 815px

  .avatar-list-buttons
    padding-top 40px
    display flex
    justify-content center

    .list-buttons-first
      display flex
      justify-content center

      .avatar-button
        margin-right 10px

  @media(max-width 600px)
    .avatar-list-buttons
      display block

      .avatar-button-upload
        margin 0 auto
        margin-top 10px
</style>
