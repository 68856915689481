<template>
  <div class="container-dropdown" :class="{'is-invalid': $v.$error}">
    <input class="input-dropdown"
           type="text"
           v-model="inputValue"
           @change="handleInput"
           :placeholder="placeholder"
           maxlength="100">
    <b-dropdown :class="classSelectDropdown" style="padding: 0;" v-model="value" :disabled="disabled" no-caret>
      <template #button-content>
        <div class="custom-dropdown-button-icon icon-dropdown"></div>
      </template>
      <template>
        <b-dropdown-item v-for="(option, index) in options"
                        :key="`${_uid}-${index}`"
                        :value="option[valueField]"
                        @click="onItemClick(option)">
          {{ option.name }}
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators"

export default {
  name: "InputSelect",
  props: {
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    value: {
      type: [Number, String, Object],
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    classSelect: {
      type: String,
    },
    state: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    valueField:
      {
        type: String,
        default: 'id'
      },
  },
  mounted () {
    this.inputValue = this.value
  },
  data () {
    return {
      inputValue: ''
    }
  },
  validations() {
    return {
      inputValue: {
        required
      }
    }
  },
  methods: {
    onItemClick (option) {
      this.optionSelected = option
      this.inputValue = option.name
      this.$emit('Option:selected', option)

    },
    handleInput (option) {
      this.$v.$touch()
      option = {
        name: option.target.value,
        customName: option.target.value
      }
      this.$emit('Option:selected', option)
      this.$emit('input-error', this.$v.$error)

    }
  },
  computed: {
    classSelectDropdown () {
      const className = {
        'custom-dropdown': true
      }

      className[this.classSelect] = !!this.classSelect
      className[this.state] = !!this.state

      return {
        ...className
      }
    },
    optionSelected: {
      get () {
        return this.options.find(option => option[this.valueField] === this.value)
      },
      set (option) {
        if (option) {
          this.$emit('input', option[this.valueField])
        }
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.container-dropdown
  background-color white
  border 2px solid #E8ECEE
  border-radius(4px)
  padding 5px
  width 100%
  font-weight 700
  font-size 14px
  outline none
  text-align left
  box-shadow(transparent-shadow !important)
  display flex
  position relative
  justify-content space-between

  .input-dropdown
    border none
    width 90%

.custom-dropdown
  width 30%
  border none
  height 50px
  margin 0

  ::v-deep
    .dropdown-toggle
      background-color color-6
      border 2px solid color-3
      border-radius(4px)
      padding 0 px
      width 100%
      font-weight 700
      font-size 14px
      outline none
      text-align left
      flexbox(flex)
      align-items(center)
      box-shadow(transparent-shadow !important)

      &:focus
        box-shadow(transparent-shadow !important)

      span
        font font-opensans-semibold
        font-size 14px
        color color-7
        width 100%
        overflow hidden
        text-overflow ellipsis

      .custom-dropdown-button-icon
        position absolute
        right 0.391rem
        top 0.313rem
        bottom 5px
        height auto
        font-size 2.2rem

        &:before
          color color-3

      &:active
        background-color color-6

    .dropdown-menu
      width auto !important
      background-color color-6 !important
      text-align left
      font font-opensans-bold
      font-size 10px
      letter-spacing 0
      color color-black-300 !important
      text-transform uppercase
      padding 0
      border 2px solid color-3
      border-radius(0 0 4px 4px)
      margin-top -1px
      width 100%
      min-width 100%
      max-height: 280px
      overflow-x: hidden
      overflow-y: auto

      .dropdown-item
        padding 6px 38px 6px 20px
        font font-opensans-semibold
        font-size 14px
        color color-7
        line-height 2em

        &:hover
        &:active
          color color-black-300 !important
          text-decoration none
          background-color #e9ecef

      li
        padding 0 2px

        &:not(:first-of-type)
          .dropdown-item
            border-top 1px solid color-3

  &.show
    ::v-deep
      .dropdown-toggle
        border-radius(4px 4px 0 0)
        border-bottom 2px solid transparent

  &--md
    height 40px

  ::v-deep
    .dropdown-toggle
      &:after
        width 29px
        height 29px
        top 3px

  &--secondary
    height auto !important

    ::v-deep
      .dropdown-toggle
        background-color unset !important
        border none !important
        color none !important

        span
          color color-8

        .custom-dropdown-button-icon
          font-size 1.65rem
          top -0.1rem

        &:active
          background-color white

      .dropdown-menu
        background-color color-20 !important
        border 2px solid color-16
        left 8px !important

        .dropdown-item
          padding 6px 38px 6px 20px
          color color-8

          &:hover
          &:active
            color color-black-300 !important
            text-decoration none

        li
          padding 0 2px

          &:not(:first-of-type)
            .dropdown-item
              border-top 1px solid color-16

.is-invalid
  border 2px color-38 solid !important
</style>
