<template>
  <div class="tab-container">
    <div class="select-course-button-wrapper justify-content-center" v-if="!classroom.course_id && !isClassPairing">
      <b-button @click="goToChooseCourseContentPage(classroom.id)" btn-text="SELECT A COURSE"/>
    </div>
    <component v-else @on-change-component="onChangeComponent" v-bind:is="currentComponent" v-bind:task="task"
               v-bind="{...$props}"/>
  </div>
</template>

<script>
import ClassroomDetailTabMyTasksList from "./ClassroomDetailTabMyTasksList";
import ClassroomDetailTabMyTasksLibrary from "./ClassroomDetailTabMyTasksLibrary";
import ClassroomDetailTabMyTasksCreate from "./ClassroomDetailTabMyTasksCreate";
import ClassroomDetailTabMyTaskDetail from "./ClassroomDetailTabMyTaskDetail";
import {Button} from "@/core/components/Button";

export default {
  name: "ClassroomDetailTabMyTasks",
  components: {
    ClassroomDetailTabMyTasksList,
    ClassroomDetailTabMyTasksLibrary,
    ClassroomDetailTabMyTasksCreate,
    ClassroomDetailTabMyTaskDetail,
    'b-button': Button,
  },
  data() {
    return {
      currentComponent: null,
      task: null
    }
  },
  props: {
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    }
  },
  mounted() {
    this.onChangeComponent("ClassroomDetailTabMyTasksList")
  },
  methods: {
    onChangeComponent(currentComponent, task) {
      this.currentComponent = currentComponent;
      this.task = task;
    }
  },
  computed: {
    isClassPairing () {
      return this.classroom.class_pairing && this.classroom.class_pairing.status === "active"
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.select-course-button-wrapper
  display flex
  align-items center
  min-height 10rem

  button
    max-height 3rem


</style>
