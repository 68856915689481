export const getters = {
  isLoadingProfile: (state) => {
    return state.loadingProfile
  },
  getUser: (state) => {
    return state.user
  },
  getReadNotification: (state) => {
    return state.readNotification
  },
}
