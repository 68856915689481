/**
 * Common const to handle http requests and responses
 * src/http.js
 */
import axios from 'axios'
import qs from 'qs'
import { AuthService } from '@/core/Services'
import { AppStorage } from '@/core/Utils'
import router from '@/router'

/**
 *
 * parse error response
 */
function parseError(messages) {
  // error
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject(new Error(messages[0]))
    }
    if (messages instanceof Object && messages.detail) {
      // JWT DRF returns an object error response
      return Promise.reject(messages.detail)
    } else {
      return Promise.reject(messages)
    }
  } else {
    return Promise.reject(new Error('Error'))
  }
}

/**
 * parse response
 */
function parseBody(response) {
  if (response.status === 200) {
    return response.data
  } else {
    return parseError(response.data.messages)
  }
}

/**
 * axios instance
 */
let instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  paramsSerializer: function (params) {
    return qs.stringify(params, {indices: false})
  }
})

// request header
instance.interceptors.request.use((config) => {
  // Do something before request is sent
  // api token

  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    config.headers['Authorization'] = 'Bearer ' + user.token;
    if (user.token_admin) {
      config.headers['AuthorizationAdmin'] = 'Bearer ' + user.token_admin;
    }

    if(user.user_type && user.user_type.slug === 'teacher-demo') {
      config.url = config.url.replace('/teacher/', '/teacher-demo/')
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  return parseBody(response);
}, function (error) {
  const originalRequest = error.config ? error.config : {}
  const user = AppStorage.getUser()
  if (error.response && error.response.status === 401 && user.id) {
    router.app.$bvModal.msgBoxOk(`You have been logged out of your account, log back in. Please try again.`, {
      size: 'sm',
      dialogClass: "modal-message-box",
      noCloseOnBackdrop: true,
      centered: true
    })
    router.push({ name: 'LogOutPage'})
  } else if (error.response && error.response.status === 403 && user !== {} && !originalRequest._retry) {
    originalRequest._retry = true

    return AuthService.loginWithToken(user.refreshToken).then(() => {
      const user = AppStorage.getUser()
      originalRequest.headers.Authorization = 'Bearer ' + user.token;
      if (user.token_admin) {
        originalRequest.headers['AuthorizationAdmin'] = 'Bearer ' + user.token_admin;
      }
      return instance(originalRequest)
    }).catch(() => {
      AuthService.logout();
      router.push({ name: 'LoginPage'})
    })
  } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.error_code === 20) {
    if (AppStorage.getUser()['id'] || router.app.$root._route.name === 'LoginPage') {
      AuthService.logout()
      router.app.$bvModal.show('teacher-disabled-modal')
    }

    if(router.app.$root._route.name !== 'LoginPage') {
      router.push({ name: 'LoginPage'})
    }

    return parseError('teacher-is-disabled')
  }

  const responseData = error.response && error.response.data ? error.response.data : {}
  return parseError(responseData)
})

export const http = instance
