<template>
  <div v-if="isViewList" class="row-classroom-list">
    <div class="column column-name">
      <a href="javascript:void(0)" @click="goToClassroom">
        {{ classroom.name }} - {{ classroom.subject.name }}
      </a>
    </div>
    <div class="column column-status">
      {{ classroom.status }}
    </div>
    <div class="column column-code">
      <copy-text-element :text="classroom.code"></copy-text-element>
    </div>
    <div class="column column-progressbar">
      <b-progress class="column-progressbar-body progress-course" height="1.81rem" :max="100">
        <b-progress-bar :value="classroom.progression.total_percent"/>
        <span>{{ classroom.progression.total_percent ? classroom.progression.total_percent : 0 }}%</span>
      </b-progress>
    </div>
    <div class="column column-global-partner" v-if="classroom.partner">
      <b-avatar class="column-global-partner-avatar" variant="info" :src="classroom.partner.avatar" icon="people-fill" square></b-avatar>
      <span class="column-global-partner-name">{{ classroom.partner.full_name }}</span>

    </div>
    <div class="column column-global-partner-not" v-else>
      Not Paired
    </div>
  </div>
  <div v-else class="row-classroom-grid">
    <div class="classroom-body">
      <div class="column column-name">
        {{ classroom.name }} - {{ classroom.subject.name }}
      </div>
      <div class="column column-status">
        Status: <span class="course-card__status">{{ classroom.status }}</span>
      </div>
      <div class="column column-submissions">
        <div class="column-submissions-task">Number of Tasks : <span>{{ classroom.tasks_count }}</span></div>
        <div class="column-submissions-posts">Student Posts :
          <span>{{ classroom.submissions_count + classroom.responses_count }}</span></div>
      </div>
      <div class="column column-dates">
        Dates : <span>{{ classroom.start_date | dateMonthYear }}</span> - <span>{{
          classroom.end_date | dateMonthYear
        }}</span>
      </div>
      <div class="column column-code">
        Classroom Code :
        <copy-text-element :text="classroom.code"></copy-text-element>
      </div>
      <div class="column column-progressbar">
        Course Completion
        <b-progress class="progress-course" height="1.81rem" :max="100">
          <b-progress-bar :value="classroom.progression.total_percent"/>
          <span>{{ classroom.progression.total_percent ? classroom.progression.total_percent : 0 }}%</span>
        </b-progress>
      </div>
    </div>
    <div class="classroom-actions">
      <div class="column">
        <b-button @click="goToClassroom" btn-text="Enter Classroom" outline/>
        <b-button @click="goToClassroomEditPage(classroom.id)" btn-text="Edit Classroom" outline/>
        <b-button @click="goToClassroom(true)" btn-text="Show Details" outline/>
      </div>
    </div>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button"
import CopyTextElement from "@/core/components/Copy/CopyTextElement";

export default {
  name: "ClassroomCard",
  components: {
    CopyTextElement,
    'b-button': Button,
  },
  data: function () {
    return {}
  },
  inject: ['mediaQueries'],
  props: {
    classroom: {
      type: Object,
      default: function () {
        return {
          id: 0,
          name: '',
          subject: '',
          code: '',
          status: '',
          tasks_count: '',
          submissions_count: '',
          responses_count: '',
          start_date: '',
          end_date: '',
          progression: {
            submissions: {
              "to-do": 0,
              "submitted": 0,
              "approved": 2,
              "blocked": 0
            },
            responses: {
              "to-do": 0,
              "submitted": 0,
              "approved": 0,
              "blocked": 0
            },
            total_percent: 0
          }
        }
      }
    },
    isViewList: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goToClassroom(collapseInformation = false) {
      const queryString = {}

      if (collapseInformation === true) {
        queryString.collapseInformation = true
      }

      this.goToClassroomPage(this.classroom.id, {...queryString})
    }
  },
  computed: {
    classListActions() {
      let styles = {
        'column': true,
        'column-actions': true
      }

      if (this.mediaQueries.mobile || this.mediaQueries.tablet) {
        styles['column-actions--mobile'] = true
      }

      return {
        ...styles
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "styles/card.styl"
@import "~@/core/styles/variables.styl"

.row-classroom-list
  width 80vw
  display flex
  flex-direction row
  align-items center
  @media (min-width 445px)
    width 85vw
  @media (min-width 600px)
    width 89vw
  @media (min-width 768px)
    width 91vw
  @media (min-width 1240px)
    width 1140px

  .column
    font-size 0.5rem
    margin-right 3px
    font font-opensans-regular
    font-size 0.5rem
    height 40px
    display flex
    align-items center

    &-status
      text-transform capitalize
      font font-opensans-bold
      font-size 0.5rem

    &-name
      font font-opensans-bold
      font-size 0.5rem
      white-space nowrap
      overflow hidden
      text-overflow ellipsis

    &-code
      font font-opensans-bold
      font-size 0.5rem

    &-progressbar-body
      width 100%

    &-global-partner
      background-color color-44
      font-size 0.5rem
      white-space nowrap
      overflow hidden
      text-overflow ellipsis

      &-avatar
        margin 0 15px
      &-name
        text-align center

    &-global-partner-not
      background-color color-48
      color color-1
      justify-content center

    &-actions
      &--mobile
        display flex
        flex-direction column
        align-items center
        justify-content center

      &::v-deep
        .btn-common
          min-width 50px
          font-size 0.5rem
          letter-spacing unset
          padding 2px 5px
          display inline-block
          margin-left 5px
          line-height 11px

          &:first-child
            margin-bottom 3px

      @media (min-width 575px)
        &--mobile
          flex-direction row

        &::v-deep
          .btn-common:first-child
            margin-bottom 0

  @media (min-width 445px)
    .column
      &-global-partner-not
        font-size 1rem

  @media (min-width 600px)
    .column
      &-global-partner-not
        font-size 1.3rem
        padding 0 15px
        justify-content unset

  @media (min-width: 768px)
    .column
      font-size 0.9rem
      margin-right 7px

      &:first-child
        margin-right 12px

      &:last-child
        margin-right 0

      &-actions
        flex-direction column

        &::v-deep
          .btn-common
            width 100px
            font-size 0.7rem
            line-height 14px
            padding 4px 10px

            &:first-child
              margin-bottom 4px

      &-global-partner-not
        font-size 1.8rem

  @media (min-width: 992px)
    .column
      font-size 1rem

      &-actions
        flex-direction row

        &::v-deep
          .btn-common:first-child
            margin-bottom 0

      &-global-partner-not
        font-size 1.8rem

  @media (min-width 1100px)
    .column
      &-actions
        display flex
        justify-content space-evenly

      &-global-partner-not
        font-size 1.8rem

.row-classroom-grid
  width 100%
  display flex
  flex-direction column
  box-shadow 0 2px 4px rgba(color-8, 0.29)
  border: 1px solid #8d91c8;
  border-radius 4px

  .classroom-body
    background-color: #fff
    border-radius 4px 4px 0 0
    padding 1.25rem
    padding-bottom 0.813rem

    .column
      flex 1
      color color-8

      &-name
        letter-spacing: 0.75px
        font font-opensans-bold
        font-size 15px
        max-width 260px
        width 100%
        white-space: nowrap
        overflow hidden
        text-overflow ellipsis

        @media (min-width 400px)
          max-width 300px
        @media (min-width 500px)
          max-width 400px
        @media (min-width 600px)
          max-width 505px
        @media (min-width 768px)
          max-width 280px
        @media (min-width 900px)
          max-width 350px
        @media (min-width 992px)
          max-width 230px
        @media (min-width 1200px)
          max-width 300px

      &-status
        font font-opensans-bold
        padding-bottom 15px

        span
          color #0443C1
          text-transform capitalize
          text-decoration underline

      &-submissions
        display flex
        padding-bottom 4px

        &-task
        &-posts
          font font-opensans-regular
          font-size 13px

          span
            font font-opensans-bold
            font-size 13px

        &-task
          padding-right 30px

      &-progressbar .progress-course
        margin-top 0.313rem

      &-progressbar
      &-code
      &-dates
        font font-opensans-regular
        font-size 13px
        padding-bottom 3px

        span
          font font-opensans-bold
          font-size 13px

  .classroom-actions
    .column
      display flex
      flex-direction column
      justify-content center
      align-items center
      padding-bottom 18px
      padding-top 18px
      gap 10px

    ::v-deep
      .btn-common
        min-width 150px
        padding 6px 12px
        font font-opensans-bold
        letter-spacing 0
        font-size 13px
        line-height 18px

.progress-course
  background-color color-20
  border-radius 0
  border 2px solid color-36
  padding 2px 3px
  align-items center

  ::v-deep
    .progress-bar
      background-color color-37
      margin-right 7.65px
      height 20px

</style>
