<template>
  <div class="wrapper-create-classroom-page">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col cols="12" xl="5" lg="6" md="8">
          <div class="wrapper-security-form">
            <h2 class="security-form-subtitle mb-1">EDIT LUV CLASSROOM</h2>
            <div class="security-form-page-info mb-4">
              <div class="required-fields">all fields required *</div>
            </div>
            <form-classroom :id="classroomId"
                            @Classroom:Update:Success="showSuccessModal"
                            @Classroom:Update:ErrorAPI="showErrorModal"
                            @Classroom:Form:Cancel="$router.go(-1)"/>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <modal ref="classroom_create_success">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">Changes Save Successful</h2>
        <p></p>
      </template>
      <template #footer>
        <b-button @click="closeSuccessModal" btn-text="OK"/>
      </template>
    </modal>
    <modal ref="classroom_create_error">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">Create classroom fail</h2>
        <p>{{ createErrorMessage }}</p>
      </template>
      <template #footer>
        <b-button @click="closeErrorModal()" btn-text="OK"/>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal} from "@/core/components/Modal";
import {Button} from "@/core/components/Button";
import ClassroomForm from "@/modules/Classroom/Components/ClassroomForm";
import {ClassroomMixins} from "@/modules/Classroom/Mixins";

export default {
  name: "EditClassroomPage",
  mixins: [ClassroomMixins],
  components: {
    Modal,
    'b-button': Button,
    'form-classroom': ClassroomForm
  },
  data() {
    return {
      classroomId: 0,
      createErrorMessage: '',
    }
  },
  mounted() {
  },
  methods: {
    showSuccessModal: function () {
      return this.$refs["classroom_create_success"].showModal()
    },
    closeSuccessModal: function () {
      if(!this.classroom.course_id) {
        this.goToChooseCourseContentPage(this.classroom.course_id)
      } else {
        this.$router.go(-1)
      }
    },
    showErrorModal: function () {
      return this.$refs["classroom_create_error"].showModal()
    },
    closeErrorModal: function () {
      return this.$refs["classroom_create_error"].hideModal()
    },
  },
  watch: {
    '$route.params.classroomId': {
      handler: function (classroomId) {
        if (typeof classroomId === 'string') {
          this.classroomId = parseInt(classroomId);
        } else {
          this.classroomId = classroomId;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
</style>
