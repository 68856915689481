<template>
  <div class="form-group" style="position: relative" v-click-outside="clickOutside">
    <label v-if="label" :for="`input-data-list-${_uid}`">{{ label }}</label>

    <b-form-input v-model="searchValue" type="search" :state="state" autocomplete="off"
                  ref="input-data-list"
                  :id="`input-data-list-${_uid}`"
                  :list="`input-data-list-${_uid}-list`"
                  :aria-describedby="`input-data-list-${_uid}-live-feedback`"
                  :class="customClassInput"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  @change="$emit('change', $event)"
                  @keyup="$emit('keyup', $event)"
                  @keypress="$emit('keypress', $event)"
                  @blur="onBlur"
                  @focus="onFocus"
                  @input="onInput"/>

    <b-list-group v-show="showList" class="dropdown-menu" role="menu" tabindex="-1"
                  style="position: absolute; top: 2.2rem; left: 0; will-change: transform; padding: 0;">
      <b-list-group-item v-for="option in options_filtered" :key="option.id" @click="optionClick(option)">
        {{ option.name }}
      </b-list-group-item>
    </b-list-group>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="`input-data-list-${_uid}-live-feedback`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`input-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import {InputMixins} from "@/core/components/Form/Mixins/input";

export default {
  name: "InputDataList",
  data: function () {
    return {
      showList: false,
      focused: false,
      options_filtered: [],
      previous_input_value: '',
      searchValue: ''
    }
  },
  mixins: [InputMixins],
  props: {
    customClass: {
      type: String,
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    valueField: {
      type: String,
      default: 'name'
    },
  },
  mounted () {
    this.previous_input_value = this.value
    const optionSelected = this.options.find(opt=> opt.id === this.value)
    this.searchValue = this.valueField === 'name' ? this.value : (optionSelected && optionSelected.name)
  },
  methods: {
    onInput (school_name) {
      if (this.focused) {
        this.showList = true
      }

      this.options_filtered = [];
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].name.toLowerCase().search(school_name.toLowerCase().trim()) >= 0) {
          this.options_filtered.push(this.options[i]);
        }

        if (this.options_filtered.length === 5) {
          break
        }
      }
      //alphabetical order
      this.options_filtered.sort((a, b) => {
          return a.name.localeCompare(b.name)
      })

      if (!this.options_filtered.length) {
        this.options_filtered.push({id: 'no_figure', name: 'Not Listed'});
      }

      this.$emit('input', school_name)
      this.searchValue = school_name
    },
    onBlur (e) {
      this.focused = false
      this.$emit('blur', e)
    },
    onFocus (e) {
      this.focused = true
      this.$emit('focus', e)
    },
    optionClick (option) {
      this.focused = false
      this.showList = false

      if (option.id !== 'no_figure') {
        this.previous_input_value = option[this.valueField]
        this.$emit('input', option[this.valueField])
        this.searchValue = option.name
      } else {
        this.previous_input_value = this.inputValue
      }

      this.$emit('on-option-selected', option)
    },
    clickOutside () {
      this.options_filtered = [];
      this.showList = false
      if (!this.previous_input_value && !this.searchValue) {
        return
      }
      this.$emit('input', this.previous_input_value)
      this.searchValue = this.valueField === 'name' ? this.previous_input_value : this.options.find(opt => opt.id === this.previous_input_value)?.name
    },
    clearPreviousInputValue() {
      this.previous_input_value = ''
    }
  },
  computed: {
    customClassInput () {
      const className = {
        '': true
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.dropdown-menu
  width 100%
  ::v-deep
    .list-group-item
      text-overflow ellipsis
      white-space normal
      overflow hidden
</style>
