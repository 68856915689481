<template>
  <div>
    <upload-or-record-video @close-video-upload-modal="goToTaskDetailPage"
                            @upload-video-success="uploadVideoSucess"
                            :record-new-video-button="true"
                            :review-prompt-button="true"
                            :classroom-id="classroomId"
                            :task-id="teacherActionId"/>
  </div>
</template>

<script>
import { ActionsService } from '@/core/Services'
import UploadOrRecordVideo from '../Components/UploadOrRecordVideo.vue'

export default {
  name: 'UploadOrRecordPage',
  components: {
    UploadOrRecordVideo
  },
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    teacherActionId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      taskIsComplete: null
    }
  },
  methods: {
    uploadVideoSucess(fileUrl) {
      ActionsService.saveActionVideo(this.teacherActionId, fileUrl)
    },
    goToTaskDetailPage () {
      this.$router.push({
        name: 'ClassroomPage',
        params: {
          classroomId: this.classroomId
        }
      })
    },
    async init () {
      let actionItem
      ActionsService.getActionItemById(this.teacherActionId).then((res) => {
        actionItem = res.data
        this.taskIsComplete = !!actionItem.completed
        if (this.taskIsComplete) {
          this.$bvModal.msgBoxOk('You already submitted a video for this task.', {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }).then(() => {
            this.$router.push({ name: 'ClassroomTaskDetail', params: {classroomId: this.classroomId, teacherActionId: this.teacherActionId} })
          })
        }
      }).catch((e) => {
        const errorMessage = e.message ? e.message : 'Action not found.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        }).then(() => {
          this.$router.push({ name: 'ClassroomTaskDetail', params: {classroomId: this.classroomId, teacherActionId: this.teacherActionId}})
        })
      })
    }
  },
  created () {
    this.init()
  },
  watch: {
    teacherActionId () {
      this.init()
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

</style>
