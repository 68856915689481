<template>
  <div class="tab-container-student-detail">
    <b-overlay :show="sendingData" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">Please wait...</p>
          <b-spinner label="Spinning"></b-spinner>
        </div>
      </template>

      <div class="student-detail-back">
        <a href="javascript:void(0)" @click="onBack"><span class="icon-caret-left"></span>Back</a>
      </div>
      <hr>
      <div class="student-detail-body">
        <div class="student-detail-body-header">
          <h3>{{titleProfile}}</h3>
        </div>
        <div class="student-detail-body-general-information">
          <h4>General information</h4>
          <hr>
          <div class="general-information-profile">
            <div class="text-information">
              <div class="profile-left">
                <b-avatar v-if="!student.thumbnail" icon="people-fill"></b-avatar>
                <b-avatar v-else variant="info" :src="student.thumbnail"></b-avatar>
              </div>
              <div class="profile-right">
                <div class="attributes">
                  <div class="full-name-container">
                    <div class="full-name">{{ studentName.first_name }} {{ studentName.last_initial }}</div>
                    <!-- Groups will only be created by the system and cannot be edited -->
                    <group-edit-button :classroom="classroom"
                                       v-if="isSmallGroupType"
                                       :student="studentName"
                                       @on-edit-student-name="updatedStudentName"/>
                    <student-edit-button :classroom="classroom"
                                         :student="studentName"
                                         @on-edit-student-name="updatedStudentName"
                                         v-else/>
                  </div>
                  <div class="username">{{ student.username }}</div>
                  <div class="posts-permissions">
                    <b-form-checkbox class="custom-checkbox-primary"
                                     v-model="student.pivot.approve_student_submissions"
                                     :disabled="studentsUnderThirteenYearsOld"
                                     @change="onUpdateSubmissionsPermissions">
                      Allow To Post Without Approval
                    </b-form-checkbox>
                  </div>
                  <div v-if="classroom.paymentRequired" class="label-paid">
                    {{labelStudentPaid}}
                  </div>
                </div>
                <div class="actions">
                  <b-button @click="resetStudentPassword"
                            :disabled="classroomExchangeExpired"
                            btn-text="Reset Password"
                            small/>
                  <b-button @click="$bvModal.show('modal-delete-student')"
                            :disabled="classroomExchangeExpired"
                            v-if="!isSmallGroupType"
                            btn-text="Delete Student"
                            class="btn-delete-student"
                            small/>
                  <b-button @click="masqueradeStudent" :btn-text="labelButtonView" small
                            class="btn-masquerade-student"/>
                </div>
              </div>
            </div>
            <hr>
            <div class="student-progress">
              <p>{{titleProgress}}</p>
              <vc-donut
                background="#F7F8FF" foreground="grey"
                :size="104" unit="px" :thickness="10"
                has-legend legend-placement="right"
                :sections="sections" :total="100"
                :start-angle="0" :auto-adjust-text-size="true">
              </vc-donut>
            </div>
          </div>
        </div>
        <div class="student-detail-body-tabs">
          <b-tabs content-class="tab-panel-student-detail" nav-class="nav-tabs-student-detail" align="center">
            <b-tab :active="tab.active" :key="'tab-student-'+index" v-for="(tab, index) in tabs">
              <template #title>
                {{ tab.name }} <span :class="tab.icon"></span>
              </template>
              <component :is="tab.component" v-bind="{...$props}" :tasks="tasks"
                         @on-submission-status-changed="onSubmissionStatusChanged"></component>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-overlay>

    <modal id="modal-reset-student-password" modal-class="classroom_crated_classroom_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">You have successfully reset the password for {{ student.full_name }}!</h2>
        <p>Please provide them with the following instructions:</p>
        <div class="modal-reset-password-instructions-wrapper">
          <p>Your password has been reset. Please go to the student portal and login with the following credentials:</p>
          <br/>
          <ul>
            <li>URL: <b>{{ student_side_url }}</b></li>
            <li>Username: <b>{{ student.username }}</b></li>
            <li>Classroom Code: <b>{{ classroom.code }}</b></li>
          </ul>
          <p>After logging in you will be prompted to create a new password.</p>
        </div>

      </template>
      <template #footer>
        <b-button @click="copyInstructions"
                  v-b-tooltip="{ title: 'Copied!', disabled:disabled, trigger:'click'}"
                  btn-text="Copy Instructions"/>
        <b-button @click="$bvModal.hide('modal-reset-student-password')" btn-text="CLOSE"/>
      </template>
    </modal>
    <modal id="modal-reset-student-password-error" modal-class="classroom_crated_classroom_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">Error</h2>
        <p class="second-text text-center">
          An error occurs when try to reset the student password, please contact us at support@levelupvillage.com
        </p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('modal-reset-student-password-error')" btn-text="OK"/>
      </template>
    </modal>
    <modal id="modal-delete-student" modal-class="classroom_crated_classroom_modal">
      <template #header>
      </template>
      <template #body>
        <h2 class="modal-title">{{isSmallGroupType ? 'Delete Group' : 'Delete Student'}}</h2>
        <p class="second-text text-center">
          Deleting a {{isSmallGroupType ? 'group' : 'student'}} will permanently remove all data and videos associated with this {{isSmallGroupType ? 'group' : 'student'}}. Are you sure?
        </p>
      </template>
      <template #footer>
        <b-button @click="$bvModal.hide('modal-delete-student')" btn-text="CANCEL" :disabled="sendingData"/>
        <b-button @click="deleteStudent" btn-text="OK" :disabled="sendingData"/>
      </template>
    </modal>
    <modal id="modal-masquerade-student" modal-class="classroom_crated_classroom_modal" hideFooter>
      <template #body>
        <a :href="urlMasqueradeStudent"
           target="_blank"
           class="masquerade-link"
           @click="$bvModal.hide('modal-masquerade-student')">{{urlMasqueradeStudent}}</a>
      </template>
    </modal>
  </div>
</template>

<script>
import StudentProfileCourseVideos from "./StudentProfileCourseVideos"
import StudentProfileResponses from "./StudentProfileResponses"
import {ClassroomService, MiscService} from "@/core/Services";
import {Button} from "@/core/components/Button";
import {TasksMixins, CourseMixins} from "@/modules/Classroom/Mixins";
import {Modal} from "@/core/components/Modal";
import {Notifications, AppStorage} from "@/core/Utils";
import ClassroomDetailStudentEdit from './ClassroomDetailStudentEdit.vue';
import ClassroomDetailGroupEdit from './ClassroomDetailGroupEdit.vue';
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"

export default {
  name: "ClassroomDetailTabStudentDetail",
  mixins: [TasksMixins, CourseMixins, ExchangesMixins],
  components: {
    StudentProfileResponses,
    StudentProfileCourseVideos,
    'student-edit-button': ClassroomDetailStudentEdit,
    'group-edit-button': ClassroomDetailGroupEdit,
    'b-button': Button,
    Modal,
  },
  data() {
    const iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
    return {
      sendingData: false,
      tabs: [
        {
          name: "TASK VIDEOS",
          icon: "icon-exclamation",
          component: "StudentProfileCourseVideos",
          active: true
        },
        {
          name: "RESPONSES",
          icon: "icon-exclamation",
          component: "StudentProfileResponses",
          active: false,
        },
      ],
      student_side_url: process.env.VUE_APP_BASE_URL_STUDENT_PORTAL,
      copyButtonTooltipTimeout: null,
      copyButtonTooltipDisabled: true,
      iOSDevice: iOSDevice,
      urlMasqueradeStudent: '',
      studentName: {
        first_name: '',
        last_initial: '',
        id:''
      },
    }
  },
  props: {
    classroom: Object,
    student: Object,
  },
  mounted() {
    this.getTasksList({classroom_id: this.classroom.id, student_id: this.student.id})
    this.studentName =  {
      first_name: this.student.first_name,
      last_initial: this.student.last_initial,
      id: this.student.id,
      username: this.student.username
    }
  },
  methods: {
    updatedStudentName(newName) {
      this.studentName = newName
    },
    copyInstructions() {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText('' +
        'Your password has been reset. Please go to the student portal and login with the following credentials:\n\n' +
        '   URL: ' + this.student_side_url + '\n' +
        '   Username: ' + this.student.username + '\n' +
        '   Classroom Code: ' + this.classroom.code + '\n\n' +
        'After logging in you will be prompted to create a new password.'
      );

      this.copyButtonTooltipDisabled = false
      this.copyButtonTooltipTimeout = false

      this.copyButtonTooltipTimeout = setTimeout(() => {
        this.copyButtonTooltipDisabled = true
        this.$root.$emit('bv::hide::tooltip')
      }, 1000)
    },
    onBack() {
      this.$emit('on-change-component', {component: 'ClassroomDetailTabItemStudents'})
    },
    resetStudentPassword() {
      this.sendingData = true
      ClassroomService.resetStudentPassword({classroom_id: this.classroom.id, student_id: this.student.id}).then(() => {
        this.sendingData = false
        this.$bvModal.show('modal-reset-student-password')
      }).catch(() => {
        this.sendingData = false
        this.$bvModal.show('modal-reset-student-password-error')
      })
    },
    deleteStudent() {
      this.sendingData = true
      ClassroomService.deleteStudent({classroom_id: this.classroom.id, student_id: this.student.id}).then(() => {
        this.onBack()
      }).catch(() => {
        this.sendingData = false
        this.$bvModal.show('modal-reset-student-password-error')
      })
    },
    onUpdateSubmissionsPermissions() {
      this.sendingData = true
      ClassroomService.setStudentPermissions({
        classroom_id: this.classroom.id,
        approve_student_submissions: this.student.pivot.approve_student_submissions,
        students: [this.student]
      }).then(() => {
        this.sendingData = false
      }).catch(() => {
        this.sendingData = false
      })
    },
    onSubmissionStatusChanged() {
      this.sendingData = true
      ClassroomService.getStudent({classroom_id: this.classroom.id, student_id: this.student.id}).then(response => {
        this.student.submissions_progress = response.data.submissions_progress
        this.student.responses_progress = response.data.responses_progress
        this.sendingData = false
      })
    },
    async masqueradeStudent () {

      MiscService.masquerade(this.classroom.id, this.student.id).then((res) => {
        AppStorage.setItem('student-token', res.token)

        this.urlMasqueradeStudent = `${process.env.VUE_APP_BASE_URL_STUDENT_PORTAL}/#/login-as-student?`
        this.urlMasqueradeStudent += `teacher_token=${AppStorage.getUser().token}`
        this.urlMasqueradeStudent += `&student_token=${res.token}`
        this.urlMasqueradeStudent += `&user_type=${res.user_type.slug}`

        if (this.iOSDevice) {
          this.$bvModal.show('modal-masquerade-student')
          return
        }

        const link = document.createElement('a')

        link.href = this.urlMasqueradeStudent
        link.target = '_blank'

        document.body.appendChild(link)
        link.click()
      })
    }
  },
  computed: {
    sections: function () {
      let sections = [
        {label: 'To-do', value: 100, color: '#C5C7FE'},
        {label: 'Submitted', value: 0, color: '#FCD68F'},
        {label: 'Approved', value: 0, color: '#CBE2A3'},
        {label: 'Blocked', value: 0, color: '#DBDBEC'},
      ]

      const todoCount = this.student.submissions_progress["to-do"] + this.student.responses_progress["to-do"]
      const submittedCount = this.student.submissions_progress["submitted"] + this.student.responses_progress["submitted"]
      const approvedCount = this.student.submissions_progress["approved"] + this.student.responses_progress["approved"]
      const blockedCount = this.student.submissions_progress["blocked"] + this.student.responses_progress["blocked"]
      const totalCount = todoCount + submittedCount + approvedCount + blockedCount

      sections[1].value = submittedCount * 100 / totalCount
      sections[2].value = approvedCount * 100 / totalCount
      sections[3].value = blockedCount * 100 / totalCount
      sections[0].value = (100 - (sections[1].value + sections[2].value + sections[3].value))

      sections[0].value = isNaN(sections[0].value) ? 0 : sections[0].value
      sections[1].value = isNaN(sections[1].value) ? 0 : sections[1].value
      sections[2].value = isNaN(sections[2].value) ? 0 : sections[2].value
      sections[3].value = isNaN(sections[3].value) ? 0 : sections[3].value

      return sections
    },
    studentsUnderThirteenYearsOld() {
      return this.classroom.age_range ? this.classroom.age_range.min_age < 13 : true
    },
    titleProfile() {
      return this.isSmallGroupType ? 'Group Profile' : 'Student Profile'
    },
    labelButtonView() {
      return this.isSmallGroupType ? 'Group View' : 'Student View'
    },
    titleProgress() {
      return this.isSmallGroupType ? 'Group Progress' : 'Student Progress'
    },
    getPaymentStudent(){
      return  this.student.payments.filter(x => typeof x!==undefined).shift();
    },
    labelStudentPaid(){
      if(this.classroom.student_payment_required){
        if(!this.student.hasPayments){
            return 'The student has not yet paid'
        }

        const paymentDate = this.$options.filters.dateMonthDayYear(this.getPaymentStudent.created_at);
        const amount = `$${this.getPaymentStudent.amount}`

        return `Student paid ${amount} on ${paymentDate}`
      }

      return ''
    }
  },
  watch: {
    currentTabIndex: {
      handler: function (value) {
        try {
          this.tabs[value].active = true
        } catch (e) {
          this.tabs[0].active = true
        }
      },
      immediate: true
    },
    '$route.query.notification_id': {
      handler: function (notification_id) {
        let currentTabIndex = 0
        try {
          if (notification_id) {
            const notificationOpen = AppStorage.getNotificationOpen(notification_id);
            switch (notificationOpen.type) {
              case Notifications.type.studentVideoPosted: {
                currentTabIndex = 0
                break;
              }
              case Notifications.type.studentResponseVideo: {
                currentTabIndex = 1
                break
              }
            }
          }
        } catch (e) {
          currentTabIndex = 0
        }

        this.tabs[currentTabIndex].active = true
      },
      immediate: true
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

::v-deep
  .btn
    letter-spacing 0
    border-radius 7px

  .btn-common--small
    padding 4px 12px
    border-width 2px
    border-color color-3
    font-size 10.5px
    line-height 14px
    border-radius 4px

.btn-delete-student, .btn-masquerade-student
  margin-top 15px
  min-width 120px

.tab-container-student-detail
  padding 13px 10px

.student-detail-back
  a
    font font-opensans-bold
    font-size 17px
    cursor pointer
    text-decoration none
    color color-3
    display flex
    align-items center

    span
      font-size 0.7em
      padding-right 5px

      &:before
        color color-3

.student-detail-body
  & > div:not(:last-child)
    padding-left 0

  &-header
    background-color rgba(color-31, 0.27)
    padding 9px 0
    margin-bottom 2px

    h3
      margin 0
      font font-opensans-bold
      font-size 18px
      color color-8
      text-transform uppercase
      padding-left 8px

  &-general-information
    background-color color-32
    padding-top 22px

    h4
      font font-opensans-bold
      font-size 16px
      color color-8
      padding-bottom 10px
      padding-left 8px

    .general-information-profile
      padding-bottom 40px

      hr
        border-top-color color-1
        margin 22px 0

      .text-information
        padding 27px 20px 13px 20px
        display flex
        align-items center
        justify-content center

        .profile-left
          .b-avatar
            border-radius 50%
            width 70px
            height 70px

        .profile-right
          display flex
          flex-direction column
          padding-left 20px

          .attributes
            font font-opensans-bold
            padding-right 10px

            .full-name-container
              display flex
              @media(max-width 500px)
                display block

              .full-name
                display flex
                align-items center
                overflow-wrap anywhere

            .username
              font-size 13px
              color color-40
              text-transform: capitalize
              margin-bottom 10px

            .posts-permissions
              margin-bottom 10px

              ::v-deep
                .custom-control-label
                  font-size 13px

            .label-paid
              font-size 13px
              color color-3

        .actions
          margin-top auto

      .student-progress
        p
          font font-opensans-bold
          font-size 13.5px
          line-height 30px
          color color-40
          margin-bottom 0
          position absolute
          margin-left 140px
          margin-top -10px

        ::v-deep
          .cdc-legend
            flex-direction unset !important
            letter-spacing 0.6px


@media (min-width: 768px)
  ::v-deep
    .btn-common--small
      padding 6px 20px
      font-size 11px
      line-height 15px
      border-radius 4px
      min-width 178px

  .student-detail-body
    &-general-information .general-information-profile
      padding-right 40px
      padding-left 15px
      justify-content flex-start

    &-general-information .general-information-profile
      .text-information
        padding-right 15px
        padding-left 15px
        justify-content flex-start

        .profile-left
          width 104px

          .b-avatar
            width 104px
            height 104px

        .profile-right
          padding-left 40px
          flex 1

          .attributes
            .full-name
            .username
              font-size 14px

            .posts-permissions
              ::v-deep
                .custom-control-label
                  font-size 14px

          .common-btn
            font-size 0.9rem

      .student-progress p
        margin-left 140px
        margin-top 13px

@media (min-width: 992px)

  .student-detail-body
    & > div:not(:last-child)
      padding-left 100px
      padding-right 0

    &-general-information .general-information-profile
      padding-right 100px

      .text-information
        .profile-right
          flex-direction row
          justify-content space-between
          align-items flex-end
          padding-left 40px
          flex 1

          ::v-deep
            .custom-control-label
              max-width 240px

@media (min-width: 1200px)
  ::v-deep
    .btn-common--small
      padding 8px 30px
      font-size 13px
      line-height 18px
      border-radius 7px

  .student-detail-body
    & > div:not(:last-child)
      padding-left 150px
      padding-right 0

    &-general-information
      .general-information-profile
      padding-right 150px

      .general-information-profile .text-information .profile-right .attributes
        font-size 14px

        ::v-deep
          .custom-control-label
            max-width 100%

.student-detail-body-tabs
  ::v-deep
    .tab-panel-student-detail
      background-color color-32
      padding-top 57px
      padding-bottom 53px
      padding-left 10px
      padding-right 10px

    .nav-tabs-student-detail
      border-bottom-color color-23

      .nav-item
        display flex
        justify-content center
        align-items center
        margin-left 10px

        .nav-link
          font font-opensans-semibold
          font-size 14px
          padding-top 14px
          color color-33
          border-left 0
          border-right 0
          border-top-left-radius 0
          border-top-right-radius 0

          span
            font-size 16px

            &:before
              color color-22

          &.active
            font font-opensans-bold
            font-size 16px
            border-bottom 3px solid color-3
            border-top-color transparent
            border-left-color transparent
            border-right-color transparent
            color color-3

            &:hover
              border-bottom-color color-3

            span
              &:before
                color color-3

          &:hover
            border-color transparent

  @media (min-width: 992px)
    ::v-deep
      .tab-panel-student-detail
        padding-left 100px
        padding-right 100px

  @media (min-width: 1200px)
    ::v-deep
      .tab-panel-student-detail
        padding-left 150px
        padding-right 150px

::v-deep
  .cdc-container
    padding-left 15px
    justify-content flex-start

  .cdc-legend
    padding-top 15px
    justify-content flex-start

  .cdc-legend-item
    font font-opensans-bold
    font-size 11px
    letter-spacing 0.4px
    margin-right 10px

  .cdc-legend-item-color
    font-size 16px
    border-radius 4px
    border 1px solid #3D3D3D


.modal-reset-password-instructions-wrapper
  padding 15px

  ul
    list-style none
</style>

<style lang="stylus">
#modal-masquerade-student
  .body
    padding-bottom 20px

    .masquerade-link
      word-wrap break-word
      text-decoration underline
</style>
