import {mapActions, mapGetters} from 'vuex';
import validateForms from "@/core/Mixins/validate-forms";
import {SubjectTypes} from "@/core/Utils"

export const ClassroomMixins = {
  data() {
    return {
      classroomId: null
    }
  },
  mixins: [validateForms],
  mounted() {
    if (!this.classroomId) {
      this.form = {
        name: null,
        subject_id: null,
        age_range_id: null,
        lang_instruction_id: null,
        lang_native_id: null,
        start_date: null,
        end_date: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'ClassroomModule/getClassroomData',
      isLoadingClassroom: 'ClassroomModule/isLoadingClassroom',
      isAddingClassroomContent: 'ClassroomModule/isAddingClassroomContent',
      user: 'UserModule/getUser',
      isLoadingMiscData: 'MiscModule/isLoadingMiscData',
      payment_methods: 'MiscModule/getPaymentMethods',
      levels: 'MiscModule/getLevels',
      subjects: 'MiscModule/getSubjects',
      languages: 'MiscModule/getLanguages',
      age_ranges: 'MiscModule/getAgeRanges',
      chat_messages: 'ClassroomModule/chat_messages',
    }),
    studentPaymentRequired () {
      return this.classroom.student_payment_required
    },
    payingStudentAges() {
      return this.studentPaymentRequired ? this.age_ranges.filter((age) => age.min_age >= 13) : this.age_ranges
    }
  },
  methods: {
    ...mapActions({
      getClassroom: 'ClassroomModule/getClassroom',
      createClassroom: 'ClassroomModule/createClassroom',
      updateClassroom: 'ClassroomModule/updateClassroom',
      addClassroomContent: 'ClassroomModule/addCourseContent',
      getProfile: 'UserModule/getProfile',
      getMiscData: 'MiscModule/fetchMiscData',
      getPaymentMethods: 'MiscModule/fetchPaymentMethods',
      getLevels: 'MiscModule/fetchLevels',
      getSubjects: 'MiscModule/fetchSubjects',
      getLanguages: 'MiscModule/fetchLanguages',
      getAgeRanges: 'MiscModule/fetchAgeRanges',
      getChatMessages: 'ClassroomModule/fetchClassroomChatMessages',
      setPlayersTrust: 'MiscModule/setPlayersTrust',
    }),
    cancel: function () {
      this.$emit('Classroom:Form:Cancel')
    },
    updateClassroomData: function () {
      if (!this.isWorldLanguage) {
        this.form.lang_native_id = this.form.lang_instruction_id
      }

      const id = this.id
      const form = this.form

      if (this.isValidForm()) {
        this.updateClassroom({id: id, form: form}).then((response) => {
          if (response.success === true) {
            if (!this.classroom.course_id) {
              this.goToChooseCourseContentPage(this.id)
            } else {
              this.$emit('Classroom:Update:Success', response.data)
            }
          } else {
            this.createErrorMessage = response.message
            this.$emit('Classroom:Update:Error', response.message)
          }
        }).catch(err => {
          this.$emit('Classroom:Update:ErrorAPI', err)
        })
      } else {
        this.$emit('Classroom:Update:Error', this.errors)
      }
    },
  },
  watch: {
    selectedSubject: function (newValue) {
      this.form.subjectId = newValue.id

      this.selectedNativeLanguage = null
      this.selectedLanguageInstruction = null
      this.form.nativeLanguage = null
      this.form.langInstruction = null
    },
    selectedNativeLanguage(newValue) {
      this.form.nativeLanguage = newValue.id ?? null
    },
    selectedLanguageInstruction(newValue) {
      this.form.langInstruction = newValue.id ?? null

      if (!this.isWorldLanguage) {
        this.selectedNativeLanguage = newValue
      }
    },
    classroom: {
      handler: function (classroom) {
        classroom.start_date = classroom.start_date?.split(" ")[0]
        classroom.end_date = classroom.end_date?.split(" ")[0]
        this.form = classroom
      },
      deep: true,
      immediate: true
    },
    '$route.params.classroomId': {
      handler: function (classroomId) {
        if (!classroomId) {
          return
        }

        if (typeof classroomId === 'string') {
          this.classroomId = parseInt(classroomId);
        } else {
          this.classroomId = classroomId;
        }

        this.getClassroom(this.classroomId).then((response) => {
          const classroom = response.data
          this.setPlayersTrust(classroom?.course?.subject_id === SubjectTypes.playersTrust.id)
          if(!response.success) {
            if(this.$route.query['partner-invitation']) {
              this.$EventBus.$emit("on-wrong-teacher-partner-invitation", response);
            } else {
              this.$EventBus.$emit("on-classroom-error", response);
            }
          }
        })
      },
      deep: true,
      immediate: true
    }
  },
};
