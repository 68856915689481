<template>
  <modal id="alert-modal"
         size="xl"
         @hidden="hideModal"
         modal-class="modal--alert"
         :hide-footer="true">
    <template #body>
      <b-alert :variant="alert.color"
               :show="true"
               class="alert-container"
               fade>
        <div class="alert-container__message" v-html="alert.message"/>
      </b-alert>
      <p class="date-container" v-if="alert.starts_at">
        <span class="date-container__label">Dated:</span>
        {{setDate(alert.starts_at)}} to {{setDate(alert.ends_at)}}
      </p>
      <b-overlay no-wrap :show="loadingAlert"/>
    </template>
  </modal>
</template>

<script>
import { Modal } from '@/core/components/Modal'
import {AlertsService} from "@/core/Services";
import moment from 'moment';

export default {
  name: "AlertModal",
  components: {
    Modal
  },
  props: {
    alertId: {
      type: Number
    }
  },
  data() {
    return {
      alert: {},
      loadingAlert: false
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show('alert-modal')
      this.loadingAlert = true
      AlertsService.getAlert().then(({data}) => {
        const alertSelected = data.find((alert) => alert.id === this.alertId)
        if (alertSelected) {
          return this.alert = alertSelected
        }
        this.$bvModal.msgBoxOk(`Alert not found.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          centered: true
        }).then(() => {
          this.hideModal()
        })
      }).finally(() => {
        this.loadingAlert = false
      })
    },
    hideModal() {
      this.alert = {}
      this.$bvModal.hide('alert-modal')
    },
    setDate(date) {
      return moment(date).format('MM-DD-YYYY HH:mm')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.alert-container
  max-width 800px
  margin-right auto
  margin-left auto
  word-wrap break-word
  white-space pre-wrap
  padding 0.75rem 1.25rem

  &__message
    font font-opensans-regular

  ::v-deep
    .close
      pointer-events none
      padding 0
      padding-right 4px

.date-container
  &__label
    font-weight 700

</style>
<style lang="stylus">
.modal--alert
  .modal-dialog
    max-width 950px
    @media (min-width 575px)
      padding 10px

  .modal-body
    p
      margin-top 0
      margin-bottom 1rem
</style>
