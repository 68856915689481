<template>
  <b-button
    @click="onClick"
    :href="href"
    :target="target"
    :variant="variant"
    :disabled="disabled"
    :class="buttonClass">
    <slot name="icon-before"></slot>
    {{ btnText }}
    <slot name="icon-after"></slot>
  </b-button>
</template>

<script>
export default {
  name: "Button",
  props: {
    variant: {
      type: String,
      default: "common"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: "ok"
    },
    href: null,
    target: null,
    outline: null,
    small: null,
  },
  methods: {
    onClick: function () {
      this.$emit('click', this)
    },
    hideModal: function () {
      this.$refs['modal'].hide()
    }
  },
  computed: {
    buttonClass() {
      const className = {
        '': true
      }

      className['btn-common--outline'] = !(typeof this.outline === 'undefined')
      className['btn-common--small'] = !(typeof this.small === 'undefined')

      return {
        ...className
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "styles/button.styl";
</style>
