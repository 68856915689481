<template>
  <modal
    id="teacher-agreement-modal"
    modal-class="classroom_first_classroom_modal"
    @shown="agreementVerificationIntervalStarts"
    no-close-on-esc
    no-close-on-backdrop
    hide-close-button
  >
    <template #header></template>
    <template #body>
      If your school policy requires another individual's signature,
      please share this link with them to complete this form

      <a
        @click.prevent="copyAgreementFormUrl"
        :href="formQuery"
        v-b-tooltip="{ title: 'Copied!', trigger:'click'}"
      >
        Here
      </a>

      <br>
      <br>
      <iframe :src="formQuery" title="luv teacher agreement dev" width="100%" height="700"></iframe>
    </template>
    <template #footer>
      <b-button @click="onHideAgreement" btn-text="CANCEL CLASSROOM EXCHANGE"/>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@/core/components/Modal";
import { ProfileService } from "@/core/Services";
import { UtilsMixins } from "@/core/Mixins";
import { Button } from "@/core/components/Button";

export default {
  name: "TeacherAgreementModal",
  components: {
    'b-button': Button,
    Modal,
  },
  mixins: [UtilsMixins],
  data () {
    return {
      closed: true
    }
  },
  methods: {
    agreementVerificationIntervalStarts () {
      this.closed = false
      this.teacherAgreementVerification()
    },
    teacherAgreementVerification () {
      if (this.closed) {
        return
      }
      ProfileService.checkTeacherAgreement().then((success) => {
        if (success) {
          this.$emit('teacher-agreement-success')
          this.closeTeacherAgreement()
        } else {
          this.teacherAgreementVerification()
        }
      })
    },
    copyAgreementFormUrl () {
      navigator.clipboard.writeText(this.formQuery);

      this.copyButtonTooltipDisabled = false
      this.copyButtonTooltipTimeout = false

      this.copyButtonTooltipTimeout = setTimeout(() => {
        this.copyButtonTooltipDisabled = true
        this.$root.$emit('bv::hide::tooltip')
      }, 1000)
    },
    closeTeacherAgreement () {
      this.$bvModal.hide('teacher-agreement-modal')
    },
    onHideAgreement () {
      this.$bvModal.msgBoxConfirm('This Classroom will be cancelled. You may create a new Classroom once the Teacher Agreement has been signed.', {
        size: 'sm',
        dialogClass: "modal-message-box purchase-center",
        buttonSize: 'sm',
        okTitle: 'OK',
        cancelTitle: 'GO BACK',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then((value) => {
          if (value) {
            this.closed = true
            this.goToMyClassroomsPage()
          }
        })
        .catch(() => {
          // An error occurred
        })
    },
  },
  computed: {
    formQuery: function () {
      let query = `name=${encodeURIComponent(`${this.user.first_name} ${this.user.last_name}`)}`
      query += `&school=${encodeURIComponent(this.user.school_name)}`
      query += `&email=${encodeURIComponent(this.user.email)}`
      query += `&teacher_id=${this.user.id}`

      return `${process.env.VUE_APP_LUV_TEACHER_AGREEMENT_FORM_URL}?${query}`
    }
  }
}
</script>

<style scoped>

</style>
