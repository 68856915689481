<template>
  <PageBase>
    <router-view></router-view>
  </PageBase>
</template>
<script>
import {PageBase} from "@/core/components"

export default {
  name: "AccountView",
  components: {
    PageBase
  }
}
</script>
