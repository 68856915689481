<template>
  <div class="wrapper-find-global-partner">
    <div class="container-find-global-partner">
      <component @on-change-component="onChangeComponent"
                 v-bind="{...$props,...componentProps, form: formGlobalPartnerChoose}"
                 :is="currentComponent"/>
    </div>
  </div>
</template>

<script>
import GlobalPartnerCreateFind from "./GlobalPartnerCreateFind"
import GlobalPartnerCreateBestMatch from "./GlobalPartnerCreateBestMatch"
import GlobalPartnerCreateChoose from "./GlobalPartnerCreateChoose"

export default {
  name: "GlobalPartnerCreateFindContent",
  components: {
    GlobalPartnerCreateFind,
    GlobalPartnerCreateBestMatch,
    GlobalPartnerCreateChoose
  },
  data() {
    return {
      currentComponent: null,
      componentProps:{}
    }
  },
  mounted() {
    this.currentComponent ='GlobalPartnerCreateFind'
  },
  props: {
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    },
    formGlobalPartnerChoose: {
      type: Object,
      required: true
    }
  },
  methods: {
    onChangeComponent(data){
      this.currentComponent = data.component
      this.componentProps =  data.params || {};
    }
  }
}
</script>
