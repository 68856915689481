<template>
  <div>log out</div>
</template>

<script>
import {AuthService} from '@/core/Services'

export default {
  name: "LogOutPage",
  mounted() {
    AuthService.logout()
    this.goToLoginPage()
  }
};
</script>
