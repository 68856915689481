<template>
  <div class="record-container" :class="'recorded'">
    <div class="video-container">
      <video-custom
        v-if="!loading"
        :src="uploadedFile.objectURL"
        :objectURL="true"
        @on-video-loaded="onVideoLoaded"
        @on-video-error="onVideoError"/>
    </div>
    <div class="submit-recording" :class="{'submit-recording--task': !taskId}">
      <div role="button" class="submit review" @click.prevent="reviewPrompt" v-if="reviewPromptButton">
        REVIEW PROMPT
      </div>
      <div role="button" class="submit" @click.prevent="submitVideo">
        SUBMIT
      </div>
      <div role="button" class="submit delete" @click.prevent="$emit('on-upload-try-again')">
        DELETE & TRY AGAIN
      </div>
    </div>
    <TaskPromptModal :prompt="actionDetails"
                     :response-prompt="responseDetails"
                     :is-response-action="isResponseACtion"/>
    <b-overlay no-wrap :show="loading"/>
  </div>
</template>

<script>
import { ActionsService } from '@/core/Services'
import TaskPromptModal from './TaskPromptModal'
import VideoCustom from './VideoCustom.vue'

export default {
  name: 'VideoUploaded',
  components: {
    TaskPromptModal,
    VideoCustom
  },
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    submissionId: {
      type: Number,
      required: false
    },
    uploadedFile: {
      type: Object,
      required: true
    },
    reviewPromptButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      actionDetails: null,
      responseDetails: '',
      isResponseACtion: false
    }
  },
  methods: {
    async init () {
      this.loading = true
      try {
        ActionsService.getActionItemById(this.taskId).then(({data}) => {
          this.actionDetails = data.action.details
          this.responseDetails = data.action.details_response
          this.isResponseACtion = !!data.teacher_action
        })
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while loading task information.'
        await this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      } finally {
        this.loading = false
      }
    },
    reviewPrompt () {
      this.$bvModal.show('task-prompt-modal')
    },
    submitVideo () {
      this.$emit('on-upload-video')
    },
    onVideoLoaded () {
    },
    async onVideoError (e) {
      const errorMessage = e.message ? e.message : 'Error while load video.'
      if (errorMessage !== 'Error while load unplayable video.') {
        await this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
        this.$emit('on-video-error')
      }
    }
  },
  mounted () {
    if (this.reviewPromptButton) {
      this.init()
    }
  },
  watch: {
    classroomId () {
      if (this.reviewPromptButton) {
        this.init()
      }
    },
    taskId () {
      if (this.reviewPromptButton) {
        this.init()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
.myVideo-dimensions.vjs-fluid
  padding-top unset

.video-js
  height 100%
  width 100%

.record-container
  height calc(100% - 120px)

  &.recorded
    height calc(100% - 213px)

  .timer-container-bg
    background-color color-13

  .timer-container
    max-width 720px
    margin auto
    height 60px
    color color-3
    font-family "Open Sans"
    font-size 12px
    font-weight bolder
    padding-top 15px

    .timer
      text-align center

    .timer-title
      display inline-block
      margin-right 10px

    .timer-counter
      display inline-block
      vertical-align middle
      width 25px
      margin-left 8px

  .bar
    border 2px solid
    height 12px
    margin-left 30px
    padding 2px
    border-radius 3px
    width calc(100% - 90px)
    display inline-block
    vertical-align middle

    .fill
      border-radius 3px
      border solid 2px
      height 4px
      width 80%

  .video-container
    max-width 720px
    height 100%
    margin auto

  .submit-recording
    width 100%
    height auto
    background-color color-13
    text-align center
    padding-top 20px
    padding-bottom 20px

    .submit
      cursor pointer
      margin auto
      width 114px
      height 31px
      line-height 31px
      background-color color-3
      color white
      font-size 12px
      font-weight bold
      border-radius 4px
      letter-spacing 2px
      margin-top 10px

      &.delete
        width 226px

      &.review
        width 226px

  .submit-recording--task
    background-color unset
</style>
