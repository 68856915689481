<template>
  <component v-on="$listeners"
             v-bind="$attrs"
             class="copy-text"
             v-b-tooltip="{ title: 'Copied!', disabled:disabled, trigger:'click'}"
             @touchstart="handleSelectedText"
             @click="handleSelectedText" :is="tag">{{ text }}
  </component>
</template>

<script>
import Clipboard from 'clipboard'

export default {
  name: "CopyTextElement",
  props: {
    text: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'span'
    },
    activeSelectionText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      disabled: true,
      timeoutTooltip: null
    }
  },
  methods: {
    handleSelectedText(e) {
      let range = null
      this.copyText().then(() => {
        window.clearTimeout(this.timeoutTooltip)
        this.disabled = false

        this.timeoutTooltip = setTimeout(() => {
          this.disabled = true
          this.$root.$emit('bv::hide::tooltip')
        }, 1000)
      })

      if (this.activeSelectionText) {
        if (document.selection) {
          range = document.body.createTextRange()
          range.moveToElementText(e.target);
          range.select();
        } else if (window.getSelection) {
          range = document.createRange()
          range.selectNode(e.target);
          window.getSelection().removeAllRanges()
          window.getSelection().addRange(range)
        }
      }
    },
    copyText() {
      return new Promise((resolve, reject) => {
        const fakeElement = document.createElement('button')
        const text = this.text

        const clipboard = new Clipboard(fakeElement, {
          text: function () {
            return text
          },
          action: function () {
            return 'copy'
          },
          container: document.body
        })

        clipboard.on('success', function (e) {
          clipboard.destroy()
          resolve(e)
        })

        clipboard.on('error', function (e) {
          clipboard.destroy()
          reject(e)
        })

        document.body.appendChild(fakeElement)
        fakeElement.click()
        document.body.removeChild(fakeElement)
      })
    }
  },
  beforeDestroy() {
    window.clearTimeout(this.timeoutTooltip)
  }
}
</script>

<style lang="stylus" scoped>
.copy-text
  cursor copy

</style>
