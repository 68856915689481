<template>
  <b-modal id="task-prompt-modal" centered hide-footer hide-header size="xs">
    <div class="prompt-area">
      <div v-if="prompt && !isResponseAction"
           class="promt-text"
           v-html="replaceLineBreaks(prompt)"/>
      <div v-if="responsePrompt && isResponseAction"
           class="promt-text promt-text--response"
           v-html="replaceLineBreaks(responsePrompt)"/>
      <div v-if="videoTimeLimit" class="task-video-time-limit">TIME: <span>{{ videoTimeLimit }} seconds</span></div>
    </div>
    <div class="modal-btns">
      <div role="button" class="btn-ok" @click="$bvModal.hide('task-prompt-modal')">
        OK
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'DownloadRecordedVideoModal',
  props: {
    videoTimeLimit: {
      type: [String, Number]
    },
    prompt: {
      type: String
    },
    responsePrompt: {
      type: String
    },
    isResponseAction: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    replaceLineBreaks(text) {
      return text ? text.replace(/(?:\\r\\n|\\r|\\n)/g, '<br />') : ''
    }
  }
}
</script>

<style lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#task-prompt-modal
  .modal-content
    margin auto
    width 500px
    height auto

  .modal-title
    text-align center
    font-size 16px
    font-weight bold
    margin-top 65px

  .modal-text
    text-align center
    font-size 14px
    width 79%
    margin auto
    height 75px
    margin-top 10px
    text-overflow ellipsis
    overflow hidden

  .modal-btns
    text-align center

    .btn-cancel, .btn-ok
      text-align center
      display inline-block
      vertical-align top
      width 102px
      height 31px
      background-color color-3
      border none
      border-radius 4px
      color white
      font-weight 600
      margin-bottom 25px
      letter-spacing 2px
      line-height 31px

    .btn-ok
      width 82px

  .prompt-area
    padding-bottom 20px
    margin 20px
    border-bottom solid 2px #D4D4D4
    word-break break-word

    .promt-title
      text-transform uppercase
      font-weight bold
      color #3D3D3D
      margin-bottom 10px
      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

    .task-video-time-limit
      text-transform uppercase
      font-weight bold
      color #3D3D3D
      margin-top 10px

      span
        font-weight normal
        text-transform none
        color #3D3D3D
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px

      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

    .promt-text
      color #3D3D3D
      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

    .promt-text--response
      margin-top 30px
</style>
