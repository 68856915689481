<template>
  <fragment>
    <h2 class="modal-title">Contact Support</h2>
        <b-form>
          <b-textarea
            v-model="form.report"
            :maxlength="255"
            placeholder="Please let us know how we can help."
            custom-class="common-input common-input--primary"/>
        <hr>
          <div class="form-group form-group-actions">
            <b-button @click="onCancel" btn-text="Cancel" small/>
            <b-button @click="sendReport" btn-text="Send" small/>
          </div>
        </b-form>
  </fragment>
</template>

<script>
import BTextarea from "@/core/components/FormInput/Textarea";
import {Button} from "@/core/components/Button";
import validateForms from "@/core/Mixins/validate-forms";


export default {
  name: "ClassroomDetailCreateReport",
  mixins: [validateForms],
  components: {
    BTextarea,
    'b-button': Button,
  },
  data() {
    return {
      form: {
        report: '',
      },
      validations: {
        report: {
          validate: 'required',
        },
      },
    }
  },
  methods: {
    onCancel() {
      this.$emit('on-cancel-report')
    },
    sendReport(){
        this.$emit('create-report', this.form)
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.form-group {
  &-links {
    margin-top 15px

    ul {
      padding 0
      margin 0
      list-style-type none
      display block

      & > li {
        border 0
        padding 0
        display block
        color color-3

        a {
          font font-opensans-bold
          font-size 14px
          color color-34
          text-transform uppercase
        }

        button {
          border 0
          background-color transparent

          span {
            font-size 0.7rem

            &:before {
              color color-3
            }
          }
        }
      }
    }
  }

  &-actions {
    display flex
    justify-content center

    .btn-common {
      padding 10px 30px
      font-size 14px
      line-height 19px
      letter-spacing 1.4px
      &:first-child {
        margin-right 10px
      }
    }
  }
}
hr {
  margin-bottom 15px
  margin-top 20px
}

</style>
