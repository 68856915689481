<template>
  <div class="container-detail-submission">
    <div class="container">
      <div class="detail-content">
        <div class="col">
          <div class="attribute">
            <div class="attribute-left">
              <h2 class="modal-tittle">ORIGINAL POST</h2>
              <div class="profile">
                <div class="profile-left">
                  <b-avatar :src="student_submission_responding.student_thumbnail" size="3.1rem"></b-avatar>
                </div>
                <div class="profile-right">
                  <div class="username">
                    {{ studentFullNameResponse }}
                  </div>
                  <div class="username">{{ student_submission_responding.task_name }}</div>
                  <div class="username">Posted On: {{
                      student_submission_responding.created_at | dateMonthDayYearHour
                    }}
                  </div>
                </div>
              </div>
              <video-custom class="img-fluid"
                            ref="video-custom"
                            @on-video-play="playVideo"
                            :src="student_submission_responding.url"
                            :video_processed="student_submission_responding.video_processed"/>
              <flag-as-inappropiate v-if="!showActions"
                                    @show-flag-inappropriate-modal="pauseVideos"
                                    :classroom-id="classroom_id"
                                    :submission-id="selectedVideo.id"/>
            </div>
          </div>
        </div>
        <div class="col col--response-container">
          <div class="attribute">
            <div class="attribute-left">
              <h2 class="modal-tittle">RESPONSE</h2>
              <div class="profile">
                <div class="profile-left">
                  <b-avatar :src="student_submission.student_thumbnail" size="3.1rem"></b-avatar>
                </div>

                <div class="profile-right">
                  <div class="username">{{ studentFullNameSubmission }}</div>
                  <div v-if="student_submission.response_type === 'video'" class="username">Video Response</div>
                  <div v-else class="username">Text Response</div>
                  <div class="username">Posted On: {{ student_submission.created_at | dateMonthDayYearHour }}</div>
                </div>
              </div>

              <h3 v-if="!student_submission.video_processed" class="video-not-ready-status">The video is not ready
                yet</h3>

              <video-custom class="img-fluid"
                            ref="video-custom-response"
                            @on-video-play="playVideo"
                            v-if="student_submission.response_type === 'video'"
                            :src="student_submission.url"
                            :video_processed="student_submission.video_processed"/>
              <div v-else class="attribute-right-course student-text-wrapper">
                <div class="description">{{ student_submission.text }}</div>
              </div>

              <div class="attribute-right-status">
                <h3 :class="student_submission.status" v-if="showActions">STATUS: {{ student_submission.status }}</h3>
                <div class="actions" id="actions-submission-detail">
                  <select-dropdown v-if="actions.length && showActions"
                                   v-model="action"
                                   :disabled="classroomExchangeExpired"
                                   value-field="value"
                                   placeholder="Video Approval"
                                   @input="applyAction"
                                   :options="actions"/>
                  <b-button @click="onNextSubmission"
                            class="action-submissions-button"
                            :disabled="disabledNextButton"
                            btn-text="Next">
                            <template #icon-after>
                              <b-icon icon="arrow-right"/>
                            </template>
                  </b-button>
                </div>
                <div class="comment-container" v-if="showActions">
                  <div class="comment-container__label">Optional comments for {{ studentFullNameSubmission }}</div>
                  <b-form-textarea
                    id="comment-textarea"
                    v-model="userComment"
                    maxLength="500"
                    rows="5"
                    max-rows="5"/>
                  <b-button @click="saveComment"
                            :disabled="disableSaveCommentButton"
                            class="save-button"
                            btn-text="Save"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-overlay no-wrap :show="loading || loadingComment"></b-overlay>
  </div>
</template>

<script>
import {SelectDropdown} from "@/core/components/SelectDropdown";
import VideoCustom from "@/core/components/Video/VideoCustom";
import {Button} from "@/core/components/Button";
import {VideoCommentsMixins} from "@/modules/Classroom/Mixins";
import {CourseMixins} from "@/modules/Classroom/Mixins"
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"
import {FlagAsInappropiate} from '@/core/components/FlagAsInappropiate'
import {actionOptions} from "./ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabSubmissions/constantsTabSubmissions"
import {TrackingActivityService} from '@/core/Services'
import {TrackingTypes} from "@/core/Utils"

export default {
  name: "StudentResponseModal",
  mixins: [VideoCommentsMixins, CourseMixins, ExchangesMixins],
  components: {
    'select-dropdown': SelectDropdown,
    'video-custom': VideoCustom,
    'b-button': Button,
    FlagAsInappropiate
  },
  data() {
    return {
      action: '',
      actions: actionOptions,
      userComment: '',
      loadingComment: false
    }
  },
  props: {
    selectedVideo: Object,
    classroom_id: Number,
    loading: Boolean,
    disabledNextButton: Boolean,
    showActions: {
      type: Boolean,
      default: true
    },
    isSubmissionTab: {
      Type: Number,
      default: false
    }
  },
  mounted() {
    this.userComment = this.selectedVideo.teacher_comment ? this.selectedVideo.teacher_comment : ''
  },
  methods: {
    applyAction() {
      this.$emit('applyAction', {action: this.action, videos: [this.selectedVideo.id]})
    },
    async onNextSubmission() {
      const validationResponse = await this.validateSavedComment(false)
      if (validationResponse === null || validationResponse === true) {
        return
      }
      this.$emit('next-submission')
    },
    pauseVideos() {
      this.$refs['video-custom'].pauseVid()
      this.$refs['video-custom-response'].pauseVid()
    },
    playVideo() {
      if (this.isSubmissionTab) {
        TrackingActivityService.trackingActivityTeachers(TrackingTypes.watchSubmissionVideo)
      } else {
        TrackingActivityService.trackingActivityTeachers(TrackingTypes.watchStudentVideo)
      }
    }
  },
  computed: {
    student_submission() {
      return this.selectedVideo
    },
    student_submission_responding() {
      return this.selectedVideo.responding_to ? this.selectedVideo.responding_to : {}
    },
    studentFullNameSubmission() {
      return this.student_submission.student_first_name + ' ' + (this.isIndividualGroupType ? this.student_submission.student_last_initial : '')
    },
    studentFullNameResponse() {
      return this.student_submission_responding.student_first_name + ' ' + (this.isIndividualGroupType ? this.student_submission_responding.student_last_initial : '')
    }
  },
  watch: {
    selectedVideo () {
      this.action = ''
      this.userComment = this.selectedVideo.teacher_comment ? this.selectedVideo.teacher_comment : ''
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.video-not-ready-status
  font font-opensans-bold
  font-size 14px
  text-transform uppercase
  letter-spacing 0
  color #262626
  padding 10px 14px
  background: color-43 0% 0% no-repeat padding-box
  text-align center

.student-text-wrapper
  margin-top 38px
  height: 509px
  overflow-y auto

.container-detail-submission
  padding-top 30px
  position relative

  .container
    max-width 1400px
    padding 0

  .detail-content
    display flex
    width 100%
    @media (max-width 750px)
      flex-direction column

      .col--response-container
        margin-top 30px

  .profile
    display flex
    align-items center
    margin-bottom 38px

    &-left
      width 50px


    &-right
      text-align left
      padding-left 25px
      font font-opensans-bold
      font-size 0.7rem
      max-width 300px
      @media(max-width 800px)
        max-width 250px
      @media(max-width 400px)
        max-width 200px

      .username
        overflow hidden
        text-overflow ellipsis


  .attribute
    display flex
    flex-direction column

    &-left
      flex-basis 300px
      //margin auto

      .video-overlay
        width 100%
        height 395px
        border-radius 4px

    &-right
      text-align left

      &-course .title
        font font-opensans-bold
        font-size 1.25rem
        text-transform uppercase

      .subtitle
        font-size 0.875rem
        font font-opensans-bold
        margin-bottom 10px

      .posted
      .tags
      .description
        font font-opensans-regular
        font-size 0.75rem


      .description
        margin-bottom 25px


      .tags
        margin-bottom 10px

      &-status
        margin-top 21px

        h3
          font font-opensans-bold
          font-size 1.25rem
          text-transform uppercase
          text-align left
          letter-spacing 0px

          color #262626
          padding 10px 14px

          &.pending
          &.deleted
          &.blocked
            background: color-39 0% 0% no-repeat padding-box

          &.approved
            background: color-35 0% 0% no-repeat padding-box

        .actions
          display flex
          align-items center
          justify-content flex-end

          .custom-dropdown
            flex-basis 197px
            width auto
            margin-right 10px

            ::v-deep
              .dropdown-toggle
                border none

          .btn-common
            padding 8px 30px

          .bi-arrow-right
            font-size 1.8rem
            margin-left 10px

        .comment-container
          .comment-container__label
            font font-opensans-semibold
            margin 10px 0
            margin-top 20px
            font-size 13px

          #comment-textarea
            resize none
            overflow-y auto

          .save-button
            float right
            transform none !important
            border-width 0 !important

::v-deep
  .flag-inappropriate-menu
    padding-top 20px
    margin 0 auto

@media (min-width: 992px) {
  .container-detail-submission {
    .profile {
      &-right {
        font-size 1rem
      }
    }

    .attribute {
      flex-direction row

      &-left {
        flex-basis 600px
        margin auto

        .video-overlay {
          width 100%
          height 725px
          border-radius 4px
        }
      }

      &-right {
        flex 1
      }
    }
  }
}

</style>
<style lang="stylus">
@import "~@/core/styles/variables.styl"
#actions-submission-detail
  .btn-secondary.dropdown-toggle
    background-color color-6 !important

  .show > .btn-secondary.dropdown-toggle
    background-color color-50 !important
</style>
