const trackingActivityTypes = Object.freeze({
  login: 'Login',
  dashboardTab: 'Switch to Dashboard tab',
  sendPartnerMessage: 'Send partner message',
  completeActionItem: 'Complete action item',
  tasksTab: 'Switch to My Tasks tab',
  studentsTab: 'Switch to My Students tab',
  watchStudentVideo: 'Watch student video',
  changeStudentStatus: 'Change student video status',
  submissionsTab: 'Switch to Submissions tab',
  watchSubmissionVideo: 'Watch submission video',
  changeSubmissionStatus: 'Change submission video status'
});

export default trackingActivityTypes
