import {mapActions, mapGetters} from 'vuex';
import validateForms from "@/core/Mixins/validate-forms";

export const TasksMixins = {
  data() {
    return {}
  },
  props: {
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    }
  },
  mixins: [validateForms],
  mounted() {},
  computed: {
    ...mapGetters({
      tasks: 'ClassroomModule/tasksList',
      isLoadingTasks: 'ClassroomModule/isLoadingTasks',
    })
  },
  methods: {
    ...mapActions({
      createTask: 'ClassroomModule/createTask',
      updateTask: 'ClassroomModule/updateTask',
      getTasksList: 'ClassroomModule/getTasksList',
      setTaskVisibility: 'ClassroomModule/setTaskVisibility',
    }),
  },
  watch: {
    '$route.params.classroomId': {
      handler: function (classroomId) {
        if (typeof classroomId === 'string') {
          this.classroomId = parseInt(classroomId);
        } else {
          this.classroomId = classroomId;
        }
      },
      deep: true,
      immediate: true
    }
  },
};
