import * as labels from "./mutation-types";

export const
  mutations = {
    [labels.FETCH_COURSE_LIST_START](state) {
      state.loadingCourses = true
    },
    [labels.FETCH_COURSE_LIST_DATA](state, courses) {
      state.courses = courses
      state.loadingCourses = false
    },
  }
