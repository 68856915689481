import {mapActions, mapGetters} from 'vuex';

export const UtilsMixins = {
  data () {
    return {
      loadingMiscData: true
    }
  },
  computed: {
    ...mapGetters({
      classrooms: 'MyClassroomsModule/getMyClassrooms',
      courses: 'CourseModule/getCourses',
      user: 'UserModule/getUser',
      payment_methods: 'MiscModule/getPaymentMethods',
      levels: 'MiscModule/getLevels',
      subjects: 'MiscModule/getSubjects',
      languages: 'MiscModule/getLanguages',
      age_ranges: 'MiscModule/getAgeRanges',
      number_students_ranges: 'MiscModule/getNumberStudentsRanges',
      bundles: 'MiscModule/getBundles',
      topics: 'MiscModule/getTopics',
      loadingProfile: 'UserModule/isLoadingProfile',
      isPlayersTrustClassroom: 'MiscModule/isPlayersTrustClassroom',
    })
  },
  methods: {
    ...mapActions({
      getClassrooms: 'MyClassroomsModule/getClassrooms',
      getCourses: 'CourseModule/fetchCourses',
      getProfile: 'UserModule/getProfile',
      getMiscData: 'MiscModule/fetchMiscData',
      getPaymentMethods: 'MiscModule/fetchPaymentMethods',
      getLevels: 'MiscModule/fetchLevels',
      getSubjects: 'MiscModule/fetchSubjects',
      getLanguages: 'MiscModule/fetchLanguages',
      getAgeRanges: 'MiscModule/fetchAgeRanges',
      getNumberStudentsRanges: 'MiscModule/fetchNumberStudentsRanges',
      getBundles: 'MiscModule/fetchBundles',
      getTopics: 'MiscModule/fetchTopics',
      setPlayersTrust: 'MiscModule/setPlayersTrust',
    })
  }
};
