<template>
  <div @click="onRow" :class="classTableRow">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TeacherTableRow",
  props: {
    rowHeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classTableRow() {
      const className = {
        'table-row': true
      }

      className['header'] =  this.rowHeader

      return className
    }
  },
  methods:{
    onRow(){
      this.$emit('on-table-row')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.table-row
  &.header
    background-color rgba(color-27, 0.27)
    margin-bottom 3px
    border 0
</style>
