<template>
  <div :class="['table-body', tableClassContent]">
    <div :class="czzClassTableView">
      <teacher-table-row v-if="!tableIsGrid && hasHeaderSlot" :row-header="true">
        <slot name="tableHeader"/>
      </teacher-table-row>
    </div>
    <draggable v-model="rowsList"
                tag="tbody"
                :handle="handle"
                :class="czzClassTableView"
                @change="$emit('update-list-order', rowsList)">
      <teacher-table-row @on-table-row="onclickRow(index, row)" :key="'table-row-' + index" v-for="(row, index) in rows">
        <slot name="tableRowListDraggable" v-if="!tableIsGrid" :row="row"/>
        <slot name="tableRowGridDraggable" v-else :row="row"/>
      </teacher-table-row>
    </draggable>
    <b-overlay no-wrap :show="loadingTeacherTable"/>
  </div>
</template>

<script>
import TeacherTableRow from "./TeacherTableRow";
import draggable from "vuedraggable";

export default {
  name: "TeacherTableDraggable",
  components: {
    TeacherTableRow,
    draggable
  },
  props: {
    view: {
      type: String,
      default:'grid'
    },
    tableClassContent: {
      type: String,
      default: null
    },
    rows: {
      type: Array,
      default: () => []
    },
    loadingTeacherTable: {
      type: Boolean,
      default: false
    },
    handle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      rowsList: []
    }
  },
  computed: {
    czzClassTableView() {
      const czzClasses = {
        'table-body-view': true
      }

      if (this.view) {
        czzClasses[this.view] = true
      }

      return czzClasses
    },
    tableIsGrid() {
      return this.view ? this.view === 'grid' : false;
    },
    hasHeaderSlot() {
      return !!this.$slots.tableHeader
    }
  },
  methods:{
    onclickRow(index, row){
      this.$emit('on-table-row', {index, row})
    }
  },
  watch: {
    rows(newValue) {
      this.rowsList = newValue
    }
  }
}
</script>

<style lang="stylus" scoped>
.table-body
  &-view
    display grid
    grid-template-columns 1fr
    width 100%
    position relative

    &.list
      grid-template-columns 1fr

  @media (min-width: 992px)
    &-view
      &.grid
        grid-template-columns 1fr 1fr
</style>
