<template>
  <fragment>
    <div class="btn-collapse-general-information custom-dropdown" v-b-toggle.collapse-general-information>
      <span class="dropdown-toggle">General information <span class="custom-dropdown-button-icon icon-dropdown"></span></span>
    </div>
    <b-collapse :visible="isCollapse" class="collapse-general-information-content" id="collapse-general-information">
      <b-card>
        <vc-donut
          background="white" foreground="grey"
          :size="150" unit="px" :thickness="20"
          has-legend legend-placement="right"
          :sections="sections" :total="100"
          :start-angle="0" :auto-adjust-text-size="true">
          <div class="graph-text">
            <span>{{ progressPercent }}%</span> <span>completed</span>
          </div>
        </vc-donut>
        <div class="wrapper-list">
          <ul class="list-information" :class="{'list-information--library': isLibrarian}">
            <li class="classroom-name-label">{{ classroom.name }}</li>
            <li v-if="!isWorldLanguages">Language: {{ classroom.lang_instruction && classroom.lang_instruction.name }}</li>
            <li v-if="isWorldLanguages">Primary Language: {{ classroom.lang_native && classroom.lang_native.name }}</li>
            <li v-if="isWorldLanguages">Language Taught: {{ classroom.lang_instruction && classroom.lang_instruction.name }}</li>
            <li v-if="!isWorldLanguages">Student Age Range: {{ classroom.age_range.name }}</li>
            <li v-else>{{ classroomLevel }}</li>
            <li>Subject: {{ classroom.subject.name }}</li>
            <li>Start: {{ classroom.start_date | dateMonthYear }}</li>
            <li>End: {{ classroom.end_date | dateMonthYear }}</li>
          </ul>
          <ul class="list-information list-information--librarian-names" v-if="isLibrarian">
            <li v-if="isLibrarian">Teacher: {{ teacherAssistantsName() }}</li>
            <li v-if="isLibrarian">Librarian: {{ classroom.teacher && classroom.teacher.full_name }}</li>
          </ul>
        </div>
        <section class="container-buttons">
          <b-button :disabled="classroomExchangeExpired" @click="goToClassroomEditPage(classroom.id)" btn-text="Edit Classroom"/>
          <add-teacher-button v-if="isLibrarian" :classroom="classroom"/>
        </section>
      </b-card>
    </b-collapse>
  </fragment>
</template>

<script>
import {Button} from "@/core/components/Button";
import { ExchangesMixins } from "@/modules/MyClassrooms/Mixins"
import { userTypeMixins } from "@/core/Mixins"
import ClassroomDetailTeacherAddNew from '@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabsGroup/ClassroomDetailTabMyStudents/ClassroomDetailTeacherAddNew'

export default {
  name: "ClassroomDetailInformation",
  mixins: [ExchangesMixins, userTypeMixins],
  components: {
    'b-button': Button,
    'add-teacher-button': ClassroomDetailTeacherAddNew
  },
  props: {
    classroom: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
  },
  methods: {
    teacherAssistantsName() {
      return this.classroom?.teacher_assistants[0]?.full_name
    }
  },
  computed: {
    classroomAgeRange() {
      if (!this.classroom.age_range) {
        return 'not set'
      }

      return this.classroom.age_range.name
    },
    classroomLevel() {
      if (!this.classroom.level) {
        return 'Level: not set'
      }

      return this.classroom.level.name
    },
    isWorldLanguages() {
      return this.classroom.subject.type === 'world_languages'
    },
    sections: function () {
      let sections = [
        {label: 'To-do', value: 100, color: '#C5C7FE'},
        {label: 'Submitted', value: 0, color: '#FCD68F'},
        {label: 'Approved', value: 0, color: '#CBE2A3'},
        {label: 'Blocked', value: 0, color: '#DBDBEC'}
      ]

      if (this.classroom.progression) {
        const todoCount = this.classroom.progression.submissions["to-do"] + this.classroom.progression.responses["to-do"]
        const submittedCount = this.classroom.progression.submissions["submitted"] + this.classroom.progression.responses["submitted"]
        const approvedCount = this.classroom.progression.submissions["approved"] + this.classroom.progression.responses["approved"]
        const blockedCount = this.classroom.progression.submissions["blocked"] + this.classroom.progression.responses["blocked"]
        const totalCount = todoCount + submittedCount + approvedCount + blockedCount

        sections[1].value = submittedCount * 100 / totalCount
        sections[2].value = approvedCount * 100 / totalCount
        sections[3].value = blockedCount * 100 / totalCount
        sections[0].value = (100 - (sections[1].value + sections[2].value + sections[3].value))

        sections[0].value = isNaN(sections[0].value) ? 0 : sections[0].value
        sections[1].value = isNaN(sections[1].value) ? 0 : sections[1].value
        sections[2].value = isNaN(sections[2].value) ? 0 : sections[2].value
        sections[3].value = isNaN(sections[3].value) ? 0 : sections[3].value
      }

      return sections
    },
    progressPercent() {
      if (this.classroom.progression) {
        return this.classroom.progression.total_percent
      }

      return 0
    }
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.btn-collapse-general-information
  height auto

  .dropdown-toggle
    border 2px solid color-16 !important;
    border-left 0 !important
    border-right 0 !important
    font font-opensans-bold
    border-radius 0
    line-height 16px
    padding 19px 8px 19px 20px
    justify-content space-between

    &:after
      display none

    ::v-deep
      .custom-dropdown-button-icon
        height 30px
        width 30px
        transform rotate(90deg)
        margin-top 8px

        &:before
          font-size 30px

  &.not-collapsed
    &:after
      display none

    ::v-deep
      .custom-dropdown-button-icon
        height 30px
        width 30px
        transform rotate(0deg)
        margin-top 8px

.collapse-general-information-content
  .card
    border-radius 0
    border 0
    border-bottom 2px solid color-16 !important;

  .graph-text
    display flex
    flex-direction column

    span
      font font-opensans-bold
      font-size 12px
      letter-spacing 0.6px

  ::v-deep
    .btn
      letter-spacing 0

    .btn-common
      padding 4px 12px
      border-width 2px
      border-color color-3
      font-size 10.5px

    .cdc-legend
      display grid
      grid-template-columns 1fr 1fr

      span
        font font-opensans-bold
        font-size 9px
        letter-spacing 0.4px

    .cdc-legend-item-color
      height 20px
      width 20px
      border 1px solid #3D3D3D
      border-radius 4px


  @media (min-width: 768px)
    ::v-deep
      .btn-common
        line-height 17px
        padding 6px 20px
        font-size 12px

  @media (min-width: 1200px)
    .card
      padding 0 130px

    ::v-deep
      .btn-common
        line-height 19px
        padding 8px 30px
        font-size 14px

  .wrapper-list
    display flex
    @media (max-width: 400px)
      flex-direction column

  .list-information
    width 100%
    list-style none
    flex-wrap wrap
    display flex
    padding 0
    margin-bottom 20px
    margin-top 40px

    & > li
      border-bottom 1px solid #fff
      flex-grow 1
      flex-shrink 0
      flex-basis 33.33%
      padding 0
      font: normal normal normal 14px / 30px Open Sans;
      letter-spacing: 0;
      color: color-21;
      text-transform: capitalize;
      line-height 1.8em

    .classroom-name-label
      text-overflow ellipsis
      white-space nowrap
      overflow hidden

  .list-information--library
    width 80%
    display grid
    grid-template-columns repeat(3, 1fr)
    @media (max-width: 400px)
      margin-bottom 0
    @media (max-width 991px)
      grid-template-columns repeat(2, 1fr)
    @media (max-width 500px)
      grid-template-columns repeat(1, 1fr)

  .list-information--librarian-names
    flex-direction column
    width unset
    @media (max-width: 400px)
      margin-top 0

    & > li
      flex-grow unset

  .container-buttons
    width 100%
    position relative

    ::v-deep
      .add-teacher-button
        position absolute
        right 0
        top 0

  .common-checkbox-secondary
    margin-bottom 10px

  .common-btn--primary
    margin-top 25px
</style>
