import {http} from './http'

const resource = "/api/teacher/accounts";

export default {
  create(email, password, privacyPolicy, termsOfUse, playersTrust = null) {
    return http.post(`${resource}`, {
      email: email,
      password: password,
      privacy_policy_agree: privacyPolicy,
      terms_of_use_agree: termsOfUse,
      is_players: playersTrust
    });
  },
  verificationResend(email) {
    return http.post(`${resource}/verify-request`, {
      email: email,
    }).then(response => {
      return response
    });
  },
  verification(id, token) {
    return http.post(`${resource}/verify/${id}/${token}`).then(response => {
      return response
    });
  },
  passwordUpdate(password, passwordOld) {
    return http.post(`${resource}/password`, {
      passwordOld: passwordOld,
      password: password,
    }).then(response => {
      return response
    });
  },
  termsAndPrivacyPolicies() {
    return http.get(`api/teacher/privacy-policy-and-terms-of-use`).then(response => {
      return response
    });
  },
}
