<template>
  <div class="course-card" v-if="course.published">
    <div class="course-card__header">
      <div class="course-card__title">{{ course.name }}</div>
    </div>
    <div class="course-card__body">
      <div class="course-card__info">
        <div class="course-card__info-item" v-if="course.topic_id">
          Topic: {{ course.topic && course.topic.name }}
        </div>
        <div class="course-card__info-item">
          Number of Tasks: {{ course.tasks.length }}
        </div>
        <div class="course-card__info-item">
          Estimated Duration: {{ course.weeks }} <strong>weeks</strong>
        </div>
      </div>
      <div class="course-card__btns">
        <b-button :disabled="!course.pdf_url" @click="$emit('on-course-detail', course)" btn-text="SEE DETAILS"
                  outline/>
        <b-button v-if="!noAddButton" @click="$emit('on-add-course-content', course)" :btn-text="isLibrarian ? 'SELECT COURSE' : 'ADD TO CLASSROOM'"
                  outline/>
      </div>
    </div>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button";

export default {
  name: "CourseCard",
  components: {
    'b-button': Button,
  },
  data() {
    return {}
  },
  props: {
    course: {
      type: Object,
      required: true
    },
    noAddButton: {
      type: Boolean,
      default: false
    },
    isLibrarian: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.security-form-subtitle--normal
  font font-opensans-regular
  font-size 20px
  margin-bottom 30px !important

.modal-title
  margin-top 80px

.container-courses
  position relative

.course-card__title
  overflow hidden
  width 250px
  text-overflow ellipsis

@media (min-width: 1710px)
  ::v-deep
    .btn-common
      min-width 146px
      margin-bottom 11px
      padding 6px
      font font-opensans-bold
      letter-spacing 0
      font-size 14px
      line-height 18px

@media (min-width: 576px)
  ::v-deep
    .btn-common
      min-width 146px
      margin-bottom 11px
      padding 6px
      font font-opensans-bold
      letter-spacing 0
      font-size 11px
      line-height 18px

@media (max-width: 575px)
  .course-card__btns
    flex-direction column

    button
      margin-right 0 !important
      margin-bottom 10px
      width 100%
</style>
