import {CoursesService} from '@/core/Services'
import * as labels from "./mutation-types";

export const actions = {
  fetchCourses({commit}, filters = {}) {
    commit(labels.FETCH_COURSE_LIST_START)

    return CoursesService.list(filters).then(response => {
      const data = response.data;

      commit(labels.FETCH_COURSE_LIST_DATA, data)

      return response
    })
  },
}

