export const UploadVideo = {
  data () {
    return {
      uploadingVideo: false,
      uploadedVideo: false,
      uploadedFile: {}
    }
  },
  beforeRouteLeave (to, from, next) {
    // If the system is uploading a video
    if (this.confirmStayInDirtyForm()) {
      next(false)
    } else {
      // Navigate to next view
      next()
    }
  },
  created () {
    window.addEventListener('beforeunload', this.beforeWindowUnload)

    this.$on('on-uploading-video-start', () => {
      this.uploadingVideo = true
      this.uploadedVideo = false
    })

    this.$on('on-uploading-video-success', () => {
      this.uploadingVideo = false
      this.uploadedVideo = true
    })
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  methods: {
    confirmLeave () {
      return window.confirm('Do you really want to leave? Your file will not be uploaded and you will need to start over.')
    },
    confirmStayInDirtyForm () {
      return this.uploadingVideo && !this.uploadedVideo && !this.confirmLeave()
    },
    beforeWindowUnload (e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    }
  }
}
