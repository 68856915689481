import {mapActions, mapGetters} from 'vuex';

export const UserMixins = {
  computed: {
    ...mapGetters({
      isLoadingProfile: 'UserModule/isLoadingProfile',
      getReadNotification: 'UserModule/getReadNotification',
    }),
  },
  methods: {
    ...mapActions({
      getProfile: 'UserModule/getProfile',
      readNotification: 'UserModule/readNotification',
    })
  },
};
