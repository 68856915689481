<template>
    <modal id="masquerade-modal"
           modal-class="classroom_crated_classroom_modal"
           ref="masqueradeModal"
           hideFooter>
      <template #body>
        <a :href="urlMasqueradeStudent"
           target="_blank"
           class="masquerade-link"
           @click="$bvModal.hide('masquerade-modal')">{{urlMasqueradeStudent}}</a>
      </template>
    </modal>
</template>

<script>
import {MiscService} from "@/core/Services"
import {AppStorage} from "@/core/Utils"
import {Modal} from "@/core/components/Modal"
import {CourseMixins} from "@/modules/Classroom/Mixins"

export default {
  name: "MasqueradeWholeClass",
  mixins: [CourseMixins],
  components: {
    Modal
    },
  props: {
    classroom: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    const iosDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
    return {
      urlMasqueradeStudent: '',
      iosDevice: iosDevice,
    }
  },
  methods: {
    onMasquerade ({classroomId, submissionId, taskId, trigger}) {
      MiscService.masqueradeWholeClass(classroomId).then(({data}) => {
        AppStorage.setItem('student-token', data.token)

        this.urlMasqueradeStudent = `${process.env.VUE_APP_BASE_URL_STUDENT_PORTAL}/#/login-as-student?`
        this.urlMasqueradeStudent += `teacher_token=${AppStorage.getUser().token}`
        this.urlMasqueradeStudent += `&student_token=${data.token}`
        this.urlMasqueradeStudent += `&user_type=${AppStorage.getUser().user_type?.slug}`
        classroomId && (this.urlMasqueradeStudent += `&classroom_id=${classroomId}`)
        submissionId && (this.urlMasqueradeStudent += `&submission_id=${submissionId}`)
        taskId && (this.urlMasqueradeStudent += `&task_id=${taskId}`)
        trigger && (this.urlMasqueradeStudent += `&trigger=${trigger}`)

        if (this.iosDevice) {
          this.$refs.masqueradeModal.showModal()
          return
        }

        const link = document.createElement('a')

        link.href = this.urlMasqueradeStudent
        link.target = '_blank'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }
}
</script>

<style lang="stylus">
#masquerade-modal
  .body
    padding-bottom 20px

    .masquerade-link
      word-wrap break-word
      text-decoration underline
</style>
