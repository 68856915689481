import {AuthService} from "@/core/Services";

export default {
  methods: {
    goToLoginPage(query) {
      return this.$router.push({name: 'LoginPage', query: query ?? {} })
    },
    goToLogoutPage(query) {
      AuthService.logout()
      return this.$router.push({name: 'LoginPage', query: query ?? {} })
    },
    goToCreateAccountPage() {
      return this.$router.push({name: 'CreateAccountPage'})
    },
    goToVerificationAccountPage() {
      return this.$router.push({name: 'VerificationAccountPage'})
    },
    goToRecoverPasswordPage() {
      return this.$router.push({name: 'RecoverPasswordPage'})
    },
    goToForgotPasswordPage() {
      return this.$router.push({name: 'ForgotPasswordPage'})
    },
    goToMyClassroomsPage() {
      return this.$router.push({name: 'MyClassroomsPage'})
    },
    goToCreateClassroomPage() {
      return this.$router.push({name: 'CreateClassroomPage'})
    },
    goToChooseCourseContentPage(classroomId) {
      return this.$router.push({name: 'ChooseCourseContentPage', params: {classroomId: classroomId}})
    },
    goToClassroomEditPage(classroomId) {
      return this.$router.push({name: 'EditClassroomPage', params: {classroomId: classroomId}})
    },
    goToClassroomTaskDetail(classroomId, teacherActionId) {
      return this.$router.push({name: 'ClassroomTaskDetail', params: {classroomId, teacherActionId}})
    },
    goToClassroomSubmissionDetail(classroomId, teacherActionId) {
      return this.$router.push({name: 'ClassroomSubmissionDetail', params: {classroomId, teacherActionId}})
    },
    goToRecordUpload(classroomId, taskId) {
      return this.$router.push({name: 'UploadOrRecordPage', params: {classroomId: classroomId, taskId: taskId}})
    },
    goToClassroomPage(classroomId, query = {}) {
      return this.$router.push({name: 'ClassroomPage', params: {classroomId: classroomId}, query: query})
    },
    goToTeacherProfilePage() {
      return this.$router.push({name: 'TeacherProfilePage'})
    },
    goToProfileWizardPage() {
      return this.$router.push({name: 'ProfileWizardPage'})
    },
    goToNotificationsPage() {
      return this.$router.push({name: 'NotificationsPage'}).catch(()=>{});
    },
    goToPartnerConnectionsPage(invitation_id = null) {
      return this.$router.push({name: 'PartnerConnectionsPage',params: {invitation_id: invitation_id}});
    },
    goToPartnerCreateClassroomPage(invitation_id) {
      return this.$router.push({name: 'PartnerCreateClassroomPage', params: {invitation_id: invitation_id}})
    },
    goToAccountChangePasswordPage() {
      return this.$router.push({name: 'AccountChangePassword'});
    },
    goToClassroomDetailPage(classroomId, query) {
      return this.$router.push({name: 'ClassroomPage', params: {classroomId}, query: query ?? {}});
    },
    goToLibraryConnectionsPage() {
      return this.$router.push({name: 'LibraryConnectionsPage' });
    },
  }
}
